import { useState } from 'react';
import L from 'leaflet';
import { MapContainer, Marker, TileLayer, useMapEvents } from 'react-leaflet';
import { useMediaQuery } from 'react-responsive';
import cn from 'classnames';

import { desktopQuery } from '../../consts';
import { getHouseImageUrl } from '../../utilities';
import { Modal, Flex, Button, IconButton, Text, Input, Select } from '../../ui';
import { ArrowIcon, CloseIcon, MapPointWaveIcon } from '../../static/icons';
import styles from './EditHouseProfileModal.module.scss';
import styleMap from './map.module.scss';
import { useTranslation } from 'react-i18next';
import { getHouseValidationSchema } from '../../components/validationSchema';

const DraggableMarker = ({
  position,
  setPosition,
  customMarkerIcon,
  mapZoom,
  setMapZoom,
}) => {
  const map = useMapEvents({
    click(e) {
      setPosition([e.latlng.lat, e.latlng.lng]);
      setMapZoom(map.getZoom());
    },
  });

  return position === null ? null : (
    <Marker position={position} icon={customMarkerIcon} />
  );
};

const MapInfo = ({ name, coordinates, t }) => (
  <div className={styleMap.map_info}>
    <div className={styleMap.place}>
      <span>{t('location')}:</span>
      <p>{name}</p>
    </div>
    <div className={styleMap.coordination}>
      <span>{t('coordinates')}:</span>
      <p>{coordinates ? `${coordinates[1]}, ${coordinates[0]}` : ''}</p>
    </div>
  </div>
);

export const EditHouseProfileModal = (props) => {
  const [mapZoom, setMapZoom] = useState(13);
  const { t } = useTranslation();

  const {
    closeModal,
    house,
    selectedPhoto,
    handleHousePhotoUpload,
    editedHouse,
    setEditedHouse,
    saveHouse,
    deleteHouse,
  } = props;

  const [mapExpanded, setMapExpanded] = useState(true);
  const [markerPosition, setMarkerPosition] = useState(
    house.mapLocationCoordinates?.coordinates
      ? [
          house.mapLocationCoordinates.coordinates[1],
          house.mapLocationCoordinates.coordinates[0],
        ]
      : null
  );

  const customMarkerIcon = L.icon({
    iconUrl: '/marker/marker-icon.png',
    shadowUrl: '/marker/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });
  const [errors, setErrors] = useState({});

  const validateField = async (name, value) => {
    try {
      await getHouseValidationSchema(t).validateAt(name, {
        ...{
          ...editedHouse,
        },
        [name]: value,
      });
      setErrors((prev) => ({ ...prev, [name]: '' }));
    } catch (error) {
      setErrors((prev) => ({ ...prev, [name]: error.message }));
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  const isDesktop = useMediaQuery(desktopQuery);

  const handleMarkerPositionChange = (position) => {
    setMarkerPosition(position);
    setEditedHouse({
      ...editedHouse,
      mapLocationCoordinates: {
        coordinates: [position[1], position[0]],
      },
    });
  };

  const handleFormErrors = () => {
    let hasError = false;
    Object.keys(errors).forEach((key) => {
      if (errors[key] !== '' && Object.keys(editedHouse).includes(key)) {
        hasError = true;
      }
    });
    return hasError;
  };

  return (
    <Modal className={styles.Modal} closeModal={closeModal}>
      <Flex direction='column' gap={30}>
        <Flex justify='between' align='baseline'>
          <Text size={20} lineHeight='25px' weight='bold'>
            {t('edit_house_profile')}
          </Text>
          <IconButton type='transparent' size='m' onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </Flex>

        <label className={styles.labelImageInput}>
          <img
            className={styles.houseImage}
            src={selectedPhoto ? selectedPhoto : getHouseImageUrl(house.image)}
            alt='Изображение дома'
          />
          <input
            type='file'
            onChange={handleHousePhotoUpload}
            accept='image/*'
            className={styles.displayNone}
          />
          <Flex
            className={styles.houseImageCoverText}
            justify='center'
            align='center'
          >
            <Text size={14} lineHeight='14px' color='white' weight='medium'>
              {t('change_house_photo')}
            </Text>
          </Flex>
        </label>

        <Flex direction='column' gap={16}>
          <Input
            label={`${t('house_name')}:`}
            name='name'
            value={editedHouse.name}
            onChange={(value) =>
              setEditedHouse({
                ...editedHouse,
                name: value,
              })
            }
            onBlur={handleBlur}
          />
          {errors.name && (
            <div className={styles.errorMessage}>{errors.name}</div>
          )}

          <Input
            label={`${t('squares')}`}
            name='squareMeters'
            value={editedHouse.squareMeters}
            onChange={(value) =>
              setEditedHouse({
                ...editedHouse,
                squareMeters: value,
              })
            }
            onBlur={handleBlur}
          />
          {errors.squareMeters && (
            <div className={styles.errorMessage}>{errors.squareMeters}</div>
          )}

          <Input
            label={`${t('address')}:`}
            name='address'
            value={editedHouse.address}
            onChange={(value) =>
              setEditedHouse({
                ...editedHouse,
                address: value,
              })
            }
            onBlur={handleBlur}
          />
          {errors.address && (
            <div className={styles.errorMessage}>{errors.address}</div>
          )}

          <Select
            label={`${t('house_type')}:`}
            name='housingType'
            value={editedHouse.housingType}
            onChange={(value) =>
              setEditedHouse({
                ...editedHouse,
                housingType: value,
              })
            }
            options={[
              { value: 'House', label: t('house') },
              { value: 'Apartment', label: t('apartment') },
            ]}
            onBlur={handleBlur}
          />

          {editedHouse.housingType === 'Apartment' && (
            <>
              <Input
                label={`${t('floor')}:`}
                name='floor'
                value={editedHouse.floor}
                onChange={(value) =>
                  setEditedHouse({
                    ...editedHouse,
                    floor: value,
                  })
                }
                onBlur={handleBlur}
              />

              {errors.floor && (
                <div className={styles.errorMessage}>{errors.floor}</div>
              )}

              <Input
                label={`${t('apartment_number')}:`}
                name='apartmentNumber'
                value={editedHouse.apartmentNumber}
                onChange={(value) =>
                  setEditedHouse({
                    ...editedHouse,
                    apartmentNumber: value,
                  })
                }
                onBlur={handleBlur}
              />
              {errors.apartmentNumber && (
                <div className={styles.errorMessage}>
                  {errors.apartmentNumber}
                </div>
              )}
            </>
          )}

          <Flex
            gap={5}
            onClick={() => setMapExpanded((e) => !e)}
            className={cn(styles.expandable, styles.expandableMapBar)}
          >
            <MapPointWaveIcon />
            <Text size={15} weight='semiBold' className={styles.growable}>
              {t('show_on_map')}
            </Text>
            <ArrowIcon
              className={cn(styles.expandableArrow, {
                [styles.expandableArrowExpanded]: mapExpanded,
              })}
            />
          </Flex>

          {mapExpanded && (
            <div className='house-map'>
              <MapContainer
                key={markerPosition?.join(',')}
                center={markerPosition || [0, 0]}
                zoom={mapZoom}
                className={styleMap.map}
              >
                <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
                <DraggableMarker
                  position={markerPosition}
                  setPosition={handleMarkerPositionChange}
                  customMarkerIcon={customMarkerIcon}
                  mapZoom={mapZoom}
                  setMapZoom={setMapZoom}
                />
              </MapContainer>
              <MapInfo
                name={editedHouse.name}
                coordinates={markerPosition}
                t={t}
              />
            </div>
          )}
        </Flex>

        <Flex justify='between'>
          <Button
            type='black'
            onClick={async (e) => {
              try {
                await getHouseValidationSchema(t).validate(editedHouse, {
                  abortEarly: false,
                });
              } catch (error) {
                if (error.name === 'ValidationError') {
                  const newErrors = {};
                  error.inner.forEach((err) => {
                    newErrors[err.path] = err.message;
                  });
                  setErrors(newErrors);
                }
              }

              if (handleFormErrors()) {
                return;
              }

              saveHouse(e);
            }}
          >
            {t('save')}
          </Button>
          <Button type='text' onClick={deleteHouse}>
            <Text size={14} weight='bold'>
              {t('delete_profile')}
            </Text>
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
