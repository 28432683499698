import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  // Изначально выбран английский язык
  const [selectedLanguage, setSelectedLanguage] = useState({
    code: 'en',
    name: 'ENG',
    flag: '/img/flag-en.png',
    languageName: 'English',
  });

  const languages = [
    {
      code: 'en',
      name: 'ENG',
      flag: '/img/flag-en.png',
      languageName: 'English',
    },
    {
      code: 'ru',
      name: 'RUS',
      flag: '/img/flag-ru.png',
      languageName: 'Русский',
    },
    { code: 'th', name: 'THAI', flag: '/img/flag-th.png', languageName: 'ไทย' }, // Тайский язык на тайском
    {
      code: 'my',
      name: 'BAHASA',
      flag: '/img/flag-my.png',
      languageName: 'Bahasa',
    },
    {
      code: 'my',
      name: 'BAHASA',
      flag: '/img/flag-ind.png',
      languageName: 'Bahasa',
    },
  ];

  const changeLanguage = (language) => {
    console.log('Selected language:', language);
    i18n.changeLanguage(language.code);
    setSelectedLanguage(language);
    setIsOpen(false);
    localStorage.setItem('preferredLanguage', language.code);
    console.log('Saved language to localStorage:', language.code);
  };

  return (
    <div>
      <div
        onClick={() => setIsOpen(!isOpen)}
        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
      >
        <img
          src={selectedLanguage.flag}
          alt={selectedLanguage.languageName}
          style={{ width: '30px', marginRight: '5px' }}
        />
        {selectedLanguage.languageName}
      </div>
      {isOpen && (
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'white',
            border: '1px solid #ccc',
          }}
        >
          {languages.map((lang) => (
            <div
              key={lang.code}
              onClick={() => changeLanguage(lang)}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                padding: '5px',
              }}
            >
              <img
                src={lang.flag}
                alt={lang.name}
                style={{ width: '20px', marginRight: '5px' }}
              />
              {lang.languageName}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;