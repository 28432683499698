import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import withAuth from './withAuth';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import { tokenService } from './services/tokenService';

// Импортируем все компоненты страниц
import HomePage from './pages/HomePage';
import ClientPage from './pages/ClientPage';
import WorkerPage from './pages/WorkerPage';
import ClientRegister from './components/ClientRegister';
import WorkerRegister from './components/admin/WorkerRegister';
import HousePage from './pages/HousePage';
import AddHouse from './components/AddHouse';
import NotFoundPage from './pages/NotFoundPage';
import SubscriptionPage from './pages/SubscriptionPlans';
import ManageSubscriptionsPage from './components/ManageSubscriptionsPage';
import Login from './components/Login';
import PaymentForm from './components/PaymentForm';
import SuccessPayment from './components/SuccessPayment';
import AdminDash from './pages/AdminDash';
import WorkerDetails from './components/admin/WorkerDetails';
import WorkersList from './components/admin/WorkersList';

import ClientsDetails from './components/admin/ClientsDetails';
import SubscriptionsManage from './components/admin/SubscriptionsManage';
import SalesManage from './components/admin/SalesManage';
import SubscriptionsCountList from './components/admin/SubscriptionsCountList';

import WorkerAssignedHousesMap from './components/admin/WorkerAssignedHousesMap';
import ProtectedRoute from './components/ProtectedRoute';

import WorkerLogin from './components/admin/WorkerLogin';

import WorkerJob from './components/admin/WorkerJob';
import WorkerCalendar from './components/admin/WorkerCalendar';

// Оборачиваем страницы в HOC для проверки авторизации
const ClientPageWithAuth = withAuth(ClientPage);

const WorkerPageWithAuth = withAuth(WorkerPage);
const AdminDashWithAuth = withAuth(AdminDash);

const HousePageWithAuth = withAuth(HousePage);
const AddHouseWithAuth = withAuth(AddHouse);
const SubscriptionPageWithAuth = withAuth(SubscriptionPage);
const ManageSubscriptionsPageWithAuth = withAuth(ManageSubscriptionsPage);
const PaymentFormWithAuth = withAuth(PaymentForm);
const SuccessPaymentWithAuth = withAuth(SuccessPayment);

const WorkerListWithAuth = withAuth(WorkersList);
const WorkerDetailsWithAuth = withAuth(WorkerDetails);

const ClientsDetailsWithAuth = withAuth(ClientsDetails);
const SubscriptionsManageWithAuth = withAuth(SubscriptionsManage);
const SalesManageWithAuth = withAuth(SubscriptionsCountList);

const WorkerAssignedHousesMapWithAuth = withAuth(WorkerAssignedHousesMap);

const WorkerJobWithAuth = withAuth(WorkerJob);
const WorkerCalendarWithAuth = withAuth(WorkerCalendar);

i18n
  .use(initReactI18next) // Подключаем модуль initReactI18next к i18next
  .use(HttpApi) // Подключаем модуль HttpApi для загрузки переводов
  .init({
    fallbackLng: 'en', // Язык по умолчанию
    lng: 'en', // Текущий язык
    backend: {
      loadPath: '/locales/{{lng}}/translation.json', // Путь к файлам переводов
    },
    interpolation: {
      escapeValue: false, // Не экранируем значения
    },
  });

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = tokenService.getToken();
    setIsAuthenticated(!!token);
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          path='/'
          element={isAuthenticated ? <HomePage /> : <HomePage />}
        />
        <Route path='/client-register' element={<ClientRegister />} />
        {/* <Route path='/login' element={<Login />} /> */}
        <Route path='/client-page' element={<ClientPageWithAuth />} />

        <Route path='/house/:houseId' element={<HousePageWithAuth />} />
        <Route path='/add-house' element={<AddHouseWithAuth />} />

        <Route path='/subscription' element={<SubscriptionPageWithAuth />} />
        <Route
          path='/manage-subscriptions'
          element={<ManageSubscriptionsPageWithAuth />}
        />

        <Route path='/payment' element={<PaymentFormWithAuth />} />
        <Route path='/success' element={<SuccessPaymentWithAuth />} />

        <Route path='/worker-register' element={<WorkerRegister />} />
        <Route path='/worker-login' element={<WorkerLogin />} />
        <Route
          path='/worker-dash'
          element={
            <ProtectedRoute element={WorkerPageWithAuth} roles={['worker']} />
          }
        />
        <Route
          path='/worker-job'
          element={
            <ProtectedRoute element={WorkerJobWithAuth} roles={['worker']} />
          }
        />
        <Route
          path='/worker-calendar'
          element={
            <ProtectedRoute
              element={WorkerCalendarWithAuth}
              roles={['admin', 'worker']}
            />
          }
        />

        <Route path='/worker/:id' element={<WorkerPageWithAuth />} />
        <Route
          path='/worker/:id/assigned-houses-map'
          element={<WorkerAssignedHousesMapWithAuth />}
        />

        <Route
          path='/admin-dashboard/'
          element={
            <ProtectedRoute
              element={AdminDashWithAuth}
              roles={['admin', 'worker']}
            />
          }
        />

        <Route
          path='/admin-dashboard/worker-data/:workerId'
          element={<WorkerDetailsWithAuth />}
        />
        <Route
          path='/admin-dashboard/worker-list'
          element={<WorkerListWithAuth />}
        />
        <Route
          path='/admin-dashboard/client-data'
          element={<ClientsDetailsWithAuth />}
        />
        <Route
          path='/admin-dashboard/subs-manage'
          element={<SubscriptionsManageWithAuth />}
        />
        <Route
          path='/admin-dashboard/sales'
          element={<SalesManageWithAuth />}
        />

        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;
