import cn from 'classnames';

import styles from './Button.module.scss';

export const Button = (props) => {
  const {
    tag = "button",
    children,
    type,
    short = false,
    size,
    gap = "small",
    className,
    onClick,
    isDisabled = false,
    ...restProps
  } = props;

  const Component = tag;

  return (
    <Component 
      className={cn(styles.button, {
        [styles.buttonBlack]: type === "black",
        [styles.buttonGray]: type === "gray",
        [styles.buttonWhite]: type === "white",
        [styles.buttonUnderline]: type === "underline",
        [styles.buttonOutline]: type === "outline",
        [styles.buttonText]: type === "text",
        [styles.thin]: size === "thin",
        [styles.medium]: size === "medium",
        [styles.thick]: size === "thick",
        [styles.short]: short,
        [styles.gapSmall]: gap === "small",
        [styles.gapLarge]: gap === "large",
        [styles.disabled]: isDisabled,
      }, className)} 
      {...(!isDisabled && {onClick})}
      {...restProps}
    >
      {children}
    </Component>
  )
}