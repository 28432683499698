import React from 'react';
import styles from './WorkerPageBlock.module.scss';
import { Flex } from '../../ui';
import PenIcon from './../../static/icons/pen.svg';
import { useTranslation } from 'react-i18next';

const WorkerPageBlock = ({
  workerPhoto,
  worker,
  workerSkills,
  handleEditClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.workerInfoBox}>
      <Flex justify='between' align='start' className={styles.workerContent}>
        <Flex className={styles.workerContentBlock}>
          <div>
            <img
              src={workerPhoto}
              alt='Фото работника'
              className={styles.workerPhoto}
            />
          </div>
          <div>
            <p className={styles.info}>
              <span className={styles.label}>{t('name')}:</span> {worker.name}
            </p>
            <p className={styles.info}>
              <span className={styles.label}>{t('phone')}:</span> {worker.phone}
            </p>
            <p className={styles.info}>
              <span className={styles.label}>{t('preferred_language')}:</span>{' '}
              {worker.preferredLanguage}
            </p>
            <p className={styles.info}>
              <span className={styles.label}>{t('salary')}:</span>{' '}
              {worker.salary && worker.salary.$numberDecimal}
            </p>
            {workerSkills.map((skill) => (
              <p className={styles.info} key={skill._id}>
                <span className={styles.label}>{t('skill')}:</span>{' '}
                {skill.skillName}
              </p>
            ))}
          </div>
        </Flex>
        <button className={styles.editButton} onClick={handleEditClick}>
          <img src={PenIcon} alt='edit' />
          <span>{t('edit')}</span>
        </button>
      </Flex>
    </div>
  );
};

export default WorkerPageBlock;
