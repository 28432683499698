import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AdminPanel from './AdminPanel';
import styles from './ClientsDetails.module.css';
import { tokenService } from '../../services/tokenService';
import { FooterBlock } from '../../features/FooterBlock/FooterBlock';
import { Flex } from '../../ui';
import searchIcon from '../../static/icons/mingcute_search-line.svg';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../LanguageSwitcher';
import { useNavigate } from 'react-router-dom';
import AdminMobileLinks from '../../features_admin/AdminMobileLinks/AdminMobileLinks';
import { useMediaQuery } from 'react-responsive';
import { desktopQuery } from '../../consts';

const ClientsComponent = () => {
  const [clients, setClients] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showActiveSubscriptionsOnly, setShowActiveSubscriptionsOnly] =
    useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(desktopQuery);

  useEffect(() => {
    fetchClientsAndSubscriptions();
  }, []);

  const fetchClientsAndSubscriptions = async () => {
    const token = tokenService.getToken();
    try {
      const clientsResponse = await axios.get(
        'https://fix.house/api/users/clients',
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const subscriptionsResponse = await axios.get(
        'https://fix.house/api/subscriptions/all',
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (
        Array.isArray(clientsResponse.data) &&
        Array.isArray(subscriptionsResponse.data)
      ) {
        setClients(clientsResponse.data);
        setSubscriptions(subscriptionsResponse.data);
      } else {
        console.error(
          'Полученные данные не являются массивами:',
          clientsResponse.data,
          subscriptionsResponse.data
        );
      }
    } catch (error) {
      console.error('Ошибка при получении данных:', error);
    }
  };

  const countUserSubscriptions = (userId) => {
    return subscriptions.filter(
      (subscription) =>
        subscription.userID && subscription.userID._id === userId
    ).length;
  };

  const getClientSubscriptions = (userId) => {
    return subscriptions.filter(
      (subscription) =>
        subscription.userID && subscription.userID._id === userId
    );
  };

  const filteredClients = clients.filter((client) => {
    const matchesSearch =
      searchTerm === '' ||
      client.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      client.email.toLowerCase().includes(searchTerm.toLowerCase());
    const hasActiveSubscription =
      !showActiveSubscriptionsOnly ||
      getClientSubscriptions(client._id).length > 0;
    return matchesSearch && hasActiveSubscription;
  });

  const handleLogout = () => {
    tokenService.removeToken();
    navigate('/client-register');
  };

  return (
    <div>
      <AdminPanel handleLogout={handleLogout} />
      <LanguageSwitcher />
      <div className={styles.container}>
        <div className={styles.blockContent}>
          {!isDesktop && <AdminMobileLinks />}
          <h1 className={styles.nametotalClients}>
            {t('admin_clients_title')}
          </h1>
          <Flex
            justify='between'
            align='start'
            className={styles.optionsContainer}
          >
            <Flex gap={60} align='center' className={styles.optionsBlock}>
              <label className={styles.checkbox}>
                <span>{t('admin_clients_active_only')}</span>
                <input
                  type='checkbox'
                  checked={showActiveSubscriptionsOnly}
                  onChange={(e) =>
                    setShowActiveSubscriptionsOnly(e.target.checked)
                  }
                />
                <span className={styles.checkmark}></span>
              </label>

              <div className={styles.search}>
                <input
                  type='text'
                  placeholder={t('admin_clients_search')}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <img src={searchIcon} alt='search' />
              </div>
            </Flex>
            <p className={styles.totalClients}>
              {t('clients_total')}: <b>{filteredClients.length}</b>
            </p>
          </Flex>

          <div className={styles.tableContainer}>
            <table className={styles.clientsTable}>
              <thead>
                <tr>
                  <th>{t('name')}</th>
                  <th>{t('email')}</th>
                  <th>{t('registration_date')}</th>
                  <th>{t('phone')}</th>
                  <th>{t('messenger')}</th>
                  <th>{t('messenger_type')}</th>
                  <th>{t('language')}</th>
                  <th>{t('subscriptions_count')}</th>
                  <th>{t('type_name')}</th>
                  <th>{t('subscription_start')}</th>
                  <th>{t('subscription_end')}</th>
                </tr>
              </thead>
              <tbody>
                {filteredClients.map((client) =>
                  getClientSubscriptions(client._id).length > 0 ? (
                    getClientSubscriptions(client._id).map((sub, index) => (
                      <tr key={`${client._id}-${index}`}>
                        {index === 0 ? (
                          <td
                            rowSpan={getClientSubscriptions(client._id).length}
                          >
                            {client.name}
                          </td>
                        ) : null}
                        {index === 0 ? (
                          <td
                            rowSpan={getClientSubscriptions(client._id).length}
                          >
                            {client.email}
                          </td>
                        ) : null}
                        {index === 0 ? (
                          <td
                            rowSpan={getClientSubscriptions(client._id).length}
                          >
                            {new Date(client.registeredAt).toLocaleDateString()}
                          </td>
                        ) : null}
                        {index === 0 ? (
                          <td
                            rowSpan={getClientSubscriptions(client._id).length}
                          >
                            {client.phone ?? t('no_data')}
                          </td>
                        ) : null}
                        {index === 0 ? (
                          <td
                            rowSpan={getClientSubscriptions(client._id).length}
                          >
                            {client.messenger ?? t('no_data')}
                          </td>
                        ) : null}
                        {index === 0 ? (
                          <td
                            rowSpan={getClientSubscriptions(client._id).length}
                          >
                            {client.messengerType ?? t('no_data')}
                          </td>
                        ) : null}
                        {index === 0 ? (
                          <td
                            rowSpan={getClientSubscriptions(client._id).length}
                          >
                            {client.preferredLanguage}
                          </td>
                        ) : null}
                        {index === 0 ? (
                          <td
                            rowSpan={getClientSubscriptions(client._id).length}
                          >
                            {countUserSubscriptions(client._id)}
                          </td>
                        ) : null}
                        <td>{sub.subscriptionTypeID.typeName}</td>
                        <td>{new Date(sub.startDate).toLocaleDateString()}</td>
                        <td>
                          {new Date(sub.expiresDate).toLocaleDateString()}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr key={client._id}>
                      <td>{client.name}</td>
                      <td>{client.email}</td>
                      <td>
                        {client.registeredAt
                          ? new Date(client.registeredAt).toLocaleDateString()
                          : t('no_date')}
                      </td>
                      <td>{client.phone ?? t('no_data')}</td>
                      <td>{client.messenger ?? t('no_data')}</td>
                      <td>{client.messengerType ?? t('no_data')}</td>
                      <td>{client.preferredLanguage}</td>
                      <td>{countUserSubscriptions(client._id)}</td>
                      <td colSpan={3}>{t('no_active_subscriptions')}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <FooterBlock />
    </div>
  );
};

export default ClientsComponent;
