import React from 'react';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';

import { desktopQuery } from "../../consts";
import styles from './HomePageBackgroundBlock.module.scss';

export const HomePageBackgroundBlock = (props) => {
  const { className, subscriptions, children, ...restProps } = props;
  const isDesktop = useMediaQuery(desktopQuery);

  return (
    <div className={cn(styles.backgroundBlock, className)} {...restProps}>
      {isDesktop ? (
        children
      ) : 
      (
        children
      )}
    </div>
  )
}