import React, { useState, useEffect, useCallback } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import axios from 'axios';
import Select from 'react-select';
import { tokenService } from './../../services/tokenService';
import WorkerPanel from './WorkerPanel';
import { FooterBlock } from '../../features';
import styles from './WorkerCalendar.module.scss';
import { Flex, IconButton, Modal } from '../../ui';
import { CloseIcon } from '../../static';
import './CalendarStylesOverride.css'; // css module customization doesn't work
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../LanguageSwitcher';
import { useNavigate } from 'react-router-dom';
import WorkerMobileLinks from '../../features_worker/WorkerMobileLinks/WorkerMobileLinks';
import { useMediaQuery } from 'react-responsive';
import { desktopQuery } from '../../consts';

const WorkerCalendar = () => {
  const [events, setEvents] = useState([]);
  const [workers, setWorkers] = useState([]);
  const [selectedWorker, setSelectedWorker] = useState(null);
  const [workerId, setWorkerId] = useState(tokenService.getWorkerId());
  const [isManager, setIsManager] = useState(
    tokenService.getRole() === 'admin' || tokenService.getRole() === 'manager'
  );
  const [openEvent, setOpenEvent] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const isDesktop = useMediaQuery(desktopQuery);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const fetchEvents = useCallback(async () => {
    console.log('Начало fetchEvents, workerId:', workerId);
    try {
      const url = `https://fix.house/api/workers/${workerId}/calendar`;
      console.log('Отправка запроса на URL:', url);

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${tokenService.getToken()}`,
        },
      });
      console.log('Получен ответ:', response.data);
      setEvents(response.data);
    } catch (error) {
      console.error(
        'Ошибка при загрузке событий:',
        error.response ? error.response.data : error.message
      );
    }
  }, [workerId]);

  const fetchWorkers = async () => {
    if (isManager) {
      try {
        const response = await axios.get('https://fix.house/api/workers');
        setWorkers(
          response.data.map((worker) => ({
            value: worker._id,
            label: worker.name,
          }))
        );
      } catch (error) {
        console.error('Ошибка при загрузке списка работников:', error);
      }
    }
  };

  useEffect(() => {
    if (workerId) {
      fetchEvents();
    } else {
      console.error('workerId не установлен');
    }
    fetchWorkers();
  }, [fetchEvents, workerId, isManager]);

  const handleEventDrop = async (dropInfo) => {
    try {
      await axios.put(`https://fix.house/api/problems/${dropInfo.event.id}`, {
        scheduledDate: dropInfo.event.start,
      });
      fetchEvents();
    } catch (error) {
      console.error('Ошибка при обновлении даты события:', error);
      dropInfo.revert();
    }
  };

  const handleWorkerChange = (selectedOption) => {
    setSelectedWorker(selectedOption);
    if (selectedOption) {
      setWorkerId(selectedOption.value);
    }
  };

  const renderEventContent = (eventInfo) => {
    const { event } = eventInfo;
    const { extendedProps } = event;
    return (
      <div
        className={styles.eventContent}
        onClick={() => {
          setCurrentEvent({ ...event, ...extendedProps });
          handleOpenEvent();
        }}
      >
        <p>
          <strong>{event.title}</strong>
        </p>
        <p>
          {t('status')}: {extendedProps.status}
        </p>
        <p>
          {t('address')}: {extendedProps.houseAddress}
        </p>
        <p>
          {t('urgency_level')}: {extendedProps.urgencyLevel}
        </p>
        <p>
          {t('preferred_time')}:{' '}
          {extendedProps.preferredTime || t('not_specified')}
        </p>
        <p>
          {t('creation_date')}:{' '}
          {new Date(extendedProps.creationDate).toLocaleDateString()}
        </p>
        {extendedProps.startedDate && (
          <p>
            {t('start_date')}:{' '}
            {new Date(extendedProps.startedDate).toLocaleDateString()}
          </p>
        )}
        {extendedProps.finishedDate && (
          <p>
            {t('end_date')}:{' '}
            {new Date(extendedProps.finishedDate).toLocaleDateString()}
          </p>
        )}
        {extendedProps.rating && (
          <p>
            {t('rating')}: {extendedProps.rating.value}
          </p>
        )}
      </div>
    );
  };

  const handleOpenEvent = () => {
    setOpenEvent(true);
  };

  const handleCloseEvent = () => {
    setOpenEvent(false);
  };

  const handleLogout = () => {
    tokenService.removeToken();
    navigate('/client-register');
  };

  return (
    <div className='worker-calendar'>
      <WorkerPanel handleLogout={handleLogout} />
      <LanguageSwitcher />
      <div className={styles.container}>
        <div className={styles.blockContent}>
          {!isDesktop && <WorkerMobileLinks />}
          <h1>{t('worker_calendar')}</h1>
          {isManager && (
            <Select
              value={selectedWorker}
              onChange={handleWorkerChange}
              options={workers}
              placeholder={t('select_worker')}
            />
          )}
          <div className={styles.calendar}>
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView='dayGridMonth'
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay',
              }}
              events={events}
              eventContent={renderEventContent}
              editable={isManager}
              eventDrop={handleEventDrop}
              height='auto'
            />
          </div>
        </div>
      </div>
      {openEvent && (
        <Modal closeModal={handleCloseEvent} className={styles.Modal}>
          <Flex direction='column' gap={30}>
            <Flex justify='end' align='baseline'>
              <IconButton
                type='transparent'
                size='m'
                onClick={handleCloseEvent}
              >
                <CloseIcon />
              </IconButton>
            </Flex>
            <Flex direction='column' gap={16}>
              <p className={styles.info}>
                <strong>{currentEvent.title}</strong>
              </p>
              <p className={styles.info}>
                <span className={styles.label}>{t('description')}:</span>{' '}
                {currentEvent.description}
              </p>
              <p className={styles.info}>
                <span className={styles.label}>{t('status')}:</span>{' '}
                {currentEvent.status}
              </p>
              <p className={styles.info}>
                <span className={styles.label}>{t('address')}:</span>{' '}
                {currentEvent.houseAddress}
              </p>
              <p className={styles.info}>
                <span className={styles.label}>{t('urgency_level')}:</span>{' '}
                {currentEvent.urgencyLevel}
              </p>
              <p className={styles.info}>
                <span className={styles.label}>{t('preferred_time')}:</span>{' '}
                {currentEvent.preferredTime || 'Не указано'}
              </p>
              <p className={styles.info}>
                <span className={styles.label}>{t('creation_date')}:</span>{' '}
                {new Date(currentEvent.creationDate).toLocaleDateString()}
              </p>
              {currentEvent.startedDate && (
                <p className={styles.info}>
                  <span className={styles.label}>{t('start_date')}:</span>{' '}
                  {new Date(currentEvent.startedDate).toLocaleDateString()}
                </p>
              )}
              {currentEvent.finishedDate && (
                <p className={styles.info}>
                  <span className={styles.label}>{t('end_date')}:</span>{' '}
                  {new Date(currentEvent.finishedDate).toLocaleDateString()}
                </p>
              )}
              {currentEvent.rating && (
                <p className={styles.info}>
                  <span className={styles.label}>{t('rating')}:</span>{' '}
                  {currentEvent.rating.value}
                </p>
              )}
              <Flex>
                <button onClick={() => {}} className={styles.openButton}>
                  {t('open_house_data')}
                </button>
              </Flex>
              <Flex gap={10}>
                <button onClick={() => {}} className={styles.cancelButton}>
                  {t('decline')}
                </button>
                <button onClick={() => {}} className={styles.saveButton}>
                  {t('finish')}
                </button>
              </Flex>
            </Flex>
          </Flex>
        </Modal>
      )}
      <FooterBlock />
    </div>
  );
};

export default WorkerCalendar;
