import cn from 'classnames';

import {Text} from '../Text/Text'
import {Flex} from '../Flex/Flex'
import styles from './Input.module.scss';

export const Input = (props) => {
  const {
    name,
    type = "text",
    label,
    value,
    onChange,
    placeholder,
    errorMessage,
    className,
    children,
    ...restProps
  } = props;

  return (
    <Flex 
      direction="column"
      gap={6}
      className={cn(styles.input, className)}
    >
      <label styles={styles.inputLabel} htmlFor={name}>
        <Text color="gray">
          {label}
        </Text>
      </label>

      <input
        className={styles.inputField}
        id={name}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={e => onChange(e.target.value)}
        {...restProps}
      />

      {
        errorMessage && (
          <Text size="s">{errorMessage}</Text>
        )
      }
    </Flex>
  )
}