import React from 'react';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
// import { useTranslation } from 'react-i18next';

import { desktopQuery } from '../../../../consts';
import { Flex, Text } from '../../../../ui';
import styles from './SubscriptionCard.module.scss';

export const SubscriptionCard = (props) => {
  const {
    className,
    title,
    price,
    description,
    buttonText,
    buttonText2,
    buttonText3,
    handleClick,
    handleClick2,
    note,
    subscription,
    ...restProps
  } = props;
  const isDesktop = useMediaQuery(desktopQuery);
  // const { t, i18n } = useTranslation();

  return (
    <Flex
      direction='column'
      gap={60}
      align='center'
      className={cn(styles.card, className)}
      {...restProps}
    >
      <Flex direction='column' gap={24} className={styles.title}>
        <Text
          weight='medium'
          size={36}
          align={isDesktop ? 'start' : 'center'}
          color='white'
        >
          {title}
        </Text>
      </Flex>
      <Flex direction='column' className={styles.cardContent} gap={40}>
        <Flex direction='row' gap={16} justify='between'>
          <Flex direction='column' align='center'>
            <Text size={20} align='center' className={styles.infoText}>
              Planned Calls:
            </Text>
            <Text size={24} align='center'>
              {subscription.plannedCallsLimit}
            </Text>
          </Flex>
          <Flex direction='column' align='center'>
            <Text size={20} align='center' className={styles.infoText}>
              Duration:
            </Text>
            <Text size={24} align='center'>
              {subscription.duration} days
            </Text>
          </Flex>
          <Flex direction='column' align='center'>
            <Text size={20} align='center' className={styles.infoText}>
              Urgent Calls:
            </Text>
            <Text size={24} align='center'>
              {subscription.urgentCallsLimit}
            </Text>
          </Flex>
        </Flex>
        <Text
          weight='medium'
          size={20}
          align={'center'}
          className={styles.description}
        >
          {description}
        </Text>
        {/* Добавляем новую информацию о подписке */}
        <Text
          weight='medium'
          size={40}
          align='center'
          className={styles.priceText}
        >
          {price}
        </Text>
        <Flex direction='column' gap={32} className={styles.cardBtnBlock}>
          <button className={styles.cardBtn} onClick={handleClick}>
            <Text weight='bold' size={20}>
              {buttonText}
            </Text>
          </button>
          {note && (
            <Text
              weight='bold'
              size={18}
              align={isDesktop ? 'start' : 'center'}
            >
              {note}
            </Text>
          )}
          <button className={styles.cardBtn} onClick={handleClick2}>
            <Text weight='bold' size={20}>
              {buttonText2}
            </Text>
          </button>
          {/* <button className={styles.cardBtn} onClick={handleClick}>
            <Text weight='bold' size={20}>
              {buttonText3}
            </Text>
          </button> */}
        </Flex>
      </Flex>
    </Flex>
  );
};
