import { Link } from 'react-router-dom';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';

import { desktopQuery } from '../../consts';
import { Container, Flex, Text, IconButton, Button } from '../../ui';
import {
  logoImg,
  VkIcon,
  InstagramIcon,
  YoutubeIcon,
  FacebookIcon,
} from '../../static';
import styles from './FooterBlock.module.scss';
import { useTranslation } from 'react-i18next';

export const FooterBlock = (props) => {
  const { handleAskQuestion, className, ...restProps } = props;

  const isDesktop = useMediaQuery(desktopQuery);
  const { t } = useTranslation();

  return isDesktop ? (
    <div className={cn(styles.footer, className)} {...restProps}>
      <Container>
        <div className={styles.footerInner}>
          <Flex className={styles.footerContacts} direction='column' gap={40}>
            <Link to='/'>
              <img width='240' src={logoImg} alt='logo'></img>
            </Link>

            <Flex direction='column' gap={20}>
              <Flex direction='column' gap={6}>
                <Text lineHeight={1.5} color='gray'>
                  {t('address')}:
                </Text>
                <Text size={20} lineHeight={1.5} color='white'>
                  {t('address_text')}
                </Text>
              </Flex>

              <Flex direction='column' gap={6}>
                <Text lineHeight={1.5} color='gray'>
                  {t('phone')}:
                </Text>
                <Text size={20} lineHeight={1.5} color='white'>
                  +7 495 365-98-96
                </Text>
              </Flex>

              <Flex direction='column' gap={6}>
                <Text lineHeight={1.5} color='gray'>
                  {t('email')}:
                </Text>
                <Text size={20} lineHeight={1.5} color='white'>
                  info@sait.com
                </Text>
              </Flex>
            </Flex>

            <Button
              type='outline'
              onClick={handleAskQuestion}
              className={styles.footerContactsButton}
            >
              <Text color='white' size={17} weight='bold'>
                {t('ask_question')}
              </Text>
            </Button>
          </Flex>
          <div className={styles.footerNavMenu1}>
            <nav>
              <Flex tag='ul' gap={20} direction='column'>
                <li>
                  <Link to='/'>
                    <Text weight='medium' color='white' size={17}>
                      {t('home')}
                    </Text>
                  </Link>
                </li>
                <li>
                  <Link to='/'>
                    <Text weight='medium' color='white' size={17}>
                      {t('houses')}
                    </Text>
                  </Link>
                </li>
                <li>
                  <Link to='/'>
                    <Text weight='medium' color='white' size={17}>
                      {t('appartments')}
                    </Text>
                  </Link>
                </li>
                <li>
                  <Link to='/'>
                    <Text weight='medium' color='white' size={17}>
                      {t('services')}
                    </Text>
                  </Link>
                </li>
                <li>
                  <Link to='/'>
                    <Text weight='medium' color='white' size={17}>
                      {t('cost')}
                    </Text>
                  </Link>
                </li>
                <li>
                  <Link to='/'>
                    <Text weight='medium' color='white' size={17}>
                      {t('contacts')}
                    </Text>
                  </Link>
                </li>
              </Flex>
            </nav>
          </div>

          <div className={styles.footerNavMenu2}>
            <nav>
              <Flex tag='ul' gap={20} direction='column'>
                <li>
                  <Link to='/'>
                    <Text weight='medium' color='white' size={17}>
                      {t('home')}
                    </Text>
                  </Link>
                </li>
                <li>
                  <Link to='/'>
                    <Text weight='medium' color='white' size={17}>
                      {t('houses')}
                    </Text>
                  </Link>
                </li>
                <li>
                  <Link to='/'>
                    <Text weight='medium' color='white' size={17}>
                      {t('appartments')}
                    </Text>
                  </Link>
                </li>
                <li>
                  <Link to='/'>
                    <Text weight='medium' color='white' size={17}>
                      {t('services')}
                    </Text>
                  </Link>
                </li>
                <li>
                  <Link to='/'>
                    <Text weight='medium' color='white' size={17}>
                      {t('cost')}
                    </Text>
                  </Link>
                </li>
                <li>
                  <Link to='/'>
                    <Text weight='medium' color='white' size={17}>
                      {t('contacts')}
                    </Text>
                  </Link>
                </li>
              </Flex>
            </nav>
          </div>
          <div className={styles.footerSocial}>
            <Flex direction='column' gap={42}>
              <Flex gap={10}>
                <a href='#vk' target='_blank'>
                  <IconButton size='l' type='gray'>
                    <VkIcon />
                  </IconButton>
                </a>

                <a href='#instagram' target='_blank'>
                  <IconButton size='l' type='gray'>
                    <InstagramIcon />
                  </IconButton>
                </a>

                <a href='#youtube' target='_blank'>
                  <IconButton size='l' type='gray'>
                    <YoutubeIcon />
                  </IconButton>
                </a>

                <a href='#facebook' target='_blank'>
                  <IconButton size='l' type='gray'>
                    <FacebookIcon />
                  </IconButton>
                </a>
              </Flex>

              <Text color='gray' size={17}>
                {t('copyright')}
              </Text>
            </Flex>
          </div>
        </div>
      </Container>
    </div>
  ) : null;
};
