import cn from 'classnames';

import styles from './Divider.module.scss';

export const Divider = (props) => {
  const {className, ...restProps} = props;

  return (
    <hr className={cn(styles.divider, className)} {...restProps} />
  )
}