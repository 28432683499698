// AdminPanel.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { desktopQuery } from '../../consts';
import cn from 'classnames';
import { Button, Container, Flex, Text } from '../../ui';
import { logoImg, UserIcon } from '../../static';
import styles from './WorkerPanel.module.scss';
import { useTranslation } from 'react-i18next';
import { tokenService } from '../../services/tokenService';
import axios from 'axios';
import { LogoutModal } from '../../features/LogoutModal/LogoutModal';

function WorkerPanel(props) {
  const { className, handleLogout, ...restProps } = props;
  const isDesktop = useMediaQuery(desktopQuery);
  const { t } = useTranslation();
  const [userName, setUserName] = useState('');
  const [userId, setUserId] = useState('');

  const [logoutModal, setLogoutModal] = useState(false);

  const handleOpenLogout = () => {
    setLogoutModal(true);
  };

  const handleCloseLogout = () => {
    setLogoutModal(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const userId = await decodeToken();
      if (!userId) return;

      setUserId(userId);
      await loadUserProfile(userId);
    };

    fetchData();
  }, []);

  const decodeToken = async () => {
    console.log('decodeToken: Начало');
    try {
      const token = tokenService.getToken();
      if (!token) {
        console.error('Token not found');
        //navigate('/client-register');

        return null;
      }

      const base64Url = token.split('.')[1];
      if (!base64Url) {
        console.error('Invalid token format');
        return null;
      }

      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );

      const decoded = JSON.parse(jsonPayload);
      if (!decoded || !decoded._id) {
        console.error('Invalid token: no _id found');
        return null;
      }

      console.log('decodeToken: Конец, userId:', decoded._id);
      return decoded._id;
    } catch (error) {
      console.error('Ошибка при декодировании токена:', error);
      //navigate('/client-register');

      return null;
    }
  };

  const loadUserProfile = async (userId) => {
    if (!userId) {
      console.error('User ID is required to load profile.');
      return;
    }

    try {
      const token = tokenService.getToken();
      const response = await axios.get(
        `https://fix.house/api/users/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const userData = response.data;
      setUserName(userData.name);
    } catch (error) {
      console.error('Error loading user profile:', error);
      throw error;
    }
  };

  return isDesktop ? (
    <div className={cn(styles.header, className)} {...restProps}>
      <Container>
        <Flex justify='between' align='center'>
          <Link to='/'>
            <img width='240' src={logoImg} alt='logo'></img>
          </Link>

          <Flex direction='column' gap={30} align='end'>
            <Flex gap={40} align='center'>
              <Flex gap={6}>
                <>
                  <Button type='black'>
                    <Text color='white'>
                      <UserIcon width='20px' height='20px' />
                    </Text>
                    <Text weight='medium' color='white' size={17}>
                      {userName}
                    </Text>
                  </Button>
                  <Button type='outline' onClick={handleLogout}>
                    <Text weight='medium' color='white' size={17}>
                      {t('logout')}
                    </Text>
                  </Button>
                </>
              </Flex>
            </Flex>

            <nav>
              <Flex tag='ul' gap={50}>
                <li>
                  <Link to='/worker-dash'>
                    <Text weight='medium' color='white' size={17}>
                      {t('dashboard')}{' '}
                    </Text>
                  </Link>
                </li>
                <li>
                  <Link to='/worker-profile'>
                    <Text weight='medium' color='white' size={17}>
                      {t('profile')}{' '}
                    </Text>
                  </Link>
                </li>
                <li>
                  <Link to='/worker-job'>
                    <Text weight='medium' color='white' size={17}>
                      {t('my_jobs')}
                    </Text>
                  </Link>
                </li>

                <li>
                  <Link to='/worker-calendar'>
                    <Text weight='medium' color='white' size={17}>
                      {t('work_calendar')}
                    </Text>
                  </Link>
                </li>
                <li>
                  <Link to='/manager-contacts'>
                    <Text weight='medium' color='white' size={17}>
                      {t('managers_contacts')}
                    </Text>
                  </Link>
                </li>
                <li>
                  <Link to='/worker-settings'>
                    <Text weight='medium' color='white' size={17}>
                      {t('settings')}
                    </Text>
                  </Link>
                </li>
              </Flex>
            </nav>
          </Flex>
        </Flex>
        {logoutModal && (
          <LogoutModal
            closeModal={handleCloseLogout}
            handleLogout={handleLogout}
          />
        )}
      </Container>
    </div>
  ) : null;
}

export default WorkerPanel;
