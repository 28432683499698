import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import { useNavigate } from 'react-router-dom';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import markerIconUrl from './img/marker-icon.png';
import markerShadowUrl from './img/marker-shadow.png';
import styles from './AddHouse.module.css';
import { tokenService } from '../services/tokenService';
import { FooterBlock, HeaderBlock } from '../features';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';
import { getHouseValidationSchema } from './validationSchema';

function AddHouse() {
  const phuketCoordinates = [7.8804, 98.3923];
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  const [userId, setUserId] = useState(null);
  const { t } = useTranslation();

  const [houseData, setHouseData] = useState({
    name: '',
    address: '',
    mapLocationCoordinates: { type: 'Point', coordinates: [] },
    squareMeters: '',
    housingType: 'House',
    floor: '',
    apartmentNumber: '',
  });
  const [markerPosition, setMarkerPosition] = useState(null);
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});

  const goToHome = () => {
    navigate('/client-page');
  };

  const customMarkerIcon = L.icon({
    iconUrl: markerIconUrl,
    shadowUrl: markerShadowUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  const validateField = async (name, value) => {
    try {
      await getHouseValidationSchema(t).validateAt(name, {
        ...houseData,
        [name]: value,
      });
      setErrors((prev) => ({ ...prev, [name]: '' }));
    } catch (error) {
      setErrors((prev) => ({ ...prev, [name]: error.message }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setHouseData((prev) => ({ ...prev, [name]: value }));
    validateField(name, value);
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  const handleFileUpload = (event) => {
    setFile(event.target.files[0]);
  };

  const LocationMarker = () => {
    useMapEvents({
      click(e) {
        setMarkerPosition(e.latlng);
        setHouseData((prev) => ({
          ...prev,
          mapLocationCoordinates: {
            type: 'Point',
            coordinates: [e.latlng.lng, e.latlng.lat],
          },
        }));
      },
    });

    return markerPosition ? (
      <Marker position={markerPosition} icon={customMarkerIcon} />
    ) : null;
  };

  const submitHouse = async (event) => {
    event.preventDefault();

    try {
      await getHouseValidationSchema(t).validate(houseData, {
        abortEarly: false,
      });

      let formData = new FormData();
      formData.append('name', houseData.name);
      formData.append('address', houseData.address);
      formData.append('mapLocationCoordinates[type]', 'Point');
      formData.append(
        'mapLocationCoordinates[coordinates][0]',
        houseData.mapLocationCoordinates.coordinates[0]
      );
      formData.append(
        'mapLocationCoordinates[coordinates][1]',
        houseData.mapLocationCoordinates.coordinates[1]
      );
      formData.append('squareMeters', houseData.squareMeters);
      formData.append('housingType', houseData.housingType);
      if (houseData.housingType === 'Apartment') {
        formData.append('floor', houseData.floor);
        formData.append('apartmentNumber', houseData.apartmentNumber);
      }
      if (file) {
        formData.append('image', file);
      }

      const token = tokenService.getToken();
      const url = `https://fix.house/api/client/houses`;

      const response = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });

      navigate(`/house/${response.data._id}`);
    } catch (error) {
      if (error.name === 'ValidationError') {
        const newErrors = {};
        error.inner.forEach((err) => {
          newErrors[err.path] = err.message;
        });
        setErrors(newErrors);
      } else {
        alert(
          'Ошибка при добавлении дома: ' + error.response?.data?.message ||
            error.message
        );
      }
    }
  };

  const loadUserProfile = async (userId) => {
    if (!userId) {
      console.error('User ID is required to load profile.');
      return;
    }

    try {
      const token = tokenService.getToken();
      const response = await axios.get(
        `https://fix.house/api/users/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const userData = response.data;
      setUserName(userData.name);
    } catch (error) {
      console.error('Error loading user profile:', error);
      throw error;
    }
  };

  const decodeToken = async () => {
    console.log('decodeToken: Начало');
    try {
      const token = tokenService.getToken();
      if (!token) {
        console.error('Token not found');
        return null;
      }

      const base64Url = token.split('.')[1];
      if (!base64Url) {
        console.error('Invalid token format');
        return null;
      }

      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );

      const decoded = JSON.parse(jsonPayload);
      if (!decoded || !decoded._id) {
        console.error('Invalid token: no _id found');
        return null;
      }

      console.log('decodeToken: Конец, userId:', decoded._id);
      return decoded._id;
    } catch (error) {
      console.error('Ошибка при декодировании токена:', error);
      return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const userId = await decodeToken();
      if (!userId) return;

      setUserId(userId);
      await loadUserProfile(userId);
    };

    fetchData();
  }, []);

  const handleLogout = () => {
    tokenService.removeToken();
    navigate('/client-register');
  };

  const handleFormErrors = () => {
    let hasError = false;
    Object.keys(errors).forEach((key) => {
      if (errors[key] !== '' && Object.keys(houseData).includes(key)) {
        hasError = true;
      }
    });
    return hasError;
  };

  return (
    <div>
      <HeaderBlock isLoggedIn userName={userName} handleLogout={handleLogout} />
      <div className={styles.addHouseContainer}>
        <LanguageSwitcher />
        <h1>
          <button onClick={goToHome} className={styles.homeButton}>
            {t('back_to_client')}
          </button>
        </h1>
        <div className={styles.box}>
          <h1>
            {t('add_new_house_1')} <b>{t('add_new_house_2')}:</b>
          </h1>
          <form onSubmit={submitHouse} className={styles.form}>
            <select
              name='housingType'
              value={houseData.housingType}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value='House'>{t('house')}</option>
              <option value='Apartment'>{t('apartment')}</option>
            </select>

            <p className={styles.addHouseContainerLabel}>{t('house_name')}</p>
            <input
              type='text'
              name='name'
              value={houseData.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.name && (
              <div className={styles.errorMessage}>{errors.name}</div>
            )}

            <p className={styles.addHouseContainerLabel}>{t('address')}</p>
            <input
              type='text'
              name='address'
              value={houseData.address}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.address && (
              <div className={styles.errorMessage}>{errors.address}</div>
            )}

            <p className={styles.addHouseContainerLabel}>
              {t('square_meters')}
            </p>
            <input
              type='number'
              name='squareMeters'
              value={houseData.squareMeters || ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.squareMeters && (
              <div className={styles.errorMessage}>{errors.squareMeters}</div>
            )}

            {houseData.housingType === 'Apartment' && (
              <>
                <p className={styles.addHouseContainerLabel}>{t('floor')}</p>
                <input
                  type='number'
                  name='floor'
                  value={houseData.floor || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.floor && (
                  <div className={styles.errorMessage}>{errors.floor}</div>
                )}

                <p className={styles.addHouseContainerLabel}>
                  {t('apartment_number')}
                </p>
                <input
                  type='text'
                  name='apartmentNumber'
                  value={houseData.apartmentNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.apartmentNumber && (
                  <div className={styles.errorMessage}>
                    {errors.apartmentNumber}
                  </div>
                )}
              </>
            )}

            <input type='file' onChange={handleFileUpload} id='fileUpload' />

            <MapContainer
              center={phuketCoordinates}
              zoom={11}
              className={styles.mapContainer}
            >
              <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
              <LocationMarker />
            </MapContainer>

            <button
              className={styles.button}
              type='submit'
              disabled={() => handleFormErrors()}
            >
              {t('add_this_house')}
            </button>
          </form>
        </div>
      </div>
      <FooterBlock />
    </div>
  );
}

export default AddHouse;
