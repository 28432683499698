import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AdminPanel from './AdminPanel';
import { FooterBlock } from '../../features/FooterBlock/FooterBlock';
import { tokenService } from '../../services/tokenService';
import styles from './SubscriptionsCountList.module.scss';
import { Flex } from '../../ui';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../LanguageSwitcher';
import { useNavigate } from 'react-router-dom';
import AdminMobileLinks from '../../features_admin/AdminMobileLinks/AdminMobileLinks';
import { useMediaQuery } from 'react-responsive';
import { desktopQuery } from '../../consts';

const SubscriptionsCountList = () => {
  const [subscriptionCounts, setSubscriptionCounts] = useState({});
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(desktopQuery);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      const token = tokenService.getToken();
      const url = 'https://fix.house/api/subscriptions/all';

      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const subscriptionsData = response.data;

        // Группировка подписок по типу и подсчет количества
        const counts = subscriptionsData.reduce((acc, subscription) => {
          const typeName = subscription.subscriptionTypeID.typeName;
          acc[typeName] = (acc[typeName] || 0) + 1;
          return acc;
        }, {});

        setSubscriptionCounts(counts);
      } catch (error) {
        console.error('Ошибка при получении данных о подписках:', error);
      }
    };

    fetchSubscriptions();
  }, []);

  const handleLogout = () => {
    tokenService.removeToken();
    navigate('/client-register');
  };

  return (
    <div>
      <AdminPanel handleLogout={handleLogout} />
      <LanguageSwitcher />
      <div className={styles.typesContainer}>
        <div className={styles.blockContent}>
          {!isDesktop && <AdminMobileLinks />}
          <h1>{t('subscriptions_count_list')}:</h1>
          <ul className={styles.subsList}>
            {Object.entries(subscriptionCounts).map(([typeName, count]) => (
              <div key={typeName} className={styles.subCard}>
                <Flex justify='between'>
                  <p className={styles.info}>
                    <span className={styles.label}>{t('type_name')}:</span>
                    {typeName}
                  </p>
                  <p className={styles.info}>
                    <span className={styles.label}>{t('sold')}:</span>
                    <span className={styles.count}>
                      {count} {t('counts_of')}
                    </span>
                  </p>
                </Flex>
              </div>
            ))}
          </ul>
        </div>
      </div>
      <FooterBlock />
    </div>
  );
};

export default SubscriptionsCountList;
