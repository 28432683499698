import React, { useEffect, useState } from 'react';
import { Flex, IconButton, Modal } from '../../ui';
import { CloseIcon } from '../../static';
import styles from './CurrentProblem.module.scss';
import markerIconUrl from './../../pages/img/marker-icon.png';
import markerShadowUrl from './../../pages/img/marker-shadow.png';
import markerIconActiveUrl from './../../pages/img/marker-icon.png';
import markerIconAllUrl from './../../pages/img/marker-icon.png';
import L from 'leaflet';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { tokenService } from '../../services/tokenService';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const CurrentProblemModal = ({
  handleCloseEvent,
  currentEvent,
  unassignProblemFromSelf,
  markProblemAsFinished,
  previewPhotos,
}) => {
  const [ownerDetails, setOwnerDetails] = useState(null);
  const customMarkerIcon = L.icon({
    iconUrl: markerIconUrl,
    shadowUrl: markerShadowUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  const customMarkerIconActive = L.icon({
    iconUrl: markerIconActiveUrl,
    shadowUrl: markerShadowUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  const customMarkerIconAll = L.icon({
    iconUrl: markerIconAllUrl,
    shadowUrl: markerShadowUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });
  const { t } = useTranslation();

  useEffect(() => {
    const fetchOwnerDetails = async (ownerId) => {
      try {
        const token = tokenService.getToken();
        const response = await axios.get(
          `https://fix.house/api/users/${ownerId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setOwnerDetails(response.data);
      } catch (error) {
        console.error('Ошибка при загрузке данных владельца:', error);
      }
    };

    if (currentEvent && currentEvent.house.ownerID) {
      fetchOwnerDetails(currentEvent.house.ownerID);
    }
  }, [currentEvent]);

  return (
    <Modal closeModal={handleCloseEvent} className={styles.Modal}>
      <Flex direction='column' gap={10}>
        <Flex justify='end' align='baseline'>
          <IconButton type='transparent' size='m' onClick={handleCloseEvent}>
            <CloseIcon />
          </IconButton>
        </Flex>
        <Flex direction='column' gap={16}>
          <p className={styles.info}>
            <strong>{currentEvent.title}</strong>
          </p>
          <p className={styles.info}>
            <span className={styles.label}>{t('address')}:</span>{' '}
            {currentEvent.house.address}
          </p>
          <p className={styles.info}>
            <span className={styles.label}>{t('title')}:</span>{' '}
            {currentEvent.house.name}
          </p>
          <p className={styles.info}>
            <span className={styles.label}>{t('area')}:</span>{' '}
            {currentEvent.house.squareMeters} {t('sq_m')}
          </p>
          <p className={styles.info}>
            <span className={styles.label}>{t('house_type')}:</span>{' '}
            {currentEvent.house.housingType}
          </p>
          <p className={styles.info}>
            <span className={styles.label}>{t('owner_name')}:</span>{' '}
            {ownerDetails ? ownerDetails.name : t('no_info')}
          </p>
          <p className={styles.info}>
            <span className={styles.label}>{t('phone')}:</span>{' '}
            {ownerDetails ? ownerDetails.phone : t('no_info')}
          </p>
          <p className={styles.info}>
            <span className={styles.label}>{t('email')}:</span>{' '}
            {ownerDetails ? ownerDetails.email : t('no_info')}
          </p>
          <p className={styles.info}>
            <span className={styles.label}>{t('messenger')}:</span>{' '}
            {ownerDetails
              ? `${ownerDetails.messenger} (${ownerDetails.messengerType})`
              : t('no_info')}
          </p>
          <p className={styles.info}>
            <span className={styles.label}>{t('language')}:</span>{' '}
            {ownerDetails ? ownerDetails.preferredLanguage : t('no_info')}
          </p>
          {currentEvent.startedDate && (
            <p className={styles.info}>
              <span className={styles.label}>{t('start_date')}:</span>{' '}
              {new Date(currentEvent.startedDate).toLocaleDateString()}
            </p>
          )}
          {currentEvent.finishedDate && (
            <p className={styles.info}>
              <span className={styles.label}>{t('end_date')}:</span>{' '}
              {new Date(currentEvent.finishedDate).toLocaleDateString()}
            </p>
          )}
          {currentEvent.rating && (
            <p className={styles.info}>
              <span className={styles.label}>{t('rating')}:</span>{' '}
              {currentEvent.rating.value}
            </p>
          )}
          {/* <Flex>
            <button onClick={() => {}} className={styles.openButton}>
              Открыть данные дома
            </button>
          </Flex> */}
          <div className={styles.mapContainer}>
            <MapContainer
              center={[
                currentEvent.house.mapLocationCoordinates.coordinates[1],
                currentEvent.house.mapLocationCoordinates.coordinates[0],
              ]}
              zoom={13}
              scrollWheelZoom={false}
              style={{ height: 200, width: '100%' }}
            >
              <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
              <Marker
                position={[
                  currentEvent.house.mapLocationCoordinates.coordinates[1],
                  currentEvent.house.mapLocationCoordinates.coordinates[0],
                ]}
                icon={customMarkerIcon}
              ></Marker>
            </MapContainer>
          </div>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default CurrentProblemModal;
