import React from 'react';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';

import { desktopQuery } from '../../consts';
import { Flex, Text, Container, Button } from '../../ui';
import { UrgentIcon, PlannedIcon } from '../../static';
import { ProblemCard } from '../ProblemCard/ProblemCard';
import styles from './CurrentProblemsBlock.module.scss';
import { useTranslation } from 'react-i18next';

export const CurrentProblemsBlock = (props) => {
  const {
    urgentProblems,
    plannedProblems,
    getProblemsByStatusAndType,
    skills,
    startEditingProblem,
    ControlModuleDelete,
    ControlModuleInfo,
    handleOpenActive,
    className,
    children,
    activeUrgency,
    setActiveUrgency,
    handleOpenPhotoModal,
    ...restProps
  } = props;

  const isDesktop = useMediaQuery(desktopQuery);
  const { t } = useTranslation();

  return (
    <section
      className={cn(styles.currentProblemsBlock, className)}
      {...restProps}
    >
      <Container>
        {isDesktop ? (
          <Flex direction='column' gap={30}>
            <Flex justify='between' align='center'>
              <Text tag='h2' size={36} lineHeight='36px' color='gray'>
                {t('current_problems')}
              </Text>

              <Button
                type='outline'
                size='medium'
                short
                onClick={handleOpenActive}
              >
                <Text size={17} weight='bold'>
                  {t('all_problems')}
                </Text>
              </Button>
            </Flex>

            <div className={styles.problemsGrid}>
              <Flex gap={12} className={styles.problemsGridHeader}>
                <UrgentIcon width={20} height={20} />

                <Text size={20} lineHeight='30px' weight='bold'>
                  {t('urgent')}
                </Text>
              </Flex>

              <Flex gap={12} className={styles.problemsGridHeader}>
                <PlannedIcon width={20} height={20} />

                <Text size={20} lineHeight='30px' weight='bold'>
                  {t('planned')}
                </Text>
              </Flex>

              <div className={styles.problemCardsGrid}>
                {getProblemsByStatusAndType(
                  urgentProblems,
                  'Open',
                  'Urgent'
                ).map((problem) => {
                  return (
                    <ProblemCard
                      key={problem._id}
                      problem={problem}
                      skills={skills}
                      handleEditProblem={() => startEditingProblem(problem)}
                      handleDeleteProblem={() => ControlModuleDelete(problem)}
                      handleGetProblemInfo={() => ControlModuleInfo(problem)}
                      handleOpenPhotoModal={() => {
                        handleOpenPhotoModal(problem.initialPhotoID?.imageURL);
                      }}
                    />
                  );
                })}
              </div>

              <div className={styles.problemCardsGrid}>
                {getProblemsByStatusAndType(
                  plannedProblems,
                  'Open',
                  'Planned'
                ).map((problem) => {
                  return (
                    <ProblemCard
                      key={problem._id}
                      problem={problem}
                      skills={skills}
                      handleEditProblem={() => startEditingProblem(problem)}
                      handleDeleteProblem={() => ControlModuleDelete(problem)}
                      handleGetProblemInfo={() => ControlModuleInfo(problem)}
                      handleOpenPhotoModal={() => {
                        handleOpenPhotoModal(problem.initialPhotoID?.imageURL);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </Flex>
        ) : (
          <Flex direction='column' gap={30}>
            <Flex justify='between' gap={15}>
              <Text tag='h2' size={15} color='gray'>
                {t('current_problems')}
              </Text>

              <Button
                className={styles.noPaddingButton}
                onClick={handleOpenActive}
              >
                <Text size={15} weight='semiBold' decoration='underline'>
                  {t('all_problems')}
                </Text>
              </Button>
            </Flex>

            <div className={styles.problemTabs}>
              <Button
                onClick={() => setActiveUrgency('Urgent')}
                className={cn(styles.problemTab, {
                  [styles.problemTabActive]: activeUrgency === 'Urgent',
                })}
              >
                <Flex gap={4} justify='center'>
                  <UrgentIcon width={20} height={20} />

                  <Text
                    color={activeUrgency === 'Urgent' ? 'black' : 'gray'}
                    weight={activeUrgency === 'Urgent' ? 'bold' : 'semiBold'}
                  >
                    {t('urgent')}
                  </Text>
                </Flex>
              </Button>

              <Button
                onClick={() => setActiveUrgency('Planned')}
                className={cn(styles.problemTab, {
                  [styles.problemTabActive]: activeUrgency === 'Planned',
                })}
              >
                <Flex gap={4} justify='center'>
                  <PlannedIcon width={20} height={20} />

                  <Text
                    color={activeUrgency === 'Planned' ? 'black' : 'gray'}
                    weight={activeUrgency === 'Planned' ? 'bold' : 'semiBold'}
                  >
                    {t('planned')}
                  </Text>
                </Flex>
              </Button>
            </div>

            {/* <div className={styles.scrollable}> */}
            <div className={styles.problemCardsGrid}>
              {getProblemsByStatusAndType(
                activeUrgency === 'Planned' ? plannedProblems : urgentProblems,
                'Open',
                activeUrgency
              ).map((problem) => {
                return (
                  <ProblemCard
                    key={problem._id}
                    problem={problem}
                    skills={skills}
                    handleEditProblem={() => startEditingProblem(problem)}
                    handleDeleteProblem={() => ControlModuleDelete(problem)}
                    handleGetProblemInfo={() => ControlModuleInfo(problem)}
                    handleOpenPhotoModal={() => {
                      handleOpenPhotoModal(problem.initialPhotoID?.imageURL);
                    }}
                  />
                );
              })}
            </div>
            {/* </div> */}
          </Flex>
        )}
      </Container>
    </section>
  );
};
