import React, { useState } from 'react';
import axios from './../../axios';
import { useNavigate } from 'react-router-dom';
import styles from './../Login.module.css';

function WorkerLogin() {
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  });
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    setErrorMessage('');

    try {
      const response = await axios.post(
        'https://fix.house/api/workers/login',
        credentials
      );

      if (response.status === 200) {
        localStorage.setItem('workerToken', response.data.token);
        localStorage.setItem('workerId', response.data.worker._id);
        localStorage.setItem('userRole', response.data.worker.role);

        if (response.data.worker.role === 'admin') {
          navigate('/admin-dashboard');
        } else if (response.data.worker.role === 'worker') {
          navigate('/worker-dash');
        } else {
          navigate('/'); // Перенаправление на главную страницу для неизвестных ролей
        }
      } else {
        setErrorMessage('Ошибка при входе в систему');
      }
    } catch (error) {
      console.error('Ошибка при входе в систему:', error);
      setErrorMessage('Ошибка при входе в систему');
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Вход для Рабочих</h1>
      <p className={styles['welcome-back']}>
        Рады видеть вас снова на рабочем месте!
      </p>
      <form onSubmit={submitForm}>
        <div className={styles['form-group']}>
          <input
            type='email'
            name='email'
            value={credentials.email}
            onChange={handleInputChange}
            placeholder='Email'
            className={styles.input}
          />
        </div>
        <div className={styles['form-group']}>
          <input
            type='password'
            name='password'
            value={credentials.password}
            onChange={handleInputChange}
            placeholder='Пароль'
            className={styles.input}
          />
        </div>
        <button type='submit' className={styles.button}>
          Войти
        </button>
        {errorMessage && (
          <p className={styles['error-message']}>{errorMessage}</p>
        )}
      </form>
      <br />
      <div className={styles['form-group']}>
        <button
          onClick={() => navigate('/worker-forgot-password')}
          className={styles.button}
        >
          Забыл пароль
        </button>
      </div>
    </div>
  );
}

export default WorkerLogin;
