import React from 'react';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { desktopQuery } from "../../consts";
import { Flex, Divider, Text, Bar, Container } from "../../ui";
import { CalendarIcon, PaymentIcon } from '../../static';
import styles from './ActiveSubscriptionsBlock.module.scss';
import { useTranslation } from 'react-i18next';

export const ActiveSubscriptionsBlock = (props) => {
  const { className, subscriptions, ...restProps } = props;
  const isDesktop = useMediaQuery(desktopQuery);
  const { t, i18n } = useTranslation();

  const getPaymentStatusColor = (status) => {
    switch (status) {
      case 'paid':
        return 'green';
      case 'pending':
      case 'cash':
      case 'crypto':
        return 'orange';
      default:
        return 'gray';
    }
  };

  const renderPaymentInfo = (subscription) => (
    <Flex gap={8} direction="column">
      <Text size={16} lineHeight="24px" color="gray">{t('payment_status')}</Text>
      <Text size={18} lineHeight="28px" weight="medium" color={getPaymentStatusColor(subscription.paymentStatus)}>
        {t(`payment_status_${subscription.paymentStatus}`)}
      </Text>
      <Text size={16} lineHeight="24px" color="gray">{t('payment_method')}</Text>
      <Text size={18} lineHeight="28px" weight="medium">
        {t(`payment_method_${subscription.paymentMethod}`)}
      </Text>
    </Flex>
  );

  return (
    <section className={cn(styles.activeSubscriptionsBlock, className)} {...restProps}>
      <Container>
        {isDesktop ? (
          <>
            <Text tag="h2" size={36} lineHeight="36px" color="gray">{t('my_subscriptions')}</Text>
            <Flex direction="column" gap={30} className={styles.activeSubscriptionsList}>
              {subscriptions.length ? 
                subscriptions.map((subscription) => (
                  <Bar key={subscription._id}>
                    <Flex gap={40} align="center">
                      <div className={styles.growable}>
                        <Text color="gray" size={16} lineHeight="24px">{t('subscription_type')}</Text>
                        <Text weight="bold" size={24} lineHeight="34px">
                          {subscription.subscriptionType ? subscription.subscriptionType.typeName : 'Unknown Subscription Type'}
                        </Text>
                        <Text size={16} lineHeight="24px">
                          {subscription.subscriptionType ? subscription.subscriptionType.description : ''}
                        </Text>
                      </div>
  
                      <Flex gap={14}>
                        <CalendarIcon width={24} height={24} />
                        <div>
                          <Text size={16} lineHeight="24px" color="gray">{t('start_date')}</Text>
                          <Text size={18} lineHeight="28px" weight="medium">{new Date(subscription.startDate).toLocaleDateString(i18n.language)}</Text>
                        </div>
                      </Flex>
  
                      <Flex gap={8}>
                        <CalendarIcon width={24} height={24}/>
                        <div>
                          <Text size={16} lineHeight="24px" color="gray">{t('end_date')}</Text>
                          <Text size={18} lineHeight="28px" weight="medium">{new Date(subscription.expiresDate).toLocaleDateString(i18n.language)}</Text>
                        </div>
                      </Flex>

                      {renderPaymentInfo(subscription)}
                    </Flex>
                  </Bar>
                )) 
                : <Text size={24} color="gray">{t('no_active_subscriptions')}</Text>
              }
            </Flex> 
          </>
        ) : (
          <>
            <Text tag="h2" size={36} lineHeight="36px" color="gray">{t('my_subscriptions')}</Text>
            <Flex direction="column" gap={20} className={styles.activeSubscriptionsList}>
              {subscriptions.length ? 
                subscriptions.map((subscription, index) => (
                  <React.Fragment key={subscription._id}>
                    <div className={styles.activeSubscriptionCard}>
                      <Bar className={styles.activeSubscriptionCardBar}>
                        <Text color="gray" size={16} lineHeight="24px">{t('subscription_type')}</Text>
                        <Text weight="bold" size={24} lineHeight="34px">
                          {subscription.subscriptionType ? subscription.subscriptionType.typeName : 'Unknown Subscription Type'}
                        </Text>
                        <Text size={16} lineHeight="24px">
                          {subscription.subscriptionType ? subscription.subscriptionType.description : ''}
                        </Text>
                      </Bar>
  
                      <Flex gap={8}>
                        <CalendarIcon/>
                        <div>
                          <Text size={16} lineHeight="24px" color="gray">{t('start_date')}</Text>
                          <Text weight="medium">{new Date(subscription.startDate).toLocaleDateString(i18n.language)}</Text>
                        </div>
                      </Flex>
  
                      <Flex gap={8}>
                        <CalendarIcon/>
                        <div>
                          <Text size={16} lineHeight="24px" color="gray">{t('end_date')}</Text>
                          <Text weight="medium">{new Date(subscription.expiresDate).toLocaleDateString(i18n.language)}</Text>
                        </div>
                      </Flex>

                      {renderPaymentInfo(subscription)}
                    </div>
                    {index < subscriptions.length - 1 && <Divider/>}
                  </React.Fragment>
                )) 
                : <Text size={24} color="gray">{t('no_active_subscriptions')}</Text>
              }
            </Flex> 
          </>
        )}
      </Container>
    </section>
  )
}