import React, { useEffect } from 'react';
import axios from 'axios';
import { tokenService } from '../services/tokenService';

const GoogleLoginButton = ({ onSuccess, onFailure, country, city }) => {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  useEffect(() => {
    console.log("Client ID from .env:", clientId);
    if (!window.google) {
      console.error('Google API script not loaded');
      if (onFailure) {
        onFailure('Google API script not loaded');
      }
      return;
    }

    window.google.accounts.id.initialize({
      client_id: clientId,
      callback: handleCredentialResponse,
      auto_select: true
    });

    window.google.accounts.id.renderButton(
      document.getElementById('googleSignInButton'),
      { theme: 'outline', size: 'large' }
    );

    async function handleCredentialResponse(response) {
      console.log('Encoded JWT ID token: ' + response.credential);
      try {
        const res = await axios.post('/api/users/auth/google', {
          token: response.credential,
          country,
          city
        });

        if (res.status === 200) {
          console.log('User authenticated successfully:', res.data);
          tokenService.saveToken(res.data.token);
          if (res.data.user && res.data.user._id) {
            tokenService.saveUserId(res.data.user._id);
          } else {
            console.warn('User ID not found in the response');
          } 
          onSuccess(res.data);
          window.location.href = '/client-page';
        } else {
          console.error('Authentication failed:', res.data);
          onFailure(res.data);
        }
      } catch (error) {
        console.error('Error during authentication:', error);
        onFailure(error.message);
      }
    }
  }, [clientId, onSuccess, onFailure, country, city]);

  return <div id="googleSignInButton"></div>;
};

export default GoogleLoginButton;