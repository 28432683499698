import React from 'react';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { desktopQuery } from "../../consts";
import { Flex, Text, Container } from "../../ui";
import styles from './HomePageEndingBlock.module.scss';

export const HomePageEndingBlock = (props) => {
  const { className, isAuthenticated, ...restProps } = props;
  const isDesktop = useMediaQuery(desktopQuery);
  const { t } = useTranslation();

  return (
    <section className={cn(styles.ending, className)} {...restProps}>
      <Container className={styles.fullHeight}>
        {isDesktop ? (
          <Flex direction="column" gap={40} justify="between" className={styles.fullHeight}>
            <Text tag="h2" size={72} color="white" weight="medium" className={styles.endingTitle}>{t('ready_to_trust')}</Text>

            <Link to={isAuthenticated ? "/client-page" : "/client-register"} className={cn(styles.endingBtn, styles.flexStart)}>
              <Text color="white" weight="medium" size={24}>
                {isAuthenticated ? t('my_account') : "Join now"}
              </Text>
            </Link>

            <Flex justify="between" align="baseline">
              <Text color="white" size={32} lineHeight={1} weight="medium">Fix.house</Text>

              <Text className={styles.endingCopyright} size={20}>{t('copyright')}</Text>

              <Text className={styles.hidden} color="white" size={32} lineHeight={1} weight="medium">Fix.house</Text>
            </Flex>
          </Flex>
        ) : 
        (
          <Flex direction="column" gap={40} justify="between" align="center" className={styles.fullHeight}>
            <Text tag="h2" size={60} color="white" weight="medium" align="center" className={styles.endingTitle}>{t('ready_to_trust')}</Text>

            <Link to={isAuthenticated ? "/client-page" : "/client-register"} className={styles.endingBtn}>
              <Text color="white" weight="medium" size={24}>
                {isAuthenticated ? t('my_account') : "Join now"}
              </Text>
            </Link>


            <Flex direction="column" gap={20}>
              <Text color="white" size={32} lineHeight={1} weight="medium">Fix.house</Text>

              <Text className={styles.endingCopyright} size={20}>{t('copyright')}</Text>
            </Flex>
          </Flex>
        )}
      </Container>
    </section>
  )
}