import React from 'react';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom'; // Предполагаю, что вы используете react-router

import { desktopQuery } from '../../consts';
import { Flex, Text, Container, Bar, Button } from '../../ui';
import { UrgentIcon, PlannedIcon, AddCircleIcon } from '../../static';
import styles from './ProblemSolvingBlock.module.scss';
import { useTranslation } from 'react-i18next';

export const ProblemSolvingBlock = (props) => {
  const {
    urgentCallsUsedTotal,
    urgentCallsLimitTotal,
    plannedCallsUsedTotal,
    plannedCallsLimitTotal,
    handleAddUrgentProblem,
    handleAddPlannedProblem,
    remainingPlannedCalls,
    remainingUrgentCalls,
    className,
    ...restProps
  } = props;

  const isDesktop = useMediaQuery(desktopQuery);
  const { t } = useTranslation();

  const showNoSubscriptionsWarning =
    remainingUrgentCalls === 0 && remainingPlannedCalls === 0;

  const renderNoSubscriptionsWarning = () => (
    <Text size={24} className={styles.noSubscriptionsWarning} weight='bold'>
      {t('no_sub_step_1')}{' '}
      <Link to='/#pricing' className={styles.linkStyle}>
        {t('no_sub_step_2')}
      </Link>
      , {t('no_sub_step_3')}
    </Text>
  );

  const renderHeader = () => (
    <Flex justify='between' align='center'>
      <Text
        tag='h2'
        size={isDesktop ? 36 : 15}
        lineHeight={isDesktop ? '36px' : 'normal'}
        color='gray'
      >
        {t('problem_solving')}
      </Text>
      <Button
        type={isDesktop ? 'outline' : 'text'}
        size={isDesktop ? 'medium' : 'small'}
        short={isDesktop}
        className={!isDesktop ? styles.noPadding : ''}
      >
        <Text
          size={isDesktop ? 17 : 15}
          weight={isDesktop ? 'bold' : 'semiBold'}
          decoration={!isDesktop ? 'underline' : 'none'}
        >
          {t('what')}?
        </Text>
      </Button>
    </Flex>
  );

  const renderCallInfo = (icon, title, remaining, used, limit) => (
    <div className={isDesktop ? styles.barItem : ''}>
      <Flex align='center' gap={isDesktop ? 20 : 10}>
        <div className={cn(styles.iconCircle, styles.iconCircleBig)}>
          {icon}
        </div>
        <div>
          <Text color='gray'>{title}</Text>
          <Text size={isDesktop ? 30 : 18} weight='bold'>
            {t('remained')}: {remaining} ( {used}/{limit} )
          </Text>
        </div>
      </Flex>
    </div>
  );

  const renderAddProblemButtons = () => (
    <div className={styles.addProblemButtons}>
      <Button
        className={cn(styles.problemButton, styles.problemButtonDark)}
        onClick={handleAddUrgentProblem}
      >
        <Flex
          direction={isDesktop ? 'row' : 'column'}
          gap={isDesktop ? 10 : 15}
          align='center'
        >
          <AddCircleIcon
            width={isDesktop ? 33 : 50}
            height={isDesktop ? 33 : 50}
          />
          <Text
            size={isDesktop ? 'inherit' : 12}
            weight='medium'
            lineHeight={isDesktop ? '24px' : 'normal'}
            color='white'
            align='center'
          >
            {t('add_urgent_problem')}
          </Text>
        </Flex>
      </Button>

      <Button
        className={cn(styles.problemButton, styles.problemButtonLight)}
        onClick={handleAddPlannedProblem}
      >
        <Flex
          direction={isDesktop ? 'row' : 'column'}
          gap={isDesktop ? 10 : 15}
          align='center'
        >
          <AddCircleIcon
            width={isDesktop ? 33 : 50}
            height={isDesktop ? 33 : 50}
          />
          <Text
            size={isDesktop ? 'inherit' : 12}
            weight='medium'
            lineHeight={isDesktop ? '24px' : 'normal'}
            color='black'
            align='center'
          >
            {t('planned_problem')}
          </Text>
        </Flex>
      </Button>
    </div>
  );

  return (
    <section
      className={cn(styles.problemSolvingBlock, className)}
      {...restProps}
    >
      <Container>
        <Flex direction='column' gap={20}>
          {renderHeader()}

          {showNoSubscriptionsWarning && renderNoSubscriptionsWarning()}

          {isDesktop ? (
            <>
              {renderCallInfo(
                <UrgentIcon />,
                `${t('available_urgent_calls')}:`,
                remainingUrgentCalls,
                urgentCallsUsedTotal,
                urgentCallsLimitTotal
              )}
              {renderCallInfo(
                <PlannedIcon />,
                `${t('available_planned_calls')}:`,
                remainingPlannedCalls,
                plannedCallsUsedTotal,
                plannedCallsLimitTotal
              )}
            </>
          ) : (
            <>
              <Bar>
                {renderCallInfo(
                  <UrgentIcon />,
                  `${t('available_urgent_calls')}:`,
                  remainingUrgentCalls,
                  urgentCallsUsedTotal,
                  urgentCallsLimitTotal
                )}
              </Bar>
              <Bar>
                {renderCallInfo(
                  <PlannedIcon />,
                  `${t('available_planned_calls')}:`,
                  remainingPlannedCalls,
                  plannedCallsUsedTotal,
                  plannedCallsLimitTotal
                )}
              </Bar>
            </>
          )}

          {renderAddProblemButtons()}
        </Flex>
      </Container>
    </section>
  );
};
