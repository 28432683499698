import axios from 'axios';
import L from 'leaflet';
import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, Marker, TileLayer, useMapEvents } from 'react-leaflet';
import { useNavigate, useParams } from 'react-router-dom';
import { tokenService } from '../services/tokenService';

import {
  HeaderBlock,
  HouseProfileBlock,
  ProblemSolvingBlock,
  CurrentProblemsBlock,
  ProblemsInProgressBlock,
  FooterBlock,
  AddProblemModal,
  EditProblemModal,
  EditHouseProfileModal,
  DeleteProblemModal,
  ViewProblemModal,
  ProblemsListModal,
} from '../features';
import { getProblems, addProblem } from '../api';

import styles from './HousePage.module.scss';
import styleAddProblem from './CSS/addproblem.module.css';
import styleCardFillInfo from './CSS/cardfullinfo.module.css';
import styleCardProblem from './CSS/cardproblem.module.css';
import styleCardProblems from './CSS/cardproblems.module.css';
import styleDelete from './CSS/delete.module.css';
import styleEditHouse from './CSS/edithouse.module.css';
import styleEditProblem from './CSS/editproblem.module.css';
import styleMap from './CSS/map.module.css';
import style from './CSS/app.module.css';
import './CSS/index.css';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../components/LanguageSwitcher';
import PhotoModal from '../features/PhotoModal/PhotoModal';

function HousePage() {
  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 3);
  const formattedMinDate = minDate.toISOString().split('T')[0];

  const { houseId } = useParams();
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [Active, setActive] = useState(false);
  const [showDelete, setShowDelete] = useState(null);
  const [showFullInfo, setShowFullInfo] = useState(null);
  const [showFullInfoSmall, setshowFullInfoSmall] = useState(null);
  const [Status, SetStatus] = useState('Open');
  const [showModuleListProblem, setShowModuleListProblem] = useState(false);
  const [showModuleListProblemOpen, setShowModuleListProblemOpen] =
    useState(false);
  const [StatusProblem, setStatusProblem] = useState('');
  const [StatusProblemOpen, setStatusProblemOpen] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [house, setHouse] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [editedHouse, setEditedHouse] = useState({});
  const [urgentProblems, setUrgentProblems] = useState([]);
  const [plannedProblems, setPlannedProblems] = useState([]);
  const [subscriptionUsage, setSubscriptionUsage] = useState([]);
  const [scheduledDate, setScheduledDate] = useState(
    minDate.toISOString().split('T')[0]
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState('');

  const [callsData, setCallsData] = useState({
    urgentCallsUsedTotal: 0,
    urgentCallsLimitTotal: 10,
    plannedCallsUsedTotal: 0,
    plannedCallsLimitTotal: 10,
    remainingUrgentCalls: 10,
    remainingPlannedCalls: 10,
  });

  const [defaultTime, setDefaultTime] = useState('9:00--12:00');
  const [newUrgentProblem, setNewUrgentProblem] = useState({
    description: '',
    problemType: 'Plumbing',
    status: 'Open',
  });
  const [newPlannedProblem, setNewPlannedProblem] = useState({
    description: '',
    problemType: 'Plumbing',
    status: 'Open',
    preferredTime: defaultTime,
  });

  const [newProblemPhoto, setNewProblemPhoto] = useState(null);
  const [editingProblem, setEditingProblem] = useState(null);
  const [mapCenter, setMapCenter] = useState([7.8804, 98.3923]);
  const [markerPosition, setMarkerPosition] = useState([0, 0]);
  const [skills, setSkills] = useState([]);
  const [editedHousePhoto, setEditedHousePhoto] = useState(null);
  const [editingProblemPhoto, setEditingProblemPhoto] = useState(null);
  const navigate = useNavigate();
  const finishedProblemsRef = useRef(null);
  const [showFinishedProblems, setShowFinishedProblems] = useState(false);
  const [latestInProgressProblems, setLatestInProgressProblems] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [showMapEditHouse, setShowMapEditHouse] = useState(false);
  const [showUrgentProblemModal, setShowUrgentProblemModal] = useState(false);
  const [showPlannedProblemModal, setShowPlannedProblemModal] = useState(false);
  const urgentProblemModalRef = useRef(null);
  const plannedProblemModalRef = useRef(null);
  const [subscriptionError, setSubscriptionError] = useState(null);

  const [activeUrgency, setActiveUrgency] = React.useState('Urgent'); // "Urgent" | "Planned"
  const [openPhotoModal, setOpenPhotoModal] = useState(false);
  const [photoModal, setPhotoModal] = useState(null);
  const { t } = useTranslation();

  const handleOpenPhotoModal = (photo) => {
    setPhotoModal(photo);
    setOpenPhotoModal(true);
  };

  const handleClosePhotoModal = () => {
    setOpenPhotoModal(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Scheduled Date:', scheduledDate);
    // Здесь логика отправки данных на сервер
  };

  // DATA AND MAP INIT ////////////////////////////
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        const houseData = await fetchHouse();

        const problems = await fetchProblems(houseId);
        const fetchedSkills = await fetchSkills();

        const storedTime = localStorage.getItem('preferredTime') || defaultTime;

        setDefaultTime(storedTime);

        if (fetchedSkills.length > 0) {
          setNewUrgentProblem((problem) => ({
            ...problem,
            problemType: fetchedSkills[0]._id,
            timeRange: storedTime,
          }));
          setNewPlannedProblem((problem) => ({
            ...problem,
            problemType: fetchedSkills[0]._id,
            timeRange: storedTime,
          }));
        }

        // const urgentProblems = problemsData
        //   .filter(p => p.urgencyLevel === 'Urgent')
        //   .map(p => ({ ...p, isEditing: false }))
        // const plannedProblems = problemsData
        //   .filter(p => p.urgencyLevel === 'Planned')
        //   .map(p => ({ ...p, isEditing: false }))

        // setUrgentProblems(urgentProblems)
        // setPlannedProblems(plannedProblems)

        const inProgressProblems = problems.urgent.filter(
          (p) => p.status === 'In Progress'
        );
        const latestProblems = inProgressProblems.slice(-3);
        setLatestInProgressProblems(latestProblems);

        if (houseData?.mapLocationCoordinates?.coordinates.length === 2) {
          initializeMap(houseData.mapLocationCoordinates.coordinates);
        }
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [houseId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        finishedProblemsRef.current &&
        !finishedProblemsRef.current.contains(event.target)
      ) {
        setShowFinishedProblems(false);
      }

      if (
        urgentProblemModalRef.current &&
        !urgentProblemModalRef.current.contains(event.target)
      ) {
        setShowUrgentProblemModal(false);
      }
      if (
        plannedProblemModalRef.current &&
        !plannedProblemModalRef.current.contains(event.target)
      ) {
        setShowPlannedProblemModal(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showFinishedProblems]);

  useEffect(() => {
    const loadSubscriptionUsage = async () => {
      const userId = tokenService.getUserId();
      console.log('Retrieved userId:', userId);
      if (userId) {
        try {
          console.log('Loading subscription usage for userId:', userId);
          await SubscriptionUsage(userId);
        } catch (error) {
          console.error(
            'Ошибка при загрузке данных об использовании подписки:',
            error
          );
        }
      } else {
        console.log('userId is missing in tokenService');
      }
    };

    loadSubscriptionUsage();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const userId = await decodeToken();
      if (!userId) return;

      setUserId(userId);
      await loadUserProfile(userId);
    };

    fetchData();
  }, []);

  const decodeToken = async () => {
    console.log('decodeToken: Начало');
    try {
      const token = tokenService.getToken();
      if (!token) {
        console.error('Token not found');
        //navigate('/client-register');

        return null;
      }

      const base64Url = token.split('.')[1];
      if (!base64Url) {
        console.error('Invalid token format');
        return null;
      }

      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );

      const decoded = JSON.parse(jsonPayload);
      if (!decoded || !decoded._id) {
        console.error('Invalid token: no _id found');
        return null;
      }

      console.log('decodeToken: Конец, userId:', decoded._id);
      return decoded._id;
    } catch (error) {
      console.error('Ошибка при декодировании токена:', error);
      //navigate('/client-register');

      return null;
    }
  };

  const loadUserProfile = async (userId) => {
    if (!userId) {
      console.error('User ID is required to load profile.');
      return;
    }

    try {
      const token = tokenService.getToken();
      const response = await axios.get(
        `https://fix.house/api/users/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const userData = response.data;
      setUserName(userData.name);
    } catch (error) {
      console.error('Error loading user profile:', error);
      throw error;
    }
  };

  const handleAddUrgentProblem = () => {
    setShowUrgentProblemModal(true);
  };

  const handleAddPlannedProblem = () => {
    setShowPlannedProblemModal(true);
  };

  // FETCH HOUSE ///////////////
  const fetchHouse = async () => {
    try {
      const token = tokenService.getToken();

      if (!token) {
        console.error('Аутентификационный  11 токен не найден');
        navigate('/client-register');

        return;
      }

      const response = await axios.get(
        `https://fix.house/api/client/houses/${houseId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setHouse(response.data);
      console.log(`Данные о доме получены:`, response.data);
      setEditedHouse({ ...response.data });
    } catch (error) {
      console.error('Ошибка при получении данных о доме:', error);
    }
  };

  // FETCH PROBLEMS /////////////////////
  const fetchProblems = async () => {
    try {
      const receivedProblems = await getProblems({ houseId: houseId });

      const filteredReceivedProblems = receivedProblems.reduce(
        (accumulator, receivedProblem) => {
          const problemWithWorker = {
            ...receivedProblem,
            isEditing: false,
            worker: receivedProblem.workerID, // Теперь данные работника доступны здесь
          };

          if (receivedProblem.urgencyLevel === 'Urgent') {
            accumulator.urgent.push(problemWithWorker);
          }
          if (receivedProblem.urgencyLevel === 'Planned') {
            accumulator.planned.push(problemWithWorker);
          }

          return accumulator;
        },
        { urgent: [], planned: [] }
      );

      setUrgentProblems(filteredReceivedProblems.urgent);
      setPlannedProblems(filteredReceivedProblems.planned);

      return filteredReceivedProblems;
    } catch (error) {
      console.error('Ошибка при получении проблем:', error);
    }
  };

  const fetchSkills = async () => {
    try {
      const token = tokenService.getToken();
      if (!token) {
        console.error('Аутентификационный токен не найден');
        return;
      }

      const response = await axios.get('https://fix.house/api/skills', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setSkills(response.data);
      return response.data;
    } catch (error) {
      console.error('Ошибка при получении списка навыков:', error);
      return [];
    }
  };

  const getSkillNameById = (skillId) => {
    const skill = skills.find((s) => s._id === skillId);
    return skill ? skill.skillName : 'Неизвестный навык';
  };

  const initializeMap = () => {
    if (
      house &&
      house.mapLocationCoordinates &&
      house.mapLocationCoordinates.coordinates.length === 2
    ) {
      const [lng, lat] = house.mapLocationCoordinates.coordinates;
      console.log('Инициализация карты с координатами: ', lat, lng);
      setMapCenter([lat, lng]);
      setMarkerPosition([lat, lng]);
    } else {
      console.log('Координаты не загружены: ', house);
    }
  };

  const saveHouse = async () => {
    const formData = new FormData();
    formData.append('name', editedHouse.name);
    formData.append('address', editedHouse.address);
    formData.append('housingType', editedHouse.housingType);
    formData.append('mapLocationCoordinates[type]', 'Point');
    formData.append(
      'mapLocationCoordinates[coordinates][0]',
      editedHouse.mapLocationCoordinates.coordinates[0]
    );
    formData.append(
      'mapLocationCoordinates[coordinates][1]',
      editedHouse.mapLocationCoordinates.coordinates[1]
    );
    formData.append('squareMeters', editedHouse.squareMeters);

    if (editedHousePhoto) {
      formData.append('image', editedHousePhoto);
    }

    if (editedHouse.housingType === 'Apartment') {
      formData.append('floor', editedHouse.floor);
      formData.append('apartmentNumber', editedHouse.apartmentNumber);
    }

    try {
      const token = tokenService.getToken();
      if (!token) {
        console.error('Аутентификационный токен не найден');
        return;
      }

      const response = await axios.put(
        `https://fix.house/api/client/houses/${house._id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200) {
        setHouse(response.data);
        setIsEditing(false);

        const [lng, lat] = response.data.mapLocationCoordinates.coordinates;
        setMapCenter([lat, lng]);
        setMarkerPosition([lat, lng]);
      }
    } catch (error) {
      console.error('Ошибка при сохранении данных о доме:', error);
    }
  };

  const ControlModuleEditHouse = () => {
    setIsEditing(!isEditing);
  };

  const submitProblem = async (urgencyLevel, problemData) => {
    try {
      const createdProblem = await addProblem({
        houseId: houseId,
        urgencyLevel: urgencyLevel,
        description: problemData.description,
        problemType: problemData.problemType,
        timeRange: problemData.timeRange,
        scheduledDate: scheduledDate,
        newProblemPhoto: newProblemPhoto,
      });

      // Обновляем состояние проблем
      if (urgencyLevel === 'Urgent') {
        setUrgentProblems((urgentProblems) => [
          ...urgentProblems,
          {
            ...createdProblem.problem,
            initialPhotoID: createdProblem.photo,
            isEditing: false,
          },
        ]);
      } else if (urgencyLevel === 'Planned') {
        setPlannedProblems((plannedProblems) => [
          ...plannedProblems,
          {
            ...createdProblem.problem,
            initialPhotoID: createdProblem.photo,
            isEditing: false,
          },
        ]);
      }

      // Запрашиваем обновленные данные об использовании подписки
      const userId = tokenService.getUserId();
      if (userId) {
        try {
          const updatedSubscriptionData = await SubscriptionUsage(userId);
          if (updatedSubscriptionData) {
            setCallsData({
              urgentCallsUsedTotal: updatedSubscriptionData.urgentCallsUsed,
              urgentCallsLimitTotal: updatedSubscriptionData.urgentCallsLimit,
              plannedCallsUsedTotal: updatedSubscriptionData.plannedCallsUsed,
              plannedCallsLimitTotal: updatedSubscriptionData.plannedCallsLimit,
              remainingUrgentCalls:
                updatedSubscriptionData.remainingUrgentCalls,
              remainingPlannedCalls:
                updatedSubscriptionData.remainingPlannedCalls,
            });
          }
        } catch (error) {
          console.error(
            'Ошибка при обновлении данных об использовании подписки:',
            error
          );
        }
      }

      // Закрываем модальное окно
      if (urgencyLevel === 'Urgent') {
        setShowUrgentProblemModal(false);
      } else {
        setShowPlannedProblemModal(false);
      }

      return { status: 200, message: 'Проблема успешно добавлена' };
    } catch (error) {
      console.error('Ошибка при добавлении проблемы:', error);
      // Обработка ошибок...
    }
  };

  const resetProblemForm = (urgencyLevel) => {
    const defaultProblemType = skills.length > 0 ? skills[0]._id : '';
    const defaultPrefferedTime =
      localStorage.getItem('preferredTime') || defaultTime;

    if (urgencyLevel === 'Urgent') {
      setNewUrgentProblem({
        description: '',
        problemType: defaultProblemType,
        status: 'Open',
        timeRange: defaultPrefferedTime,
      });
    } else if (urgencyLevel === 'Planned') {
      setNewPlannedProblem({
        description: '',
        problemType: defaultProblemType,
        status: 'Open',
        timeRange: defaultPrefferedTime,
      });
    }
  };

  const getProblemsByStatusAndType = (problems, status, type) => {
    return problems.filter(
      // problem => problem.status === status && problem.urgencyLevel === type
      (problem) =>
        problem.status === status && (!type || problem.urgencyLevel === type)
    );
  };

  const startEditingProblem = (problem) => {
    setEditingProblem({ ...problem });
    SetStatus(problem.status);
  };

  const saveProblem = async () => {
    console.log('Saving problem', editingProblem);

    if (!editingProblem) return;

    const formData = new FormData();
    console.log('formData', Object.fromEntries(formData.entries()));

    formData.append('description', editingProblem.description);
    formData.append('urgencyLevel', editingProblem.urgencyLevel);
    formData.append('problemType', editingProblem.problemType);
    formData.append('status', editingProblem.status);
    formData.append('preferredTime', editingProblem.preferredTime);
    if (editingProblem.status === 'In Progress') {
      formData.append('startedDate', new Date().toISOString());
    } else if (editingProblem.status === 'Finished') {
      formData.append('finishedDate', new Date().toISOString());
    }

    if (
      editingProblem.status === 'Open' &&
      editingProblem.urgencyLevel === 'Planned' &&
      editingProblem.scheduledDate
    ) {
      formData.append('scheduledDate', editingProblem.scheduledDate);
    }

    console.log('Editing problem photo:', editingProblemPhoto);
    if (editingProblemPhoto) {
      formData.append('photo', editingProblemPhoto);
    } else {
      console.log('No photo to upload');
    }

    try {
      const token = tokenService.getToken();
      if (!token) {
        console.error('Аутентификационный токен не найден');
        return;
      }

      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }

      const response = await axios.put(
        `https://fix.house/api/problems/client/houses/${houseId}/problems/${editingProblem._id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      console.log('Response from server:', response);

      if (response.status === 200) {
        await fetchProblems();
        console.error('Error fetchProblems 200 не 200:');
      }
    } catch (error) {
      console.error('Ошибка при сохранении изменений проблемы:', error);
    }

    setEditingProblem(null);
    setEditingProblemPhoto(null);
  };

  const getProblemImageUrl = (imagePath) => {
    if (!imagePath) {
      return 'путь/к/запасному/изображению.jpg';
    }
    const correctedPath = imagePath.replace(/\\/g, '/');
    return `https://fix.house/${correctedPath}`;
  };

  const getHouseImageUrl = (imagePath) => {
    if (!imagePath) {
      return 'путь/к/запасному/изображению.jpg';
    }
    const correctedPath = imagePath.replace(/\\/g, '/');
    return `https://fix.house/${correctedPath}`;
  };

  const handlePhotoUploads = (files) => {
    const file = files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedPhoto(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePhotoUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      handlePhotoUploads(event.target.files);
      setNewProblemPhoto(event.target.files[0]);
    } else {
      setNewProblemPhoto(null);
    }
  };

  const handleProblemPhotoUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      handlePhotoUploads(event.target.files);
      setEditingProblemPhoto(event.target.files[0]);
    } else {
      setEditingProblemPhoto(null);
    }
  };

  const handleHousePhotoUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      handlePhotoUploads(event.target.files);
      setEditedHousePhoto(event.target.files[0]);
    } else {
      setEditedHousePhoto(null);
    }
  };

  const deleteProblem = async (problemId, urgencyLevel) => {
    try {
      const token = tokenService.getToken();
      if (!token) {
        console.error('Аутентификационный токен не найден');
        return;
      }

      const response = await axios.delete(
        `https://fix.house/api/problems/client/houses/${houseId}/problems/${problemId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const updatedProblems = await fetchProblems();

        if (urgencyLevel === 'Urgent') {
          setUrgentProblems(
            updatedProblems.filter((p) => p.urgencyLevel === 'Urgent')
          );
        } else if (urgencyLevel === 'Planned') {
          setPlannedProblems(
            updatedProblems.filter((p) => p.urgencyLevel === 'Planned')
          );
        }
        setShowDelete(null);
      }
    } catch (error) {
      console.error('Ошибка при удалении проблемы:', error);
    }
  };

  const getProblemsByStatus = (problems, status) => {
    return problems.filter((problem) => problem.status === status);
  };

  const deleteHouse = async () => {
    try {
      const token = tokenService.getToken();
      if (!token) {
        console.error('Аутентификационный токен не найден');
        return;
      }

      const response = await axios.delete(
        `https://fix.house/api/client/houses/${houseId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log('Дом успешно удален');
        // Редирект на страницу клиента
        navigate('/client-page');
      }
    } catch (error) {
      console.error('Ошибка при удалении дома:', error);
      // Doбавить обработку ошибки, показать уведомление пользователю
    }
  };

  ///// РАСКРЫТЬ КАРТУ
  const toggleMapVisibility = () => {
    setShowMap(!showMap);
  };
  ///// РАСКРЫТЬ КАРТУ

  const mapClicked = (event) => {
    updateMarkerPosition(event.latlng);
  };

  const updateMarkerPosition = (latlng) => {
    setMarkerPosition([latlng.lat, latlng.lng]);

    setEditedHouse({
      ...editedHouse,
      mapLocationCoordinates: {
        ...editedHouse.mapLocationCoordinates,
        coordinates: [latlng.lng, latlng.lat],
      },
    });
  };

  const LocationMarker = () => {
    useMapEvents({
      click(e) {
        setMarkerPosition(e.latlng);
        setEditedHouse({
          ...editedHouse,
          mapLocationCoordinates: {
            ...editedHouse.mapLocationCoordinates,
            coordinates: [e.latlng.lng, e.latlng.lat],
          },
        });
      },
    });

    return markerPosition ? (
      <Marker position={markerPosition} icon={customMarkerIcon} />
    ) : null;
  };

  const customMarkerIcon = L.icon({
    iconUrl: '/marker/marker-icon.png',
    shadowUrl: '/marker/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  const handleButtonClickUrgent = () => {
    setActive(true);
  };

  const handleButtonClickPlanned = () => {
    setActive(false);
  };

  const ControlModuleDelete = (problem) => {
    setShowDelete(problem);
  };

  const ControlModuleInfo = (problem) => {
    setShowFullInfo(problem);
    setShowDelete(null);
  };

  const handleStatusClick = (status) => {
    setEditingProblem({ ...editingProblem, status: status });
    SetStatus(status);
    setShowDelete(null);
  };

  const ControlModuleProblemPlanned = () => {
    setSelectedPhoto(null);
    setShowPlannedProblemModal(!showPlannedProblemModal);
    setShowDelete(null);
  };

  const ControlModuleProblemUrgent = () => {
    setSelectedPhoto(null);
    setShowUrgentProblemModal(!showUrgentProblemModal);
    setShowDelete(null);
  };

  const ControlModuleListProblem = (status) => {
    setStatusProblem(status);
    setShowModuleListProblem((s) => !s);
    setShowDelete(null);
  };

  const ControlModuleListProblemOpen = (status) => {
    setStatusProblemOpen(status);
    setShowModuleListProblemOpen(!showModuleListProblemOpen);
    setShowUrgentProblemModal(false);
    setShowPlannedProblemModal(false);
    setShowDelete(null);
    setshowFullInfoSmall(null);
    setShowFullInfo(null);
  };

  const ToggleShowMapEditHouse = () => {
    setShowMapEditHouse(!showMapEditHouse);
    setShowDelete(null);
  };

  const ControlShowInfoModalSmall = (problem) => {
    setshowFullInfoSmall(problem);
    setShowDelete(null);
  };

  const SubscriptionUsage = async (userIdFromParam) => {
    const userIdEffective = userIdFromParam || userId;
    console.log('Fetching subscription usage for userId:', userIdEffective);

    if (!userIdEffective) {
      console.error('User ID is required to load subscription usage.');
      return;
    }

    try {
      const token = tokenService.getToken();
      if (!token) {
        console.error('Authentication token not found');
        return;
      }

      const response = await axios.get(
        `https://fix.house/api/subscription-usage/user/${userIdEffective}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log('Subscription usage response:', response.data);

      if (response.data && response.data.length > 0) {
        const usage = response.data[0]; // Берем первый элемент массива
        setSubscriptionUsage(response.data);

        // Обновляем callsData
        setCallsData({
          urgentCallsUsedTotal: usage.urgentCallsUsed,
          urgentCallsLimitTotal: usage.urgentCallsLimit,
          plannedCallsUsedTotal: usage.plannedCallsUsed,
          plannedCallsLimitTotal: usage.plannedCallsLimit,
          remainingUrgentCalls: usage.remainingUrgentCalls,
          remainingPlannedCalls: usage.remainingPlannedCalls,
        });
      } else {
        console.log('Response data is empty or undefined');
      }

      return response.data;
    } catch (error) {
      console.error('Error loading subscription usage:', error);
      setSubscriptionError(
        error.message || 'Failed to load subscription usage data'
      );
    }
  };

  // Группировка данных об использовании подписки
  const getSubscriptionUsageTotals = () => {
    let plannedCallsLimitTotal = 0;
    let plannedCallsUsedTotal = 0;
    let urgentCallsLimitTotal = 0;
    let urgentCallsUsedTotal = 0;
    let remainingPlannedCallsTotal = 0;
    let remainingUrgentCallsTotal = 0;

    subscriptionUsage.forEach((usage) => {
      plannedCallsLimitTotal += usage.plannedCallsLimit;
      plannedCallsUsedTotal += usage.plannedCallsUsed;
      urgentCallsLimitTotal += usage.urgentCallsLimit;
      urgentCallsUsedTotal += usage.urgentCallsUsed;
      remainingPlannedCallsTotal += usage.remainingPlannedCalls;
      remainingUrgentCallsTotal += usage.remainingUrgentCalls;
    });

    return {
      plannedCallsLimitTotal,
      plannedCallsUsedTotal,
      urgentCallsLimitTotal,
      urgentCallsUsedTotal,
      remainingPlannedCallsTotal,
      remainingUrgentCallsTotal,
    };
  };

  const handleRatingSubmit = async (problemId, rating) => {
    try {
      const token = tokenService.getToken();
      if (!token) {
        console.error('Аутентификационный токен не найден');
        return;
      }

      const response = await axios.patch(
        `https://fix.house/api/problems/${problemId}/rating`,
        { rating },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // Обновите состояние или перезагрузите данные после успешной отправки рейтинга
        fetchProblems();
      }
    } catch (error) {
      console.error('Ошибка при сохранении рейтинга:', error);
    }
  };

  const handleLogout = () => {
    tokenService.removeToken();
    navigate('/client-register');
  };

  //JSX

  return (
    <>
      <div className={styles.rootContainer}>
        <HeaderBlock
          isLoggedIn
          userName={userName}
          handleLogout={handleLogout}
        />

        <main className={styles.housePage}>
          <LanguageSwitcher />
          <HouseProfileBlock
            className={styles.houseProfileBlock}
            house={house}
            getImageUrl={getHouseImageUrl}
            handleEditHouse={ControlModuleEditHouse}
          />
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <ProblemSolvingBlock
            urgentCallsUsedTotal={callsData.urgentCallsUsedTotal}
            urgentCallsLimitTotal={callsData.urgentCallsLimitTotal}
            plannedCallsUsedTotal={callsData.plannedCallsUsedTotal}
            plannedCallsLimitTotal={callsData.plannedCallsLimitTotal}
            remainingUrgentCalls={callsData.remainingUrgentCalls}
            remainingPlannedCalls={callsData.remainingPlannedCalls}
            handleAddUrgentProblem={handleAddUrgentProblem}
            handleAddPlannedProblem={handleAddPlannedProblem}
          />

          <CurrentProblemsBlock
            className={styles.currentProblemsBlock}
            urgentProblems={urgentProblems}
            plannedProblems={plannedProblems}
            getProblemsByStatusAndType={getProblemsByStatusAndType}
            skills={skills}
            startEditingProblem={startEditingProblem}
            ControlModuleDelete={ControlModuleDelete}
            ControlModuleInfo={ControlModuleInfo}
            handleOpenActive={() => ControlModuleListProblem('Open')}
            activeUrgency={activeUrgency}
            setActiveUrgency={setActiveUrgency}
            handleOpenPhotoModal={handleOpenPhotoModal}
          />

          <ProblemsInProgressBlock
            className={styles.problemsInProgressBlock}
            urgentProblemsInProcess={getProblemsByStatusAndType(
              urgentProblems,
              'In Progress',
              'Urgent'
            )}
            plannedProblemsInProcess={getProblemsByStatusAndType(
              plannedProblems,
              'In Progress',
              'Planned'
            )}
            completedProblems={getProblemsByStatusAndType(
              [...urgentProblems, ...plannedProblems],
              'Finished'
            )}
            handleOpenUrgent={() => ControlModuleListProblem('Urgent')}
            handleOpenPlanned={() => ControlModuleListProblem('Planned')}
            handleOpenCompleted={() => ControlModuleListProblem('Finished')}
          />
        </main>

        <FooterBlock />
      </div>

      {openPhotoModal && (
        <PhotoModal
          photoModal={photoModal}
          closePhotoModal={handleClosePhotoModal}
        />
      )}

      {isEditing && (
        <EditHouseProfileModal
          closeModal={ControlModuleEditHouse}
          house={house}
          selectedPhoto={selectedPhoto}
          handleHousePhotoUpload={handleHousePhotoUpload}
          editedHouse={editedHouse}
          setEditedHouse={setEditedHouse}
          saveHouse={saveHouse}
          deleteHouse={deleteHouse}
        />
      )}

      {showUrgentProblemModal && (
        <AddProblemModal
          title={t('add_urgent_problem')}
          closeModal={ControlModuleProblemUrgent}
          selectedPhoto={selectedPhoto}
          handleProblemPhotoUpload={handlePhotoUpload}
          setNewProblem={setNewUrgentProblem}
          newProblem={newUrgentProblem}
          skills={skills}
          handleAddProblem={() => submitProblem('Urgent', newUrgentProblem)}
        />
      )}

      {showPlannedProblemModal && (
        <AddProblemModal
          title={t('add_urgent_problem')}
          closeModal={ControlModuleProblemPlanned}
          selectedPhoto={selectedPhoto}
          handleProblemPhotoUpload={handlePhotoUpload}
          setNewProblem={setNewPlannedProblem}
          newProblem={newPlannedProblem}
          skills={skills}
          handleAddProblem={() => submitProblem('Planned', newPlannedProblem)}
          // Scheduled date
          scheduledDate={scheduledDate}
          setScheduledDate={setScheduledDate}
          formattedMinDate={formattedMinDate}
        />
      )}

      {showFullInfo && !editingProblem && (
        <ViewProblemModal
          closeModal={() => ControlModuleInfo(null)}
          problem={showFullInfo}
          getSkillNameById={getSkillNameById}
          editProblem={() => startEditingProblem(showFullInfo)}
          deleteProblem={() => ControlModuleDelete(showFullInfo)}
        />
      )}

      {editingProblem && (
        <EditProblemModal
          closeModal={() => setEditingProblem(null)}
          problem={editingProblem}
          selectedPhoto={selectedPhoto}
          handleProblemPhotoUpload={handleProblemPhotoUpload}
          editingProblem={editingProblem}
          setEditingProblem={setEditingProblem}
          saveProblem={() => saveProblem(editingProblem)}
          deleteProblem={() => ControlModuleDelete(editingProblem)}
          skills={skills}
          setStatus={handleStatusClick}
          status={Status}
        />
      )}

      {showModuleListProblem &&
        StatusProblem === 'Open' &&
        !showFullInfo &&
        !editingProblem && (
          <ProblemsListModal
            closeModal={() => ControlModuleListProblem('')}
            title={t('open_problems')}
            problems={getProblemsByStatusAndType(
              [...plannedProblems, ...urgentProblems],
              'Open'
            )}
            getSkillNameById={getSkillNameById}
            startEditingProblem={startEditingProblem}
            ControlModuleDelete={ControlModuleDelete}
            ControlModuleInfo={ControlModuleInfo}
            skills={skills}
            handleOpenPhotoModal={handleOpenPhotoModal}
          />
        )}

      {showModuleListProblem &&
        StatusProblem === 'Planned' &&
        !showFullInfo &&
        !editingProblem && (
          <ProblemsListModal
            closeModal={() => ControlModuleListProblem('')}
            title={t('planned_problems')}
            problems={getProblemsByStatusAndType(
              plannedProblems,
              'In Progress',
              'Planned'
            )}
            getSkillNameById={getSkillNameById}
            startEditingProblem={startEditingProblem}
            ControlModuleDelete={ControlModuleDelete}
            ControlModuleInfo={ControlModuleInfo}
            skills={skills}
            handleOpenPhotoModal={handleOpenPhotoModal}
          />
        )}

      {showModuleListProblem &&
        StatusProblem === 'Urgent' &&
        !showFullInfo &&
        !editingProblem && (
          <ProblemsListModal
            closeModal={() => ControlModuleListProblem('')}
            title={t('urgent_in_process')}
            problems={getProblemsByStatusAndType(
              urgentProblems,
              'In Progress',
              'Urgent'
            )}
            getSkillNameById={getSkillNameById}
            startEditingProblem={startEditingProblem}
            ControlModuleDelete={ControlModuleDelete}
            ControlModuleInfo={ControlModuleInfo}
            skills={skills}
            handleOpenPhotoModal={handleOpenPhotoModal}
          />
        )}

      {showModuleListProblem &&
        StatusProblem === 'Finished' &&
        !showFullInfo &&
        !editingProblem && (
          <ProblemsListModal
            closeModal={() => ControlModuleListProblem('')}
            title={t('finished_work')}
            problems={getProblemsByStatus(
              [...urgentProblems, ...plannedProblems],
              'Finished'
            )}
            getSkillNameById={getSkillNameById}
            startEditingProblem={startEditingProblem}
            ControlModuleDelete={ControlModuleDelete}
            ControlModuleInfo={ControlModuleInfo}
            skills={skills}
            handleOpenPhotoModal={handleOpenPhotoModal}
          />
        )}

      {showDelete && (
        <DeleteProblemModal
          closeModal={() => ControlModuleDelete(null)}
          handleDelete={() => deleteProblem(showDelete._id)}
        />
      )}

      {showModuleListProblemOpen && StatusProblemOpen === 'Planned' && (
        <>
          <div
            className={styleCardFillInfo.blur}
            onClick={() => ControlModuleListProblemOpen('')}
          ></div>
          <div
            className={styleCardFillInfo.card_block}
            onClick={(e) => e.stopPropagation()}
          >
            <header className={styleCardFillInfo.header}>
              <div className={styleCardFillInfo.header_container}>
                <div
                  className={styleCardFillInfo.header_exit}
                  onClick={() => ControlModuleListProblemOpen('')}
                >
                  <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                </div>
                <div className={styleCardFillInfo.header__title}>
                  <h2>{t('planned_problems')}</h2>
                </div>
                <div className={styleCardFillInfo.header_search}>
                  <img src='/img/svg/Magnifer.svg' alt='Magnifer' />
                </div>
              </div>
            </header>
            <main className='content'>
              <div className={styleCardFillInfo.content_container}>
                <div className={style.block_list}>
                  {getProblemsByStatusAndType(
                    plannedProblems,
                    'Open',
                    'Planned'
                  ).map((problem) => (
                    <div className={styleCardProblem.block_problem_small}>
                      <div
                        className={styleCardProblem.problem_img_small}
                        style={{
                          backgroundImage: `url(${getProblemImageUrl(
                            problem.initialPhotoID?.imageURL
                          )})`,
                        }}
                        onClick={() => ControlShowInfoModalSmall(problem)}
                      >
                        <div className={styleCardProblem.problem_status_small}>
                          <span>{problem.urgencyLevel}</span>
                          <span>{problem.status}</span>
                        </div>
                        <div className={styleCardProblem.problem_date_small}>
                          <span>
                            {new Date(
                              problem.creationDate
                            ).toLocaleDateString()}
                          </span>
                        </div>
                      </div>
                      <div
                        className={styleCardProblem.problem_title_small}
                        onClick={() => ControlShowInfoModalSmall(problem)}
                      >
                        <h2>{problem.description}</h2>
                      </div>
                      <div className={styleCardProblem.problem_type_small}>
                        <span>
                          {t('type_of_issue')}:
                          <strong>
                            {getSkillNameById(problem.problemType)}
                          </strong>
                        </span>
                      </div>
                      <nav className={styleCardProblem.problem_nav_small}>
                        <button
                          type='submit'
                          className={styleCardProblem.edit_small}
                          onClick={() => startEditingProblem(problem)}
                        >
                          <span>{t('edit')}</span>
                        </button>
                        <button
                          type='submit'
                          className={styleCardProblem.delete_small}
                          onClick={() => ControlModuleDelete(problem)}
                        >
                          <img
                            src='/img/svg/TrashBinMinimalistic.svg'
                            alt='TrashBinMinimalistic'
                          />
                        </button>
                      </nav>
                      {showDelete && showDelete._id === problem._id && (
                        <>
                          <div
                            className={styleDelete.blur}
                            onClick={() => ControlModuleDelete(null)}
                          ></div>
                          <div
                            className={styleDelete.delete_block}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <div className={styleDelete.block_title}>
                              <h2>{t('problem_deletion')}</h2>
                            </div>
                            <div className={styleDelete.block_text}>
                              <span>{t('delete_problem_text')}</span>
                            </div>
                            <div className={styleDelete.block_buttons}>
                              <button
                                type='submit'
                                className={styleDelete.cancel}
                                onClick={() => ControlModuleDelete(null)}
                              >
                                {t('cancel')}
                              </button>
                              <button
                                type='submit'
                                className={styleDelete.delete}
                                onClick={() => deleteProblem(showDelete._id)}
                              >
                                {t('delete')}
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                      {showFullInfoSmall &&
                        showFullInfoSmall._id === problem._id && (
                          <>
                            <div
                              className={styleCardFillInfo.blur_none}
                              onClick={() => ControlShowInfoModalSmall(null)}
                              style={{ background: 'none' }}
                            ></div>
                            <div
                              className={styleCardFillInfo.card_block}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <header className={styleCardFillInfo.header}>
                                <div
                                  className={styleCardFillInfo.header_container}
                                >
                                  <div
                                    className={styleCardFillInfo.header_exit}
                                    onClick={() =>
                                      ControlShowInfoModalSmall(null)
                                    }
                                  >
                                    <img
                                      src='/img/svg/AltArrowLeft.svg'
                                      alt='arrow'
                                    />
                                  </div>
                                  <div
                                    className={styleCardFillInfo.header__title}
                                  >
                                    <h2>{t('planned_work')}</h2>
                                  </div>
                                  <div
                                    className={styleCardFillInfo.header_search}
                                  >
                                    <img
                                      src='/img/svg/Magnifer.svg'
                                      alt='Magnifer'
                                    />
                                  </div>
                                </div>
                              </header>
                              <main className='content'>
                                <div className='content_container'>
                                  <div
                                    className={styleCardFillInfo.block_image}
                                    style={{
                                      backgroundImage: `url(${getProblemImageUrl(
                                        showFullInfoSmall.initialPhotoID
                                          ?.imageURL
                                      )})`,
                                    }}
                                  >
                                    <div
                                      className={styleCardFillInfo.block_type}
                                    >
                                      <span>
                                        {showFullInfoSmall.urgencyLevel}
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      styleCardFillInfo.block_title_date
                                    }
                                  >
                                    <h2>{showFullInfoSmall.description}</h2>
                                    <span>
                                      {new Date(
                                        showFullInfoSmall.creationDate
                                      ).toLocaleDateString()}
                                    </span>
                                  </div>
                                  <div
                                    className={styleCardFillInfo.block_status}
                                  >
                                    <span>{t('status')}:</span>
                                    <span>{showFullInfoSmall.status}</span>
                                  </div>
                                  <div
                                    className={
                                      styleCardFillInfo.block_type_problem
                                    }
                                  >
                                    <span>{t('type_of_issue')}:</span>
                                    <span>
                                      {getSkillNameById(
                                        showFullInfoSmall.problemType
                                      )}
                                    </span>
                                  </div>
                                  <div className={styleCardFillInfo.block_time}>
                                    <span>{t('time')}</span>
                                    <span>
                                      {showFullInfoSmall.preferredTime ||
                                        'Не указано'}
                                    </span>
                                  </div>
                                  <div
                                    className={styleCardFillInfo.block_buttons}
                                  >
                                    <button
                                      type='submit'
                                      className={styleCardFillInfo.edit}
                                      onClick={() =>
                                        startEditingProblem(problem)
                                      }
                                    >
                                      <span>{t('edit')}</span>
                                    </button>
                                    <button
                                      type='submit'
                                      className={styleCardFillInfo.delete}
                                      onClick={() =>
                                        ControlModuleDelete(problem)
                                      }
                                    >
                                      <img
                                        src='/img/svg/TrashBinMinimalistic.svg'
                                        alt='TrashBinMinimalistic'
                                      />
                                    </button>
                                  </div>
                                </div>
                              </main>
                            </div>
                          </>
                        )}
                      {editingProblem && (
                        <>
                          <div
                            className={styleCardFillInfo.blur_none}
                            onClick={() => setEditingProblem(null)}
                            style={{ background: 'none' }}
                          ></div>
                          <div
                            className={styleCardFillInfo.card_block}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <header className={styleCardFillInfo.header}>
                              <div
                                className={styleCardFillInfo.header_container}
                              >
                                <div
                                  className={styleCardFillInfo.header_exit}
                                  onClick={() => setEditingProblem(null)}
                                >
                                  <img
                                    src='/img/svg/AltArrowLeft.svg'
                                    alt='arrow'
                                  />
                                </div>
                                <div
                                  className={styleEditHouse.header_save}
                                  onClick={() => saveProblem(editingProblem)}
                                >
                                  <span>{t('save')}</span>
                                </div>
                              </div>
                            </header>
                            <main className='content'>
                              <div className='content_container'>
                                <div className={styleEditHouse.header__title}>
                                  <h2>
                                    {t('edit')}{' '}
                                    <strong>{t('planned_in_process')}</strong>
                                  </h2>
                                </div>
                                <label
                                  className={styleEditHouse.block_image}
                                  style={{
                                    backgroundImage: `url(${getProblemImageUrl(
                                      problem.initialPhotoID?.imageURL
                                    )})`,
                                  }}
                                >
                                  <input
                                    type='file'
                                    onChange={handleProblemPhotoUpload}
                                    accept='image/*'
                                    style={{ display: 'none' }}
                                  />
                                  <div className={styleEditHouse.block_text}>
                                    <span>{t('change_photo')}</span>
                                  </div>
                                </label>
                                <div className={styleEditProblem.block_input}>
                                  <label htmlFor=''>
                                    {t('edit_problem_name')}:
                                  </label>
                                  <div className={styleEditProblem.input}>
                                    <input
                                      type='text'
                                      value={editingProblem.description}
                                      onChange={(e) =>
                                        setEditingProblem({
                                          ...editingProblem,
                                          description: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                                <div className={styleEditProblem.block_input}>
                                  <label htmlFor=''>
                                    {t('type_of_issue')}:
                                  </label>
                                  <div
                                    className={styleEditProblem.select_arrow}
                                  >
                                    <select
                                      value={editingProblem.problemType}
                                      onChange={(e) =>
                                        setEditingProblem({
                                          ...editingProblem,
                                          problemType: e.target.value,
                                        })
                                      }
                                    >
                                      {skills.map((skill) => (
                                        <option
                                          key={skill._id}
                                          value={skill._id}
                                        >
                                          {skill.skillName}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                <div className={styleEditProblem.block_input}>
                                  <label htmlFor=''>
                                    {t('edit_urgency_level')}:
                                  </label>
                                  <div
                                    className={styleEditProblem.select_arrow}
                                  >
                                    <select
                                      value={editingProblem.urgencyLevel}
                                      onChange={(e) =>
                                        setEditingProblem({
                                          ...editingProblem,
                                          urgencyLevel: e.target.value,
                                        })
                                      }
                                    >
                                      <option value='Urgent'>
                                        {t('urgent')}
                                      </option>
                                      <option value='Planned'>
                                        {t('planned')}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div className={styleEditProblem.block_input}>
                                  <label htmlFor=''>{t('time_range')}</label>
                                  <div
                                    className={styleEditProblem.select_arrow}
                                  >
                                    <select
                                      value={editingProblem.preferredTime}
                                      onChange={(e) =>
                                        setEditingProblem({
                                          ...editingProblem,
                                          preferredTime: e.target.value,
                                        })
                                      }
                                      className={styleEditHouse.time}
                                    >
                                      <option value='9:00--12:00'>
                                        9:00--12:00
                                      </option>
                                      <option value='12:00--15:00'>
                                        12:00--15:00
                                      </option>
                                      <option value='15:00--18:00'>
                                        15:00--18:00
                                      </option>
                                      <option value='18:00--21:00'>
                                        18:00--21:00
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div className={styleEditHouse.block_input}>
                                  <label htmlFor=''>{t('set_status')}:</label>
                                  <div className={styleEditHouse.row}>
                                    <div
                                      className={`${
                                        styleEditHouse.block_status
                                      } ${
                                        Status === 'Open'
                                          ? styleEditHouse.active
                                          : ''
                                      }`}
                                      onClick={() => handleStatusClick('Open')}
                                    >
                                      <span>{t('open')}</span>
                                    </div>
                                    <div
                                      className={`${
                                        styleEditHouse.block_status
                                      } ${
                                        Status === 'In Progress'
                                          ? styleEditHouse.active
                                          : ''
                                      }`}
                                      onClick={() =>
                                        handleStatusClick('In Progress')
                                      }
                                    >
                                      <span>{t('in_progress')}</span>
                                    </div>
                                    <div
                                      className={`${
                                        styleEditHouse.block_status
                                      } ${
                                        Status === 'Finished'
                                          ? styleEditHouse.active
                                          : ''
                                      }`}
                                      onClick={() =>
                                        handleStatusClick('Finished')
                                      }
                                    >
                                      <span>{t('finished')}</span>
                                    </div>
                                  </div>
                                </div>
                                <button
                                  type='submit'
                                  className={styleEditHouse.block_delete}
                                  onClick={() => ControlModuleDelete(problem)}
                                >
                                  <span>{t('delete_profile')}</span>
                                  <img
                                    src='/img/svg/AltArrowDown.svg'
                                    alt='arrow'
                                  />
                                </button>
                              </div>
                            </main>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </main>
          </div>
        </>
      )}
      {/* {showModuleListProblem && StatusProblem === 'Urgent' &&
            <>
              <div className={styleCardFillInfo.blur} onClick={() => ControlModuleListProblem('')}></div>
              <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                <header className={styleCardFillInfo.header}>
                  <div className={styleCardFillInfo.header_container}>
                    <div
                      className={styleCardFillInfo.header_exit}
                      onClick={() => ControlModuleListProblem('')}
                    >
                      <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                    </div>
                    <div className={styleCardFillInfo.header__title}>
                      <h2>Срочные в процессе</h2>
                    </div>
                    <div className={styleCardFillInfo.header_search}>
                      <img src='/img/svg/Magnifer.svg' alt='Magnifer' />
                    </div>
                  </div>
                </header>
                <main className='content'>
                  <div className={styleCardFillInfo.content_container}>
                    <div className={style.block_list}>
                      {getProblemsByStatusAndType(
                        urgentProblems,
                        'In Progress',
                        'Urgent'
                      ).map(problem => (
                        <div className={styleCardProblem.block_problem_small}>
                          <div
                            className={styleCardProblem.problem_img_small}
                            style={{
                              backgroundImage: `url(${getProblemImageUrl(
                                problem.initialPhotoID?.imageURL
                              )})`,
                            }}
                            onClick={() => ControlShowInfoModalSmall(problem)}
                          >
                            <div className={styleCardProblem.problem_status_small}>
                              <span>{problem.urgencyLevel}</span>
                              <span>{problem.status}</span>
                            </div>
                            <div className={styleCardProblem.problem_date_small}>
                              <span>{new Date(problem.creationDate).toLocaleDateString()}</span>
                            </div>
                          </div>
                          <div
                            className={styleCardProblem.problem_title_small}
                            onClick={() => ControlShowInfoModalSmall(problem)}
                          >
                            <h2>{problem.description}</h2>
                          </div>
                          <div className={styleCardProblem.problem_type_small}>
                            <span>
                              Тип проблемы:
                              <strong>{getSkillNameById(problem.problemType)}</strong>
                            </span>
                          </div>
                          <nav className={styleCardProblem.problem_nav_small}>
                            <button type="submit"
                              className={styleCardProblem.edit_small}
                              onClick={() => startEditingProblem(problem)}
                            >
                              <span>Редактировать</span>
                            </button>
                            <button type="submit"
                              className={styleCardProblem.delete_small}
                              onClick={() => ControlModuleDelete(problem)}
                            >
                              <img
                                src='/img/svg/TrashBinMinimalistic.svg'
                                alt='TrashBinMinimalistic'
                              />
                            </button>
                          </nav>
                          {showDelete && showDelete._id === problem._id && <>
                            <div className={styleDelete.blur} onClick={() => ControlModuleDelete(null)}></div>
                            <div className={styleDelete.delete_block} onClick={e => e.stopPropagation()}>
                              <div className={styleDelete.block_title}>
                                <h2>Удаление проблемы</h2>
                              </div>
                              <div className={styleDelete.block_text}>
                                <span>Вы действительно хотите удалить проблему?</span>
                              </div>
                              <div className={styleDelete.block_buttons}>
                                <button type="submit"
                                  className={styleDelete.cancel}
                                  onClick={() => ControlModuleDelete(null)}
                                >
                                  Отмена
                                </button>
                                <button type="submit"
                                  className={styleDelete.delete}
                                  onClick={() => deleteProblem(showDelete._id)}
                                >
                                  Удалить
                                </button>
                              </div>
                            </div>
                          </>}
                          {showFullInfoSmall && showFullInfoSmall._id === problem._id &&
                            <>
                              <div className={styleCardFillInfo.blur_none} onClick={() => ControlShowInfoModalSmall(null)}></div>
                              <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                                <header className={styleCardFillInfo.header}>
                                  <div className={styleCardFillInfo.header_container}>
                                    <div
                                      className={styleCardFillInfo.header_exit}
                                      onClick={() => ControlShowInfoModalSmall(null)}
                                    >
                                      <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                                    </div>
                                    <div className={styleCardFillInfo.header__title}>
                                      <h2>срочные проблемы</h2>
                                    </div>
                                    <div className={styleCardFillInfo.header_search}>
                                      <img src='/img/svg/Magnifer.svg' alt='Magnifer' />
                                    </div>
                                  </div>
                                </header>
                                <main className='content'>
                                  <div className='content_container'>
                                    <div
                                      className={styleCardFillInfo.block_image}
                                      style={{
                                        backgroundImage: `url(${getProblemImageUrl(
                                          showFullInfoSmall.initialPhotoID?.imageURL
                                        )})`,
                                      }}
                                    >
                                      <div className={styleCardFillInfo.block_type}>
                                        <span>{showFullInfoSmall.urgencyLevel}</span>
                                      </div>
                                    </div>
                                    <div className={styleCardFillInfo.block_title_date}>
                                      <h2>{showFullInfoSmall.description}</h2>
                                      <span>{new Date(showFullInfoSmall.creationDate).toLocaleDateString()}</span>
                                    </div>
                                    <div className={styleCardFillInfo.block_status}>
                                      <span>Статус:</span>
                                      <span>{showFullInfoSmall.status}</span>
                                    </div>
                                    <div className={styleCardFillInfo.block_type_problem}>
                                      <span>Тип проблемы:</span>
                                      <span>{getSkillNameById(showFullInfoSmall.problemType)}</span>
                                    </div>
                                    <div className={styleCardFillInfo.block_time}>
                                      <span>Time</span>
                                      <span>{showFullInfoSmall.preferredTime || 'Не указано'}</span>
                                    </div>
                                    <div className={styleCardFillInfo.block_buttons}>
                                      <button type="submit"
                                        className={styleCardFillInfo.edit}
                                        onClick={() => startEditingProblem(problem)}
                                      >
                                        <span>Редактировать</span>
                                      </button>
                                      <button type="submit"
                                        className={styleCardFillInfo.delete}
                                        onClick={() => ControlModuleDelete(problem)}
                                      >
                                        <img
                                          src='/img/svg/TrashBinMinimalistic.svg'
                                          alt='TrashBinMinimalistic'
                                        />
                                      </button>
                                    </div>
                                  </div>
                                </main>
                              </div>
                            </>
                          }
                          {editingProblem && (
                            <>
                              <div className={styleCardFillInfo.blur_none} onClick={() => setEditingProblem(null)}></div>
                              <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                                <header className={styleCardFillInfo.header}>
                                  <div className={styleCardFillInfo.header_container}>
                                    <div
                                      className={styleCardFillInfo.header_exit}
                                      onClick={() => setEditingProblem(null)}
                                    >
                                      <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                                    </div>
                                    <div
                                      className={styleEditHouse.header_save}
                                      onClick={() => saveProblem(editingProblem)}
                                    >
                                      <span>Сохранить</span>
                                    </div>
                                  </div>
                                </header>
                                <main className='content'>
                                  <div className='content_container'>
                                    <div className={styleEditHouse.header__title}>
                                      <h2>
                                        Редактирование <strong>Срочные в процессе</strong>
                                      </h2>
                                    </div>
                                    <label
                                      className={styleEditHouse.block_image}
                                      style={{
                                        backgroundImage: `url(${getProblemImageUrl(
                                          problem.initialPhotoID?.imageURL
                                        )})`,
                                      }}
                                    >
                                      <input
                                        type='file'
                                        onChange={handleProblemPhotoUpload}
                                        accept='image/*'
                                        style={{ display: 'none' }}
                                      />
                                      <div className={styleEditHouse.block_text}>
                                        <span>Изменить фото</span>
                                      </div>
                                    </label>
                                    <div className={styleEditProblem.block_input}>
                                      <label htmlFor=''>Название проблемы:</label>
                                      <div className={styleEditProblem.input}>
                                        <input type='text' value={editingProblem.description}
                                          onChange={e => setEditingProblem({ ...editingProblem, description: e.target.value })} />
                                      </div>
                                    </div>
                                    <div className={styleEditProblem.block_input}>
                                      <label htmlFor=''>Тип проблемы:</label>
                                      <div className={styleEditProblem.select_arrow}>
                                        <select
                                          value={editingProblem.problemType}
                                          onChange={e =>
                                            setEditingProblem({
                                              ...editingProblem,
                                              problemType: e.target.value,
                                            })
                                          }
                                        >
                                          {skills.map(skill => (
                                            <option key={skill._id} value={skill._id}>
                                              {skill.skillName}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                    <div className={styleEditProblem.block_input}>
                                      <label htmlFor=''>Сложность проблемы:</label>
                                      <div className={styleEditProblem.select_arrow}>
                                        <select
                                          value={editingProblem.urgencyLevel}
                                          onChange={e =>
                                            setEditingProblem({
                                              ...editingProblem,
                                              urgencyLevel: e.target.value,
                                            })
                                          }
                                        >
                                          <option value='Urgent'>Urgent</option>
                                          <option value='Planned'>Planned</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className={styleEditProblem.block_input}>
                                      <label htmlFor=''>Время работ</label>
                                      <div className={styleEditProblem.select_arrow}>
                                        <select
                                          value={editingProblem.preferredTime}
                                          onChange={e =>
                                            setEditingProblem({
                                              ...editingProblem,
                                              preferredTime: e.target.value,
                                            })
                                          }
                                          className={styleEditHouse.time}
                                        >
                                          <option value='9:00--12:00'>9:00--12:00</option>
                                          <option value='12:00--15:00'>12:00--15:00</option>
                                          <option value='15:00--18:00'>15:00--18:00</option>
                                          <option value='18:00--21:00'>18:00--21:00</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className={styleEditHouse.block_input}>
                                      <label htmlFor=''>Сделать статус:</label>
                                      <div className={styleEditHouse.row}>
                                        <div
                                          className={`${styleEditHouse.block_status} ${Status === 'Open' ? styleEditHouse.active : ''
                                            }`}
                                          onClick={() => handleStatusClick('Open')}
                                        >
                                          <span>Open</span>
                                        </div>
                                        <div
                                          className={`${styleEditHouse.block_status} ${Status === 'In Progress' ? styleEditHouse.active : ''
                                            }`}
                                          onClick={() => handleStatusClick('In Progress')}
                                        >
                                          <span>In Progress</span>
                                        </div>
                                        <div
                                          className={`${styleEditHouse.block_status} ${Status === 'Finished' ? styleEditHouse.active : ''
                                            }`}
                                          onClick={() => handleStatusClick('Finished')}
                                        >
                                          <span>Finished</span>
                                        </div>
                                      </div>
                                    </div>
                                    <button type="submit"
                                      className={styleEditHouse.block_delete}
                                      onClick={() => ControlModuleDelete(problem)}
                                    >
                                      <span>Удалить профиль</span>
                                      <img src='/img/svg/AltArrowDown.svg' alt='arrow' />
                                    </button>
                                  </div>
                                </main>
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </main>
              </div>
            </>
          } */}
      {/* {showModuleListProblem && StatusProblem === 'Planned' &&
            <>
              <div className={styleCardFillInfo.blur} onClick={() => ControlModuleListProblem('')}></div>
              <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                <header className={styleCardFillInfo.header}>
                  <div className={styleCardFillInfo.header_container}>
                    <div
                      className={styleCardFillInfo.header_exit}
                      onClick={() => ControlModuleListProblem('')}
                    >
                      <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                    </div>
                    <div className={styleCardFillInfo.header__title}>
                      <h2>Планированные в процессе</h2>
                    </div>
                    <div className={styleCardFillInfo.header_search}>
                      <img src='/img/svg/Magnifer.svg' alt='Magnifer' />
                    </div>
                  </div>
                </header>
                <main className='content'>
                  <div className={styleCardFillInfo.content_container}>
                    <div className={style.block_list}>
                      {getProblemsByStatusAndType(
                        plannedProblems,
                        'In Progress',
                        'Planned'
                      ).map(problem => (
                        <div className={styleCardProblem.block_problem_small}>
                          <div
                            className={styleCardProblem.problem_img_small}
                            style={{
                              backgroundImage: `url(${getProblemImageUrl(
                                problem.initialPhotoID?.imageURL
                              )})`,
                            }}
                            onClick={() => ControlShowInfoModalSmall(problem)}
                          >
                            <div className={styleCardProblem.problem_status_small}>
                              <span>{problem.urgencyLevel}</span>
                              <span>{problem.status}</span>
                            </div>
                            <div className={styleCardProblem.problem_date_small}>
                              <span>{new Date(problem.creationDate).toLocaleDateString()}</span>
                            </div>
                          </div>
                          <div
                            className={styleCardProblem.problem_title_small}
                            onClick={() => ControlShowInfoModalSmall(problem)}
                          >
                            <h2>{problem.description}</h2>
                          </div>
                          <div className={styleCardProblem.problem_type_small}>
                            <span>
                              Тип проблемы:
                              <strong>{getSkillNameById(problem.problemType)}</strong>
                            </span>
                          </div>
                          <nav className={styleCardProblem.problem_nav_small}>
                            <button type="submit"
                              className={styleCardProblem.edit_small}
                              onClick={() => startEditingProblem(problem)}
                            >
                              <span>Редактировать</span>
                            </button>
                            <button type="submit"
                              className={styleCardProblem.delete_small}
                              onClick={() => ControlModuleDelete(problem)}
                            >
                              <img
                                src='/img/svg/TrashBinMinimalistic.svg'
                                alt='TrashBinMinimalistic'
                              />
                            </button>
                          </nav>
                          {showDelete && showDelete._id === problem._id && <>
                            <div className={styleDelete.blur} onClick={() => ControlModuleDelete(null)}></div>
                            <div className={styleDelete.delete_block} onClick={e => e.stopPropagation()}>
                              <div className={styleDelete.block_title}>
                                <h2>Удаление проблемы</h2>
                              </div>
                              <div className={styleDelete.block_text}>
                                <span>Вы действительно хотите удалить проблему?</span>
                              </div>
                              <div className={styleDelete.block_buttons}>
                                <button type="submit"
                                  className={styleDelete.cancel}
                                  onClick={() => ControlModuleDelete(null)}
                                >
                                  Отмена
                                </button>
                                <button type="submit"
                                  className={styleDelete.delete}
                                  onClick={() => deleteProblem(showDelete._id)}
                                >
                                  Удалить
                                </button>
                              </div>
                            </div>
                          </>}
                          {showFullInfoSmall && showFullInfoSmall._id === problem._id &&
                            <>
                              <div className={styleCardFillInfo.blur_none} onClick={() => ControlShowInfoModalSmall(null)}></div>
                              <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                                <header className={styleCardFillInfo.header}>
                                  <div className={styleCardFillInfo.header_container}>
                                    <div
                                      className={styleCardFillInfo.header_exit}
                                      onClick={() => ControlShowInfoModalSmall(null)}
                                    >
                                      <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                                    </div>
                                    <div className={styleCardFillInfo.header__title}>
                                      <h2>Планированные проблемы</h2>
                                    </div>
                                    <div className={styleCardFillInfo.header_search}>
                                      <img src='/img/svg/Magnifer.svg' alt='Magnifer' />
                                    </div>
                                  </div>
                                </header>
                                <main className='content'>
                                  <div className='content_container'>
                                    <div
                                      className={styleCardFillInfo.block_image}
                                      style={{
                                        backgroundImage: `url(${getProblemImageUrl(
                                          showFullInfoSmall.initialPhotoID?.imageURL
                                        )})`,
                                      }}
                                    >
                                      <div className={styleCardFillInfo.block_type}>
                                        <span>{showFullInfoSmall.urgencyLevel}</span>
                                      </div>
                                    </div>
                                    <div className={styleCardFillInfo.block_title_date}>
                                      <h2>{showFullInfoSmall.description}</h2>
                                      <span>{new Date(showFullInfoSmall.creationDate).toLocaleDateString()}</span>
                                    </div>
                                    <div className={styleCardFillInfo.block_status}>
                                      <span>Статус:</span>
                                      <span>{showFullInfoSmall.status}</span>
                                    </div>
                                    <div className={styleCardFillInfo.block_type_problem}>
                                      <span>Тип проблемы:</span>
                                      <span>{getSkillNameById(showFullInfoSmall.problemType)}</span>
                                    </div>
                                    <div className={styleCardFillInfo.block_time}>
                                      <span>Time</span>
                                      <span>{showFullInfoSmall.preferredTime || 'Не указано'}</span>
                                    </div>
                                    <div className={styleCardFillInfo.block_buttons}>
                                      <button type="submit"
                                        className={styleCardFillInfo.edit}
                                        onClick={() => startEditingProblem(problem)}
                                      >
                                        <span>Редактировать</span>
                                      </button>
                                      <button type="submit"
                                        className={styleCardFillInfo.delete}
                                        onClick={() => ControlModuleDelete(problem)}
                                      >
                                        <img
                                          src='/img/svg/TrashBinMinimalistic.svg'
                                          alt='TrashBinMinimalistic'
                                        />
                                      </button>
                                    </div>
                                  </div>
                                </main>
                              </div>
                            </>
                          }
                          {editingProblem && (
                            <>
                              <div className={styleCardFillInfo.blur_none} onClick={() => setEditingProblem(null)}></div>
                              <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                                <header className={styleCardFillInfo.header}>
                                  <div className={styleCardFillInfo.header_container}>
                                    <div
                                      className={styleCardFillInfo.header_exit}
                                      onClick={() => setEditingProblem(null)}
                                    >
                                      <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                                    </div>
                                    <div
                                      className={styleEditHouse.header_save}
                                      onClick={() => saveProblem(editingProblem)}
                                    >
                                      <span>Сохранить</span>
                                    </div>
                                  </div>
                                </header>
                                <main className='content'>
                                  <div className='content_container'>
                                    <div className={styleEditHouse.header__title}>
                                      <h2>
                                        Редактирование <strong>Планированные в процессе</strong>
                                      </h2>
                                    </div>
                                    <label
                                      className={styleEditHouse.block_image}
                                      style={{
                                        backgroundImage: `url(${getProblemImageUrl(
                                          problem.initialPhotoID?.imageURL
                                        )})`,
                                      }}
                                    >
                                      <input
                                        type='file'
                                        onChange={handleProblemPhotoUpload}
                                        accept='image/*'
                                        style={{ display: 'none' }}
                                      />
                                      <div className={styleEditHouse.block_text}>
                                        <span>Изменить фото</span>
                                      </div>
                                    </label>
                                    <div className={styleEditProblem.block_input}>
                                      <label htmlFor=''>Название проблемы:</label>
                                      <div className={styleEditProblem.input}>
                                        <input type='text' value={editingProblem.description}
                                          onChange={e => setEditingProblem({ ...editingProblem, description: e.target.value })} />
                                      </div>
                                    </div>
                                    <div className={styleEditProblem.block_input}>
                                      <label htmlFor=''>Тип проблемы:</label>
                                      <div className={styleEditProblem.select_arrow}>
                                        <select
                                          value={editingProblem.problemType}
                                          onChange={e =>
                                            setEditingProblem({
                                              ...editingProblem,
                                              problemType: e.target.value,
                                            })
                                          }
                                        >
                                          {skills.map(skill => (
                                            <option key={skill._id} value={skill._id}>
                                              {skill.skillName}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                    <div className={styleEditProblem.block_input}>
                                      <label htmlFor=''>Сложность проблемы:</label>
                                      <div className={styleEditProblem.select_arrow}>
                                        <select
                                          value={editingProblem.urgencyLevel}
                                          onChange={e =>
                                            setEditingProblem({
                                              ...editingProblem,
                                              urgencyLevel: e.target.value,
                                            })
                                          }
                                        >
                                          <option value='Urgent'>Urgent</option>
                                          <option value='Planned'>Planned</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className={styleEditProblem.block_input}>
                                      <label htmlFor=''>Время работ</label>
                                      <div className={styleEditProblem.select_arrow}>
                                        <select
                                          value={editingProblem.preferredTime}
                                          onChange={e =>
                                            setEditingProblem({
                                              ...editingProblem,
                                              preferredTime: e.target.value,
                                            })
                                          }
                                          className={styleEditHouse.time}
                                        >
                                          <option value='9:00--12:00'>9:00--12:00</option>
                                          <option value='12:00--15:00'>12:00--15:00</option>
                                          <option value='15:00--18:00'>15:00--18:00</option>
                                          <option value='18:00--21:00'>18:00--21:00</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className={styleEditProblem.block_input}>
                                      <label htmlFor=''>Сделать статус:</label>
                                      <div className={styleEditHouse.row}>
                                        <div
                                          className={`${styleEditHouse.block_status} ${Status === 'Open' ? styleEditHouse.active : ''
                                            }`}
                                          onClick={() => handleStatusClick('Open')}
                                        >
                                          <span>Open</span>
                                        </div>
                                        <div
                                          className={`${styleEditHouse.block_status} ${Status === 'In Progress' ? styleEditHouse.active : ''
                                            }`}
                                          onClick={() => handleStatusClick('In Progress')}
                                        >
                                          <span>In Progress</span>
                                        </div>
                                        <div
                                          className={`${styleEditHouse.block_status} ${Status === 'Finished' ? styleEditHouse.active : ''
                                            }`}
                                          onClick={() => handleStatusClick('Finished')}
                                        >
                                          <span>Finished</span>
                                        </div>
                                      </div>
                                    </div>
                                    <button type="submit"
                                      className={styleEditHouse.block_delete}
                                      onClick={() => ControlModuleDelete(problem)}
                                    >
                                      <span>Удалить профиль</span>
                                      <img src='/img/svg/AltArrowDown.svg' alt='arrow' />
                                    </button>
                                  </div>
                                </main>
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </main>
              </div>
            </>
          } */}

      {showModuleListProblemOpen && StatusProblemOpen === 'Urgent' && (
        <>
          <div
            className={styleCardFillInfo.blur}
            onClick={() => ControlModuleListProblemOpen('')}
          ></div>
          <div
            className={styleCardFillInfo.card_block}
            onClick={(e) => e.stopPropagation()}
          >
            <header className={styleCardFillInfo.header}>
              <div className={styleCardFillInfo.header_container}>
                <div
                  className={styleCardFillInfo.header_exit}
                  onClick={() => ControlModuleListProblemOpen('')}
                >
                  <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                </div>
                <div className={styleCardFillInfo.header__title}>
                  <h2>{t('urgent_in_process')}</h2>
                </div>
                <div className={styleCardFillInfo.header_search}>
                  <img src='/img/svg/Magnifer.svg' alt='Magnifer' />
                </div>
              </div>
            </header>
            <main className='content'>
              <div className={styleCardFillInfo.content_container}>
                <div className={style.block_list}>
                  {getProblemsByStatusAndType(
                    urgentProblems,
                    'Open',
                    'Urgent'
                  ).map((problem) => (
                    <div className={styleCardProblem.block_problem_small}>
                      <div
                        className={styleCardProblem.problem_img_small}
                        style={{
                          backgroundImage: `url(${getProblemImageUrl(
                            problem.initialPhotoID?.imageURL
                          )})`,
                        }}
                        onClick={() => ControlShowInfoModalSmall(problem)}
                      >
                        <div className={styleCardProblem.problem_status_small}>
                          <span>{problem.urgencyLevel}</span>
                          <span>{problem.status}</span>
                        </div>
                        <div className={styleCardProblem.problem_date_small}>
                          <span>
                            {new Date(
                              problem.creationDate
                            ).toLocaleDateString()}
                          </span>
                        </div>
                      </div>
                      <div
                        className={styleCardProblem.problem_title_small}
                        onClick={() => ControlShowInfoModalSmall(problem)}
                      >
                        <h2>{problem.description}</h2>
                      </div>
                      <div className={styleCardProblem.problem_type_small}>
                        <span>
                          {t('problem_type')}:
                          <strong>
                            {getSkillNameById(problem.problemType)}
                          </strong>
                        </span>
                      </div>
                      <nav className={styleCardProblem.problem_nav_small}>
                        <button
                          type='submit'
                          className={styleCardProblem.edit_small}
                          onClick={() => startEditingProblem(problem)}
                        >
                          <span>{t('edit')}</span>
                        </button>
                        <button
                          type='submit'
                          className={styleCardProblem.delete_small}
                          onClick={() => ControlModuleDelete(problem)}
                        >
                          <img
                            src='/img/svg/TrashBinMinimalistic.svg'
                            alt='TrashBinMinimalistic'
                          />
                        </button>
                      </nav>
                      {showDelete && showDelete._id === problem._id && (
                        <>
                          <div
                            className={styleDelete.blur}
                            onClick={() => ControlModuleDelete(null)}
                          ></div>
                          <div
                            className={styleDelete.delete_block}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <div className={styleDelete.block_title}>
                              <h2>{t('delete_problem')}</h2>
                            </div>
                            <div className={styleDelete.block_text}>
                              <span>{t('delete_problem_text')}</span>
                            </div>
                            <div className={styleDelete.block_buttons}>
                              <button
                                type='submit'
                                className={styleDelete.cancel}
                                onClick={() => ControlModuleDelete(null)}
                              >
                                {t('cancel')}
                              </button>
                              <button
                                type='submit'
                                className={styleDelete.delete}
                                onClick={() => deleteProblem(showDelete._id)}
                              >
                                {t('delete')}
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                      {showFullInfoSmall &&
                        showFullInfoSmall._id === problem._id && (
                          <>
                            <div
                              className={styleCardFillInfo.blur_none}
                              onClick={() => ControlShowInfoModalSmall(null)}
                            ></div>
                            <div
                              className={styleCardFillInfo.card_block}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <header className={styleCardFillInfo.header}>
                                <div
                                  className={styleCardFillInfo.header_container}
                                >
                                  <div
                                    className={styleCardFillInfo.header_exit}
                                    onClick={() =>
                                      ControlShowInfoModalSmall(null)
                                    }
                                  >
                                    <img
                                      src='/img/svg/AltArrowLeft.svg'
                                      alt='arrow'
                                    />
                                  </div>
                                  <div
                                    className={styleCardFillInfo.header__title}
                                  >
                                    <h2>{t('urgent_problems')}</h2>
                                  </div>
                                  <div
                                    className={styleCardFillInfo.header_search}
                                  >
                                    <img
                                      src='/img/svg/Magnifer.svg'
                                      alt='Magnifer'
                                    />
                                  </div>
                                </div>
                              </header>
                              <main className='content'>
                                <div className='content_container'>
                                  <div
                                    className={styleCardFillInfo.block_image}
                                    style={{
                                      backgroundImage: `url(${getProblemImageUrl(
                                        showFullInfoSmall.initialPhotoID
                                          ?.imageURL
                                      )})`,
                                    }}
                                  >
                                    <div
                                      className={styleCardFillInfo.block_type}
                                    >
                                      <span>
                                        {showFullInfoSmall.urgencyLevel}
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      styleCardFillInfo.block_title_date
                                    }
                                  >
                                    <h2>{showFullInfoSmall.description}</h2>
                                    <span>
                                      {new Date(
                                        showFullInfoSmall.creationDate
                                      ).toLocaleDateString()}
                                    </span>
                                  </div>
                                  <div
                                    className={styleCardFillInfo.block_status}
                                  >
                                    <span>{t('status')}:</span>
                                    <span>{showFullInfoSmall.status}</span>
                                  </div>
                                  <div
                                    className={
                                      styleCardFillInfo.block_type_problem
                                    }
                                  >
                                    <span>{t('problem_type')}:</span>
                                    <span>
                                      {getSkillNameById(
                                        showFullInfoSmall.problemType
                                      )}
                                    </span>
                                  </div>
                                  <div className={styleCardFillInfo.block_time}>
                                    <span>{t('time')}</span>
                                    <span>
                                      {showFullInfoSmall.preferredTime ||
                                        t('not_specified')}
                                    </span>
                                  </div>
                                  <div
                                    className={styleCardFillInfo.block_buttons}
                                  >
                                    <button
                                      type='submit'
                                      className={styleCardFillInfo.edit}
                                      onClick={() =>
                                        startEditingProblem(problem)
                                      }
                                    >
                                      <span>{t('edit')}</span>
                                    </button>
                                    <button
                                      type='submit'
                                      className={styleCardFillInfo.delete}
                                      onClick={() =>
                                        ControlModuleDelete(problem)
                                      }
                                    >
                                      <img
                                        src='/img/svg/TrashBinMinimalistic.svg'
                                        alt='TrashBinMinimalistic'
                                      />
                                    </button>
                                  </div>
                                </div>
                              </main>
                            </div>
                          </>
                        )}
                      {editingProblem && (
                        <>
                          <div
                            className={styleCardFillInfo.blur_none}
                            onClick={() => setEditingProblem(null)}
                          ></div>
                          <div
                            className={styleCardFillInfo.card_block}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <header className={styleCardFillInfo.header}>
                              <div
                                className={styleCardFillInfo.header_container}
                              >
                                <div
                                  className={styleCardFillInfo.header_exit}
                                  onClick={() => setEditingProblem(null)}
                                >
                                  <img
                                    src='/img/svg/AltArrowLeft.svg'
                                    alt='arrow'
                                  />
                                </div>
                                <div
                                  className={styleEditHouse.header_save}
                                  onClick={() => saveProblem(editingProblem)}
                                >
                                  <span>{t('save')}</span>
                                </div>
                              </div>
                            </header>
                            <main className='content'>
                              <div className='content_container'>
                                <div className={styleEditHouse.header__title}>
                                  <h2>
                                    {t('edit')}{' '}
                                    <strong>{t('urgent_in_process')}</strong>
                                  </h2>
                                </div>
                                <label
                                  className={styleEditHouse.block_image}
                                  style={{
                                    backgroundImage: `url(${getProblemImageUrl(
                                      problem.initialPhotoID?.imageURL
                                    )})`,
                                  }}
                                >
                                  <input
                                    type='file'
                                    onChange={handleProblemPhotoUpload}
                                    accept='image/*'
                                    style={{ display: 'none' }}
                                  />
                                  <div className={styleEditHouse.block_text}>
                                    <span>{t('change_photo')}</span>
                                  </div>
                                </label>
                                <div className={styleEditProblem.block_input}>
                                  <label htmlFor=''>
                                    {t('edit_problem_name')}:
                                  </label>
                                  <div className={styleEditProblem.input}>
                                    <input
                                      type='text'
                                      value={editingProblem.description}
                                      onChange={(e) =>
                                        setEditingProblem({
                                          ...editingProblem,
                                          description: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                                <div className={styleEditProblem.block_input}>
                                  <label htmlFor=''>
                                    {t('edit_problem_type')}:
                                  </label>
                                  <div
                                    className={styleEditProblem.select_arrow}
                                  >
                                    <select
                                      value={editingProblem.problemType}
                                      onChange={(e) =>
                                        setEditingProblem({
                                          ...editingProblem,
                                          problemType: e.target.value,
                                        })
                                      }
                                    >
                                      {skills.map((skill) => (
                                        <option
                                          key={skill._id}
                                          value={skill._id}
                                        >
                                          {skill.skillName}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                <div className={styleEditProblem.block_input}>
                                  <label htmlFor=''>
                                    {t('edit_urgency_level')}:
                                  </label>
                                  <div
                                    className={styleEditProblem.select_arrow}
                                  >
                                    <select
                                      value={editingProblem.urgencyLevel}
                                      onChange={(e) =>
                                        setEditingProblem({
                                          ...editingProblem,
                                          urgencyLevel: e.target.value,
                                        })
                                      }
                                    >
                                      <option value='Urgent'>
                                        {t('urgent')}
                                      </option>
                                      <option value='Planned'>
                                        {t('planned')}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div className={styleEditProblem.block_input}>
                                  <label htmlFor=''>{t('time_range')}</label>
                                  <div
                                    className={styleEditProblem.select_arrow}
                                  >
                                    <select
                                      value={editingProblem.preferredTime}
                                      onChange={(e) =>
                                        setEditingProblem({
                                          ...editingProblem,
                                          preferredTime: e.target.value,
                                        })
                                      }
                                      className={styleEditHouse.time}
                                    >
                                      <option value='9:00--12:00'>
                                        9:00--12:00
                                      </option>
                                      <option value='12:00--15:00'>
                                        12:00--15:00
                                      </option>
                                      <option value='15:00--18:00'>
                                        15:00--18:00
                                      </option>
                                      <option value='18:00--21:00'>
                                        18:00--21:00
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div className={styleEditHouse.block_input}>
                                  <label htmlFor=''>{t('status')}:</label>
                                  <div className={styleEditHouse.row}>
                                    <div
                                      className={`${
                                        styleEditHouse.block_status
                                      } ${
                                        Status === 'Open'
                                          ? styleEditHouse.active
                                          : ''
                                      }`}
                                      onClick={() => handleStatusClick('Open')}
                                    >
                                      <span>{t('open')}</span>
                                    </div>
                                    <div
                                      className={`${
                                        styleEditHouse.block_status
                                      } ${
                                        Status === 'In Progress'
                                          ? styleEditHouse.active
                                          : ''
                                      }`}
                                      onClick={() =>
                                        handleStatusClick('In Progress')
                                      }
                                    >
                                      <span>{t('in_progress')}</span>
                                    </div>
                                    <div
                                      className={`${
                                        styleEditHouse.block_status
                                      } ${
                                        Status === 'Finished'
                                          ? styleEditHouse.active
                                          : ''
                                      }`}
                                      onClick={() =>
                                        handleStatusClick('Finished')
                                      }
                                    >
                                      <span>{t('finished')}</span>
                                    </div>
                                  </div>
                                </div>
                                <button
                                  type='submit'
                                  className={styleEditHouse.block_delete}
                                  onClick={() => ControlModuleDelete(problem)}
                                >
                                  <span>{t('delete_profile')}</span>
                                  <img
                                    src='/img/svg/AltArrowDown.svg'
                                    alt='arrow'
                                  />
                                </button>
                              </div>
                            </main>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </main>
          </div>
        </>
      )}

      {/* {showModuleListProblem && StatusProblem === 'Finished' &&
            <>
              <div className={styleCardFillInfo.blur} onClick={() => ControlModuleListProblem('')}></div>
              <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                <header className={styleCardFillInfo.header}>
                  <div className={styleCardFillInfo.header_container}>
                    <div
                      className={styleCardFillInfo.header_exit}
                      onClick={() => ControlModuleListProblem('')}
                    >
                      <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                    </div>
                    <div className={styleCardFillInfo.header__title}>
                      <h2>Завершенные проблемы</h2>
                    </div>
                    <div className={styleCardFillInfo.header_search}>
                      <img src='/img/svg/Magnifer.svg' alt='Magnifer' />
                    </div>
                  </div>
                </header>
                <main className='content'>
                  <div className={styleCardFillInfo.content_container}>
                    <div className={style.block_list}>
                      {getProblemsByStatus(
                        [...urgentProblems, ...plannedProblems],
                        'Finished'
                      ).map(problem => (
                        <div className={styleCardProblem.block_problem_small}>
                          <div
                            className={styleCardProblem.problem_img_small}
                            style={{
                              backgroundImage: `url(${getProblemImageUrl(
                                problem.initialPhotoID?.imageURL
                              )})`,
                            }}
                            onClick={() => ControlShowInfoModalSmall(problem)}
                          >
                            <div className={styleCardProblem.problem_status_small}>
                              <span>{problem.urgencyLevel}</span>
                              <span>{problem.status}</span>
                            </div>
                            <div className={styleCardProblem.problem_date_small}>
                              <span>{new Date(problem.creationDate).toLocaleDateString()}</span>
                            </div>
                          </div>
                          <div
                            className={styleCardProblem.problem_title_small}
                            onClick={() => ControlShowInfoModalSmall(problem)}
                          >
                            <h2>{problem.description}</h2>
                          </div>
                          <div className={styleCardProblem.problem_type_small}>
                            <span>
                              Тип проблемы:
                              <strong>{getSkillNameById(problem.problemType)}</strong>
                            </span>
                          </div>
                          <nav className={styleCardProblem.problem_nav_small}>
                            <button type="submit"
                              className={styleCardProblem.edit_small}
                              onClick={() => startEditingProblem(problem)}
                            >
                              <span>Редактировать</span>
                            </button>
                            <button type="submit"
                              className={styleCardProblem.delete_small}
                              onClick={() => ControlModuleDelete(problem)}
                            >
                              <img
                                src='/img/svg/TrashBinMinimalistic.svg'
                                alt='TrashBinMinimalistic'
                              />
                            </button>
                          </nav>
                          {showDelete && showDelete._id === problem._id && <>
                            <div className={styleDelete.blur} onClick={() => ControlModuleDelete(null)}></div>
                            <div className={styleDelete.delete_block} onClick={e => e.stopPropagation()}>
                              <div className={styleDelete.block_title}>
                                <h2>Удаление проблемы</h2>
                              </div>
                              <div className={styleDelete.block_text}>
                                <span>Вы действительно хотите удалить проблему?</span>
                              </div>
                              <div className={styleDelete.block_buttons}>
                                <button type="submit"
                                  className={styleDelete.cancel}
                                  onClick={() => ControlModuleDelete(null)}
                                >
                                  Отмена
                                </button>
                                <button type="submit"
                                  className={styleDelete.delete}
                                  onClick={() => deleteProblem(showDelete._id)}
                                >
                                  Удалить
                                </button>
                              </div>
                            </div>
                          </>}
                          {showFullInfoSmall && showFullInfoSmall._id === problem._id &&
                            <>
                              <div className={styleCardFillInfo.blur_none} onClick={() => ControlShowInfoModalSmall(null)}></div>
                              <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                                <header className={styleCardFillInfo.header}>
                                  <div className={styleCardFillInfo.header_container}>
                                    <div
                                      className={styleCardFillInfo.header_exit}
                                      onClick={() => ControlShowInfoModalSmall(null)}
                                    >
                                      <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                                    </div>
                                    <div className={styleCardFillInfo.header__title}>
                                      <h2>{problem.urgencyLevel === 'Urgent' ? 'Срочные проблемы' : 'Планированные проблемы'}</h2>
                                    </div>
                                    <div className={styleCardFillInfo.header_search}>
                                      <img src='/img/svg/Magnifer.svg' alt='Magnifer' />
                                    </div>
                                  </div>
                                </header>
                                <main className='content'>
                                  <div className='content_container'>
                                    <div
                                      className={styleCardFillInfo.block_image}
                                      style={{
                                        backgroundImage: `url(${getProblemImageUrl(
                                          showFullInfoSmall.initialPhotoID?.imageURL
                                        )})`,
                                      }}
                                    >
                                      <div className={styleCardFillInfo.block_type}>
                                        <span>{showFullInfoSmall.urgencyLevel}</span>
                                      </div>
                                    </div>
                                    <div className={styleCardFillInfo.block_title_date}>
                                      <h2>{showFullInfoSmall.description}</h2>
                                      <span>{new Date(showFullInfoSmall.creationDate).toLocaleDateString()}</span>
                                    </div>
                                    <div className={styleCardFillInfo.block_status}>
                                      <span>Статус:</span>
                                      <span>{showFullInfoSmall.status}</span>
                                    </div>
                                    <div className={styleCardFillInfo.block_type_problem}>
                                      <span>Тип проблемы:</span>
                                      <span>{getSkillNameById(showFullInfoSmall.problemType)}</span>
                                    </div>
                                    <div className={styleCardFillInfo.block_time}>
                                      <span>Time</span>
                                      <span>{showFullInfoSmall.preferredTime || 'Не указано'}</span>
                                    </div>
                                    <div className={styleCardFillInfo.block_buttons}>
                                      <button type="submit"
                                        className={styleCardFillInfo.edit}
                                        onClick={() => startEditingProblem(problem)}
                                      >
                                        <span>Редактировать</span>
                                      </button>
                                      <button type="submit"
                                        className={styleCardFillInfo.delete}
                                        onClick={() => ControlModuleDelete(problem)}
                                      >
                                        <img
                                          src='/img/svg/TrashBinMinimalistic.svg'
                                          alt='TrashBinMinimalistic'
                                        />
                                      </button>
                                    </div>
                                  </div>
                                </main>
                              </div>
                            </>
                          }

                          {editingProblem && editingProblem._id && (
                            <>
                              <div className={styleCardFillInfo.blur_none} onClick={() => setEditingProblem(null)}></div>
                              <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                                <header className={styleCardFillInfo.header}>
                                  <div className={styleCardFillInfo.header_container}>
                                    <div
                                      className={styleCardFillInfo.header_exit}
                                      onClick={() => setEditingProblem(null)}
                                    >
                                      <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                                    </div>
                                    <div
                                      className={styleEditHouse.header_save}
                                      onClick={() => saveProblem(editingProblem)}
                                    >
                                      <span>Сохранить</span>
                                    </div>
                                  </div>
                                </header>
                                <main className='content'>
                                  <div className='content_container'>
                                    <div className={styleEditHouse.header__title}>
                                      <h2>
                                        Редактирование <strong>Завершенные проблемы</strong>
                                      </h2>
                                    </div>
                                    <label
                                      className={styleEditHouse.block_image}
                                      style={{
                                        backgroundImage: `url(${getProblemImageUrl(
                                          problem.initialPhotoID?.imageURL
                                        )})`,
                                      }}
                                    >
                                      <input
                                        type='file'
                                        onChange={handleProblemPhotoUpload}
                                        accept='image/*'
                                        style={{ display: 'none' }}
                                      />
                                      <div className={styleEditHouse.block_text}>
                                        <span>Изменить фото</span>
                                      </div>
                                    </label>
                                    <div className={styleEditProblem.block_input}>
                                      <label htmlFor=''>Название проблемы:</label>
                                      <div className={styleEditProblem.input}>
                                        <input type='text' value={editingProblem.description}
                                          onChange={e => setEditingProblem({ ...editingProblem, description: e.target.value })} />
                                      </div>
                                    </div>
                                    <div className={styleEditProblem.block_input}>
                                      <label htmlFor=''>Тип проблемы:</label>
                                      <div className={styleEditProblem.select_arrow}>
                                        <select
                                          value={editingProblem.problemType}
                                          onChange={e =>
                                            setEditingProblem({
                                              ...editingProblem,
                                              problemType: e.target.value,
                                            })
                                          }
                                        >
                                          {skills.map(skill => (
                                            <option key={skill._id} value={skill._id}>
                                              {skill.skillName}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                    <div className={styleEditProblem.block_input}>
                                      <label htmlFor=''>Сложность проблемы:</label>
                                      <div className={styleEditProblem.select_arrow}>
                                        <select
                                          value={editingProblem.urgencyLevel}
                                          onChange={e =>
                                            setEditingProblem({
                                              ...editingProblem,
                                              urgencyLevel: e.target.value,
                                            })
                                          }
                                        >
                                          <option value='Urgent'>Urgent</option>
                                          <option value='Planned'>Planned</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className={styleEditProblem.block_input}>
                                      <label htmlFor=''>Время работ</label>
                                      <div className={styleEditProblem.select_arrow}>
                                        <select
                                          value={editingProblem.preferredTime}
                                          onChange={e =>
                                            setEditingProblem({
                                              ...editingProblem,
                                              preferredTime: e.target.value,
                                            })
                                          }
                                          className={styleEditHouse.time}
                                        >
                                          <option value='9:00--12:00'>9:00--12:00</option>
                                          <option value='12:00--15:00'>12:00--15:00</option>
                                          <option value='15:00--18:00'>15:00--18:00</option>
                                          <option value='18:00--21:00'>18:00--21:00</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className={styleEditProblem.block_input}>
                                      <label htmlFor=''>Сделать статус:</label>
                                      <div className={styleEditHouse.row}>
                                        <div
                                          className={`${styleEditHouse.block_status} ${Status === 'Open' ? styleEditHouse.active : ''
                                            }`}
                                          onClick={() => handleStatusClick('Open')}
                                        >
                                          <span>Open</span>
                                        </div>
                                        <div
                                          className={`${styleEditHouse.block_status} ${Status === 'In Progress' ? styleEditHouse.active : ''
                                            }`}
                                          onClick={() => handleStatusClick('In Progress')}
                                        >
                                          <span>In Progress</span>
                                        </div>
                                        <div
                                          className={`${styleEditHouse.block_status} ${Status === 'Finished' ? styleEditHouse.active : ''
                                            }`}
                                          onClick={() => handleStatusClick('Finished')}
                                        >
                                          <span>Finished</span>
                                        </div>
                                      </div>
                                    </div>
                                    <button type="submit"
                                      className={styleEditHouse.block_delete}
                                      onClick={() => ControlModuleDelete(problem)}
                                    >
                                      <span>Удалить профиль</span>
                                      <img src='/img/svg/AltArrowDown.svg' alt='arrow' />
                                    </button>
                                  </div>



                                </main>
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </main>
              </div>
            </>
          } */}

      {/* ! Added ! */}

      {/* {showDelete && 
            <>
              <div className={styleDelete.blur} onClick={() => ControlModuleDelete(null)}></div>
              <div className={styleDelete.delete_block} onClick={e => e.stopPropagation()}>
                <div className={styleDelete.block_title}>
                  <h2>Удаление проблемы</h2>
                </div>
                <div className={styleDelete.block_text}>
                  <span>Вы действительно хотите удалить проблему?</span>
                </div>
                <div className={styleDelete.block_buttons}>
                  <button type="submit"
                    className={styleDelete.cancel}
                    onClick={() => ControlModuleDelete(null)}
                  >
                    Отмена
                  </button>
                  <button type="submit"
                    className={styleDelete.delete}
                    onClick={() => deleteProblem(showDelete._id)}
                  >
                    Удалить
                  </button>
                </div>
              </div>
            </>
          } */}

      {/* {showFullInfo &&
            <>
              <div className={styleCardFillInfo.blur} onClick={() => ControlModuleInfo(null)}></div>
              <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                <header className={styleCardFillInfo.header}>
                  <div className={styleCardFillInfo.header_container}>
                    <div
                      className={styleCardFillInfo.header_exit}
                      onClick={() => ControlModuleInfo(null)}
                    >
                      <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                    </div>
                    <div className={styleCardFillInfo.header__title}>
                      <h2>срочные проблемы</h2>
                    </div>
                    <div className={styleCardFillInfo.header_search}>
                      <img src='/img/svg/Magnifer.svg' alt='Magnifer' />
                    </div>
                  </div>
                </header>
                <main className='content'>
                  <div className='content_container'>
                    <div
                      className={styleCardFillInfo.block_image}
                      style={{
                        backgroundImage: `url(${getProblemImageUrl(
                          showFullInfo.initialPhotoID?.imageURL
                        )})`,
                      }}
                    >
                      <div className={styleCardFillInfo.block_type}>
                        <span>{showFullInfo.urgencyLevel}</span>
                      </div>
                    </div>
                    <div className={styleCardFillInfo.block_title_date}>
                      <h2>{showFullInfo.description}</h2>
                      <span>{new Date(showFullInfo.creationDate).toLocaleDateString()}</span>
                    </div>
                    <div className={styleCardFillInfo.block_status}>
                      <span>Статус:</span>
                      <span>{showFullInfo.status}</span>
                    </div>
                    <div className={styleCardFillInfo.block_type_problem}>
                      <span>Тип проблемы:</span>
                      <span>{getSkillNameById(showFullInfo.problemType)}</span>
                    </div>
                    <div className={styleCardFillInfo.block_time}>
                      <span>Time</span>
                      <span>{showFullInfo.preferredTime || 'Не указано'}</span>
                    </div>
                    <div className={styleCardFillInfo.block_buttons}>
                      <button type="submit"
                        className={styleCardFillInfo.edit}
                        onClick={() => startEditingProblem(showFullInfo)}
                      >
                        <span>Редактировать</span>
                      </button>
                      <button type="submit"
                        className={styleCardFillInfo.delete}
                        onClick={() => ControlModuleDelete(showFullInfo)}
                      >
                        <img
                          src='/img/svg/TrashBinMinimalistic.svg'
                          alt='TrashBinMinimalistic'
                        />
                      </button>
                    </div>
                  </div>
                </main>
              </div>
            </>
          } */}
      {/* {editingProblem && (
            <>
              <div className={showFullInfo ? styleCardFillInfo.blur_none : styleCardFillInfo.blur} onClick={() => setEditingProblem(null)}></div>
              <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                <header className={styleCardFillInfo.header}>
                  <div className={styleCardFillInfo.header_container}>
                    <div
                      className={styleCardFillInfo.header_exit}
                      onClick={() => setEditingProblem(null)}
                    >
                      <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                    </div>
                    <div
                      className={styleEditHouse.header_save}
                      onClick={() => saveProblem(editingProblem)}
                    >
                      <span>Сохранить</span>
                    </div>
                  </div>
                </header>
                <main className='content'>
                  <div className='content_container'>
                    <div className={styleEditHouse.header__title}>
                      <h2>
                        Редактирование <strong>Срочные в процессе</strong>
                      </h2>
                    </div>
                    <label
                      className={styleEditHouse.block_image}
                      style={{
                        backgroundImage: `url(${selectedPhoto ? selectedPhoto : getProblemImageUrl(
                          editingProblem.initialPhotoID?.imageURL
                        )})`,
                      }}
                    >
                      <input
                        type='file'
                        onChange={handleProblemPhotoUpload}
                        accept='image/*'
                        style={{ display: 'none' }}
                      />
                      <div className={styleEditHouse.block_text}>
                        <span>Изменить фото</span>
                      </div>
                    </label>
                    <div className={styleEditProblem.block_input}>
                      <label htmlFor=''>Название проблемы:</label>
                      <div className={styleEditProblem.input}>
                        <input type='text' value={editingProblem.description}
                          onChange={e => setEditingProblem({ ...editingProblem, description: e.target.value })} />
                      </div>
                    </div>
                    <div className={styleEditProblem.block_input}>
                      <label htmlFor=''>Тип проблемы:</label>
                      <div className={styleEditProblem.select_arrow}>
                        <select
                          value={editingProblem.problemType}
                          onChange={e => setEditingProblem({ ...editingProblem, problemType: e.target.value })}
                        >
                          {skills.map(skill => (
                            <option key={skill._id} value={skill._id}>{skill.skillName}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className={styleEditProblem.block_input}>
                      <label htmlFor=''>Сложность проблемы:</label>
                      <div className={styleEditProblem.select_arrow}>
                        <select
                          value={editingProblem.urgencyLevel}
                          onChange={e => setEditingProblem({ ...editingProblem, urgencyLevel: e.target.value })}
                        >
                          <option value='Urgent'>Urgent</option>
                          <option value='Planned'>Planned</option>
                        </select>
                      </div>
                    </div>
                    <div className={styleEditProblem.block_input}>
                      <label htmlFor=''>Время работ</label>
                      <div className={styleEditProblem.select_arrow}>
                        <select
                          value={editingProblem.preferredTime}
                          onChange={e => setEditingProblem({ ...editingProblem, preferredTime: e.target.value })}
                          className={styleEditHouse.time}
                        >
                          <option value='9:00--12:00'>9:00--12:00</option>
                          <option value='12:00--15:00'>12:00--15:00</option>
                          <option value='15:00--18:00'>15:00--18:00</option>
                          <option value='18:00--21:00'>18:00--21:00</option>
                        </select>
                      </div>
                    </div>
                    <div className={styleEditHouse.block_input}>
                      <label htmlFor=''>Сделать статус:</label>
                      <div className={styleEditHouse.row}>
                        <div
                          className={`${styleEditHouse.block_status} ${Status === 'Open' ? styleEditHouse.active : ''
                            }`}
                          onClick={() => handleStatusClick('Open')}
                        >
                          <span>Open</span>
                        </div>
                        <div
                          className={`${styleEditHouse.block_status} ${Status === 'In Progress' ? styleEditHouse.active : ''
                            }`}
                          onClick={() => handleStatusClick('In Progress')}
                        >
                          <span>In Progress</span>
                        </div>
                        <div
                          className={`${styleEditHouse.block_status} ${Status === 'Finished' ? styleEditHouse.active : ''
                            }`}
                          onClick={() => handleStatusClick('Finished')}
                        >
                          <span>Finished</span>
                        </div>
                      </div>
                    </div>
                    <button type="submit"
                      className={styleEditHouse.block_delete}
                      onClick={() => ControlModuleDelete(editingProblem)}
                    >
                      <span>Удалить профиль</span>
                      <img src='/img/svg/AltArrowDown.svg' alt='arrow' />
                    </button>
                  </div>
                </main>
              </div>
            </>
          )} */}
      {/* ! Added ! */}

      {/* <>
      <header className={style.header}>
        <div className={style.header_container}>
          <div className={style.header_title}>
            <h1>Профиль дома:</h1>
            <p>{house.name}</p>
          </div>
          <button type="submit"
            className={style.header_edit}
            onClick={ControlModuleEditHouse}
          >
            <span>Редактировать</span>
          </button>
          {isEditing && (
            <>
              <div className={styleCardFillInfo.blur} onClick={ControlModuleEditHouse}></div>
              <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                <header className={styleCardFillInfo.header}>
                  <div className={styleCardFillInfo.header_container}>
                    <div
                      className={styleCardFillInfo.header_exit}
                      onClick={ControlModuleEditHouse}
                    >
                      <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                    </div>
                    <div
                      className={styleEditHouse.header_save}
                      onClick={saveHouse}
                    >
                      <span>Сохранить</span>
                    </div>
                  </div>
                </header>
                <main className='content'>
                  <div className='content_container'>
                    <div className={styleEditHouse.header__title}>
                      <h2>
                        Редактирование <strong>профиля дома</strong>
                      </h2>
                    </div>
                    <label
                      className={styleEditHouse.block_image}
                      style={{
                        backgroundImage: `url(${selectedPhoto ? selectedPhoto : getHouseImageUrl(house.image)})`,
                      }}
                    >
                      <input
                        type='file'
                        onChange={handleHousePhotoUpload}
                        accept='image/*'
                        style={{ display: 'none' }}
                      />
                      <div className={styleEditHouse.block_text}>
                        <span>Изменить фото дома</span>
                      </div>
                    </label>
                    <div className={styleEditHouse.block_input}>
                      <label htmlFor=''>Название дома:</label>
                      <div className={styleEditHouse.input}>
                        <input
                          type='text'
                          value={editedHouse.name}
                          onChange={e =>
                            setEditedHouse({
                              ...editedHouse,
                              name: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className={styleEditHouse.block_input}>
                      <label htmlFor=''>Квадратные метры:</label>
                      <div className={styleEditHouse.input}>
                        <input
                          type='text'
                          value={editedHouse.squareMeters}
                          onChange={e =>
                            setEditedHouse({
                              ...editedHouse,
                              squareMeters: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className={styleEditHouse.block_input}>
                      <label htmlFor=''>Адрес:</label>
                      <div className={styleEditHouse.input}>
                        <input
                          type='text'
                          value={editedHouse.address}
                          onChange={e =>
                            setEditedHouse({
                              ...editedHouse,
                              address: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className={styleEditHouse.block_map}>
                      <button type="submit"
                        onClick={ToggleShowMapEditHouse}
                        className={styleEditHouse.toggleMapButton}
                      >
                        <span>
                          {showMapEditHouse
                            ? `Скрыть адрес на карте`
                            : `Показать адрес на карте`}
                        </span>
                      </button>
                      {showMapEditHouse && (
                        <MapContainer
                          key={house.mapLocationCoordinates.coordinates.join(',')}
                          center={[
                            house.mapLocationCoordinates.coordinates[1],
                            house.mapLocationCoordinates.coordinates[0],
                          ]}
                          zoom={13}
                          className={styleMap.map}
                        >
                          <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
                          <LocationMarker />
                          <div className={styleMap.map_info}>
                            <div className={styleMap.place}>
                              <span>Местоположение:</span>
                              <p>{house.name}</p>
                            </div>
                            <div className={styleMap.coordination}>
                              <span>Координаты:</span>
                              <p>{house.mapLocationCoordinates.coordinates[0]}</p>
                            </div>
                          </div>
                        </MapContainer>
                      )}
                    </div>
                    <button type="submit"
                      className={styleEditHouse.block_delete}
                      onClick={deleteHouse}
                    >
                      <span>Удалить профиль</span>
                      <img src='/img/svg/AltArrowDown.svg' alt='arrow' />
                    </button>
                  </div>
                </main>
              </div>
            </>
          )}
        </div>
      </header>
      <main className={style.content}>
        <div className={style.content_container}>
          <article className={style.content_card}>
            <div className={style.card_photo}>
              <img src={getHouseImageUrl(house.image)} alt='card_photo' />
            </div>
            <div className={style.card_info}>
              <a href="/">
                <img src='/img/svg/home.svg' alt='home' />
              </a>
              <div className={style.info_address}>
                <span>Адрес:</span>
                <p>{house.address}</p>
              </div>
              <div className={style.info_size}>
                <span>Квадратные метры:</span>
                <p>{house.squareMeters}</p>
              </div>
            </div>
          </article>
          <article className={style.content_map}>
            <button type="submit"
              onClick={toggleMapVisibility}
              className={style.toggleMapButton}
            >
              <span>
                {showMap
                  ? `Скрыть ${house.name} на карте`
                  : `Показать ${house.name} на карте`}
              </span>
              <img src='/img/svg/AltArrowDown.svg' alt='arrow' />
            </button>
            {showMap && house.mapLocationCoordinates && (
              <div className='house-map'>
                <MapContainer
                  key={house.mapLocationCoordinates.coordinates.join(',')}
                  center={[
                    house.mapLocationCoordinates.coordinates[1],
                    house.mapLocationCoordinates.coordinates[0],
                  ]}
                  zoom={13}
                  className={styleMap.map}
                >
                  <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
                  <Marker
                    position={[house.mapLocationCoordinates.coordinates[1], house.mapLocationCoordinates.coordinates[0]]}
                    icon={customMarkerIcon}
                  />
                  <div className={styleMap.map_info}>
                    <div className={styleMap.place}>
                      <span>Местоположение:</span>
                      <p>{house.name}</p>
                    </div>
                    <div className={styleMap.coordination}>
                      <span>Координаты:</span>
                      <p>{house.mapLocationCoordinates.coordinates[0]}</p>
                    </div>
                  </div>
                </MapContainer>
              </div>
            )}
          </article>


{
  subscriptionError && (
    <div className={style.errorMessage}>{subscriptionError} <a href="/">купить подписку</a></div>
  )
}



 <div>
    
    <p>Доступные плановые вызовы: {getSubscriptionUsageTotals().plannedCallsUsedTotal} / {getSubscriptionUsageTotals().plannedCallsLimitTotal} </p>
   
    <p>Доступные срочные вызовы:{getSubscriptionUsageTotals().urgentCallsUsedTotal} / {getSubscriptionUsageTotals().urgentCallsLimitTotal}</p>
   
  </div>
                   

<div><p> </p></div>





          <article className={style.content_problem}>
            <div className={style.problem_title}>
              <h2 className={style.title}>Решение проблем</h2>
              <a href='#' className={style.link}>
                Что это?
              </a>
            </div>
            <div className={style.problem_choose}>
              <div
                className={style.choose_block}
                onClick={ControlModuleProblemUrgent}
              >
                <div className={style.block_image}>
                  <img src='/img/svg/AddCircleWhite.svg' alt='' />
                </div>
                <div className={style.block_text}>
                  <span>Добавить срочную проблему</span>
                </div>
              </div>
              {showUrgentProblemModal && (
                <>
                  <div className={styleCardFillInfo.blur} onClick={ControlModuleProblemUrgent}></div>
                  <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                    <header className={styleCardFillInfo.header}>
                      <div className='header_container'>
                        <div className={styleCardFillInfo.header_exit}>
                          <img
                            src='/img/svg/AltArrowLeft.svg'
                            alt='arrow'
                            onClick={ControlModuleProblemUrgent}
                          />
                        </div>
                      </div>
                    </header>
                    <main className='content'>
                      <form
                        className='content_container'
                        onSubmit={e =>
                          submitProblem(e, 'Urgent', newUrgentProblem)
                        }
                      >
                        <div className={styleAddProblem.header__title}>
                          <h2>Urgent</h2>
                        </div>
                        <div className={styleAddProblem.block_select_img}>
                          <label className={styleAddProblem.select_img}>
                            <input
                              type='file'
                              onChange={handlePhotoUpload}
                              accept='image/*'
                              style={{ display: 'none' }}
                            />
                            <span className={styleAddProblem.camera}>
                              Cделать фото
                            </span>
                          </label>
                          <label className={styleAddProblem.select_img}>
                            <input
                              type='file'
                              onChange={handlePhotoUpload}
                              accept='image/*'
                              style={{ display: 'none' }}
                            />
                            <span className={styleAddProblem.folder}>
                              Выбрать файл
                            </span>
                          </label>
                        </div>
                        <div className={styleAddProblem.preview}>
                          {selectedPhoto && <img src={selectedPhoto} alt="" />}
                        </div>
                        <div className={styleAddProblem.input}>
                          <input
                            type='text'
                            value={newUrgentProblem.description}
                            onChange={e =>
                              setNewUrgentProblem({
                                ...newUrgentProblem,
                                description: e.target.value,
                              })
                            }
                            placeholder='Описание проблем'
                          />
                        </div>
                        <div className={styleAddProblem.block_input}>
                          <div className='select_arrow'>
                            <select
                              value={newUrgentProblem.problemType}
                              onChange={e =>
                                setNewUrgentProblem({
                                  ...newUrgentProblem,
                                  problemType: e.target.value,
                                })
                              }
                            >
                              {skills.map(skill => (
                                <option key={skill._id} value={skill._id}>
                                  {skill.skillName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className={styleAddProblem.block_input_time}>
                          <label htmlFor=''>Time</label>
                          <div className={styleAddProblem.select_arrow}>
                            <select
                              className={styleAddProblem.time}
                              value={newUrgentProblem.timeRange}
                              onChange={e =>
                                setNewUrgentProblem({
                                  ...newUrgentProblem,
                                  timeRange: e.target.value,
                                })
                              }
                            >
                              <option value='9:00--12:00'>9:00--12:00</option>
                              <option value='12:00--15:00'>12:00--15:00</option>
                              <option value='15:00--18:00'>15:00--18:00</option>
                              <option value='18:00--21:00'>18:00--21:00</option>
                            </select>
                          </div>
                        </div>
                        <button type='submit' className={styleAddProblem.add}>
                          <span>Добавить срочную проблему</span>
                        </button>
                        <button type="submit"
                          className={styleAddProblem.view}
                          onClick={() => ControlModuleListProblemOpen('Urgent')}
                        >
                          <span>
                            Посмотреть текущие проблемы <strong>Urgent</strong>
                          </span>
                          <img src='/img/svg/AltArrowDown.svg' alt='arrow' />
                        </button>
                      </form>
                    </main>
                  </div>
                </>
              )}
              {showModuleListProblemOpen && StatusProblemOpen === 'Urgent' &&
                <>
                  <div className={styleCardFillInfo.blur} onClick={() => ControlModuleListProblemOpen('')}></div>
                  <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                    <header className={styleCardFillInfo.header}>
                      <div className={styleCardFillInfo.header_container}>
                        <div
                          className={styleCardFillInfo.header_exit}
                          onClick={() => ControlModuleListProblemOpen('')}
                        >
                          <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                        </div>
                        <div className={styleCardFillInfo.header__title}>
                          <h2>Срочные в процессе</h2>
                        </div>
                        <div className={styleCardFillInfo.header_search}>
                          <img src='/img/svg/Magnifer.svg' alt='Magnifer' />
                        </div>
                      </div>
                    </header>
                    <main className='content'>
                      <div className={styleCardFillInfo.content_container}>
                        <div className={style.block_list}>
                          {getProblemsByStatusAndType(
                            urgentProblems,
                            'Open',
                            'Urgent'
                          ).map(problem => (
                            <div className={styleCardProblem.block_problem_small}>
                              <div
                                className={styleCardProblem.problem_img_small}
                                style={{
                                  backgroundImage: `url(${getProblemImageUrl(
                                    problem.initialPhotoID?.imageURL
                                  )})`,
                                }}
                                onClick={() => ControlShowInfoModalSmall(problem)}
                              >
                                <div className={styleCardProblem.problem_status_small}>
                                  <span>{problem.urgencyLevel}</span>
                                  <span>{problem.status}</span>
                                </div>
                                <div className={styleCardProblem.problem_date_small}>
                                  <span>{new Date(problem.creationDate).toLocaleDateString()}</span>
                                </div>
                              </div>
                              <div
                                className={styleCardProblem.problem_title_small}
                                onClick={() => ControlShowInfoModalSmall(problem)}
                              >
                                <h2>{problem.description}</h2>
                              </div>
                              <div className={styleCardProblem.problem_type_small}>
                                <span>
                                  Тип проблемы:
                                  <strong>{getSkillNameById(problem.problemType)}</strong>
                                </span>
                              </div>
                              <nav className={styleCardProblem.problem_nav_small}>
                                <button type="submit"
                                  className={styleCardProblem.edit_small}
                                  onClick={() => startEditingProblem(problem)}
                                >
                                  <span>Редактировать</span>
                                </button>
                                <button type="submit"
                                  className={styleCardProblem.delete_small}
                                  onClick={() => ControlModuleDelete(problem)}
                                >
                                  <img
                                    src='/img/svg/TrashBinMinimalistic.svg'
                                    alt='TrashBinMinimalistic'
                                  />
                                </button>
                              </nav>
                              {showDelete && showDelete._id === problem._id && <>
                                <div className={styleDelete.blur} onClick={() => ControlModuleDelete(null)}></div>
                                <div className={styleDelete.delete_block} onClick={e => e.stopPropagation()}>
                                  <div className={styleDelete.block_title}>
                                    <h2>Удаление проблемы</h2>
                                  </div>
                                  <div className={styleDelete.block_text}>
                                    <span>Вы действительно хотите удалить проблему?</span>
                                  </div>
                                  <div className={styleDelete.block_buttons}>
                                    <button type="submit"
                                      className={styleDelete.cancel}
                                      onClick={() => ControlModuleDelete(null)}
                                    >
                                      Отмена
                                    </button>
                                    <button type="submit"
                                      className={styleDelete.delete}
                                      onClick={() => deleteProblem(showDelete._id)}
                                    >
                                      Удалить
                                    </button>
                                  </div>
                                </div>
                              </>}
                              {showFullInfoSmall && showFullInfoSmall._id === problem._id &&
                                <>
                                  <div className={styleCardFillInfo.blur_none} onClick={() => ControlShowInfoModalSmall(null)}></div>
                                  <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                                    <header className={styleCardFillInfo.header}>
                                      <div className={styleCardFillInfo.header_container}>
                                        <div
                                          className={styleCardFillInfo.header_exit}
                                          onClick={() => ControlShowInfoModalSmall(null)}
                                        >
                                          <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                                        </div>
                                        <div className={styleCardFillInfo.header__title}>
                                          <h2>срочные проблемы</h2>
                                        </div>
                                        <div className={styleCardFillInfo.header_search}>
                                          <img src='/img/svg/Magnifer.svg' alt='Magnifer' />
                                        </div>
                                      </div>
                                    </header>
                                    <main className='content'>
                                      <div className='content_container'>
                                        <div
                                          className={styleCardFillInfo.block_image}
                                          style={{
                                            backgroundImage: `url(${getProblemImageUrl(
                                              showFullInfoSmall.initialPhotoID?.imageURL
                                            )})`,
                                          }}
                                        >
                                          <div className={styleCardFillInfo.block_type}>
                                            <span>{showFullInfoSmall.urgencyLevel}</span>
                                          </div>
                                        </div>
                                        <div className={styleCardFillInfo.block_title_date}>
                                          <h2>{showFullInfoSmall.description}</h2>
                                          <span>{new Date(showFullInfoSmall.creationDate).toLocaleDateString()}</span>
                                        </div>
                                        <div className={styleCardFillInfo.block_status}>
                                          <span>Статус:</span>
                                          <span>{showFullInfoSmall.status}</span>
                                        </div>
                                        <div className={styleCardFillInfo.block_type_problem}>
                                          <span>Тип проблемы:</span>
                                          <span>{getSkillNameById(showFullInfoSmall.problemType)}</span>
                                        </div>
                                        <div className={styleCardFillInfo.block_time}>
                                          <span>Time</span>
                                          <span>{showFullInfoSmall.preferredTime || 'Не указано'}</span>
                                        </div>
                                        <div className={styleCardFillInfo.block_buttons}>
                                          <button type="submit"
                                            className={styleCardFillInfo.edit}
                                            onClick={() => startEditingProblem(problem)}
                                          >
                                            <span>Редактировать</span>
                                          </button>
                                          <button type="submit"
                                            className={styleCardFillInfo.delete}
                                            onClick={() => ControlModuleDelete(problem)}
                                          >
                                            <img
                                              src='/img/svg/TrashBinMinimalistic.svg'
                                              alt='TrashBinMinimalistic'
                                            />
                                          </button>
                                        </div>
                                      </div>
                                    </main>
                                  </div>
                                </>
                              }
                              {editingProblem && (
                                <>
                                  <div className={styleCardFillInfo.blur_none} onClick={() => setEditingProblem(null)} ></div>
                                  <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                                    <header className={styleCardFillInfo.header}>
                                      <div className={styleCardFillInfo.header_container}>
                                        <div
                                          className={styleCardFillInfo.header_exit}
                                          onClick={() => setEditingProblem(null)}
                                        >
                                          <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                                        </div>
                                        <div
                                          className={styleEditHouse.header_save}
                                          onClick={() => saveProblem(editingProblem)}
                                        >
                                          <span>Сохранить</span>
                                        </div>
                                      </div>
                                    </header>
                                    <main className='content'>
                                      <div className='content_container'>
                                        <div className={styleEditHouse.header__title}>
                                          <h2>
                                            Редактирование <strong>Срочные в процессе</strong>
                                          </h2>
                                        </div>
                                        <label
                                          className={styleEditHouse.block_image}
                                          style={{
                                            backgroundImage: `url(${getProblemImageUrl(
                                              problem.initialPhotoID?.imageURL
                                            )})`,
                                          }}
                                        >
                                          <input
                                            type='file'
                                            onChange={handleProblemPhotoUpload}
                                            accept='image/*'
                                            style={{ display: 'none' }}
                                          />
                                          <div className={styleEditHouse.block_text}>
                                            <span>Изменить фото</span>
                                          </div>
                                        </label>
                                        <div className={styleEditProblem.block_input}>
                                          <label htmlFor=''>Название проблемы:</label>
                                          <div className={styleEditProblem.input}>
                                            <input type='text' value={editingProblem.description}
                                              onChange={e => setEditingProblem({ ...editingProblem, description: e.target.value })} />
                                          </div>
                                        </div>
                                        <div className={styleEditProblem.block_input}>
                                          <label htmlFor=''>Тип проблемы:</label>
                                          <div className={styleEditProblem.select_arrow}>
                                            <select
                                              value={editingProblem.problemType}
                                              onChange={e =>
                                                setEditingProblem({
                                                  ...editingProblem,
                                                  problemType: e.target.value,
                                                })
                                              }
                                            >
                                              {skills.map(skill => (
                                                <option key={skill._id} value={skill._id}>
                                                  {skill.skillName}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        </div>
                                        <div className={styleEditProblem.block_input}>
                                          <label htmlFor=''>Сложность проблемы:</label>
                                          <div className={styleEditProblem.select_arrow}>
                                            <select
                                              value={editingProblem.urgencyLevel}
                                              onChange={e =>
                                                setEditingProblem({
                                                  ...editingProblem,
                                                  urgencyLevel: e.target.value,
                                                })
                                              }
                                            >
                                              <option value='Urgent'>Urgent</option>
                                              <option value='Planned'>Planned</option>
                                            </select>
                                          </div>
                                        </div>
                                        <div className={styleEditProblem.block_input}>
                                          <label htmlFor=''>Время работ</label>
                                          <div className={styleEditProblem.select_arrow}>
                                            <select
                                              value={editingProblem.preferredTime}
                                              onChange={e =>
                                                setEditingProblem({
                                                  ...editingProblem,
                                                  preferredTime: e.target.value,
                                                })
                                              }
                                              className={styleEditHouse.time}
                                            >
                                              <option value='9:00--12:00'>9:00--12:00</option>
                                              <option value='12:00--15:00'>12:00--15:00</option>
                                              <option value='15:00--18:00'>15:00--18:00</option>
                                              <option value='18:00--21:00'>18:00--21:00</option>
                                            </select>
                                          </div>
                                        </div>
                                        <div className={styleEditHouse.block_input}>
                                          <label htmlFor=''>Сделать статус:</label>
                                          <div className={styleEditHouse.row}>
                                            <div
                                              className={`${styleEditHouse.block_status} ${Status === 'Open' ? styleEditHouse.active : ''
                                                }`}
                                              onClick={() => handleStatusClick('Open')}
                                            >
                                              <span>Open</span>
                                            </div>
                                            <div
                                              className={`${styleEditHouse.block_status} ${Status === 'In Progress' ? styleEditHouse.active : ''
                                                }`}
                                              onClick={() => handleStatusClick('In Progress')}
                                            >
                                              <span>In Progress</span>
                                            </div>
                                            <div
                                              className={`${styleEditHouse.block_status} ${Status === 'Finished' ? styleEditHouse.active : ''
                                                }`}
                                              onClick={() => handleStatusClick('Finished')}
                                            >
                                              <span>Finished</span>
                                            </div>
                                          </div>
                                        </div>
                                        <button type="submit"
                                          className={styleEditHouse.block_delete}
                                          onClick={() => ControlModuleDelete(problem)}
                                        >
                                          <span>Удалить профиль</span>
                                          <img src='/img/svg/AltArrowDown.svg' alt='arrow' />
                                        </button>
                                      </div>
                                    </main>
                                  </div>
                                </>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </main>
                  </div>
                </>
              }
              <div
                className={style.choose_block}
                onClick={ControlModuleProblemPlanned}
              >
                <div className={style.block_image}>
                  <img src='/img/svg/AddCircleBlack.svg' alt='' />
                </div>
                <div className={style.block_text}>
                  <span>Запланированная проблема</span>
                </div>
              </div>
              {showPlannedProblemModal && (
                <>
                  <div className={styleCardFillInfo.blur} onClick={ControlModuleProblemPlanned}></div>
                  <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                    <header className={styleCardFillInfo.header}>
                      <div className='header_container'>
                        <div className={styleCardFillInfo.header_exit}>
                          <img
                            src='/img/svg/AltArrowLeft.svg'
                            alt='arrow'
                            onClick={ControlModuleProblemPlanned}
                          />
                        </div>
                      </div>
                    </header>
                    <main className='content'>
                      <form
                        className='content_container'
                        onSubmit={e =>
                          submitProblem(e, 'Planned', newPlannedProblem)
                        }
                      >
                        <div className={styleAddProblem.header__title}>
                          <h2>Planned</h2>
                        </div>
                        <div className={styleAddProblem.block_select_img}>
                          <label className={styleAddProblem.select_img}>
                            <input
                              onChange={e => handlePhotoUpload(e, 'Planned')}
                              accept='image/*'
                              type='file'
                              style={{ display: 'none' }}
                            />
                            <span className={styleAddProblem.camera}>
                              Cделать фото
                            </span>
                          </label>
                          <label className={styleAddProblem.select_img}>
                            <input
                              onChange={e => handlePhotoUpload(e, 'Planned')}
                              accept='image/*'
                              type='file'
                              style={{ display: 'none' }}
                            />
                            <span className={styleAddProblem.folder}>
                              Выбрать файл
                            </span>
                          </label>
                        </div>
                        <div className={styleAddProblem.preview}>
                          {selectedPhoto && <img src={selectedPhoto} alt="" />}
                        </div>
                        <div className={styleAddProblem.input}>
                          <input
                            type='text'
                            value={newPlannedProblem.description}
                            onChange={e =>
                              setNewPlannedProblem({
                                ...newPlannedProblem,
                                description: e.target.value,
                              })
                            }
                            placeholder='Описание проблем'
                          />
                        </div>
                        <div className={styleAddProblem.block_input}>
                          <div className='select_arrow'>
                            <select
                              value={newPlannedProblem.problemType}
                              onChange={e =>
                                setNewPlannedProblem({
                                  ...newPlannedProblem,
                                  problemType: e.target.value,
                                })
                              }
                            >
                              {skills.map(skill => (
                                <option key={skill._id} value={skill._id}>
                                  {skill.skillName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className={styleAddProblem.block_input_time}>
                          <label htmlFor=''>Time</label>
                          <div className={styleAddProblem.select_arrow}>
                            <select
                              className={styleAddProblem.time}
                              value={newUrgentProblem.timeRange}
                              onChange={e =>
                                setNewUrgentProblem({
                                  ...newUrgentProblem,
                                  timeRange: e.target.value,
                                })
                              }
                            >
                              <option value='9:00--12:00'>9:00--12:00</option>
                              <option value='12:00--15:00'>12:00--15:00</option>
                              <option value='15:00--18:00'>15:00--18:00</option>
                              <option value='18:00--21:00'>18:00--21:00</option>
                            </select>
                          </div>
                        </div>
                        <button className={styleAddProblem.add}>
                          <span>Добавить запланированную проблему</span>
                        </button>
                        <button type="submit"
                          className={styleAddProblem.view}
                          onClick={() => ControlModuleListProblemOpen('Planned')}
                        >
                          <span>
                            Посмотреть текущие проблемы <strong>Planned</strong>
                          </span>
                          <img src='/img/svg/AltArrowDown.svg' alt='arrow' />
                        </button>
                      </form>
                    </main>
                  </div>
                </>
              )}
              {showModuleListProblemOpen && StatusProblemOpen === 'Planned' &&
                <>
                  <div className={styleCardFillInfo.blur} onClick={() => ControlModuleListProblemOpen('')}></div>
                  <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                    <header className={styleCardFillInfo.header}>
                      <div className={styleCardFillInfo.header_container}>
                        <div
                          className={styleCardFillInfo.header_exit}
                          onClick={() => ControlModuleListProblemOpen('')}
                        >
                          <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                        </div>
                        <div className={styleCardFillInfo.header__title}>
                          <h2>Планированные в процессе</h2>
                        </div>
                        <div className={styleCardFillInfo.header_search}>
                          <img src='/img/svg/Magnifer.svg' alt='Magnifer' />
                        </div>
                      </div>
                    </header>
                    <main className='content'>
                      <div className={styleCardFillInfo.content_container}>
                        <div className={style.block_list}>
                          {getProblemsByStatusAndType(
                            plannedProblems,
                            'Open',
                            'Planned'
                          ).map(problem => (
                            <div className={styleCardProblem.block_problem_small}>
                              <div
                                className={styleCardProblem.problem_img_small}
                                style={{
                                  backgroundImage: `url(${getProblemImageUrl(
                                    problem.initialPhotoID?.imageURL
                                  )})`,
                                }}
                                onClick={() => ControlShowInfoModalSmall(problem)}
                              >
                                <div className={styleCardProblem.problem_status_small}>
                                  <span>{problem.urgencyLevel}</span>
                                  <span>{problem.status}</span>
                                </div>
                                <div className={styleCardProblem.problem_date_small}>
                                  <span>{new Date(problem.creationDate).toLocaleDateString()}</span>
                                </div>
                              </div>
                              <div
                                className={styleCardProblem.problem_title_small}
                                onClick={() => ControlShowInfoModalSmall(problem)}
                              >
                                <h2>{problem.description}</h2>
                              </div>
                              <div className={styleCardProblem.problem_type_small}>
                                <span>
                                  Тип проблемы:
                                  <strong>{getSkillNameById(problem.problemType)}</strong>
                                </span>
                              </div>
                              <nav className={styleCardProblem.problem_nav_small}>
                                <button type="submit"
                                  className={styleCardProblem.edit_small}
                                  onClick={() => startEditingProblem(problem)}
                                >
                                  <span>Редактировать</span>
                                </button>
                                <button type="submit"
                                  className={styleCardProblem.delete_small}
                                  onClick={() => ControlModuleDelete(problem)}
                                >
                                  <img
                                    src='/img/svg/TrashBinMinimalistic.svg'
                                    alt='TrashBinMinimalistic'
                                  />
                                </button>
                              </nav>
                              {showDelete && showDelete._id === problem._id && <>
                                <div className={styleDelete.blur} onClick={() => ControlModuleDelete(null)}></div>
                                <div className={styleDelete.delete_block} onClick={e => e.stopPropagation()}>
                                  <div className={styleDelete.block_title}>
                                    <h2>Удаление проблемы</h2>
                                  </div>
                                  <div className={styleDelete.block_text}>
                                    <span>Вы действительно хотите удалить проблему?</span>
                                  </div>
                                  <div className={styleDelete.block_buttons}>
                                    <button type="submit"
                                      className={styleDelete.cancel}
                                      onClick={() => ControlModuleDelete(null)}
                                    >
                                      Отмена
                                    </button>
                                    <button type="submit"
                                      className={styleDelete.delete}
                                      onClick={() => deleteProblem(showDelete._id)}
                                    >
                                      Удалить
                                    </button>
                                  </div>
                                </div>
                              </>}
                              {showFullInfoSmall && showFullInfoSmall._id === problem._id &&
                                <>
                                  <div className={styleCardFillInfo.blur_none} onClick={() => ControlShowInfoModalSmall(null)} style={{ background: 'none' }}></div>
                                  <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                                    <header className={styleCardFillInfo.header}>
                                      <div className={styleCardFillInfo.header_container}>
                                        <div
                                          className={styleCardFillInfo.header_exit}
                                          onClick={() => ControlShowInfoModalSmall(null)}
                                        >
                                          <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                                        </div>
                                        <div className={styleCardFillInfo.header__title}>
                                          <h2>Планированные проблемы</h2>
                                        </div>
                                        <div className={styleCardFillInfo.header_search}>
                                          <img src='/img/svg/Magnifer.svg' alt='Magnifer' />
                                        </div>
                                      </div>
                                    </header>
                                    <main className='content'>
                                      <div className='content_container'>
                                        <div
                                          className={styleCardFillInfo.block_image}
                                          style={{
                                            backgroundImage: `url(${getProblemImageUrl(
                                              showFullInfoSmall.initialPhotoID?.imageURL
                                            )})`,
                                          }}
                                        >
                                          <div className={styleCardFillInfo.block_type}>
                                            <span>{showFullInfoSmall.urgencyLevel}</span>
                                          </div>
                                        </div>
                                        <div className={styleCardFillInfo.block_title_date}>
                                          <h2>{showFullInfoSmall.description}</h2>
                                          <span>{new Date(showFullInfoSmall.creationDate).toLocaleDateString()}</span>
                                        </div>
                                        <div className={styleCardFillInfo.block_status}>
                                          <span>Статус:</span>
                                          <span>{showFullInfoSmall.status}</span>
                                        </div>
                                        <div className={styleCardFillInfo.block_type_problem}>
                                          <span>Тип проблемы:</span>
                                          <span>{getSkillNameById(showFullInfoSmall.problemType)}</span>
                                        </div>
                                        <div className={styleCardFillInfo.block_time}>
                                          <span>Time</span>
                                          <span>{showFullInfoSmall.preferredTime || 'Не указано'}</span>
                                        </div>
                                        <div className={styleCardFillInfo.block_buttons}>
                                          <button type="submit"
                                            className={styleCardFillInfo.edit}
                                            onClick={() => startEditingProblem(problem)}
                                          >
                                            <span>Редактировать</span>
                                          </button>
                                          <button type="submit"
                                            className={styleCardFillInfo.delete}
                                            onClick={() => ControlModuleDelete(problem)}
                                          >
                                            <img
                                              src='/img/svg/TrashBinMinimalistic.svg'
                                              alt='TrashBinMinimalistic'
                                            />
                                          </button>
                                        </div>
                                      </div>
                                    </main>
                                  </div>
                                </>
                              }
                              {editingProblem && (
                                <>
                                  <div className={styleCardFillInfo.blur_none} onClick={() => setEditingProblem(null)} style={{ background: 'none' }}></div>
                                  <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                                    <header className={styleCardFillInfo.header}>
                                      <div className={styleCardFillInfo.header_container}>
                                        <div
                                          className={styleCardFillInfo.header_exit}
                                          onClick={() => setEditingProblem(null)}
                                        >
                                          <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                                        </div>
                                        <div
                                          className={styleEditHouse.header_save}
                                          onClick={() => saveProblem(editingProblem)}
                                        >
                                          <span>Сохранить</span>
                                        </div>
                                      </div>
                                    </header>
                                    <main className='content'>
                                      <div className='content_container'>
                                        <div className={styleEditHouse.header__title}>
                                          <h2>
                                            Редактирование <strong>Планированные в процессе</strong>
                                          </h2>
                                        </div>
                                        <label
                                          className={styleEditHouse.block_image}
                                          style={{
                                            backgroundImage: `url(${getProblemImageUrl(
                                              problem.initialPhotoID?.imageURL
                                            )})`,
                                          }}
                                        >
                                          <input
                                            type='file'
                                            onChange={handleProblemPhotoUpload}
                                            accept='image/*'
                                            style={{ display: 'none' }}
                                          />
                                          <div className={styleEditHouse.block_text}>
                                            <span>Изменить фото</span>
                                          </div>
                                        </label>
                                        <div className={styleEditProblem.block_input}>
                                          <label htmlFor=''>Название проблемы:</label>
                                          <div className={styleEditProblem.input}>
                                            <input type='text' value={editingProblem.description}
                                              onChange={e => setEditingProblem({ ...editingProblem, description: e.target.value })} />
                                          </div>
                                        </div>
                                        <div className={styleEditProblem.block_input}>
                                          <label htmlFor=''>Тип проблемы:</label>
                                          <div className={styleEditProblem.select_arrow}>
                                            <select
                                              value={editingProblem.problemType}
                                              onChange={e =>
                                                setEditingProblem({
                                                  ...editingProblem,
                                                  problemType: e.target.value,
                                                })
                                              }
                                            >
                                              {skills.map(skill => (
                                                <option key={skill._id} value={skill._id}>
                                                  {skill.skillName}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        </div>
                                        <div className={styleEditProblem.block_input}>
                                          <label htmlFor=''>Сложность проблемы:</label>
                                          <div className={styleEditProblem.select_arrow}>
                                            <select
                                              value={editingProblem.urgencyLevel}
                                              onChange={e =>
                                                setEditingProblem({
                                                  ...editingProblem,
                                                  urgencyLevel: e.target.value,
                                                })
                                              }
                                            >
                                              <option value='Urgent'>Urgent</option>
                                              <option value='Planned'>Planned</option>
                                            </select>
                                          </div>
                                        </div>
                                        <div className={styleEditProblem.block_input}>
                                          <label htmlFor=''>Время работ</label>
                                          <div className={styleEditProblem.select_arrow}>
                                            <select
                                              value={editingProblem.preferredTime}
                                              onChange={e =>
                                                setEditingProblem({
                                                  ...editingProblem,
                                                  preferredTime: e.target.value,
                                                })
                                              }
                                              className={styleEditHouse.time}
                                            >
                                              <option value='9:00--12:00'>9:00--12:00</option>
                                              <option value='12:00--15:00'>12:00--15:00</option>
                                              <option value='15:00--18:00'>15:00--18:00</option>
                                              <option value='18:00--21:00'>18:00--21:00</option>
                                            </select>
                                          </div>
                                        </div>
                                        <div className={styleEditHouse.block_input}>
                                          <label htmlFor=''>Сделать статус:</label>
                                          <div className={styleEditHouse.row}>
                                            <div
                                              className={`${styleEditHouse.block_status} ${Status === 'Open' ? styleEditHouse.active : ''
                                                }`}
                                              onClick={() => handleStatusClick('Open')}
                                            >
                                              <span>Open</span>
                                            </div>
                                            <div
                                              className={`${styleEditHouse.block_status} ${Status === 'In Progress' ? styleEditHouse.active : ''
                                                }`}
                                              onClick={() => handleStatusClick('In Progress')}
                                            >
                                              <span>In Progress</span>
                                            </div>
                                            <div
                                              className={`${styleEditHouse.block_status} ${Status === 'Finished' ? styleEditHouse.active : ''
                                                }`}
                                              onClick={() => handleStatusClick('Finished')}
                                            >
                                              <span>Finished</span>
                                            </div>
                                          </div>
                                        </div>
                                        <button type="submit"
                                          className={styleEditHouse.block_delete}
                                          onClick={() => ControlModuleDelete(problem)}
                                        >
                                          <span>Удалить профиль</span>
                                          <img src='/img/svg/AltArrowDown.svg' alt='arrow' />
                                        </button>
                                      </div>
                                    </main>
                                  </div>
                                </>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </main>
                  </div>
                </>
              }
            </div>
          </article>
          <article className={style.content_current_problem}>
            <div className={style.problem_title}>
              <h2 className={style.title}>Текущие проблемы</h2>
              <span className={style.link}>Все проблемы</span>
            </div>
            <nav className={style.content_current_problem_buttons}>
              <button type="submit"
                className={
                  Active ? `${style.urgent} ${style.active}` : style.urgent
                }
                onClick={handleButtonClickUrgent}
              >
                <span>Срочные</span>
              </button>
              <button type="submit"
                className={
                  !Active ? `${style.planned} ${style.active}` : style.planned
                }
                onClick={handleButtonClickPlanned}
              >
                <span>Запланированные</span>
              </button>
            </nav>
            <div className={style.list_problem}>
              {Active
                ? getProblemsByStatusAndType(
                  urgentProblems,
                  'Open',
                  'Urgent'
                ).map(problem => (
                  <div className={styleCardProblem.block_problem}>
                    <div
                      className={styleCardProblem.problem_img}
                      style={{
                        backgroundImage: `url(${getProblemImageUrl(
                          problem.initialPhotoID?.imageURL
                        )})`,
                      }}
                      onClick={() => {
                        ControlModuleInfo(problem)
                      }}
                    >
                      <div className={styleCardProblem.problem_status}>
                        <span>{problem.urgencyLevel}</span>
                        <span>{problem.status}</span>
                      </div>
                      <div className={styleCardProblem.problem_date}>
                        <span>{new Date(problem.creationDate).toLocaleDateString()}</span>
                      </div>
                    </div>
                    <div
                      className={styleCardProblem.problem_title}
                      onClick={() => ControlModuleInfo(problem)}
                    >
                      <h2>{problem.description}</h2>
                    </div>
                    <div className={styleCardProblem.problem_type}>
                      <span>
                        Тип проблемы:
                        <strong>{getSkillNameById(problem.problemType)}</strong>
                      </span>
                    </div>
                    <nav className={styleCardProblem.problem_nav}>
                      <button type="submit"
                        className={styleCardProblem.edit}
                        onClick={() => startEditingProblem(problem)}
                      >
                        <span>Редактировать</span>
                      </button>
                      <button type="submit"
                        className={styleCardProblem.delete}
                        onClick={() => ControlModuleDelete(problem)}
                      >
                        <img
                          src='/img/svg/TrashBinMinimalistic.svg'
                          alt='TrashBinMinimalistic'
                        />
                      </button>
                    </nav>
                    {showDelete && showDelete._id === problem._id && <>
                      <div className={styleDelete.blur} onClick={() => ControlModuleDelete(null)}></div>
                      <div className={styleDelete.delete_block} onClick={e => e.stopPropagation()}>
                        <div className={styleDelete.block_title}>
                          <h2>Удаление проблемы</h2>
                        </div>
                        <div className={styleDelete.block_text}>
                          <span>Вы действительно хотите удалить проблему?</span>
                        </div>
                        <div className={styleDelete.block_buttons}>
                          <button type="submit"
                            className={styleDelete.cancel}
                            onClick={() => ControlModuleDelete(null)}
                          >
                            Отмена
                          </button>
                          <button type="submit"
                            className={styleDelete.delete}
                            onClick={() => deleteProblem(showDelete._id)}
                          >
                            Удалить
                          </button>
                        </div>
                      </div>
                    </>}
                    {showFullInfo && showFullInfo._id === problem._id &&
                      <>
                        <div className={styleCardFillInfo.blur} onClick={() => ControlModuleInfo(null)}></div>
                        <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                          <header className={styleCardFillInfo.header}>
                            <div className={styleCardFillInfo.header_container}>
                              <div
                                className={styleCardFillInfo.header_exit}
                                onClick={() => ControlModuleInfo(null)}
                              >
                                <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                              </div>
                              <div className={styleCardFillInfo.header__title}>
                                <h2>срочные проблемы</h2>
                              </div>
                              <div className={styleCardFillInfo.header_search}>
                                <img src='/img/svg/Magnifer.svg' alt='Magnifer' />
                              </div>
                            </div>
                          </header>
                          <main className='content'>
                            <div className='content_container'>
                              <div
                                className={styleCardFillInfo.block_image}
                                style={{
                                  backgroundImage: `url(${getProblemImageUrl(
                                    showFullInfo.initialPhotoID?.imageURL
                                  )})`,
                                }}
                              >
                                <div className={styleCardFillInfo.block_type}>
                                  <span>{showFullInfo.urgencyLevel}</span>
                                </div>
                              </div>
                              <div className={styleCardFillInfo.block_title_date}>
                                <h2>{showFullInfo.description}</h2>
                                <span>{new Date(showFullInfo.creationDate).toLocaleDateString()}</span>
                              </div>
                              <div className={styleCardFillInfo.block_status}>
                                <span>Статус:</span>
                                <span>{showFullInfo.status}</span>
                              </div>
                              <div className={styleCardFillInfo.block_type_problem}>
                                <span>Тип проблемы:</span>
                                <span>{getSkillNameById(showFullInfo.problemType)}</span>
                              </div>
                              <div className={styleCardFillInfo.block_time}>
                                <span>Time</span>
                                <span>{showFullInfo.preferredTime || 'Не указано'}</span>
                              </div>
                              <div className={styleCardFillInfo.block_buttons}>
                                <button type="submit"
                                  className={styleCardFillInfo.edit}
                                  onClick={() => startEditingProblem(problem)}
                                >
                                  <span>Редактировать</span>
                                </button>
                                <button type="submit"
                                  className={styleCardFillInfo.delete}
                                  onClick={() => ControlModuleDelete(problem)}
                                >
                                  <img
                                    src='/img/svg/TrashBinMinimalistic.svg'
                                    alt='TrashBinMinimalistic'
                                  />
                                </button>
                              </div>
                            </div>
                          </main>
                        </div>
                      </>
                    }
                    {editingProblem && editingProblem._id === problem._id && (
                      <>
                        <div className={showFullInfo ? styleCardFillInfo.blur_none : styleCardFillInfo.blur} onClick={() => setEditingProblem(null)}></div>
                        <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                          <header className={styleCardFillInfo.header}>
                            <div className={styleCardFillInfo.header_container}>
                              <div
                                className={styleCardFillInfo.header_exit}
                                onClick={() => setEditingProblem(null)}
                              >
                                <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                              </div>
                              <div
                                className={styleEditHouse.header_save}
                                onClick={() => saveProblem(editingProblem)}
                              >
                                <span>Сохранить</span>
                              </div>
                            </div>
                          </header>
                          <main className='content'>
                            <div className='content_container'>
                              <div className={styleEditHouse.header__title}>
                                <h2>
                                  Редактирование <strong>Срочные в процессе</strong>
                                </h2>
                              </div>
                              <label
                                className={styleEditHouse.block_image}
                                style={{
                                  backgroundImage: `url(${selectedPhoto ? selectedPhoto : getProblemImageUrl(
                                    problem.initialPhotoID?.imageURL
                                  )})`,
                                }}
                              >
                                <input
                                  type='file'
                                  onChange={handleProblemPhotoUpload}
                                  accept='image/*'
                                  style={{ display: 'none' }}
                                />
                                <div className={styleEditHouse.block_text}>
                                  <span>Изменить фото</span>
                                </div>
                              </label>
                              <div className={styleEditProblem.block_input}>
                                <label htmlFor=''>Название проблемы:</label>
                                <div className={styleEditProblem.input}>
                                  <input type='text' value={editingProblem.description}
                                    onChange={e => setEditingProblem({ ...editingProblem, description: e.target.value })} />
                                </div>
                              </div>
                              <div className={styleEditProblem.block_input}>
                                <label htmlFor=''>Тип проблемы:</label>
                                <div className={styleEditProblem.select_arrow}>
                                  <select
                                    value={editingProblem.problemType}
                                    onChange={e => setEditingProblem({ ...editingProblem, problemType: e.target.value })}
                                  >
                                    {skills.map(skill => (
                                      <option key={skill._id} value={skill._id}>{skill.skillName}</option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div className={styleEditProblem.block_input}>
                                <label htmlFor=''>Сложность проблемы:</label>
                                <div className={styleEditProblem.select_arrow}>
                                  <select
                                    value={editingProblem.urgencyLevel}
                                    onChange={e => setEditingProblem({ ...editingProblem, urgencyLevel: e.target.value })}
                                  >
                                    <option value='Urgent'>Urgent</option>
                                    <option value='Planned'>Planned</option>
                                  </select>
                                </div>
                              </div>
                              <div className={styleEditProblem.block_input}>
                                <label htmlFor=''>Время работ</label>
                                <div className={styleEditProblem.select_arrow}>
                                  <select
                                    value={editingProblem.preferredTime}
                                    onChange={e => setEditingProblem({ ...editingProblem, preferredTime: e.target.value })}
                                    className={styleEditHouse.time}
                                  >
                                    <option value='9:00--12:00'>9:00--12:00</option>
                                    <option value='12:00--15:00'>12:00--15:00</option>
                                    <option value='15:00--18:00'>15:00--18:00</option>
                                    <option value='18:00--21:00'>18:00--21:00</option>
                                  </select>
                                </div>
                              </div>
                              <div className={styleEditHouse.block_input}>
                                <label htmlFor=''>Сделать статус:</label>
                                <div className={styleEditHouse.row}>
                                  <div
                                    className={`${styleEditHouse.block_status} ${Status === 'Open' ? styleEditHouse.active : ''
                                      }`}
                                    onClick={() => handleStatusClick('Open')}
                                  >
                                    <span>Open</span>
                                  </div>
                                  <div
                                    className={`${styleEditHouse.block_status} ${Status === 'In Progress' ? styleEditHouse.active : ''
                                      }`}
                                    onClick={() => handleStatusClick('In Progress')}
                                  >
                                    <span>In Progress</span>
                                  </div>
                                  <div
                                    className={`${styleEditHouse.block_status} ${Status === 'Finished' ? styleEditHouse.active : ''
                                      }`}
                                    onClick={() => handleStatusClick('Finished')}
                                  >
                                    <span>Finished</span>
                                  </div>
                                </div>
                              </div>
                              <button type="submit"
                                className={styleEditHouse.block_delete}
                                onClick={() => ControlModuleDelete(problem)}
                              >
                                <span>Удалить профиль</span>
                                <img src='/img/svg/AltArrowDown.svg' alt='arrow' />
                              </button>
                            </div>
                          </main>
                        </div>
                      </>
                    )}
                  </div>))
                : getProblemsByStatusAndType(
                  plannedProblems,
                  'Open',
                  'Planned'
                ).map(problem => <div className={styleCardProblem.block_problem}>
                  <div
                    className={styleCardProblem.problem_img}
                    style={{
                      backgroundImage: `url(${getProblemImageUrl(
                        problem.initialPhotoID?.imageURL
                      )})`,
                    }}
                    onClick={() => ControlModuleInfo(problem)}
                  >
                    <div className={styleCardProblem.problem_status}>
                      <span>{problem.urgencyLevel}</span>
                      <span>{problem.status}</span>
                    </div>
                    <div className={styleCardProblem.problem_date}>
                      <span>{new Date(problem.creationDate).toLocaleDateString()}</span>
                    </div>
                  </div>
                  <div
                    className={styleCardProblem.problem_title}
                    onClick={() => ControlModuleInfo(problem)}
                  >
                    <h2>{problem.description}</h2>
                  </div>
                  <div className={styleCardProblem.problem_type}>
                    <span>
                      Тип проблемы:
                      <strong>{getSkillNameById(problem.problemType)}</strong>
                    </span>
                  </div>
                  <nav className={styleCardProblem.problem_nav}>
                    <button type="submit"
                      className={styleCardProblem.edit}
                      onClick={() => startEditingProblem(problem)}
                    >
                      <span>Редактировать</span>
                    </button>
                    <button type="submit"
                      className={styleCardProblem.delete}
                      onClick={() => ControlModuleDelete(problem)}
                    >
                      <img
                        src='/img/svg/TrashBinMinimalistic.svg'
                        alt='TrashBinMinimalistic'
                      />
                    </button>
                  </nav>
                  {showDelete && showDelete._id === problem._id && <>
                    <div className={styleDelete.blur} onClick={() => ControlModuleDelete(null)}></div>
                    <div className={styleDelete.delete_block} onClick={e => e.stopPropagation()}>
                      <div className={styleDelete.block_title}>
                        <h2>Удаление проблемы</h2>
                      </div>
                      <div className={styleDelete.block_text}>
                        <span>Вы действительно хотите удалить проблему?</span>
                      </div>
                      <div className={styleDelete.block_buttons}>
                        <button type="submit"
                          className={styleDelete.cancel}
                          onClick={() => ControlModuleDelete(null)}
                        >
                          Отмена
                        </button>
                        <button type="submit"
                          className={styleDelete.delete}
                          onClick={() => deleteProblem(showDelete._id)}
                        >
                          Удалить
                        </button>
                      </div>
                    </div>
                  </>}
                  {showFullInfo && showFullInfo._id === problem._id &&
                    <>
                      <div className={styleCardFillInfo.blur} onClick={() => ControlModuleInfo(null)}></div>
                      <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                        <header className={styleCardFillInfo.header}>
                          <div className={styleCardFillInfo.header_container}>
                            <div
                              className={styleCardFillInfo.header_exit}
                              onClick={() => ControlModuleInfo(problem)}
                            >
                              <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                            </div>
                            <div className={styleCardFillInfo.header__title}>
                              <h2>Планированные проблемы</h2>
                            </div>
                            <div className={styleCardFillInfo.header_search}>
                              <img src='/img/svg/Magnifer.svg' alt='Magnifer' />
                            </div>
                          </div>
                        </header>
                        <main className='content'>
                          <div className={styleCardFillInfo.content_container}>
                            <div
                              className={styleCardFillInfo.block_image}
                              style={{
                                backgroundImage: `url(${getProblemImageUrl(
                                  showFullInfo.initialPhotoID?.imageURL
                                )})`,
                              }}
                            >
                              <div className={styleCardFillInfo.block_type}>
                                <span>{showFullInfo.urgencyLevel}</span>
                              </div>
                            </div>
                            <div className={styleCardFillInfo.block_title_date}>
                              <h2>{showFullInfo.description}</h2>
                              <span>{new Date(showFullInfo.creationDate).toLocaleDateString()}</span>
                            </div>
                            <div className={styleCardFillInfo.block_status}>
                              <span>Статус:</span>
                              <span>{showFullInfo.status}</span>
                            </div>
                            <div className={styleCardFillInfo.block_type_problem}>
                              <span>Тип проблемы:</span>
                              <span>{getSkillNameById(showFullInfo.problemType)}</span>
                            </div>
                            <div className={styleCardFillInfo.block_time}>
                              <span>Time</span>
                              <span>{showFullInfo.preferredTime || 'Не указано'}</span>
                            </div>
                            <div className={styleCardFillInfo.block_buttons}>
                              <button type="submit"
                                className={styleCardFillInfo.edit}
                                onClick={() => startEditingProblem(problem)}
                              >
                                <span>Редактировать</span>
                              </button>
                              <button type="submit"
                                className={styleCardFillInfo.delete}
                                onClick={() => ControlModuleDelete(problem)}
                              >
                                <img
                                  src='/img/svg/TrashBinMinimalistic.svg'
                                  alt='TrashBinMinimalistic'
                                />
                              </button>
                            </div>
                          </div>
                        </main>
                      </div>
                    </>
                  }
                  {editingProblem && editingProblem._id === problem._id && (
                    <>
                      <div className={showFullInfo ? styleCardFillInfo.blur_none : styleCardFillInfo.blur} onClick={() => setEditingProblem(null)}></div>
                      <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                        <header className={styleCardFillInfo.header}>
                          <div className={styleCardFillInfo.header_container}>
                            <div
                              className={styleCardFillInfo.header_exit}
                              onClick={() => setEditingProblem(null)}
                            >
                              <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                            </div>
                            <div
                              className={styleEditHouse.header_save}
                              onClick={() => saveProblem(editingProblem)}
                            >
                              <span>Сохранить</span>
                            </div>
                          </div>
                        </header>
                        <main className='content'>
                          <div className='content_container'>
                            <div className={styleEditHouse.header__title}>
                              <h2>
                                Редактирование <strong>Планированные в процессе</strong>
                              </h2>
                            </div>
                            <label
                              className={styleEditHouse.block_image}
                              style={{
                                backgroundImage: `url(${selectedPhoto ? selectedPhoto : getProblemImageUrl(
                                  problem.initialPhotoID?.imageURL
                                )})`,
                              }}
                            >
                              <input
                                type='file'
                                onChange={handleProblemPhotoUpload}
                                accept='image/*'
                                style={{ display: 'none' }}
                              />
                              <div className={styleEditHouse.block_text}>
                                <span>Изменить фото</span>
                              </div>
                            </label>
                            <div className={styleEditProblem.block_input}>
                              <label htmlFor=''>Название проблемы:</label>
                              <div className={styleEditProblem.input}>
                                <input type='text' value={editingProblem.description}
                                  onChange={e => setEditingProblem({ ...editingProblem, description: e.target.value })} />
                              </div>
                            </div>
                            <div className={styleEditProblem.block_input}>
                              <label htmlFor=''>Тип проблемы:</label>
                              <div className={styleEditProblem.select_arrow}>
                                <select
                                  value={editingProblem.problemType}
                                  onChange={e => setEditingProblem({ ...editingProblem, problemType: e.target.value })}
                                >
                                  {skills.map(skill => (
                                    <option key={skill._id} value={skill._id}>{skill.skillName}</option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className={styleEditProblem.block_input}>
                              <label htmlFor=''>Сложность проблемы:</label>
                              <div className={styleEditProblem.select_arrow}>
                                <select
                                  value={editingProblem.urgencyLevel}
                                  onChange={e => setEditingProblem({ ...editingProblem, urgencyLevel: e.target.value })}
                                >
                                  <option value='Urgent'>Urgent</option>
                                  <option value='Planned'>Planned</option>
                                </select>
                              </div>
                            </div>
                            <div className={styleEditProblem.block_input}>
                              <label htmlFor=''>Время работ</label>
                              <div className={styleEditProblem.select_arrow}>
                                <select
                                  value={editingProblem.preferredTime}
                                  onChange={e => setEditingProblem({ ...editingProblem, preferredTime: e.target.value })}
                                  className={styleEditHouse.time}
                                >
                                  <option value='9:00--12:00'>9:00--12:00</option>
                                  <option value='12:00--15:00'>12:00--15:00</option>
                                  <option value='15:00--18:00'>15:00--18:00</option>
                                  <option value='18:00--21:00'>18:00--21:00</option>
                                </select>
                              </div>
                            </div>
                            <div className={styleEditHouse.block_input}>
                              <label htmlFor=''>Сделать статус:</label>
                              <div className={styleEditHouse.row}>
                                <div
                                  className={`${styleEditHouse.block_status} ${Status === 'Open' ? styleEditHouse.active : ''
                                    }`}
                                  onClick={() => handleStatusClick('Open')}
                                >
                                  <span>Open</span>
                                </div>
                                <div
                                  className={`${styleEditHouse.block_status} ${Status === 'In Progress' ? styleEditHouse.active : ''
                                    }`}
                                  onClick={() => handleStatusClick('In Progress')}
                                >
                                  <span>In Progress</span>
                                </div>
                                <div
                                  className={`${styleEditHouse.block_status} ${Status === 'Finished' ? styleEditHouse.active : ''
                                    }`}
                                  onClick={() => handleStatusClick('Finished')}
                                >
                                  <span>Finished</span>
                                </div>
                              </div>
                            </div>
                            <button type="submit"
                              className={styleEditHouse.block_delete}
                              onClick={() => ControlModuleDelete(problem)}
                            >
                              <span>Удалить профиль</span>
                              <img src='/img/svg/AltArrowDown.svg' alt='arrow' />
                            </button>
                          </div>
                        </main>
                      </div>
                    </>
                  )}
                </div>)}
            </div>
          </article>
          <article className={style.content_problem_process}>
            <div className={style.process_title}>
              <h2>Проблемы в процессе</h2>
            </div>
            <div className={styleCardProblems.list_problems}>
              <div
                className={styleCardProblems.problems_block}
                onClick={() => ControlModuleListProblem('Urgent')}
              >
                <div className={styleCardProblems.block_left}>
                  <div className={styleCardProblems.problems_title}>
                    <h2>Срочные в процессе</h2>
                  </div>
                  <div className={styleCardProblems.problems_info}>
                    <div className={styleCardProblems.info_images}>
                      {getProblemsByStatusAndType(
                        urgentProblems,
                        'In Progress',
                        'Urgent'
                      ).slice(0, 3).map((problem, index) => (
                        <img
                          src={getProblemImageUrl(
                            problem.initialPhotoID?.imageURL
                          )}
                          alt='photo'
                        />
                      ))}
                    </div>
                    <div className={styleCardProblems.info_text}>
                      <span>
                        {
                          getProblemsByStatusAndType(
                            urgentProblems,
                            'In Progress',
                            'Urgent'
                          ).length
                        }{' '}
                        проблемы в процессе
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styleCardProblems.blokc_right}>
                  <button type="submit">
                    <img src='/img/svg/AltArrowDown.svg' alt='arrow' />
                  </button>
                </div>
              </div>
              {showModuleListProblem && StatusProblem === 'Urgent' &&
                <>
                  <div className={styleCardFillInfo.blur} onClick={() => ControlModuleListProblem('')}></div>
                  <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                    <header className={styleCardFillInfo.header}>
                      <div className={styleCardFillInfo.header_container}>
                        <div
                          className={styleCardFillInfo.header_exit}
                          onClick={() => ControlModuleListProblem('')}
                        >
                          <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                        </div>
                        <div className={styleCardFillInfo.header__title}>
                          <h2>Срочные в процессе</h2>
                        </div>
                        <div className={styleCardFillInfo.header_search}>
                          <img src='/img/svg/Magnifer.svg' alt='Magnifer' />
                        </div>
                      </div>
                    </header>
                    <main className='content'>
                      <div className={styleCardFillInfo.content_container}>
                        <div className={style.block_list}>
                          {getProblemsByStatusAndType(
                            urgentProblems,
                            'In Progress',
                            'Urgent'
                          ).map(problem => (
                            <div className={styleCardProblem.block_problem_small}>
                              <div
                                className={styleCardProblem.problem_img_small}
                                style={{
                                  backgroundImage: `url(${getProblemImageUrl(
                                    problem.initialPhotoID?.imageURL
                                  )})`,
                                }}
                                onClick={() => ControlShowInfoModalSmall(problem)}
                              >
                                <div className={styleCardProblem.problem_status_small}>
                                  <span>{problem.urgencyLevel}</span>
                                  <span>{problem.status}</span>
                                </div>
                                <div className={styleCardProblem.problem_date_small}>
                                  <span>{new Date(problem.creationDate).toLocaleDateString()}</span>
                                </div>
                              </div>
                              <div
                                className={styleCardProblem.problem_title_small}
                                onClick={() => ControlShowInfoModalSmall(problem)}
                              >
                                <h2>{problem.description}</h2>
                              </div>
                              <div className={styleCardProblem.problem_type_small}>
                                <span>
                                  Тип проблемы:
                                  <strong>{getSkillNameById(problem.problemType)}</strong>
                                </span>
                              </div>
                              <nav className={styleCardProblem.problem_nav_small}>
                                <button type="submit"
                                  className={styleCardProblem.edit_small}
                                  onClick={() => startEditingProblem(problem)}
                                >
                                  <span>Редактировать</span>
                                </button>
                                <button type="submit"
                                  className={styleCardProblem.delete_small}
                                  onClick={() => ControlModuleDelete(problem)}
                                >
                                  <img
                                    src='/img/svg/TrashBinMinimalistic.svg'
                                    alt='TrashBinMinimalistic'
                                  />
                                </button>
                              </nav>
                              {showDelete && showDelete._id === problem._id && <>
                                <div className={styleDelete.blur} onClick={() => ControlModuleDelete(null)}></div>
                                <div className={styleDelete.delete_block} onClick={e => e.stopPropagation()}>
                                  <div className={styleDelete.block_title}>
                                    <h2>Удаление проблемы</h2>
                                  </div>
                                  <div className={styleDelete.block_text}>
                                    <span>Вы действительно хотите удалить проблему?</span>
                                  </div>
                                  <div className={styleDelete.block_buttons}>
                                    <button type="submit"
                                      className={styleDelete.cancel}
                                      onClick={() => ControlModuleDelete(null)}
                                    >
                                      Отмена
                                    </button>
                                    <button type="submit"
                                      className={styleDelete.delete}
                                      onClick={() => deleteProblem(showDelete._id)}
                                    >
                                      Удалить
                                    </button>
                                  </div>
                                </div>
                              </>}
                              {showFullInfoSmall && showFullInfoSmall._id === problem._id &&
                                <>
                                  <div className={styleCardFillInfo.blur_none} onClick={() => ControlShowInfoModalSmall(null)}></div>
                                  <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                                    <header className={styleCardFillInfo.header}>
                                      <div className={styleCardFillInfo.header_container}>
                                        <div
                                          className={styleCardFillInfo.header_exit}
                                          onClick={() => ControlShowInfoModalSmall(null)}
                                        >
                                          <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                                        </div>
                                        <div className={styleCardFillInfo.header__title}>
                                          <h2>срочные проблемы</h2>
                                        </div>
                                        <div className={styleCardFillInfo.header_search}>
                                          <img src='/img/svg/Magnifer.svg' alt='Magnifer' />
                                        </div>
                                      </div>
                                    </header>
                                    <main className='content'>
                                      <div className='content_container'>
                                        <div
                                          className={styleCardFillInfo.block_image}
                                          style={{
                                            backgroundImage: `url(${getProblemImageUrl(
                                              showFullInfoSmall.initialPhotoID?.imageURL
                                            )})`,
                                          }}
                                        >
                                          <div className={styleCardFillInfo.block_type}>
                                            <span>{showFullInfoSmall.urgencyLevel}</span>
                                          </div>
                                        </div>
                                        <div className={styleCardFillInfo.block_title_date}>
                                          <h2>{showFullInfoSmall.description}</h2>
                                          <span>{new Date(showFullInfoSmall.creationDate).toLocaleDateString()}</span>
                                        </div>
                                        <div className={styleCardFillInfo.block_status}>
                                          <span>Статус:</span>
                                          <span>{showFullInfoSmall.status}</span>
                                        </div>
                                        <div className={styleCardFillInfo.block_type_problem}>
                                          <span>Тип проблемы:</span>
                                          <span>{getSkillNameById(showFullInfoSmall.problemType)}</span>
                                        </div>
                                        <div className={styleCardFillInfo.block_time}>
                                          <span>Time</span>
                                          <span>{showFullInfoSmall.preferredTime || 'Не указано'}</span>
                                        </div>
                                        <div className={styleCardFillInfo.block_buttons}>
                                          <button type="submit"
                                            className={styleCardFillInfo.edit}
                                            onClick={() => startEditingProblem(problem)}
                                          >
                                            <span>Редактировать</span>
                                          </button>
                                          <button type="submit"
                                            className={styleCardFillInfo.delete}
                                            onClick={() => ControlModuleDelete(problem)}
                                          >
                                            <img
                                              src='/img/svg/TrashBinMinimalistic.svg'
                                              alt='TrashBinMinimalistic'
                                            />
                                          </button>
                                        </div>
                                      </div>
                                    </main>
                                  </div>
                                </>
                              }
                              {editingProblem && (
                                <>
                                  <div className={styleCardFillInfo.blur_none} onClick={() => setEditingProblem(null)}></div>
                                  <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                                    <header className={styleCardFillInfo.header}>
                                      <div className={styleCardFillInfo.header_container}>
                                        <div
                                          className={styleCardFillInfo.header_exit}
                                          onClick={() => setEditingProblem(null)}
                                        >
                                          <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                                        </div>
                                        <div
                                          className={styleEditHouse.header_save}
                                          onClick={() => saveProblem(editingProblem)}
                                        >
                                          <span>Сохранить</span>
                                        </div>
                                      </div>
                                    </header>
                                    <main className='content'>
                                      <div className='content_container'>
                                        <div className={styleEditHouse.header__title}>
                                          <h2>
                                            Редактирование <strong>Срочные в процессе</strong>
                                          </h2>
                                        </div>
                                        <label
                                          className={styleEditHouse.block_image}
                                          style={{
                                            backgroundImage: `url(${getProblemImageUrl(
                                              problem.initialPhotoID?.imageURL
                                            )})`,
                                          }}
                                        >
                                          <input
                                            type='file'
                                            onChange={handleProblemPhotoUpload}
                                            accept='image/*'
                                            style={{ display: 'none' }}
                                          />
                                          <div className={styleEditHouse.block_text}>
                                            <span>Изменить фото</span>
                                          </div>
                                        </label>
                                        <div className={styleEditProblem.block_input}>
                                          <label htmlFor=''>Название проблемы:</label>
                                          <div className={styleEditProblem.input}>
                                            <input type='text' value={editingProblem.description}
                                              onChange={e => setEditingProblem({ ...editingProblem, description: e.target.value })} />
                                          </div>
                                        </div>
                                        <div className={styleEditProblem.block_input}>
                                          <label htmlFor=''>Тип проблемы:</label>
                                          <div className={styleEditProblem.select_arrow}>
                                            <select
                                              value={editingProblem.problemType}
                                              onChange={e =>
                                                setEditingProblem({
                                                  ...editingProblem,
                                                  problemType: e.target.value,
                                                })
                                              }
                                            >
                                              {skills.map(skill => (
                                                <option key={skill._id} value={skill._id}>
                                                  {skill.skillName}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        </div>
                                        <div className={styleEditProblem.block_input}>
                                          <label htmlFor=''>Сложность проблемы:</label>
                                          <div className={styleEditProblem.select_arrow}>
                                            <select
                                              value={editingProblem.urgencyLevel}
                                              onChange={e =>
                                                setEditingProblem({
                                                  ...editingProblem,
                                                  urgencyLevel: e.target.value,
                                                })
                                              }
                                            >
                                              <option value='Urgent'>Urgent</option>
                                              <option value='Planned'>Planned</option>
                                            </select>
                                          </div>
                                        </div>
                                        <div className={styleEditProblem.block_input}>
                                          <label htmlFor=''>Время работ</label>
                                          <div className={styleEditProblem.select_arrow}>
                                            <select
                                              value={editingProblem.preferredTime}
                                              onChange={e =>
                                                setEditingProblem({
                                                  ...editingProblem,
                                                  preferredTime: e.target.value,
                                                })
                                              }
                                              className={styleEditHouse.time}
                                            >
                                              <option value='9:00--12:00'>9:00--12:00</option>
                                              <option value='12:00--15:00'>12:00--15:00</option>
                                              <option value='15:00--18:00'>15:00--18:00</option>
                                              <option value='18:00--21:00'>18:00--21:00</option>
                                            </select>
                                          </div>
                                        </div>
                                        <div className={styleEditHouse.block_input}>
                                          <label htmlFor=''>Сделать статус:</label>
                                          <div className={styleEditHouse.row}>
                                            <div
                                              className={`${styleEditHouse.block_status} ${Status === 'Open' ? styleEditHouse.active : ''
                                                }`}
                                              onClick={() => handleStatusClick('Open')}
                                            >
                                              <span>Open</span>
                                            </div>
                                            <div
                                              className={`${styleEditHouse.block_status} ${Status === 'In Progress' ? styleEditHouse.active : ''
                                                }`}
                                              onClick={() => handleStatusClick('In Progress')}
                                            >
                                              <span>In Progress</span>
                                            </div>
                                            <div
                                              className={`${styleEditHouse.block_status} ${Status === 'Finished' ? styleEditHouse.active : ''
                                                }`}
                                              onClick={() => handleStatusClick('Finished')}
                                            >
                                              <span>Finished</span>
                                            </div>
                                          </div>
                                        </div>
                                        <button type="submit"
                                          className={styleEditHouse.block_delete}
                                          onClick={() => ControlModuleDelete(problem)}
                                        >
                                          <span>Удалить профиль</span>
                                          <img src='/img/svg/AltArrowDown.svg' alt='arrow' />
                                        </button>
                                      </div>
                                    </main>
                                  </div>
                                </>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </main>
                  </div>
                </>
              }
              <div
                className={styleCardProblems.problems_block}
                onClick={() => ControlModuleListProblem('Planned')}
              >
                <div className={styleCardProblems.block_left}>
                  <div className={styleCardProblems.problems_title}>
                    <h2>Плановые в процессе</h2>
                  </div>
                  <div className={styleCardProblems.problems_info}>
                    <div className={styleCardProblems.info_images}>
                      {getProblemsByStatusAndType(
                        plannedProblems,
                        'In Progress',
                        'Planned'
                      ).slice(0, 3).map((problem) => (
                        <img
                          src={getProblemImageUrl(
                            problem.initialPhotoID?.imageURL
                          )}
                          alt='photo'
                        />
                      ))}
                    </div>
                    <div className={styleCardProblems.info_text}>
                      <span>
                        {
                          getProblemsByStatusAndType(
                            plannedProblems,
                            'In Progress',
                            'Planned'
                          ).length
                        }{' '}
                        проблемы в процессе
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styleCardProblems.blokc_right}>
                  <button type="submit">
                    <img src='/img/svg/AltArrowDown.svg' alt='arrow' />
                  </button>
                </div>
              </div>
              {showModuleListProblem && StatusProblem === 'Planned' &&
                <>
                  <div className={styleCardFillInfo.blur} onClick={() => ControlModuleListProblem('')}></div>
                  <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                    <header className={styleCardFillInfo.header}>
                      <div className={styleCardFillInfo.header_container}>
                        <div
                          className={styleCardFillInfo.header_exit}
                          onClick={() => ControlModuleListProblem('')}
                        >
                          <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                        </div>
                        <div className={styleCardFillInfo.header__title}>
                          <h2>Планированные в процессе</h2>
                        </div>
                        <div className={styleCardFillInfo.header_search}>
                          <img src='/img/svg/Magnifer.svg' alt='Magnifer' />
                        </div>
                      </div>
                    </header>
                    <main className='content'>
                      <div className={styleCardFillInfo.content_container}>
                        <div className={style.block_list}>
                          {getProblemsByStatusAndType(
                            plannedProblems,
                            'In Progress',
                            'Planned'
                          ).map(problem => (
                            <div className={styleCardProblem.block_problem_small}>
                              <div
                                className={styleCardProblem.problem_img_small}
                                style={{
                                  backgroundImage: `url(${getProblemImageUrl(
                                    problem.initialPhotoID?.imageURL
                                  )})`,
                                }}
                                onClick={() => ControlShowInfoModalSmall(problem)}
                              >
                                <div className={styleCardProblem.problem_status_small}>
                                  <span>{problem.urgencyLevel}</span>
                                  <span>{problem.status}</span>
                                </div>
                                <div className={styleCardProblem.problem_date_small}>
                                  <span>{new Date(problem.creationDate).toLocaleDateString()}</span>
                                </div>
                              </div>
                              <div
                                className={styleCardProblem.problem_title_small}
                                onClick={() => ControlShowInfoModalSmall(problem)}
                              >
                                <h2>{problem.description}</h2>
                              </div>
                              <div className={styleCardProblem.problem_type_small}>
                                <span>
                                  Тип проблемы:
                                  <strong>{getSkillNameById(problem.problemType)}</strong>
                                </span>
                              </div>
                              <nav className={styleCardProblem.problem_nav_small}>
                                <button type="submit"
                                  className={styleCardProblem.edit_small}
                                  onClick={() => startEditingProblem(problem)}
                                >
                                  <span>Редактировать</span>
                                </button>
                                <button type="submit"
                                  className={styleCardProblem.delete_small}
                                  onClick={() => ControlModuleDelete(problem)}
                                >
                                  <img
                                    src='/img/svg/TrashBinMinimalistic.svg'
                                    alt='TrashBinMinimalistic'
                                  />
                                </button>
                              </nav>
                              {showDelete && showDelete._id === problem._id && <>
                                <div className={styleDelete.blur} onClick={() => ControlModuleDelete(null)}></div>
                                <div className={styleDelete.delete_block} onClick={e => e.stopPropagation()}>
                                  <div className={styleDelete.block_title}>
                                    <h2>Удаление проблемы</h2>
                                  </div>
                                  <div className={styleDelete.block_text}>
                                    <span>Вы действительно хотите удалить проблему?</span>
                                  </div>
                                  <div className={styleDelete.block_buttons}>
                                    <button type="submit"
                                      className={styleDelete.cancel}
                                      onClick={() => ControlModuleDelete(null)}
                                    >
                                      Отмена
                                    </button>
                                    <button type="submit"
                                      className={styleDelete.delete}
                                      onClick={() => deleteProblem(showDelete._id)}
                                    >
                                      Удалить
                                    </button>
                                  </div>
                                </div>
                              </>}
                              {showFullInfoSmall && showFullInfoSmall._id === problem._id &&
                                <>
                                  <div className={styleCardFillInfo.blur_none} onClick={() => ControlShowInfoModalSmall(null)}></div>
                                  <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                                    <header className={styleCardFillInfo.header}>
                                      <div className={styleCardFillInfo.header_container}>
                                        <div
                                          className={styleCardFillInfo.header_exit}
                                          onClick={() => ControlShowInfoModalSmall(null)}
                                        >
                                          <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                                        </div>
                                        <div className={styleCardFillInfo.header__title}>
                                          <h2>Планированные проблемы</h2>
                                        </div>
                                        <div className={styleCardFillInfo.header_search}>
                                          <img src='/img/svg/Magnifer.svg' alt='Magnifer' />
                                        </div>
                                      </div>
                                    </header>
                                    <main className='content'>
                                      <div className='content_container'>
                                        <div
                                          className={styleCardFillInfo.block_image}
                                          style={{
                                            backgroundImage: `url(${getProblemImageUrl(
                                              showFullInfoSmall.initialPhotoID?.imageURL
                                            )})`,
                                          }}
                                        >
                                          <div className={styleCardFillInfo.block_type}>
                                            <span>{showFullInfoSmall.urgencyLevel}</span>
                                          </div>
                                        </div>
                                        <div className={styleCardFillInfo.block_title_date}>
                                          <h2>{showFullInfoSmall.description}</h2>
                                          <span>{new Date(showFullInfoSmall.creationDate).toLocaleDateString()}</span>
                                        </div>
                                        <div className={styleCardFillInfo.block_status}>
                                          <span>Статус:</span>
                                          <span>{showFullInfoSmall.status}</span>
                                        </div>
                                        <div className={styleCardFillInfo.block_type_problem}>
                                          <span>Тип проблемы:</span>
                                          <span>{getSkillNameById(showFullInfoSmall.problemType)}</span>
                                        </div>
                                        <div className={styleCardFillInfo.block_time}>
                                          <span>Time</span>
                                          <span>{showFullInfoSmall.preferredTime || 'Не указано'}</span>
                                        </div>
                                        <div className={styleCardFillInfo.block_buttons}>
                                          <button type="submit"
                                            className={styleCardFillInfo.edit}
                                            onClick={() => startEditingProblem(problem)}
                                          >
                                            <span>Редактировать</span>
                                          </button>
                                          <button type="submit"
                                            className={styleCardFillInfo.delete}
                                            onClick={() => ControlModuleDelete(problem)}
                                          >
                                            <img
                                              src='/img/svg/TrashBinMinimalistic.svg'
                                              alt='TrashBinMinimalistic'
                                            />
                                          </button>
                                        </div>
                                      </div>
                                    </main>
                                  </div>
                                </>
                              }
                              {editingProblem && (
                                <>
                                  <div className={styleCardFillInfo.blur_none} onClick={() => setEditingProblem(null)}></div>
                                  <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                                    <header className={styleCardFillInfo.header}>
                                      <div className={styleCardFillInfo.header_container}>
                                        <div
                                          className={styleCardFillInfo.header_exit}
                                          onClick={() => setEditingProblem(null)}
                                        >
                                          <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                                        </div>
                                        <div
                                          className={styleEditHouse.header_save}
                                          onClick={() => saveProblem(editingProblem)}
                                        >
                                          <span>Сохранить</span>
                                        </div>
                                      </div>
                                    </header>
                                    <main className='content'>
                                      <div className='content_container'>
                                        <div className={styleEditHouse.header__title}>
                                          <h2>
                                            Редактирование <strong>Планированные в процессе</strong>
                                          </h2>
                                        </div>
                                        <label
                                          className={styleEditHouse.block_image}
                                          style={{
                                            backgroundImage: `url(${getProblemImageUrl(
                                              problem.initialPhotoID?.imageURL
                                            )})`,
                                          }}
                                        >
                                          <input
                                            type='file'
                                            onChange={handleProblemPhotoUpload}
                                            accept='image/*'
                                            style={{ display: 'none' }}
                                          />
                                          <div className={styleEditHouse.block_text}>
                                            <span>Изменить фото</span>
                                          </div>
                                        </label>
                                        <div className={styleEditProblem.block_input}>
                                          <label htmlFor=''>Название проблемы:</label>
                                          <div className={styleEditProblem.input}>
                                            <input type='text' value={editingProblem.description}
                                              onChange={e => setEditingProblem({ ...editingProblem, description: e.target.value })} />
                                          </div>
                                        </div>
                                        <div className={styleEditProblem.block_input}>
                                          <label htmlFor=''>Тип проблемы:</label>
                                          <div className={styleEditProblem.select_arrow}>
                                            <select
                                              value={editingProblem.problemType}
                                              onChange={e =>
                                                setEditingProblem({
                                                  ...editingProblem,
                                                  problemType: e.target.value,
                                                })
                                              }
                                            >
                                              {skills.map(skill => (
                                                <option key={skill._id} value={skill._id}>
                                                  {skill.skillName}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        </div>
                                        <div className={styleEditProblem.block_input}>
                                          <label htmlFor=''>Сложность проблемы:</label>
                                          <div className={styleEditProblem.select_arrow}>
                                            <select
                                              value={editingProblem.urgencyLevel}
                                              onChange={e =>
                                                setEditingProblem({
                                                  ...editingProblem,
                                                  urgencyLevel: e.target.value,
                                                })
                                              }
                                            >
                                              <option value='Urgent'>Urgent</option>
                                              <option value='Planned'>Planned</option>
                                            </select>
                                          </div>
                                        </div>
                                        <div className={styleEditProblem.block_input}>
                                          <label htmlFor=''>Время работ</label>
                                          <div className={styleEditProblem.select_arrow}>
                                            <select
                                              value={editingProblem.preferredTime}
                                              onChange={e =>
                                                setEditingProblem({
                                                  ...editingProblem,
                                                  preferredTime: e.target.value,
                                                })
                                              }
                                              className={styleEditHouse.time}
                                            >
                                              <option value='9:00--12:00'>9:00--12:00</option>
                                              <option value='12:00--15:00'>12:00--15:00</option>
                                              <option value='15:00--18:00'>15:00--18:00</option>
                                              <option value='18:00--21:00'>18:00--21:00</option>
                                            </select>
                                          </div>
                                        </div>
                                        <div className={styleEditProblem.block_input}>
                                          <label htmlFor=''>Сделать статус:</label>
                                          <div className={styleEditHouse.row}>
                                            <div
                                              className={`${styleEditHouse.block_status} ${Status === 'Open' ? styleEditHouse.active : ''
                                                }`}
                                              onClick={() => handleStatusClick('Open')}
                                            >
                                              <span>Open</span>
                                            </div>
                                            <div
                                              className={`${styleEditHouse.block_status} ${Status === 'In Progress' ? styleEditHouse.active : ''
                                                }`}
                                              onClick={() => handleStatusClick('In Progress')}
                                            >
                                              <span>In Progress</span>
                                            </div>
                                            <div
                                              className={`${styleEditHouse.block_status} ${Status === 'Finished' ? styleEditHouse.active : ''
                                                }`}
                                              onClick={() => handleStatusClick('Finished')}
                                            >
                                              <span>Finished</span>
                                            </div>
                                          </div>
                                        </div>
                                        <button type="submit"
                                          className={styleEditHouse.block_delete}
                                          onClick={() => ControlModuleDelete(problem)}
                                        >
                                          <span>Удалить профиль</span>
                                          <img src='/img/svg/AltArrowDown.svg' alt='arrow' />
                                        </button>
                                      </div>
                                    </main>
                                  </div>
                                </>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </main>
                  </div>
                </>
              }
            </div>
          </article>
          <article className={style.content_ready_problem}>
            <div className={style.process_title}>
              <h2>Завершенные проблемы</h2>
            </div>
            <div
              className={style.ready_problem_block}
              onClick={() => ControlModuleListProblem('Finished')}
            >
              <span>Посмотреть завершенные проблемы</span>
              <img src='/img/svg/AltArrowDown.svg' alt='arrow' />
            </div>
            {showModuleListProblem && StatusProblem === 'Finished' &&
              <>
                <div className={styleCardFillInfo.blur} onClick={() => ControlModuleListProblem('')}></div>
                <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                  <header className={styleCardFillInfo.header}>
                    <div className={styleCardFillInfo.header_container}>
                      <div
                        className={styleCardFillInfo.header_exit}
                        onClick={() => ControlModuleListProblem('')}
                      >
                        <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                      </div>
                      <div className={styleCardFillInfo.header__title}>
                        <h2>Завершенные проблемы</h2>
                      </div>
                      <div className={styleCardFillInfo.header_search}>
                        <img src='/img/svg/Magnifer.svg' alt='Magnifer' />
                      </div>
                    </div>
                  </header>
                  <main className='content'>
                    <div className={styleCardFillInfo.content_container}>
                      <div className={style.block_list}>
                        {getProblemsByStatus(
                          [...urgentProblems, ...plannedProblems],
                          'Finished'
                        ).map(problem => (
                          <div className={styleCardProblem.block_problem_small}>
                            <div
                              className={styleCardProblem.problem_img_small}
                              style={{
                                backgroundImage: `url(${getProblemImageUrl(
                                  problem.initialPhotoID?.imageURL
                                )})`,
                              }}
                              onClick={() => ControlShowInfoModalSmall(problem)}
                            >
                              <div className={styleCardProblem.problem_status_small}>
                                <span>{problem.urgencyLevel}</span>
                                <span>{problem.status}</span>
                              </div>
                              <div className={styleCardProblem.problem_date_small}>
                                <span>{new Date(problem.creationDate).toLocaleDateString()}</span>
                              </div>
                            </div>
                            <div
                              className={styleCardProblem.problem_title_small}
                              onClick={() => ControlShowInfoModalSmall(problem)}
                            >
                              <h2>{problem.description}</h2>
                            </div>
                            <div className={styleCardProblem.problem_type_small}>
                              <span>
                                Тип проблемы:
                                <strong>{getSkillNameById(problem.problemType)}</strong>
                              </span>
                            </div>
                            <nav className={styleCardProblem.problem_nav_small}>
                              <button type="submit"
                                className={styleCardProblem.edit_small}
                                onClick={() => startEditingProblem(problem)}
                              >
                                <span>Редактировать</span>
                              </button>
                              <button type="submit"
                                className={styleCardProblem.delete_small}
                                onClick={() => ControlModuleDelete(problem)}
                              >
                                <img
                                  src='/img/svg/TrashBinMinimalistic.svg'
                                  alt='TrashBinMinimalistic'
                                />
                              </button>
                            </nav>
                            {showDelete && showDelete._id === problem._id && <>
                              <div className={styleDelete.blur} onClick={() => ControlModuleDelete(null)}></div>
                              <div className={styleDelete.delete_block} onClick={e => e.stopPropagation()}>
                                <div className={styleDelete.block_title}>
                                  <h2>Удаление проблемы</h2>
                                </div>
                                <div className={styleDelete.block_text}>
                                  <span>Вы действительно хотите удалить проблему?</span>
                                </div>
                                <div className={styleDelete.block_buttons}>
                                  <button type="submit"
                                    className={styleDelete.cancel}
                                    onClick={() => ControlModuleDelete(null)}
                                  >
                                    Отмена
                                  </button>
                                  <button type="submit"
                                    className={styleDelete.delete}
                                    onClick={() => deleteProblem(showDelete._id)}
                                  >
                                    Удалить
                                  </button>
                                </div>
                              </div>
                            </>}
                            {showFullInfoSmall && showFullInfoSmall._id === problem._id &&
                              <>
                                <div className={styleCardFillInfo.blur_none} onClick={() => ControlShowInfoModalSmall(null)}></div>
                                <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                                  <header className={styleCardFillInfo.header}>
                                    <div className={styleCardFillInfo.header_container}>
                                      <div
                                        className={styleCardFillInfo.header_exit}
                                        onClick={() => ControlShowInfoModalSmall(null)}
                                      >
                                        <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                                      </div>
                                      <div className={styleCardFillInfo.header__title}>
                                        <h2>{problem.urgencyLevel === 'Urgent' ? 'Срочные проблемы' : 'Планированные проблемы'}</h2>
                                      </div>
                                      <div className={styleCardFillInfo.header_search}>
                                        <img src='/img/svg/Magnifer.svg' alt='Magnifer' />
                                      </div>
                                    </div>
                                  </header>
                                  <main className='content'>
                                    <div className='content_container'>
                                      <div
                                        className={styleCardFillInfo.block_image}
                                        style={{
                                          backgroundImage: `url(${getProblemImageUrl(
                                            showFullInfoSmall.initialPhotoID?.imageURL
                                          )})`,
                                        }}
                                      >
                                        <div className={styleCardFillInfo.block_type}>
                                          <span>{showFullInfoSmall.urgencyLevel}</span>
                                        </div>
                                      </div>
                                      <div className={styleCardFillInfo.block_title_date}>
                                        <h2>{showFullInfoSmall.description}</h2>
                                        <span>{new Date(showFullInfoSmall.creationDate).toLocaleDateString()}</span>
                                      </div>
                                      <div className={styleCardFillInfo.block_status}>
                                        <span>Статус:</span>
                                        <span>{showFullInfoSmall.status}</span>
                                      </div>
                                      <div className={styleCardFillInfo.block_type_problem}>
                                        <span>Тип проблемы:</span>
                                        <span>{getSkillNameById(showFullInfoSmall.problemType)}</span>
                                      </div>
                                      <div className={styleCardFillInfo.block_time}>
                                        <span>Time</span>
                                        <span>{showFullInfoSmall.preferredTime || 'Не указано'}</span>
                                      </div>
                                      <div className={styleCardFillInfo.block_buttons}>
                                        <button type="submit"
                                          className={styleCardFillInfo.edit}
                                          onClick={() => startEditingProblem(problem)}
                                        >
                                          <span>Редактировать</span>
                                        </button>
                                        <button type="submit"
                                          className={styleCardFillInfo.delete}
                                          onClick={() => ControlModuleDelete(problem)}
                                        >
                                          <img
                                            src='/img/svg/TrashBinMinimalistic.svg'
                                            alt='TrashBinMinimalistic'
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </main>
                                </div>
                              </>
                            }



                            {editingProblem && editingProblem._id && (
                              <>
                                <div className={styleCardFillInfo.blur_none} onClick={() => setEditingProblem(null)}></div>
                                <div className={styleCardFillInfo.card_block} onClick={e => e.stopPropagation()}>
                                  <header className={styleCardFillInfo.header}>
                                    <div className={styleCardFillInfo.header_container}>
                                      <div
                                        className={styleCardFillInfo.header_exit}
                                        onClick={() => setEditingProblem(null)}
                                      >
                                        <img src='/img/svg/AltArrowLeft.svg' alt='arrow' />
                                      </div>
                                      <div
                                        className={styleEditHouse.header_save}
                                        onClick={() => saveProblem(editingProblem)}
                                      >
                                        <span>Сохранить</span>
                                      </div>
                                    </div>
                                  </header>
                                  <main className='content'>
                                    <div className='content_container'>
                                      <div className={styleEditHouse.header__title}>
                                        <h2>
                                          Редактирование <strong>Завершенные проблемы</strong>
                                        </h2>
                                      </div>
                                      <label
                                        className={styleEditHouse.block_image}
                                        style={{
                                          backgroundImage: `url(${getProblemImageUrl(
                                            problem.initialPhotoID?.imageURL
                                          )})`,
                                        }}
                                      >
                                        <input
                                          type='file'
                                          onChange={handleProblemPhotoUpload}
                                          accept='image/*'
                                          style={{ display: 'none' }}
                                        />
                                        <div className={styleEditHouse.block_text}>
                                          <span>Изменить фото</span>
                                        </div>
                                      </label>
                                      <div className={styleEditProblem.block_input}>
                                        <label htmlFor=''>Название проблемы:</label>
                                        <div className={styleEditProblem.input}>
                                          <input type='text' value={editingProblem.description}
                                            onChange={e => setEditingProblem({ ...editingProblem, description: e.target.value })} />
                                        </div>
                                      </div>
                                      <div className={styleEditProblem.block_input}>
                                        <label htmlFor=''>Тип проблемы:</label>
                                        <div className={styleEditProblem.select_arrow}>
                                          <select
                                            value={editingProblem.problemType}
                                            onChange={e =>
                                              setEditingProblem({
                                                ...editingProblem,
                                                problemType: e.target.value,
                                              })
                                            }
                                          >
                                            {skills.map(skill => (
                                              <option key={skill._id} value={skill._id}>
                                                {skill.skillName}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                      </div>
                                      <div className={styleEditProblem.block_input}>
                                        <label htmlFor=''>Сложность проблемы:</label>
                                        <div className={styleEditProblem.select_arrow}>
                                          <select
                                            value={editingProblem.urgencyLevel}
                                            onChange={e =>
                                              setEditingProblem({
                                                ...editingProblem,
                                                urgencyLevel: e.target.value,
                                              })
                                            }
                                          >
                                            <option value='Urgent'>Urgent</option>
                                            <option value='Planned'>Planned</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className={styleEditProblem.block_input}>
                                        <label htmlFor=''>Время работ</label>
                                        <div className={styleEditProblem.select_arrow}>
                                          <select
                                            value={editingProblem.preferredTime}
                                            onChange={e =>
                                              setEditingProblem({
                                                ...editingProblem,
                                                preferredTime: e.target.value,
                                              })
                                            }
                                            className={styleEditHouse.time}
                                          >
                                            <option value='9:00--12:00'>9:00--12:00</option>
                                            <option value='12:00--15:00'>12:00--15:00</option>
                                            <option value='15:00--18:00'>15:00--18:00</option>
                                            <option value='18:00--21:00'>18:00--21:00</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className={styleEditProblem.block_input}>
                                        <label htmlFor=''>Сделать статус:</label>
                                        <div className={styleEditHouse.row}>
                                          <div
                                            className={`${styleEditHouse.block_status} ${Status === 'Open' ? styleEditHouse.active : ''
                                              }`}
                                            onClick={() => handleStatusClick('Open')}
                                          >
                                            <span>Open</span>
                                          </div>
                                          <div
                                            className={`${styleEditHouse.block_status} ${Status === 'In Progress' ? styleEditHouse.active : ''
                                              }`}
                                            onClick={() => handleStatusClick('In Progress')}
                                          >
                                            <span>In Progress</span>
                                          </div>
                                          <div
                                            className={`${styleEditHouse.block_status} ${Status === 'Finished' ? styleEditHouse.active : ''
                                              }`}
                                            onClick={() => handleStatusClick('Finished')}
                                          >
                                            <span>Finished</span>
                                          </div>
                                        </div>
                                      </div>
                                      <button type="submit"
                                        className={styleEditHouse.block_delete}
                                        onClick={() => ControlModuleDelete(problem)}
                                      >
                                        <span>Удалить профиль</span>
                                        <img src='/img/svg/AltArrowDown.svg' alt='arrow' />
                                      </button>
                                    </div>



                                  </main>
                                </div>
                              </>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </main>
                </div>
              </>
            }
          </article>
        </div>
      </main>
      </> */}
    </>
  );
}

export default HousePage;
