import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import AdminPanel from './AdminPanel';
import styles from './WorkersList.module.css';
import { tokenService } from '../../services/tokenService';
import { FooterBlock } from '../../features/FooterBlock/FooterBlock';
import { Flex } from '../../ui';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../LanguageSwitcher';
import AdminMobileLinks from '../../features_admin/AdminMobileLinks/AdminMobileLinks';
import { useMediaQuery } from 'react-responsive';
import { desktopQuery } from '../../consts';

const WorkersList = () => {
  const [workers, setWorkers] = useState([]);
  const [problemsByWorker, setProblemsByWorker] = useState({});
  const [workerHistories, setWorkerHistories] = useState({});
  const [minCurrentProblems, setMinCurrentProblems] = useState(0);
  const [minFinishedProblems, setMinFinishedProblems] = useState(0);
  const [minRating, setMinRating] = useState(0);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(desktopQuery);

  const token = tokenService.getToken();

  const fetchWorkers = async () => {
    try {
      const response = await axios.get('https://fix.house/api/workers', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setWorkers(response.data);
    } catch (error) {
      console.error('Ошибка при получении данных о работниках:', error);
    }
  };

  const fetchProblems = async () => {
    try {
      const response = await axios.get(
        'https://fix.house/api/problems/problemsAll',
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const problems = response.data;
      const groupedProblems = problems.reduce((acc, problem) => {
        const workerId = problem.workerID ? problem.workerID._id : 'unassigned';
        if (!acc[workerId]) {
          acc[workerId] = { assigned: [], finished: [] };
        }
        acc[workerId].assigned.push(problem);
        return acc;
      }, {});
      setProblemsByWorker(groupedProblems);
    } catch (error) {
      console.error('Ошибка при получении данных о проблемах:', error);
    }
  };

  const fetchWorkerHistory = async (workerId) => {
    try {
      const response = await axios.get(
        `https://fix.house/api/workers/${workerId}/history`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setWorkerHistories((prevHistories) => ({
        ...prevHistories,
        [workerId]: response.data,
      }));
    } catch (error) {
      console.error('Ошибка при получении истории работника:', error);
    }
  };

  useEffect(() => {
    fetchWorkers();
    fetchProblems();
  }, []);

  useEffect(() => {
    if (workers.length > 0) {
      workers.forEach((worker) => {
        fetchWorkerHistory(worker._id);
      });
    }
  }, [workers]);

  const filteredWorkers = workers.filter((worker) => {
    const currentProblemsCount = problemsByWorker[worker._id]
      ? problemsByWorker[worker._id].assigned.length
      : 0;
    const finishedProblemsCount = problemsByWorker[worker._id]
      ? problemsByWorker[worker._id].finished.length
      : 0;

    // Округление рейтинга работника до двух знаков после запятой
    const workerRating = worker.rating
      ? parseFloat(worker.rating.average.toFixed(2))
      : 0;

    // Определяем нижнюю и верхнюю границы диапазона
    const lowerBound = minRating;
    let upperBound;

    if (Number.isInteger(minRating)) {
      upperBound = minRating + 0.99; // Для целых чисел, включая все значения до следующего целого числа минус одна сотая.
    } else {
      upperBound =
        Math.floor(minRating) + Math.ceil((minRating % 1) * 10) / 10 + 0.09; // Для десятичных значений, включая все значения до следующей десятой минус одна сотая.
    }

    // Проверяем, что фильтр по рейтингу активен
    const isRatingFiltered =
      minRating > 0
        ? workerRating >= lowerBound && workerRating <= upperBound
        : true;

    return (
      currentProblemsCount >= minCurrentProblems &&
      finishedProblemsCount >= minFinishedProblems &&
      isRatingFiltered
    );
  });

  const handleLogout = () => {
    tokenService.removeToken();
    navigate('/client-register');
  };

  return (
    <div>
      <AdminPanel handleLogout={handleLogout} />
      <LanguageSwitcher />
      <div className={styles.container}>
        <div className={styles.blockContent}>
          {!isDesktop && <AdminMobileLinks />}
          <h1>{t('workers_list')}</h1>
          <div className={styles.filters}>
            <label>
              {t('current_problems_count')}:{' '}
              <input
                type='number'
                value={minCurrentProblems}
                onChange={(e) => setMinCurrentProblems(Number(e.target.value))}
                min='0'
              />
            </label>
            <label>
              {t('finished_problems_count')}:{' '}
              <input
                type='number'
                value={minFinishedProblems}
                onChange={(e) => setMinFinishedProblems(Number(e.target.value))}
                min='0'
              />
            </label>
            <label>
              {t('worker_rating')}:{' '}
              <input
                type='number'
                value={minRating}
                onChange={(e) =>
                  setMinRating(e.target.value ? parseFloat(e.target.value) : 0)
                }
                min='0'
                step='0.01'
              />
            </label>
          </div>

          <div className={styles.workersListContainer}>
            {filteredWorkers.map((worker) => (
              <li key={worker._id} className={styles.workerItem}>
                <Link
                  to={`/admin-dashboard/worker-data/${worker._id}`}
                  style={{ textDecoration: 'none' }}
                >
                  <div className={styles.workerDetails}>
                    <Flex gap={10} className={styles.workerTitle}>
                      <img
                        src={
                          worker.photo
                            ? `https://fix.house/${worker.photo.replace(
                                /\\/g,
                                '/'
                              )}`
                            : '/path_to_default_placeholder.jpg'
                        }
                        alt='Worker'
                        className={styles.workerPhoto}
                      />
                      <div>
                        <div className={styles.workerName}>
                          <span>{t('name')}:</span> {worker.name}
                        </div>
                      </div>
                    </Flex>
                    <div className={styles.workerAttribute}>
                      <span className={styles.label}>{t('phone')}:</span>
                      {worker.phone}
                    </div>
                    <div className={styles.workerAttribute}>
                      <span className={styles.label}>{t('email')}:</span>
                      {worker.email}
                    </div>
                    <div className={styles.workerAttribute}>
                      <span className={styles.label}>{t('role')}:</span>
                      {worker.role}
                    </div>
                    <div className={styles.workerAttribute}>
                      <span className={styles.label}>
                        {t('preferred_language')}:
                      </span>
                      {worker.preferredLanguage}
                    </div>
                    <div className={styles.hr}></div>
                    <div className={styles.workerAttribute}>
                      <span className={styles.label}>{t('rating')}:</span>
                      {worker.rating.average.toFixed(2)} ({t('out_of')}{' '}
                      {worker.rating.count} {t('out_reviews')})
                    </div>
                  </div>
                </Link>
                <div className={styles.workerAttribute}>
                  <strong className={styles.label}>
                    {t('current_problems')}:
                  </strong>{' '}
                  {problemsByWorker[worker._id]
                    ? problemsByWorker[worker._id].assigned.length
                    : 0}{' '}
                  {t('counts_of')}
                </div>
                <div className={styles.workerAttribute}>
                  <strong className={styles.label}>
                    {t('finished_problems')}:{' '}
                  </strong>{' '}
                  {problemsByWorker[worker._id]
                    ? problemsByWorker[worker._id].finished.length
                    : 0}{' '}
                  {t('counts_of')}
                </div>
                <div className={styles.workerAttribute}>
                  <strong className={styles.label}>{t('history')}:</strong>{' '}
                  {workerHistories[worker._id]
                    ? workerHistories[worker._id].length
                    : 0}{' '}
                  {t('counts_of')}
                </div>
              </li>
            ))}
          </div>
        </div>
      </div>
      <FooterBlock />
    </div>
  );
};

export default WorkersList;
