import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';

import { desktopQuery } from '../../consts';
import { Flex, Text, Container, Divider } from '../../ui';
import { ArrowIcon, CheckCircleIcon } from '../../static';
import { ProblemList } from './components';
import styles from './ProblemsInProgressBlock.module.scss';
import { useTranslation } from 'react-i18next';

export const ProblemsInProgressBlock = (props) => {
  const {
    className,
    urgentProblemsInProcess,
    plannedProblemsInProcess,
    handleOpenUrgent,
    handleOpenPlanned,
    handleOpenCompleted,
    completedProblems,
    ...restProps
  } = props;

  const isDesktop = useMediaQuery(desktopQuery);
  const { t } = useTranslation();

  return (
    <section
      className={cn(styles.problemsInProgressBlock, className)}
      {...restProps}
    >
      <Container>
        {isDesktop ? (
          <Flex direction='column' gap={20}>
            <Text color='gray' size={36} lineHeight='36px'>
              {t('problems_in_progress')}
            </Text>

            <div className={styles.problemsInProgressGrid}>
              <ProblemList
                listTitle={t('urgent_in_process')}
                problems={urgentProblemsInProcess}
                onClick={handleOpenUrgent}
                listDescription={`${urgentProblemsInProcess.length} ${t(
                  'problems_in_progress'
                ).toLowerCase()}`}
              />

              <ProblemList
                listTitle={t('planned_in_process')}
                listDescription={`${plannedProblemsInProcess.length} ${t(
                  'problems_in_progress'
                ).toLowerCase()}`}
                problems={plannedProblemsInProcess}
                onClick={handleOpenPlanned}
              />

              <ProblemList
                className={styles.doubleSpan}
                listTitle={t('completed_problems')}
                listDescription={`${t('total_completed')}: ${
                  completedProblems.length
                }`}
                problems={completedProblems}
                onClick={handleOpenCompleted}
              />
            </div>
          </Flex>
        ) : (
          <Flex direction='column' gap={50}>
            <Flex direction='column' gap={20}>
              <Text size={15} color='gray'>
                {t('problems_in_progress')}
              </Text>

              <Flex direction='column' gap={10}>
                <ProblemList
                  listTitle={t('urgent_in_process')}
                  listDescription={`${urgentProblemsInProcess.length} ${t(
                    'problems_in_progress'
                  ).toLowerCase()}`}
                  problems={urgentProblemsInProcess}
                  onClick={handleOpenUrgent}
                />

                <ProblemList
                  listTitle={t('planned_in_process')}
                  listDescription={`${plannedProblemsInProcess.length} ${t(
                    'problems_in_progress'
                  ).toLowerCase()}`}
                  problems={plannedProblemsInProcess}
                  onClick={handleOpenPlanned}
                />
              </Flex>
            </Flex>

            <Flex direction='column' gap={20}>
              <Text size={15} color='gray'>
                {t('completed_problems')}
              </Text>

              <Divider className={styles.divider} />

              <Flex
                gap={5}
                align='center'
                className={styles.completedProblemsBar}
                onClick={handleOpenCompleted}
              >
                <CheckCircleIcon width={18} height={18} />

                <Text className={styles.growable} size={15} weight='semiBold'>
                  {t('see_all')}
                </Text>

                <ArrowIcon />
              </Flex>

              <Divider className={styles.divider} />
            </Flex>
          </Flex>
        )}
      </Container>
    </section>
  );
};
