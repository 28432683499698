import React from 'react';
import AdminSubscriptionTypes from './AdminSubscriptionTypes';
import AdminPanel from './AdminPanel';
import { FooterBlock } from '../../features/FooterBlock/FooterBlock';
import LanguageSwitcher from '../LanguageSwitcher';
import { useNavigate } from 'react-router-dom';
import { tokenService } from '../../services/tokenService';

const SubscriptionsManage = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    tokenService.removeToken();
    navigate('/client-register');
  };

  return (
    <div>
      <AdminPanel handleLogout={handleLogout} />
      <LanguageSwitcher />
      <AdminSubscriptionTypes />
      <FooterBlock />
    </div>
  );
};

export default SubscriptionsManage;
