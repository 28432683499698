import React, { useEffect, useState } from 'react';
import axios from '../axios';
import { useNavigate } from 'react-router-dom';
import styles from './ClientRegister.module.scss';
import LanguageSwitcher from '../components/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import GoogleLoginButton from '../components/GoogleLoginButton';
import { tokenService } from '../services/tokenService';
import { Flex, Text } from '../ui';
import { getHouseValidationSchema } from './validationSchema';

function ClientRegister() {
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    role: 'client',
    termsAgreed: true,
    preferredLanguage: localStorage.getItem('preferredLanguage') || 'ru',
    country: '',
    city: '',
    password: '',
  });

  const [showCountrySelector, setShowCountrySelector] = useState(false);
  const allowedCountries = ['th', 'my', 'id'];
  const cities = {
    th: ['Phuket', 'Bangkok', 'Samui'],
    id: ['Bali', 'Jakarta'],
    my: ['Kuala Lumpur', 'Penang'],
  };

  const { t, i18n } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  const validateField = async (name, value) => {
    try {
      await getHouseValidationSchema(t).validateAt(name, {
        ...{
          password: userData.password,
          email: userData.email,
          name: userData.name,
          country: userData.country,
          city: userData.city,
        },
        [name]: value,
      });
      setErrors((prev) => ({ ...prev, [name]: '' }));
    } catch (error) {
      setErrors((prev) => ({ ...prev, [name]: error.message }));
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  const handleFormErrors = () => {
    let hasError = false;
    Object.keys(errors).forEach((key) => {
      if (errors[key] !== '' && Object.keys(userData).includes(key)) {
        hasError = true;
      }
    });
    return hasError;
  };

  useEffect(() => {
    const currentLanguage = localStorage.getItem('preferredLanguage') || 'en';
    i18n.changeLanguage(currentLanguage);
    setUserData((prevData) => ({
      ...prevData,
      preferredLanguage: currentLanguage,
    }));

    const fetchLocation = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        const countryCode = response.data.country_code.toLowerCase();

        if (allowedCountries.includes(countryCode)) {
          setUserData((prevData) => ({
            ...prevData,
            country: countryCode,
            city: response.data.city,
          }));
        } else {
          setShowCountrySelector(true);
        }
      } catch (error) {
        console.error('Error fetching location data:', error);
        setShowCountrySelector(true);
      }
    };

    fetchLocation();
  }, [i18n]);

  const handleSuccess = async (token) => {
    console.log('User logged in successfully:', token);
    try {
      const response = await axios.post('https://fix.house/api/auth/google', {
        token,
        country: userData.country,
        city: userData.city,
      });
      if (response.data.user) {
        tokenService.saveToken(response.data.token);
        tokenService.saveUserId(response.data.user._id);
        tokenService.saveRole(response.data.user.role);
        navigate('/client-page');
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data.error === 'Country not allowed'
      ) {
        setShowCountrySelector(true);
      } else {
        console.error('Google login error:', error);
        setErrorMessage('Ошибка при входе через Google');
      }
    }
  };

  const handleFailure = (error) => {
    console.error('Login failed:', error);
    setErrorMessage('Ошибка при входе через Google');
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUserData({ ...userData, [name]: type === 'checkbox' ? checked : value });
  };

  const handleCountryChange = (e) => {
    const country = e.target.value;
    setUserData({ ...userData, country, city: '' });
  };

  const handleCityChange = (e) => {
    setUserData({ ...userData, city: e.target.value });
  };

  const submitForm = async (e) => {
    e.preventDefault();

    if (!userData.termsAgreed) {
      setErrorMessage('Необходимо согласиться с условиями использования.');
      return;
    }

    try {
      await getHouseValidationSchema(t).validate(
        {
          password: userData.password,
          email: userData.email,
          name: userData.name,
          country: userData.country,
          city: userData.city,
        },
        {
          abortEarly: false,
        }
      );
    } catch (error) {
      if (error.name === 'ValidationError') {
        const newErrors = {};
        error.inner.forEach((err) => {
          newErrors[err.path] = err.message;
        });
        setErrors(newErrors);
      }
    }

    if (handleFormErrors()) {
      return;
    }

    const currentLanguage = localStorage.getItem('preferredLanguage') || 'en';
    const dataToSend = {
      ...userData,
      preferredLanguage: currentLanguage,
      country: userData.country.toLowerCase(),
    };

    delete dataToSend.password;

    setErrorMessage('');
    setSuccessMessage('');

    try {
      const response = await axios.post(
        'https://fix.house/api/users/register',
        dataToSend
      );

      if (response.status === 200 || response.status === 201) {
        tokenService.saveToken(response.data.token);
        tokenService.saveUserId(response.data.user._id);
        tokenService.saveRole('client');

        setSuccessMessage('Пользователь успешно создан!');
        setTimeout(() => {
          navigate('/client-page');
        }, 500);
      } else {
        setErrorMessage('Ошибка создания пользователя');
      }
    } catch (error) {
      console.error('Ошибка создания пользователя:', error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Ошибка создания пользователя');
      }
    }
  };

  const submitLoginForm = async (e) => {
    e.preventDefault();
    setErrorMessage('');

    try {
      const response = await axios.post('https://fix.house/api/users/login', {
        email: userData.email,
        password: userData.password,
      });

      if (response.status === 200) {
        tokenService.saveToken(response.data.token);
        localStorage.setItem('userId', response.data.user._id);
        navigate('/client-page');
      } else {
        setErrorMessage('Ошибка при входе в систему');
      }
    } catch (error) {
      console.error('Ошибка при входе в систему:', error);
      setErrorMessage('Ошибка при входе в систему');
    }
  };

  return (
    <div className={styles.container}>
      <LanguageSwitcher />
      <br />
      <h1>{t('client_registration')}</h1>
      <br />
      <br />

      <GoogleLoginButton
        clientId='YOUR_CLIENT_ID.apps.googleusercontent.com'
        onSuccess={handleSuccess}
        onFailure={handleFailure}
        country={userData.country}
        city={userData.city}
      />

      <form onSubmit={() => {}} className={styles.form}>
        <input
          type='text'
          name='name'
          value={userData.name}
          onChange={handleInputChange}
          placeholder={t('name')}
          className={styles.input}
          onBlur={handleBlur}
        />
        {errors.name && (
          <div className={styles.errorMessage}>{errors.name}</div>
        )}
        <input
          type='text'
          name='email'
          value={userData.email}
          onChange={handleInputChange}
          placeholder={t('email')}
          className={styles.input}
          onBlur={handleBlur}
        />
        {errors.email && (
          <div className={styles.errorMessage}>{errors.email}</div>
        )}
        <input
          type='password'
          name='password'
          value={userData.password}
          onChange={handleInputChange}
          placeholder={t('password')}
          className={styles.input}
          onBlur={handleBlur}
        />
        {errors.password && (
          <div className={styles.errorMessage}>{errors.password}</div>
        )}
        {showCountrySelector && (
          <>
            <select
              name='country'
              value={userData.country}
              onChange={handleCountryChange}
              className={styles.input}
              onBlur={handleBlur}
            >
              <option value=''>{t('select_country')}</option>
              {allowedCountries.map((country) => (
                <option key={country} value={country}>
                  {country.toUpperCase()}
                </option>
              ))}
            </select>
            {errors.country && (
              <div className={styles.errorMessage}>{errors.country}</div>
            )}
            {userData.country && (
              <select
                name='city'
                value={userData.city}
                onChange={handleCityChange}
                className={styles.input}
              >
                <option value=''>Выберите город</option>
                {cities[userData.country].map((city) => (
                  <option key={city} value={city}>
                    {city}
                  </option>
                ))}
              </select>
            )}
            {errors.city && (
              <div className={styles.errorMessage}>{errors.city}</div>
            )}
          </>
        )}
        <div className={styles.checkbox}>
          <input
            type='checkbox'
            name='termsAgreed'
            checked={userData.termsAgreed}
            onChange={handleInputChange}
          />
          <label htmlFor='termsAgreed'>{t('agree_to_terms')}</label>
        </div>
        <button
          type='button'
          className={styles.loginButton}
          onClick={submitLoginForm}
        >
          {t('login')}
        </button>
        <button
          type='button'
          className={styles.registerButton}
          onClick={submitForm}
        >
          {t('create')}
        </button>
        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
        {successMessage && (
          <p className={styles.successMessage}>{successMessage}</p>
        )}
      </form>

      <div className={styles.socialSection}>
        <p className={styles.or}>{t('or_sign_in_with')}</p>
        <Flex gap={5}>
          <button className={styles.registerButton}>Facebook</button>
          <button className={styles.registerButton}>Google</button>
          <button className={styles.registerButton}>Twitter</button>
        </Flex>
      </div>
    </div>
  );
}

export default ClientRegister;
