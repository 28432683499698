import cn from 'classnames';
import {useMediaQuery} from 'react-responsive';

import {desktopQuery} from '../../../../consts';
import {Flex, Text, Divider} from '../../../../ui';
import {getProblemImageUrl} from '../../../../utilities';
import styles from './ProblemList.module.scss';
import {ArrowIcon} from '../../../../static';

export const ProblemList = (props) => {
  const {
    listTitle,
    listDescription,
    problems,
    className,
    children,
    ...restProps
  } = props;

  const isDesktop = useMediaQuery(desktopQuery);

  return isDesktop ? (
    <Flex className={cn(styles.problemList, className)} {...restProps} direction="column" gap={30}>
      <Flex className={styles.problemListHeader} gap={30} justify="between" align="end">
        <Text size={24} lineHeight="34px" weight="medium">{listTitle}</Text>

        <Text size={16} lineHeight="24px" weight="medium" color="gray">{listDescription}</Text>
      </Flex>

      <Flex gap={10} wrap className={styles.problemListImages}>
          {problems.map((problem) => {
            return (
              <img className={styles.smallProblemImage} key={problem._id} src={getProblemImageUrl(problem.initialPhotoID?.imageURL)} alt="problem" />
            );
          })}
        </Flex>
    </Flex>
  ) : (
    <Flex gap={48} className={cn(styles.problemList, className)} {...restProps}>
      <Flex gap={10} direction="column" className={styles.growable}>
        <Text size={15} weight="bold">
          {listTitle}
        </Text>

        <Divider className={styles.problemListDivider} />

        <Flex align="center" gap={25}>
          <Flex gap={10} wrap>
            {problems.map((problem, index) => {
              return index < 3 ? (
                <img className={styles.smallProblemImage} key={problem._id} src={getProblemImageUrl(problem.initialPhotoID?.imageURL)} alt="problem" />
              ) : null;
            })}
          </Flex>

          <Text size={13} weight="medium" color="gray">{listDescription}</Text>
        </Flex>
      </Flex>

      <ArrowIcon className={styles.expandArrow}/>
    </Flex>
  );
}