// HomePagePricingBlock.jsx
import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { redirect, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { tokenService } from '../../services/tokenService';
import { desktopQuery } from '../../consts';
import { Flex, Text, Container } from '../../ui';
import { SubscriptionCard } from './components';
import styles from './HomePagePricingBlock.module.scss';
import { BuyModal } from '../BuyModal/BuyModal';

export const HomePagePricingBlock = ({
  className,
  onSubscriptionPurchase,
  userId,
  sectionId,
  ...restProps
}) => {
  const isDesktop = useMediaQuery(desktopQuery);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  const [subscriptions, setSubscriptions] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [subscription, setSubscription] = useState(null);

  const handleOpenConfirmModal = (sub) => {
    setSubscription(sub);
    setConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setConfirmModal(false);
  };

  useEffect(() => {
    loadSubscriptionTypes();
  }, [currentLanguage]);

  const loadSubscriptionTypes = async () => {
    try {
      console.log(`Загрузка типов подписок для языка: ${currentLanguage}`);
      const response = await axios.get(
        `https://fix.house/api/subscription-types?language=${currentLanguage}`
      );

      console.log('Полученные данные о типах подписок:', response.data);
      setSubscriptions(response.data);
    } catch (error) {
      console.error('Ошибка при загрузке типов подписок:', error);
    }
  };

  const handleSubscriptionPurchase = (subscriptionType) => {
    console.log('handleSubscriptionPurchase called with:', subscriptionType);
    if (onSubscriptionPurchase) {
      onSubscriptionPurchase(subscriptionType);
    } else {
      if (!userId) {
        console.error('userId не определен');
        return;
      }
      navigate('/payment', {
        state: {
          userId,
          subscriptionType: subscriptionType,
        },
      });
    }
  };

  const handleSubscriptionPurchaseCash = async (subscriptionType) => {
    try {
      const token = tokenService.getToken();
      const response = await axios.post(
        'https://fix.house/api/subscriptions/create-subscription',
        {
          subscriptionTypeID: subscriptionType._id,
          userID: userId,
          amount: subscriptionType.price,
          paymentMethod: 'cash',
          status: 'pending',
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 201) {
        console.log('Подписка успешно создана');
        // Можно добавить уведомление пользователю
        // setNotification('Подписка успешно активирована');
      } else {
        console.error('Подписка не была создана');
      }
    } catch (error) {
      console.error('Ошибка при создании подписки:', error);
    }
  };

  return (
    <section
      id={sectionId}
      className={cn(styles.pricing, className)}
      {...restProps}
    >
      <Container className={styles.fullHeight}>
        <Flex
          direction='column'
          gap={isDesktop ? 60 : 80}
          className={styles.fullHeight}
          justify='between'
        >
          <Text
            tag='h2'
            weight='medium'
            size={60}
            className={styles.pricingHeader}
            align={isDesktop ? 'start' : 'center'}
          >
            {t('our_rates')}
          </Text>
          <div className={styles.pricingGrid}>
            {subscriptions.map((subscription) => (
              <SubscriptionCard
                key={subscription._id.$oid}
                title={subscription.typeName}
                price={`${subscription.price} THB`}
                description={subscription.description}
                buttonText={t('pay_with_bank_card')}
                buttonText2={t('pay_with_cash')}
                buttonText3={t('pay_with_crypto')}
                handleClick={() => handleSubscriptionPurchase(subscription)}
                handleClick2={() => handleOpenConfirmModal(subscription)}
                note={
                  subscription._id.$oid === 'home_care'
                    ? t('best_value_offer')
                    : null
                }
                subscription={subscription} // Передаем полный объект подписки
              />
            ))}
          </div>
          <div className={styles.pricingFooter}></div>
          {confirmModal && (
            <BuyModal
              closeModal={handleCloseConfirmModal}
              handleConfirm={handleSubscriptionPurchaseCash}
              subscription={subscription}
            />
          )}
        </Flex>
      </Container>
    </section>
  );
};
