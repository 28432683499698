// import {useMediaQuery} from 'react-responsive';
import cn from 'classnames';

// import {desktopQuery} from '../../consts';
import { getProblemImageUrl } from '../../utilities';
import { Modal, Flex, Button, IconButton, Text, Input, Select } from '../../ui';
import { CloseIcon } from '../../static/icons';
import styles from './EditProblemModal.module.scss';
import { useTranslation } from 'react-i18next';
import { getHouseValidationSchema } from '../../components/validationSchema';
import { useState } from 'react';

export const EditProblemModal = (props) => {
  const {
    closeModal,
    problem,
    selectedPhoto,
    handleProblemPhotoUpload,
    editingProblem,
    setEditingProblem,
    saveProblem,
    deleteProblem,
    skills,
    // setStatus,
    // status
  } = props;
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});

  const validateField = async (name, value) => {
    try {
      await getHouseValidationSchema(t).validateAt(name, {
        ...{
          problemDescription: editingProblem.description,
        },
        [name]: value,
      });
      setErrors((prev) => ({ ...prev, [name]: '' }));
    } catch (error) {
      setErrors((prev) => ({ ...prev, [name]: error.message }));
    }
  };

  const handleBlur = (e) => {
    let { name, value } = e.target;
    validateField(name, value);
  };

  const handleFormErrors = () => {
    let hasError = false;
    Object.keys(errors).forEach((key) => {
      if (errors[key] !== '' && key == 'problemDescription') {
        hasError = true;
      }
    });
    return hasError;
  };

  // const isDesktop = useMediaQuery(desktopQuery);

  return (
    <Modal closeModal={closeModal} className={styles.Modal}>
      {/* {isDesktop ? (
        null
      ) : ( */}
      <Flex direction='column' gap={30}>
        <Flex justify='between' align='baseline'>
          <Text size={20} lineHeight='25px' weight='bold'>
            {t('edit_urgent_problems')}
          </Text>
          <IconButton type='transparent' size='m' onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </Flex>

        <label className={styles.labelImageInput}>
          <img
            className={styles.problemImage}
            src={
              selectedPhoto
                ? selectedPhoto
                : getProblemImageUrl(problem.initialPhotoID?.imageURL)
            }
            alt='Изображение проблемы'
          />
          <input
            type='file'
            onChange={handleProblemPhotoUpload}
            accept='image/*'
            className={styles.displayNone}
          />
          <Flex
            className={styles.problemImageCoverText}
            justify='center'
            align='center'
          >
            <Text size={14} lineHeight='14px' color='white' weight='medium'>
              {t('edit_problem_photo')}
            </Text>
          </Flex>
        </label>

        <Flex direction='column' gap={16}>
          <Input
            label={t('edit_problem_name') + ':'}
            name='problemDescription'
            value={editingProblem.description}
            onChange={(value) =>
              setEditingProblem({
                ...editingProblem,
                description: value,
              })
            }
            onBlur={handleBlur}
          />
          {errors.problemDescription && (
            <div className={styles.errorMessage}>
              {errors.problemDescription}
            </div>
          )}
          <Select
            label={t('edit_problem_type') + ':'}
            onChange={(value) =>
              setEditingProblem({
                ...editingProblem,
                problemType: value,
              })
            }
            options={skills?.map((skill) => ({
              value: skill._id,
              label: skill.skillName,
            }))}
          />

          <Select
            label={t('edit_urgency_level') + ':'}
            onChange={(value) =>
              setEditingProblem((editingProblem) => ({
                ...editingProblem,
                urgencyLevel: value,
              }))
            }
            value={editingProblem.urgencyLevel}
            options={[
              {
                value: 'Urgent',
                label: 'Urgent',
              },
              {
                value: 'Planned',
                label: 'Planned',
              },
            ]}
          />

          <Select
            label={t('time_range') + ':'}
            onChange={(value) =>
              setEditingProblem((editingProblem) => ({
                ...editingProblem,
                preferredTime: value,
              }))
            }
            value={editingProblem.preferredTime}
            options={[
              {
                value: '9:00--12:00',
              },
              {
                value: '12:00--15:00',
              },
              {
                value: '15:00--18:00',
              },
              {
                value: '18:00--21:00',
              },
            ]}
          />

          {editingProblem.urgencyLevel === 'Planned' && (
            <Input
              label={t('scheduled_date') + ':'}
              type='date'
              min={
                new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000)
                  .toISOString()
                  .split('T')[0]
              } //*Это устанавливает минимальную дату на три дня вперёд
              value={editingProblem.scheduledDate.split('T')[0]} // * Фикс для правильного отображения даты в формате ISO
              onChange={(value) => {
                const date = new Date(value);
                date.setUTCHours(0, 0, 0, 0);
                setEditingProblem((editingProblem) => ({
                  ...editingProblem,
                  scheduledDate: date.toISOString(),
                }));
              }}
            />
          )}

          {/* <Flex direction="column" gap={6}>
              <Text color="gray">
                Сделать статус:
              </Text>

              <Flex gap={6}>
                <Button
                  className={cn(styles.statusButton, {
                    [styles.statusButtonActive]: status === "Open"
                  })}
                  onClick={() => setStatus("Open")}
                >
                  Open
                </Button>

                <Button
                  className={cn(styles.statusButton, {
                    [styles.statusButtonActive]: status === "In Progress"
                  })}
                  onClick={() => setStatus("In Progress")}
                >
                  In Progress
                </Button>

                <Button
                  className={cn(styles.statusButton, {
                    [styles.statusButtonActive]: status === "Finished"
                  })}
                  onClick={() => setStatus("Finished")}
                >
                  Close
                </Button>
              </Flex>
            </Flex> */}
        </Flex>

        <Flex justify='between'>
          <Button
            type='black'
            onClick={async (e) => {
              try {
                await getHouseValidationSchema(t).validate(
                  {
                    problemDescription: editingProblem.description,
                  },
                  {
                    abortEarly: false,
                  }
                );
              } catch (error) {
                if (error.name === 'ValidationError') {
                  const newErrors = {};
                  error.inner.forEach((err) => {
                    newErrors[err.path] = err.message;
                  });
                  setErrors(newErrors);
                }
              }

              if (handleFormErrors()) {
                return;
              }

              saveProblem(e);
            }}
          >
            {t('save')}
          </Button>
          <Button type='text' onClick={deleteProblem}>
            <Text size={14} weight='bold'>
              {t('delete_problem')}
            </Text>
          </Button>
        </Flex>
      </Flex>
      {/* )} */}
    </Modal>
  );
};
