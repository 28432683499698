import cn from 'classnames';

import {Text} from '../Text/Text'
import {Flex} from '../Flex/Flex'
import styles from './Select.module.scss';
import {ArrowIcon} from "../../static";

export const Select = (props) => {
  const {
    name,
    label,
    options,
    value,
    onChange,
    placeholder,
    errorMessage,
    className,
    children,
    ...restProps
  } = props;

  return (
    <Flex 
      direction="column"
      gap={6}
      className={cn(styles.select, className)}
    >
      {label && (
        <label styles={styles.selectLabel} htmlFor={name}>
          <Text color="gray">
            {label}
          </Text>
        </label>
      )}

      <div className={styles.selectWrapper}>
        <select
          className={styles.selectField}
          placeholder={placeholder}
          value={value}
          onChange={e => onChange(e.target.value)}
          id={name}
          name={name}
          {...restProps}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>{option.label ?? option.value}</option>
          ))}
        </select>
        <ArrowIcon className={styles.selectArrow}/>
      </div>
      
      {
        errorMessage && (
          <Text size={14}>{errorMessage}</Text>
        )
      }
    </Flex>
  )
}