import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { tokenService } from '../services/tokenService';
import styles from './ManageSkills.module.css';
import { CloseIcon, PenIcon, PlusIcon } from '../static';
import { Flex, IconButton, Input, Modal, Text } from '../ui';
import { useTranslation } from 'react-i18next';
import { getHouseValidationSchema } from './validationSchema';

const ManageSkills = () => {
  const [skills, setSkills] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentSkill, setCurrentSkill] = useState(null);
  const [editedSkillName, setEditedSkillName] = useState('');
  const [editedDescription, setEditedDescription] = useState('');
  const [newSkillName, setNewSkillName] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    fetchSkills();
  }, []);

  const fetchSkills = async () => {
    const token = tokenService.getToken();
    try {
      const response = await axios.get('https://fix.house/api/skills', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSkills(response.data);
    } catch (error) {
      console.error('Ошибка при получении данных о навыках:', error);
    }
  };

  const addSkill = async () => {
    const token = tokenService.getToken();
    try {
      await axios.post(
        'https://fix.house/api/skills',
        {
          skillName: newSkillName,
          description: newDescription,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setNewSkillName('');
      setNewDescription('');
      fetchSkills();
    } catch (error) {
      console.error('Ошибка при добавлении навыка:', error);
    }
  };

  const deleteSkill = async (id) => {
    const token = tokenService.getToken();
    try {
      await axios.delete(`https://fix.house/api/skills/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchSkills();
    } catch (error) {
      console.error('Ошибка при удалении навыка:', error);
    }
  };

  const startEditing = (skill) => {
    setIsEditing(true);
    setCurrentSkill(skill);
    setEditedSkillName(skill.skillName);
    setEditedDescription(skill.description);
  };

  const editSkill = async () => {
    const token = tokenService.getToken();
    try {
      await axios.put(
        `https://fix.house/api/skills/${currentSkill._id}`,
        {
          skillName: editedSkillName,
          description: editedDescription,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsEditing(false);
      fetchSkills();
    } catch (error) {
      console.error('Ошибка при редактировании навыка:', error);
    }
  };

  const validateField = async (name, value) => {
    try {
      await getHouseValidationSchema(t).validateAt(name, {
        ...{
          skill: newSkillName,
          skillDescription: newDescription,
          skillEdit: editedSkillName,
          skillDescriptionEdit: editedDescription,
        },
        [name]: value,
      });
      setErrors((prev) => ({ ...prev, [name]: '' }));
    } catch (error) {
      setErrors((prev) => ({ ...prev, [name]: error.message }));
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  return (
    <div className={styles.typesContainer}>
      <div className={styles.blockContent}>
        <h1>{t('manage_skills')}:</h1>
        <ul className={styles.subsList}>
          {skills.map((skill) => (
            <li key={skill._id} className={styles.subCard}>
              <p className={styles.label}>{t('title')}:</p>
              <p className={styles.title}>{skill.skillName}</p>
              <p className={styles.info}>
                <span className={styles.label}>{t('description')}: </span>
                {skill.description}
              </p>
              <Flex justify='between' gap={10}>
                <button
                  onClick={() => startEditing(skill)}
                  className={styles.editButton}
                >
                  <PenIcon />
                  {t('edit')}
                </button>
                <button
                  onClick={() => deleteSkill(skill._id)}
                  className={styles.deleteButton}
                >
                  {t('delete')}
                </button>
              </Flex>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.addSubContainer}>
        <div className={styles.blockContent}>
          <Flex gap={10} className={styles.addHeader}>
            <PlusIcon width={36} height={36} />
            <h2>{t('add_skill')}</h2>
          </Flex>
          <Flex
            gap={20}
            className={styles.addFields}
            direction='column'
            align='start'
          >
            <Input
              className={styles.addSubInput}
              name='skill'
              type='text'
              value={newSkillName}
              onChange={(value) => setNewSkillName(value)}
              label={t('skill_name')}
              onBlur={handleBlur}
            />
            {errors.skill && (
              <div className={styles.errorMessage}>{errors.skill}</div>
            )}
            <Input
              className={styles.addSubInput}
              name='skillDescription'
              type='text'
              value={newDescription}
              onChange={(value) => setNewDescription(value)}
              label={t('description')}
              onBlur={handleBlur}
            />
            {errors.skillDescription && (
              <div className={styles.errorMessage}>
                {errors.skillDescription}
              </div>
            )}
          </Flex>

          <button onClick={addSkill} className={styles.addButton}>
            {t('add')}
          </button>
          <button onClick={() => {}} className={styles.cancelButton}>
            {t('cancel')}
          </button>
        </div>
      </div>

      {isEditing && (
        <Modal closeModal={() => setIsEditing(false)} className={styles.Modal}>
          <Flex direction='column' gap={30}>
            <Flex justify='between' align='baseline'>
              <Text size={20} lineHeight='25px' weight='bold'>
                {t('edit_skill')}
              </Text>
              <IconButton
                type='transparent'
                size='m'
                onClick={() => setIsEditing(false)}
              >
                <CloseIcon />
              </IconButton>
            </Flex>
            <Flex direction='column' gap={16}>
              <Input
                label={t('skill_name') + ':'}
                name='skillEdit'
                value={editedSkillName || ''}
                onChange={(value) => setEditedSkillName(value)}
                type='text'
                onBlur={handleBlur}
              />
              {errors.skillEdit && (
                <div className={styles.errorMessage}>{errors.skillEdit}</div>
              )}
              <Input
                label={t('description') + ':'}
                name='skillDescriptionEdit'
                value={editedDescription || ''}
                onChange={(value) => setEditedDescription(value)}
                type='text'
                onBlur={handleBlur}
              />
              {errors.skillDescriptionEdit && (
                <div className={styles.errorMessage}>
                  {errors.skillDescriptionEdit}
                </div>
              )}
              <Flex gap={10}>
                <button onClick={editSkill} className={styles.saveButton}>
                  {t('save')}
                </button>
                <button
                  onClick={() => setIsEditing(false)}
                  className={styles.cancelButton}
                >
                  {t('cancel')}
                </button>
              </Flex>
            </Flex>
          </Flex>
        </Modal>
      )}
    </div>
  );
};

export default ManageSkills;
