// src/services/tokenService.js
const TOKEN_KEY = 'userToken';
const ROLE_KEY = 'userRole';
const USER_ID_KEY = 'userId';
const WORKER_ID_KEY = 'workerId';
const COUNTRY_KEY = 'userCountry';
const CITY_KEY = 'userCity';

export const tokenService = {
  // Методы для работы с токеном
  getToken: () => localStorage.getItem(TOKEN_KEY),
  saveToken: (token) => localStorage.setItem(TOKEN_KEY, token),
  removeToken: () => localStorage.removeItem(TOKEN_KEY),


// методы для работы со страной
  getCountry: () => localStorage.getItem(COUNTRY_KEY),
  saveCountry: (country) => localStorage.setItem(COUNTRY_KEY, country),
  removeCountry: () => localStorage.removeItem(COUNTRY_KEY),


 // методы для работы с городом
  getCity: () => localStorage.getItem(CITY_KEY),
  saveCity: (city) => localStorage.setItem(CITY_KEY, city),
  removeCity: () => localStorage.removeItem(CITY_KEY),


  // Методы для работы с ролью
  getRole: () => localStorage.getItem(ROLE_KEY),
  saveRole: (role) => localStorage.setItem(ROLE_KEY, role),
  removeRole: () => localStorage.removeItem(ROLE_KEY),

  // Методы для работы с ID пользователя
  getUserId: () => localStorage.getItem(USER_ID_KEY),
  saveUserId: (userId) => localStorage.setItem(USER_ID_KEY, userId),
  removeUserId: () => localStorage.removeItem(USER_ID_KEY),

  // Новые методы для работы с ID работника
  getWorkerId: () => localStorage.getItem(WORKER_ID_KEY),
  saveWorkerId: (workerId) => localStorage.setItem(WORKER_ID_KEY, workerId),
  removeWorkerId: () => localStorage.removeItem(WORKER_ID_KEY),

  // Метод для очистки всех данных (может быть полезен при выходе из системы)
  clearAll: () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(ROLE_KEY);
    localStorage.removeItem(USER_ID_KEY);
    localStorage.removeItem(WORKER_ID_KEY);
    localStorage.removeItem(COUNTRY_KEY);
    localStorage.removeItem(CITY_KEY);
  }
};