import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';

import { desktopQuery } from '../../consts';
import { Button, Flex, Text, Bar, Container } from '../../ui';
import styles from './AvailableSubscriptionsBlock.module.scss';
import { useTranslation } from 'react-i18next';
import { SubscriptionCard } from '../HomePagePricingBlock/components';
import { BuyModal } from '../BuyModal/BuyModal';

export const AvailableSubscriptionsBlock = (props) => {
  const {
    className,
    subscriptionTypes,
    handleSubscriptionPurchase,
    ...restProps
  } = props;

  const isDesktop = useMediaQuery(desktopQuery);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [confirmModal, setConfirmModal] = useState(false);
  const [subscription, setSubscription] = useState(null);

  const handleOpenConfirmModal = (sub) => {
    setSubscription(sub);
    setConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setConfirmModal(false);
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, []);

  return isDesktop ? (
    <section
      className={cn(styles.availableSubscriptionsBlock, className)}
      {...restProps}
    >
      <Container>
        <Flex direction='column' gap={40}>
          <Text tag='h2' size={36} lineHeight='36px' color='gray'>
            {t('available_subscriptions')}
          </Text>
          <div className={styles.availableSubscriptionsList}>
            {subscriptionTypes.map((type) => (
              <SubscriptionCard
                key={type._id.$oid}
                title={type.typeName}
                price={`${type.price} THB`}
                description={type.description}
                buttonText={t('pay_with_bank_card')}
                buttonText2={t('pay_with_cash')}
                buttonText3={t('pay_with_crypto')}
                handleClick={() => handleSubscriptionPurchase(type, 'card')}
                handleClick2={() => handleOpenConfirmModal(type)}
                note={
                  type._id.$oid === 'home_care' ? t('best_value_offer') : null
                }
                subscription={type} // Передаем полный объект подписки
              />
            ))}
          </div>
        </Flex>
      </Container>
      {confirmModal && (
        <BuyModal
          closeModal={handleCloseConfirmModal}
          handleConfirm={() => handleSubscriptionPurchase(subscription, 'cash')}
          subscription={subscription}
        />
      )}
    </section>
  ) : (
    <Container>
      <section
        className={cn(styles.availableSubscriptionsBlock, className)}
        {...restProps}
      >
        <Flex direction='column' gap={12}>
          <Text size={24} lineHeight='34px' weight='bold'>
            {t('available_subscriptions')}
          </Text>
          {subscriptionTypes.map((type) => (
            <SubscriptionCard
              key={type._id.$oid}
              title={type.typeName}
              price={`${type.price} THB`}
              description={type.description}
              buttonText={t('pay_with_bank_card')}
              buttonText2={t('pay_with_cash')}
              buttonText3={t('pay_with_crypto')}
              handleClick={() => handleSubscriptionPurchase(type)}
              handleClick2={() => handleOpenConfirmModal(type)}
              note={
                type._id.$oid === 'home_care' ? t('best_value_offer') : null
              }
              subscription={type} // Передаем полный объект подписки
            />
          ))}
        </Flex>
      </section>
      {confirmModal && (
        <BuyModal
          closeModal={handleCloseConfirmModal}
          handleConfirm={() => handleSubscriptionPurchase(subscription, 'cash')}
          subscription={subscription}
        />
      )}
    </Container>
  );
};
