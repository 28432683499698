import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './HouseInfoBlock.module.scss';
import { Link, useParams } from 'react-router-dom';
import { Flex } from '../../ui';
import { tokenService } from '../../services/tokenService';
import { useTranslation } from 'react-i18next';

const HouseInfoBlock = ({
  houseInfo,
  handleCopyCoordinates,
  showCopyNotification,
  activeTab,
  handleTabChange,
  setSubTab,
  subTab,
  handleSubTabChange,
  isProblemAssigned,
  workerId,
  unassignProblemFromSelf,
  assignProblemToSelf,
}) => {
  const { houseId } = useParams();
  const [ownerDetails, setOwnerDetails] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchOwnerDetails = async (ownerId) => {
      try {
        const token = tokenService.getToken();
        const response = await axios.get(
          `https://fix.house/api/users/${ownerId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setOwnerDetails(response.data);
      } catch (error) {
        console.error('Ошибка при загрузке данных владельца:', error);
      }
    };

    if (houseInfo && houseInfo.house.ownerID) {
      fetchOwnerDetails(houseInfo.house.ownerID);
    }
  }, [houseInfo]);

  return (
    <div className={styles.dashboardContainer}>
      <div className={styles.contentBlock}>
        {houseInfo && (
          <div className={styles.infoBox}>
            <h2 className={styles.infoBoxTitle}>{t('house_info')}</h2>
            <div className={styles.houseInfoContainer}>
              <Flex justify='between' className={styles.houseInfoRow}>
                <Flex gap={40} className={styles.houseDetails}>
                  <div className={styles.houseImageContainer}>
                    <img
                      src={houseInfo.imageUrl}
                      alt={`Изображение дома ${houseInfo.house.name}`}
                      className={styles.houseImage}
                    />
                  </div>
                  <div className={styles.houseDetailsContainer}>
                    <p className={styles.houseDetailItem}>
                      <strong className={styles.label}>{t('title')}:</strong>{' '}
                      {houseInfo.house.name}
                    </p>
                    <p className={styles.houseDetailItem}>
                      <strong className={styles.label}>{t('address')}:</strong>{' '}
                      {houseInfo.house.address}
                    </p>
                    <p className={styles.houseDetailItem}>
                      <strong className={styles.label}>{t('squares')}:</strong>{' '}
                      {houseInfo.house.squareMeters}
                    </p>
                    <p className={styles.houseDetailItem}>
                      <strong className={styles.label}>
                        {t('house_type')}:
                      </strong>{' '}
                      {houseInfo.house.housingType}
                    </p>
                    {houseInfo.house.floor && (
                      <p className={styles.houseDetailItem}>
                        <strong className={styles.label}>{t('floor')}:</strong>{' '}
                        {houseInfo.house.floor}
                      </p>
                    )}
                    {houseInfo.house.apartmentNumber && (
                      <p className={styles.houseDetailItem}>
                        <strong className={styles.label}>
                          {t('apartment_number')}:
                        </strong>{' '}
                        {houseInfo.house.apartmentNumber}
                      </p>
                    )}
                    <p className={styles.houseDetailItem}>
                      <strong className={styles.label}>
                        {t('coordinates')}:{' '}
                      </strong>
                      {houseInfo.house.mapLocationCoordinates.coordinates[1]},{' '}
                      {houseInfo.house.mapLocationCoordinates.coordinates[0]}
                      <button
                        onClick={() =>
                          handleCopyCoordinates(
                            houseInfo.house.mapLocationCoordinates
                              .coordinates[1],
                            houseInfo.house.mapLocationCoordinates
                              .coordinates[0]
                          )
                        }
                        className={styles.copyButton}
                      >
                        {t('copy')}
                      </button>
                      {showCopyNotification && (
                        <span style={{ marginLeft: '10px', color: 'green' }}>
                          {t('copied')}!
                        </span>
                      )}
                    </p>
                  </div>
                </Flex>
                <div className={styles.ownerInfoContainer}>
                  <h3 className={styles.ownerInfoTitle}>
                    {t('owner_details')}
                  </h3>
                  {ownerDetails ? (
                    <>
                      <p className={styles.ownerDetailItem}>
                        <strong className={styles.label}>{t('name')}:</strong>{' '}
                        {ownerDetails.name}
                      </p>
                      <p className={styles.ownerDetailItem}>
                        <strong className={styles.label}>{t('phone')}:</strong>{' '}
                        {ownerDetails.phone}
                      </p>
                      <p className={styles.ownerDetailItem}>
                        <strong className={styles.label}>{t('email')}:</strong>{' '}
                        {ownerDetails.email}
                      </p>
                      <p className={styles.ownerDetailItem}>
                        <strong className={styles.label}>
                          {t('messenger')}:
                        </strong>{' '}
                        {ownerDetails.messenger} ({ownerDetails.messengerType})
                      </p>
                      <p className={styles.ownerDetailItem}>
                        <strong className={styles.label}>
                          {t('language')}:
                        </strong>{' '}
                        {ownerDetails.preferredLanguage}
                      </p>
                      <p className={styles.ownerDetailItem}>
                        <strong className={styles.label}>
                          {t('registration_date')}:
                        </strong>{' '}
                        {new Date(
                          ownerDetails.registeredAt
                        ).toLocaleDateString()}
                      </p>
                      <p className={styles.ownerDetailItem}>
                        <strong className={styles.label}>
                          {t('country')}:
                        </strong>{' '}
                        {ownerDetails.country}
                      </p>
                    </>
                  ) : (
                    <p>{t('loading_owner_details')}...</p>
                  )}
                </div>
              </Flex>
            </div>
            <div className={styles.problemsContainer}>
              <Flex justify='between' className={styles.problemsWrapper}>
                <h3 className={styles.problemsTitle}>{t('problems')}</h3>
                <Flex gap={20} className={styles.problemsTabsList}>
                  {activeTab === 'Open' || activeTab === 'In Progress' ? (
                    <div className={styles.tabs}>
                      <button
                        className={
                          subTab === 'Urgent'
                            ? styles.activeSubTab
                            : styles.subTab
                        }
                        onClick={() => handleSubTabChange('Urgent')}
                      >
                        Urgent
                      </button>
                      <button
                        className={
                          subTab === 'Planned'
                            ? styles.activeSubTab
                            : styles.subTab
                        }
                        onClick={() => handleSubTabChange('Planned')}
                      >
                        Planned
                      </button>
                    </div>
                  ) : null}
                  <div className={styles.tabs}>
                    <button
                      className={
                        activeTab === 'Open' ? styles.activeTab : styles.tab
                      }
                      onClick={() => {
                        handleTabChange('Open');
                        setSubTab('Urgent'); // Сброс подкатегории при переключении основной категории
                      }}
                    >
                      Open
                    </button>
                    <button
                      className={
                        activeTab === 'In Progress'
                          ? styles.activeTab
                          : styles.tab
                      }
                      onClick={() => {
                        handleTabChange('In Progress');
                        setSubTab('Urgent'); // Сброс подкатегории при переключении основной категории
                      }}
                    >
                      In Progress
                    </button>
                  </div>
                </Flex>
              </Flex>

              <div className={styles.problemsGrid}>
                {houseInfo.problems
                  .filter(
                    (problem) =>
                      problem.status === activeTab &&
                      problem.urgencyLevel === subTab
                  )
                  .map((problem, index) => (
                    <div
                      key={index}
                      className={`${styles.problemBox} ${
                        isProblemAssigned(problem._id)
                          ? styles.assignedProblem
                          : ''
                      }`}
                    >
                      <img
                        src={problem.imageUrl}
                        alt={`Изображение проблемы ${problem.description}`}
                        className={styles.problemImage}
                      />
                      <div className={styles.problemDetails}>
                        <p className={styles.problemDetailItem}>
                          <strong className={styles.label}>
                            {t('description')}:
                          </strong>{' '}
                          {problem.description}
                        </p>
                        <p className={styles.problemDetailItem}>
                          <strong className={styles.label}>
                            {t('problem_type')}:
                          </strong>{' '}
                          {problem.problemType?.skillName || t('not_specified')}
                        </p>

                        <p className={styles.problemDetailItem}>
                          <strong className={styles.label}>
                            {t('status')}:
                          </strong>{' '}
                          {problem.status}
                        </p>
                        <p className={styles.problemDetailItem}>
                          <strong className={styles.label}>
                            {t('urgency_level')}:
                          </strong>{' '}
                          {problem.urgencyLevel}
                        </p>
                        <p className={styles.problemDetailItem}>
                          <strong className={styles.label}>
                            {t('creation_date')}:
                          </strong>{' '}
                          {new Date(problem.creationDate).toLocaleDateString()}
                        </p>

                        {problem.status === 'In Progress' ? (
                          <div>
                            <br></br>
                            <Link
                              to={`/admin-dashboard/worker-data/${problem.workerDetails?.id}`}
                              className={styles.linkHighlight}
                            >
                              <p className={styles.problemDetailItem}>
                                <strong className={styles.label}>
                                  {t('already_assigned_to')}:{' '}
                                </strong>
                              </p>
                              <p className={styles.problemDetailItem}>
                                <strong className={styles.label}>
                                  {t('name')}:
                                </strong>{' '}
                                {problem.workerDetails?.name ||
                                  t('not_specified')}
                              </p>
                            </Link>
                            <p className={styles.problemDetailItem}>
                              <strong className={styles.label}>
                                {t('phone')}:
                              </strong>{' '}
                              {problem.workerDetails?.phone ||
                                t('not_specified')}
                            </p>
                            <p className={styles.problemDetailItem}>
                              <strong className={styles.label}>
                                {t('email')}:
                              </strong>{' '}
                              {problem.workerDetails?.email ||
                                t('not_specified')}
                            </p>
                            <p className={styles.problemDetailItem}>
                              <strong className={styles.label}>
                                {t('messenger')}:{' '}
                              </strong>{' '}
                              {problem.workerDetails?.messenger ||
                                t('not_specified')}
                            </p>
                            {problem.workerDetails?.id === workerId && (
                              <button
                                className={styles.errorButton}
                                onClick={() =>
                                  unassignProblemFromSelf(problem._id)
                                }
                              >
                                {t('unassign_problem')}
                              </button>
                            )}
                          </div>
                        ) : (
                          <button
                            className={styles.assignButton}
                            onClick={() =>
                              isProblemAssigned(problem._id)
                                ? unassignProblemFromSelf(problem._id)
                                : assignProblemToSelf(
                                    problem._id,
                                    problem.initialPhotoID?.imageURL
                                  )
                            }
                          >
                            {isProblemAssigned(problem._id)
                              ? t('unassign_problem')
                              : t('assign_problem')}
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HouseInfoBlock;
