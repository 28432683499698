import React from 'react';
import { Flex, Text } from '../../ui';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './AdminMobileLinks.module.scss';

const AdminMobileLinks = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.mobileLinks}>
      <Flex tag='ul' direction='column'>
        <li>
          <Link to='/admin-dashboard'>
            <Text weight='medium' size={14}>
              {t('dashboard')}{' '}
            </Text>
          </Link>
        </li>
        <li>
          <Link to='/admin-dashboard/worker-list'>
            <Text weight='medium' size={14}>
              {t('workers')}{' '}
            </Text>
          </Link>
        </li>
        <li>
          <Link to='/admin-dashboard/client-data'>
            <Text weight='medium' size={14}>
              {t('clients')}
            </Text>
          </Link>
        </li>

        <li>
          <Link to='/admin-dashboard/subs-manage'>
            <Text weight='medium' size={14}>
              {t('subscriptions')}
            </Text>
          </Link>
        </li>
        <li>
          <Link to='/admin-dashboard/sales'>
            <Text weight='medium' size={14}>
              {t('sales')}
            </Text>
          </Link>
        </li>
        <li>
          <Link to='/admin-dashboard/settings'>
            <Text weight='medium' size={14}>
              {t('settings')}
            </Text>
          </Link>
        </li>
      </Flex>
    </div>
  );
};

export default AdminMobileLinks;
