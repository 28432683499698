import React from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import styles from './WorkerMapBlock.module.scss';

const WorkerMapBlock = ({
  mapCenter,
  mapRef,
  housesWithProblems,
  selectedHouse,
  isProblemAssigned,
  customMarkerIconActive,
  customMarkerIcon,
  customMarkerIconAll,
  setSelectedHouse,
}) => {
  return (
    <div className={styles.dashboardContainer}>
      <div className={styles.contentBlock}>
        <div className={styles.workerMap}>
          <MapContainer
            center={mapCenter}
            zoom={11}
            style={{ height: '480px', width: '100%' }}
            ref={mapRef}
          >
            <TileLayer
              url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {housesWithProblems.map((house) => {
              const isSelected =
                selectedHouse && selectedHouse.house._id === house.house._id;
              const hasAssignedProblems = house.problems.some((problem) =>
                isProblemAssigned(problem._id)
              );

              let markerIcon;
              if (isSelected) {
                markerIcon = customMarkerIconActive;
              } else if (hasAssignedProblems) {
                markerIcon = customMarkerIcon;
              } else {
                markerIcon = customMarkerIconAll;
              }

              return (
                <Marker
                  key={house.house._id}
                  position={[
                    house.house.mapLocationCoordinates.coordinates[1],
                    house.house.mapLocationCoordinates.coordinates[0],
                  ]}
                  icon={markerIcon}
                  eventHandlers={{
                    click: () => {
                      setSelectedHouse(house);
                    },
                  }}
                />
              );
            })}
          </MapContainer>
        </div>
      </div>
    </div>
  );
};

export default WorkerMapBlock;
