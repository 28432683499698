import React, { useState } from 'react';
import cn from 'classnames';
import { Divider, Flex, IconButton, Text, Button } from '../../ui';
import { CalendarAddIcon, PenIcon, TrashIcon, worker } from '../../static';
import { getSkillNameById, getProblemImageUrl } from '../../utilities';
import styles from './ProblemCard.module.scss';
import RatingComponent from '../../components/RatingComponent';
import axios from 'axios';
import { tokenService } from '../../services/tokenService';
import { useTranslation } from 'react-i18next';

export const ProblemCard = (props) => {
  const {
    handleEditProblem,
    handleDeleteProblem,
    handleGetProblemInfo,
    handleOpenPhotoModal,
    problem,
    skills,
    className,
    children,
    ...restProps
  } = props;

  const [currentRating, setCurrentRating] = useState(problem.rating || 0);
  const { t } = useTranslation();

  const getWorkerImageUrl = (imagePath) => {
    if (!imagePath) {
      return null;
    }
    return `https://fix.house/${imagePath.replace(/^house\//, '')}`;
  };

  const handleRatingSubmit = async (newRating) => {
    setCurrentRating(newRating);

    try {
      const token = tokenService.getToken();
      if (!token) {
        console.error('Аутентификационный токен не найден');
        return;
      }

      const response = await axios.patch(
        `https://fix.house/api/problems/${problem._id}/rating`,
        { rating: newRating },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log('Рейтинг успешно обновлен на сервере');
        // Здесь можно добавить дополнительную логику, например, обновление состояния родительского компонента
      }
    } catch (error) {
      console.error('Ошибка при обновлении рейтинга:', error);
      // Здесь можно добавить логику обработки ошибок, например, показ уведомления пользователю
    }
  };

  return (
    <div className={cn(styles.problemCard, className)} {...restProps}>
      <Flex direction='column' gap={14} className={styles.fullHeight}>
        <div
          className={styles.problemImageContainer}
          onClick={handleOpenPhotoModal}
        >
          <img
            className={styles.problemImage}
            src={getProblemImageUrl(problem.initialPhotoID?.imageURL)}
            alt={problem.description}
          />
          <Flex
            direction='column'
            justify='between'
            className={styles.problemImageOverlay}
          >
            <Flex justify='between' className={styles.problemImageHeader}>
              <Text
                className={cn(styles.problemBubble, styles.problemBubbleBlack)}
                size={10}
                weight='bold'
                lineHeight={1}
                color='white'
              >
                {problem.urgencyLevel}
              </Text>
              <Text
                className={cn(styles.problemBubble, styles.problemBubbleGray)}
                size={10}
                weight='bold'
                lineHeight={1}
                color='white'
              >
                {problem.status}
              </Text>
            </Flex>
            <Flex gap={5} className={styles.problemImageInfo} align='center'>
              <CalendarAddIcon width={14} heigh={14} color='white' />
              <Text color='white' size={10} weight='bold'>
                {new Date(problem.creationDate).toLocaleDateString()}
              </Text>
            </Flex>
          </Flex>
        </div>

        <Flex
          direction='column'
          gap={8}
          className={styles.growable}
          onClick={handleGetProblemInfo}
        >
          <Text
            tag='h3'
            size={16}
            lineHeight='24px'
            weight='medium'
            onClick={handleGetProblemInfo}
            ellipsis
          >
            {problem.description}
          </Text>
          <Text size={12} weight='semiBold'>
            <Text size='inherit' tag='span' weight='regular' color='gray'>
              {t('problem_type')}:&nbsp;
            </Text>
            {getSkillNameById(problem.problemType, skills)}
          </Text>
          <Text size={12} weight='semiBold'>
            <Text size='inherit' tag='span' weight='regular' color='gray'>
              {t('time')}:&nbsp;
            </Text>
            {problem.preferredTime}
          </Text>
          {problem.urgencyLevel === 'Planned' && (
            <Text size={12} weight='semiBold'>
              <Text size='inherit' tag='span' weight='regular' color='gray'>
                {t('scheduled_date')}:&nbsp;
              </Text>
              {problem.scheduledDate
                ? new Date(problem.scheduledDate).toISOString().split('T')[0]
                : t('no_date')}
            </Text>
          )}
        </Flex>

        <Divider className={styles.problemCardDivider} />

        {(() => {
          if (problem?.status === 'Open')
            return (
              <Flex gap={5} className={styles.buttonsContainer}>
                <Button
                  className={styles.problemEditButton}
                  onClick={handleEditProblem}
                >
                  <Flex gap={6} justify='center' align='center'>
                    <PenIcon width={14} height={14} color='black' />
                    <Text size={13} weight='semiBold'>
                      {t('edit2')}
                    </Text>
                  </Flex>
                </Button>
                <IconButton
                  className={styles.problemDeleteButton}
                  onClick={handleDeleteProblem}
                >
                  <TrashIcon width={15} height={15} />
                </IconButton>
              </Flex>
            );

          if (problem?.status === 'In Progress' && problem.workerID)
            return (
              <Flex gap={8} className={styles.paddedBottom}>
                <img
                  src={getWorkerImageUrl(problem.workerID.photo) || worker}
                  alt='worker profile'
                  width={73}
                  height={78}
                />
                <Flex direction='column' gap={10} className={styles.growable}>
                  <Text size={12}>{problem.workerID.name}</Text>
                  <div>
                    <Text size={12}>
                      {t('messenger')}: {problem.workerID.messenger}
                    </Text>
                    <Text size={12}>
                      {t('phone')}{' '}
                      {problem.workerID.phone || 'DEFAULT WORKER TEL'}
                    </Text>
                  </div>
                  <Text size={12}>
                    {problem.workerID.preferredLanguage || 'Eng/Rus'}
                  </Text>
                </Flex>
              </Flex>
            );

          if (problem?.status === 'Finished')
            return (
              <Flex
                gap={6}
                align='baseline'
                justify='center'
                className={styles.paddedBottom}
              >
                <Text weight='semiBold' size={14}>
                  {currentRating > 0
                    ? t('your_review') + ':'
                    : t('review') + ':'}
                </Text>
                <RatingComponent
                  problem={problem}
                  onRatingSubmit={handleRatingSubmit}
                />
              </Flex>
            );
        })()}
      </Flex>
    </div>
  );
};
