import React from 'react';
import styles from './AssignedProblemsBlock.module.scss';
import { useTranslation } from 'react-i18next';

const AssignedProblemsBlock = ({
  assignedProblems,
  markProblemAsFinished,
  unassignProblemFromSelf,
  handleFileChange,
  previewPhotos,
  setCurrentProblem,
  handleOpenProblem,
  statusMessage,
  handleOpenPhotoModal,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.assignedProblemsContainer}>
      <div className={styles.blockContent}>
        <h3 className={styles.header}>{t('my_assigned_problems')}</h3>
        {assignedProblems.length > 0 ? (
          <ul className={styles.houseProblemsListContainer}>
            {assignedProblems.map((problem) => (
              <li
                key={problem._id}
                className={styles.houseProblemsPrivateContainer}
              >
                {console.log('Problem:', problem)}
                {console.log('Initial Photo:', problem.initialPhotoID)}

                {problem.initialPhotoID && problem.initialPhotoID.imageURL ? (
                  <img
                    src={`https://fix.house/${problem.initialPhotoID.imageURL}`}
                    alt='Фото проблемы'
                    className={styles.problemImage}
                    onClick={() =>
                      handleOpenPhotoModal(problem.initialPhotoID.imageURL)
                    }
                  />
                ) : (
                  <p>{t('no_initial_photo')}</p>
                )}

                <p className={styles.info}>
                  <strong className={styles.infoLabel}>
                    {t('description')}:
                  </strong>{' '}
                  {problem.description}
                </p>
                <p className={styles.info}>
                  <strong className={styles.infoLabel}>
                    {t('urgency_level')}:
                  </strong>{' '}
                  {problem.urgencyLevel}
                </p>
                <p className={styles.info}>
                  <strong className={styles.infoLabel}>{t('status')}:</strong>{' '}
                  {problem.status}
                </p>
                <p className={styles.info}>
                  <strong className={styles.infoLabel}>
                    {t('creation_date')}:
                  </strong>{' '}
                  {new Date(problem.creationDate).toLocaleDateString()}
                </p>

                <p className={styles.info}>
                  <strong className={styles.infoLabel}>
                    {t('preferred_time')}:
                  </strong>{' '}
                  {problem.preferredTime || t('not_specified')}
                </p>
                <p className={styles.info}>
                  <strong className={styles.infoLabel}>
                    {t('scheduled_date')}:
                  </strong>{' '}
                  {problem.scheduledDate
                    ? new Date(problem.scheduledDate).toLocaleDateString()
                    : t('not_specified')}
                </p>
                <p className={styles.info}>
                  <strong className={styles.infoLabel}>
                    {t('finished_date')}:
                  </strong>{' '}
                  {new Date(problem.finishedDate).toLocaleDateString()}
                </p>

                <input
                  type='file'
                  id={`finishedPhotos-${problem._id}`}
                  accept='image/*'
                  onChange={(e) => handleFileChange(problem._id, e)}
                />
                {previewPhotos[problem._id] && (
                  <div className={styles.previewContainer}>
                    {previewPhotos[problem._id].map((preview, index) => (
                      <img
                        key={index}
                        src={preview}
                        alt={`Preview ${index + 1}`}
                        className={styles.previewImage}
                      />
                    ))}
                  </div>
                )}

                {previewPhotos[problem._id] &&
                previewPhotos[problem._id].length > 0 ? (
                  <p className={styles.successMessage}>{t('upload_success')}</p>
                ) : (
                  <p className={styles.warningMessage}>{t('upload_warning')}</p>
                )}

                <button
                  className={styles.openButton}
                  onClick={() => {
                    setCurrentProblem(problem);
                    handleOpenProblem();
                  }}
                >
                  {t('open_house_info')}
                </button>

                <button
                  className={styles.deleteButton}
                  onClick={() => unassignProblemFromSelf(problem._id)}
                >
                  {t('decline_problem')}
                </button>
                <button
                  id={`finishButton-${problem._id}`}
                  className={styles.finishButton}
                  onClick={() => markProblemAsFinished(problem._id)}
                  disabled={
                    !previewPhotos[problem._id] ||
                    previewPhotos[problem._id].length < 1
                  }
                >
                  {t('finish_problem')}
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <p className={styles.info}>{t('no_assigned_problems')}.</p>
        )}
        {statusMessage && (
          <div
            className={`${styles.statusMessage} ${styles[statusMessage.type]}`}
          >
            {statusMessage.text}
          </div>
        )}
      </div>
    </div>
  );
};

export default AssignedProblemsBlock;
