import { Link } from 'react-router-dom';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';

import { desktopQuery } from '../../consts';
import { Container, Flex, Text, IconButton, Button } from '../../ui';
import { logoImg, UserIcon, SearchIcon } from '../../static';
import styles from './HeaderBlock.module.scss';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { LogoutModal } from '../LogoutModal/LogoutModal';

export const HeaderBlock = (props) => {
  const {
    className,
    isLoggedIn,
    userName,
    handleRegister,
    handleLogin,
    handleLogout,
    handleUserClick,
    ...restProps
  } = props;

  const isDesktop = useMediaQuery(desktopQuery);
  const { t } = useTranslation();
  const [logoutModal, setLogoutModal] = useState(false);

  const handleOpenLogout = () => {
    setLogoutModal(true);
  };

  const handleCloseLogout = () => {
    setLogoutModal(false);
  };

  return isDesktop ? (
    <div className={cn(styles.header, className)} {...restProps}>
      <Container>
        <Flex justify='between' align='center'>
          <Link to='/'>
            <img width='240' src={logoImg} alt='logo'></img>
          </Link>

          <Flex direction='column' gap={30} align='end'>
            <Flex gap={40} align='center'>
              <Flex gap={6}>
                {isLoggedIn ? (
                  <>
                    <Button
                      type='black'
                      onClick={() => {
                        handleUserClick && handleUserClick();
                      }}
                    >
                      <Text color='white'>
                        <UserIcon width='20px' height='20px' />
                      </Text>
                      <Text weight='medium' color='white' size={17}>
                        {userName}
                      </Text>
                    </Button>
                    <Button type='outline' onClick={handleOpenLogout}>
                      <Text weight='medium' color='white' size={17}>
                        {t('logout')}
                      </Text>
                    </Button>
                  </>
                ) : (
                  <>
                    <Button type='outline' onClick={handleRegister}>
                      <Text color='white'>
                        <UserIcon width='20px' height='20px' />
                      </Text>
                      <Text weight='medium' color='white' size={17}>
                        {t('login')}
                      </Text>
                    </Button>
                    <Button type='outline' onClick={handleRegister}>
                      <Text weight='medium' color='white' size={17}>
                        {t('register')}
                      </Text>
                    </Button>
                  </>
                )}
              </Flex>
            </Flex>

            <nav>
              <Flex tag='ul' gap={60}>
                <li>
                  <Link to='/'>
                    <Text weight='medium' color='white' size={17}>
                      {t('home')}
                    </Text>
                  </Link>
                </li>
                <li>
                  <Link to='/client-page'>
                    <Text weight='medium' color='white' size={17}>
                      {t('client_page')}
                    </Text>
                  </Link>
                </li>
                <li>
                  <Link to='https://fix.house/#pricing'>
                    <Text weight='medium' color='white' size={17}>
                      {t('buy_subscribtion')}
                    </Text>
                  </Link>
                </li>

                <li>
                  <Link to='/'>
                    <Text weight='medium' color='white' size={17}>
                      {t('contacts')}
                    </Text>
                  </Link>
                </li>
              </Flex>
            </nav>
          </Flex>
        </Flex>
        {logoutModal && (
          <LogoutModal
            closeModal={handleCloseLogout}
            handleLogout={handleLogout}
          />
        )}
      </Container>
    </div>
  ) : null;
};
