import React from 'react';
import styles from './ChangingPasswordBlock.module.scss';
import { Flex, IconButton, Input, Modal, Text } from '../../ui';
import { CloseIcon } from '../../static';
import { useTranslation } from 'react-i18next';

const ChangingPasswordBlock = ({
  handleChangePassword,
  passwordData,
  setPasswordData,
  changingPassword,
  setChangingPassword,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      className={styles.Modal}
      closeModal={() => setChangingPassword(false)}
    >
      <Flex direction='column' gap={30}>
        <Flex justify='between' align='baseline'>
          <Text size={20} lineHeight='25px' weight='bold'>
            {t('change_password')}
          </Text>
          <IconButton
            type='transparent'
            size='m'
            onClick={() => setChangingPassword(false)}
          >
            <CloseIcon />
          </IconButton>
        </Flex>
        <form onSubmit={handleChangePassword}>
          <Input
            label={t('new_password') + ':'}
            name='password'
            type='password'
            value={passwordData.newPassword || ''}
            onChange={(value) =>
              setPasswordData({ ...passwordData, newPassword: value })
            }
          />
          <button type='submit' className={styles.button}>
            {t('change_password')}
          </button>
        </form>
      </Flex>
    </Modal>
  );
};

export default ChangingPasswordBlock;
