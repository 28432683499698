import cn from 'classnames';

import styles from './Flex.module.scss';

export const Flex = (props) => {
  const {
    align, 
    justify, 
    gap, 
    direction, 
    children, 
    className, 
    style,
    wrap,
    tag = "div", 
    ...restProps
  } = props;

  const Component = tag;

  return (
    <Component
      className={cn(styles.flex, {
        [styles.alignCenter]: align === "center",
        [styles.alignStart]: align === "start",
        [styles.alignEnd]: align === "end",
        [styles.alignBaseline]: align === "baseline",
        [styles.justifyCenter]: justify === "center",
        [styles.justifyStart]: justify === "start",
        [styles.justifyEnd]: justify === "end",
        [styles.justifyBetween]: justify === "between",
        [styles.directionColumn]: direction === "column",
        [styles.directionRow]: direction === "row",
        [styles.wrap]: wrap,
      }, className)}
      style={{gap, ...style}}
      {...restProps}
    >
      {children}
    </Component>
  )
}