import { Modal, Flex, Text, IconButton, Button, Input } from '../../ui';
import { CloseIcon } from '../../static/icons';
import { useTranslation } from 'react-i18next';
import { getHouseValidationSchema } from '../../components/validationSchema';
import { useState } from 'react';
import styles from './ChangePasswordModal.module.scss';

export const ChangePasswordModal = (props) => {
  const {
    closeModal,
    newPassword,
    setNewPassword,
    confirmNewPassword,
    setConfirmNewPassword,
    handleChangePassword,
    error,
  } = props;

  const { t } = useTranslation();
  const [errors, setErrors] = useState({});

  const validateField = async (name, value) => {
    try {
      await getHouseValidationSchema(t).validateAt(name, {
        ...{ password: newPassword, passwordConfirm: confirmNewPassword },
        [name]: value,
      });
      setErrors((prev) => ({ ...prev, [name]: '' }));
    } catch (error) {
      setErrors((prev) => ({ ...prev, [name]: error.message }));
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  const handleFormErrors = () => {
    let hasError = false;
    Object.keys(errors).forEach((key) => {
      if (errors[key] !== '') {
        hasError = true;
      }
    });
    return hasError;
  };

  return (
    <Modal closeModal={closeModal} className={styles.Modal}>
      <Flex direction='column' gap={30}>
        <Flex justify='between' align='baseline'>
          <Text size={20} lineHeight='25px' weight='bold'>
            {t('changing_password')}
          </Text>
          <IconButton type='transparent' size='m' onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </Flex>
        <Flex direction='column' gap={16}>
          <Input
            label={t('new_password') + ':'}
            name='password'
            type='password'
            value={newPassword}
            onChange={setNewPassword}
            onBlur={handleBlur}
          />
          {errors.password && (
            <div className={styles.errorMessage}>{errors.password}</div>
          )}
          <Input
            label={t('confirm_new_password') + ':'}
            name='passwordConfirm'
            type='password'
            value={confirmNewPassword}
            onChange={setConfirmNewPassword}
            errorMessage={error}
            onBlur={handleBlur}
          />

          {errors.passwordConfirm && (
            <div className={styles.errorMessage}>{errors.passwordConfirm}</div>
          )}
        </Flex>
        <Button
          type='black'
          isDisabled={!newPassword || !confirmNewPassword}
          onClick={handleChangePassword}
        >
          {t('save')}
        </Button>
      </Flex>
    </Modal>
  );
};
