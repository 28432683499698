import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './PaymentForm.module.css';
import { tokenService } from '../services/tokenService';
import { FooterBlock, HeaderBlock } from '../features';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';
import { getHouseValidationSchema } from './validationSchema';

function PaymentForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId, subscriptionType, paymentMethod } = location.state || {};
  const [error, setError] = useState('');
  const { t } = useTranslation();

  const [cardDetails, setCardDetails] = useState({
    number: '',
    name: '',
    expiration_month: '',
    expiration_year: '',
    security_code: '',
  });

  const [errors, setErrors] = useState({});

  const validateField = async (name, value) => {
    try {
      await getHouseValidationSchema(t).validateAt(name, {
        ...{
          cardNumber: cardDetails.number,
          cardHolder: cardDetails.name,
          cardExpirationMonth: cardDetails.expiration_month,
          cardExpirationYear: cardDetails.expiration_year,
          cardCVV: cardDetails.security_code,
        },
        [name]: value,
      });
      setErrors((prev) => ({ ...prev, [name]: '' }));
    } catch (error) {
      setErrors((prev) => ({ ...prev, [name]: error.message }));
    }
  };

  const handleBlur = (e) => {
    let { name, value } = e.target;
    switch (name) {
      case 'number':
        name = 'cardNumber';
        break;
      case 'name':
        name = 'cardHolder';
        break;
      case 'expiration_month':
        name = 'cardExpirationMonth';
        break;
      case 'expiration_year':
        name = 'cardExpirationYear';
        break;
      case 'security_code':
        name = 'cardCVV';
        break;
      default:
        break;
    }
    validateField(name, value);
  };

  useEffect(() => {
    if (paymentMethod === 'cash' || paymentMethod === 'crypto') {
      handleCreateSubscription(null);
    }
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCardDetails({ ...cardDetails, [name]: value });
  };

  const handleCreateSubscription = async (token) => {
    if (!userId || !subscriptionType) {
      console.error('Необходимые данные для создания подписки отсутствуют');
      setError('Необходимые данные для создания подписки отсутствуют');
      return;
    }

    const subscriptionData = {
      token: token,
      subscriptionTypeID: subscriptionType._id,
      userID: userId,
      amount: subscriptionType.price,
      paymentMethod: paymentMethod || 'card',
    };

    try {
      const response = await axios.post(
        'https://fix.house/api/subscriptions/create-subscription',
        subscriptionData,
        {
          headers: { Authorization: `Bearer ${tokenService.getToken()}` },
        }
      );

      if (response.status === 201) {
        console.log('Подписка успешно создана');
        navigate('/success');
      } else {
        console.error('Подписка не была создана');
        setError('Произошла ошибка при оплате. Пожалуйста, попробуйте снова.');
        navigate('/error');
      }
    } catch (error) {
      console.error('Ошибка при создании подписки:', error);
      setError('Внутренняя ошибка сервера. Пожалуйста, попробуйте снова.');
      navigate('/error');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await getHouseValidationSchema(t).validate(
        {
          cardNumber: cardDetails.number,
          cardHolder: cardDetails.name,
          cardExpirationMonth: cardDetails.expiration_month,
          cardExpirationYear: cardDetails.expiration_year,
          cardCVV: cardDetails.security_code,
        },
        {
          abortEarly: false,
        }
      );
    } catch (error) {
      if (error.name === 'ValidationError') {
        const newErrors = {};
        error.inner.forEach((err) => {
          newErrors[err.path] = err.message;
        });
        setErrors(newErrors);
      }
    }

    if (handleFormErrors()) {
      return;
    }
    setError('');

    if (paymentMethod === 'card') {
      window.Omise.setPublicKey('pkey_test_5yzdtnfb9xoxb0iwr9j');
      window.Omise.createToken('card', cardDetails, (statusCode, response) => {
        if (response.object === 'error' || !response.card.security_code_check) {
          setError('Оплата не удалась, проверьте все данные.');
          console.error('Error or security code check failed');
        } else {
          console.log('Token created:', response.id);
          handleCreateSubscription(response.id);
        }
      });
    } else {
      handleCreateSubscription(null);
    }
  };

  const handleLogout = () => {
    tokenService.removeToken();
    navigate('/client-register');
  };

  const handleFormErrors = () => {
    let hasError = false;
    Object.keys(errors).forEach((key) => {
      if (
        errors[key] !== '' &&
        [
          'cardNumber',
          'cardHolder',
          'cardExpirationMonth',
          'cardExpirationYear',
          'cardCVV',
        ].includes(key)
      ) {
        hasError = true;
      }
    });
    return hasError;
  };

  if (paymentMethod === 'cash' || paymentMethod === 'crypto') {
    return (
      <div>
        <HeaderBlock isLoggedIn handleLogout={handleLogout} />
        <LanguageSwitcher />
        <div className={styles.paymentFormContainer}>
          <h2 className={styles.paymentFormTitle}>{t('processing_payment')}</h2>
          <p>{t('redirecting')}</p>
        </div>
        <FooterBlock />
      </div>
    );
  }

  return (
    <div>
      <HeaderBlock isLoggedIn handleLogout={handleLogout} />
      <LanguageSwitcher />
      <div className={styles.paymentFormContainer}>
        <h2 className={styles.paymentFormTitle}>{t('payment_form_title')}</h2>

        {subscriptionType && (
          <div className={styles.subscriptionDetails}>
            <h3 className={styles.subscriptionTitle}>
              {t('subscription_details')}:
            </h3>
            <p>
              {t('title')}: {subscriptionType.typeName}
            </p>
            <p>
              {t('price')}: {subscriptionType.price} THB
            </p>
          </div>
        )}

        <form onSubmit={handleSubmit} className={styles.paymentForm}>
          <div className={styles.formGroup}>
            <label htmlFor='number' className={styles.formLabel}>
              {t('card_number')}
            </label>
            <input
              type='text'
              name='number'
              placeholder={t('card_number')}
              value={cardDetails.number}
              onChange={handleInputChange}
              className={styles.formInput}
              onBlur={handleBlur}
            />
            {errors.cardNumber && (
              <div className={styles.errorMessage}>{errors.cardNumber}</div>
            )}
          </div>
          <div className={styles.formGroup}>
            <label htmlFor='name' className={styles.formLabel}>
              {t('card_name')}
            </label>
            <input
              type='text'
              name='name'
              placeholder={t('card_name')}
              value={cardDetails.name}
              onChange={handleInputChange}
              className={styles.formInput}
              onBlur={handleBlur}
            />
            {errors.cardHolder && (
              <div className={styles.errorMessage}>{errors.cardHolder}</div>
            )}
          </div>
          <div className={`${styles.formGroup} ${styles.formGroupInline}`}>
            <div>
              <label htmlFor='expiration_month' className={styles.formLabel}>
                {t('month')}
              </label>
              <input
                type='text'
                name='expiration_month'
                placeholder='MM'
                value={cardDetails.expiration_month}
                onChange={handleInputChange}
                className={`${styles.formInput} ${styles.formInputSmall}`}
                onBlur={handleBlur}
              />
              {errors.cardExpirationMonth && (
                <div className={styles.errorMessage}>
                  {errors.cardExpirationMonth}
                </div>
              )}
            </div>
            <div>
              <label htmlFor='expiration_year' className={styles.formLabel}>
                {t('year')}
              </label>
              <input
                type='text'
                name='expiration_year'
                placeholder='YY'
                value={cardDetails.expiration_year}
                onChange={handleInputChange}
                className={`${styles.formInput} ${styles.formInputSmall}`}
                onBlur={handleBlur}
              />
              {errors.cardExpirationYear && (
                <div className={styles.errorMessage}>
                  {errors.cardExpirationYear}
                </div>
              )}
            </div>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor='security_code' className={styles.formLabel}>
              {t('security_code')}
            </label>
            <input
              type='text'
              name='security_code'
              placeholder={t('security_code')}
              value={cardDetails.security_code}
              onChange={handleInputChange}
              className={styles.formInput}
              onBlur={handleBlur}
            />
            {errors.cardCVV && (
              <div className={styles.errorMessage}>{errors.cardCVV}</div>
            )}
          </div>
          <button type='submit' className={styles.submitBtn}>
            {t('pay')} {subscriptionType ? `${subscriptionType.price} THB` : ''}
          </button>
          {error && <div className={styles.errorMessage}>{error}</div>}
        </form>
      </div>
      <FooterBlock />
    </div>
  );
}

export default PaymentForm;
