import { useTranslation } from 'react-i18next';
import { Modal, Flex, Text, Button } from '../../ui';
import styles from './LogoutModal.module.scss';

export const LogoutModal = (props) => {
  const { handleLogout, closeModal } = props;
  const { t } = useTranslation();

  return (
    <Modal className={styles.Modal}>
      <Flex direction='column' gap={30}>
        <Text
          size={20}
          lineHeight='25px'
          align='center'
          className={styles.logoutModalHeader}
        >
          {t('logout_text')}?
        </Text>
        <Flex justify='center' gap={20}>
          <Button
            type='black'
            onClick={handleLogout}
            className={styles.logoutModalButton}
          >
            {t('yes')}
          </Button>
          <Button
            type='black'
            onClick={closeModal}
            className={styles.logoutModalButton}
          >
            {t('no')}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
