import React from 'react';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import { desktopQuery } from "../../consts";
import { Flex, Text, Container } from "../../ui";
import styles from './HomePageFeaturesBlock.module.scss';

export const HomePageFeaturesBlock = (props) => {
  const { className, subscriptions, ...restProps } = props;
  const isDesktop = useMediaQuery(desktopQuery);
  const { t } = useTranslation();

  return (
    <section id="features" className={cn(styles.features, className)} {...restProps}>
      <Container className={styles.fullHeight}>
        <div className={cn(styles.featuresGrid)}>
          <Flex direction="column" gap={isDesktop ? 40 : 28} className={styles.featuresGridItem}>
            <Text color="white" tag="h3" size={isDesktop ? 18 : 24} weight="bold">{t('comprehensive_service')}</Text>
            <Text color="white" size={isDesktop ? 14 : 18} weight="bold">{t('comprehensive_service_description')}</Text>
          </Flex>
          
          <Flex direction="column" gap={isDesktop ? 40 : 28} className={styles.featuresGridItem}>
            <Text color="white" tag="h3" size={isDesktop ? 18 : 24} weight="bold">{t('qualified_specialists')}</Text>
            <Text color="white" size={isDesktop ? 14 : 18} weight="bold">{t('qualified_specialists_description')}</Text>
          </Flex>

          <Flex direction="column" gap={isDesktop ? 40 : 28} className={styles.featuresGridItem}>
            <Text color="white" tag="h3" size={isDesktop ? 18 : 24} weight="bold">{t('convenient_app')}</Text>
            <Text color="white" size={isDesktop ? 14 : 18} weight="bold">{t('convenient_app_description')}</Text>
          </Flex>

          <Flex direction="column" gap={isDesktop ? 40 : 28} className={styles.featuresGridItem}>
            <Text color="white" tag="h3" size={isDesktop ? 18 : 24} weight="bold">{t('innovative_service_management')}</Text>
            <Text color="white" size={isDesktop ? 14 : 18} weight="bold">{t('innovative_service_management_description')}</Text>
          </Flex>
        </div>
      </Container>
    </section>
  )
}
