import React from 'react';
import { Navigate } from 'react-router-dom';
import { tokenService } from '../services/tokenService';


const ProtectedRoute = ({ element: Element, roles }) => {
  const userToken = tokenService.getToken();
  const userRole = tokenService.getRole();

  if (!userToken) {
    return <Navigate to="/" replace />;
  }

  if (!roles.includes(userRole)) {
    if (userRole === 'admin') {
      return <Element />;
    } else if (userRole === 'worker') {
      return <Navigate to="/admin-dashboard" replace />;
    } else {
      return <Navigate to="/worker/:id" replace />;
    }
  }

  return <Element />;
};

export default ProtectedRoute;
