import cn from 'classnames';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

import { desktopQuery } from '../../consts';
import { Button, Flex, Text, Bar, Container } from '../../ui';
import {
  PenIcon,
  UserIcon,
  MessageIcon,
  TelegramIcon,
  WhatsappIcon,
  LineIcon,
  PhoneIcon,
  BoltIcon,
  UrgentIcon,
  PlannedIcon,
} from '../../static';
import styles from './UserProfileBlock.module.scss';
import { useTranslation } from 'react-i18next';

const messengerData = {
  telegram: {
    icon: <TelegramIcon />,
  },
  whatsapp: {
    icon: <WhatsappIcon />,
  },
  line: {
    icon: <LineIcon />,
  },
};

export const UserProfileBlock = (props) => {
  const {
    className,
    editProfile,
    userName,
    userMessenger,
    userEmail,
    userPhone,
    selectedMessengerType,
    subscriptionsNumber,
    plannedCallsUsedTotal,
    plannedCallsLimitTotal,
    urgentCallsUsedTotal,
    urgentCallsLimitTotal,
    remainingPlannedCallsTotal,
    remainingUrgentCallsTotal,
    toggleProfileVisibility,
    isProfileVisible,
    ...restProps
  } = props;

  const isDesktop = useMediaQuery(desktopQuery);
  const { t } = useTranslation();

  const MessengerBlock = () => (
    <Flex direction='column' gap={isDesktop ? 10 : 0}>
      <Text color='gray'>{t('messenger')}:</Text>
      <Flex gap={isDesktop ? 10 : 4} align='center'>
        {userMessenger && selectedMessengerType ? (
          <>
            <div className={cn(styles.iconCircle, styles.iconCircleSmall)}>
              {messengerData[selectedMessengerType]?.icon}
            </div>
            <Text size={isDesktop ? 30 : 18} weight='bold'>
              {userMessenger}
            </Text>
          </>
        ) : (
          <Text size={isDesktop ? 30 : 18} color='gray'>
            {t('not_selected')}
          </Text>
        )}
      </Flex>
    </Flex>
  );

  const NoSubscriptionsWarning = () => (
    <Text size={24} className={styles.noSubscriptionsWarning} weight='bold'>
      {t('no_sub_step_1')}{' '}
      <Link to='/#pricing' className={styles.linkStyle}>
        {t('no_sub_step_2')}
      </Link>{' '}
      {t('no_sub_step_3')}
    </Text>
  );

  return (
    <section className={cn(styles.profileBlock, className)} {...restProps}>
      <Container>
        {isDesktop ? (
          <Flex direction='column' gap={60}>
            <Flex justify='between' gap={60}>
              <Text tag='h1' size={60} lineHeight='70px' weight='bold'>
                {t('client_page')}
              </Text>
              <Button
                type='outline'
                size='medium'
                short
                onClick={toggleProfileVisibility}
              >
                <Text size={17} weight='bold'>
                  {t('profile')}
                </Text>
              </Button>
            </Flex>

            {subscriptionsNumber === 0 && <NoSubscriptionsWarning />}

            {isProfileVisible && (
              <Flex direction='column' gap={30}>
                <div className={styles.barItem}>
                  <Flex align='center' gap={20}>
                    <div
                      className={cn(styles.iconCircle, styles.iconCircleBig)}
                    >
                      <UserIcon />
                    </div>
                    <div>
                      <Text color='gray'>{t('name')}:</Text>
                      <Text size={30} weight='bold'>
                        {userName}
                      </Text>
                    </div>
                  </Flex>
                </div>

                <div className={styles.barItem}>
                  <Flex align='center' gap={20}>
                    <div
                      className={cn(styles.iconCircle, styles.iconCircleBig)}
                    >
                      <MessageIcon />
                    </div>
                    <div>
                      <Text color='gray'>{t('email')}:</Text>
                      <Text
                        className={styles.limitedText}
                        size={18}
                        lineHeight='36px'
                        weight='bold'
                      >
                        {userEmail}
                      </Text>
                    </div>
                  </Flex>
                </div>

                <div className={styles.barItem}>
                  <Flex align='center' gap={20}>
                    <div
                      className={cn(styles.iconCircle, styles.iconCircleBig)}
                    >
                      <PhoneIcon />
                    </div>
                    <div>
                      <Text color='gray'>{t('phone')}:</Text>
                      {userPhone ? (
                        <Text size={30} weight='bold'>
                          {userPhone}
                        </Text>
                      ) : (
                        <Text size={30} color='gray'>
                          {t('not_specified')}
                        </Text>
                      )}
                    </div>
                  </Flex>
                </div>

                <Flex justify='between' align='start' gap={60}>
                  <Flex gap={60} direction='row'>
                    <div className={styles.barItem}>
                      <Flex align='center' gap={20}>
                        <div
                          className={cn(
                            styles.iconCircle,
                            styles.iconCircleBig
                          )}
                        >
                          <BoltIcon />
                        </div>
                        <div>
                          <Text color='gray'>{t('active_subscriptions')}:</Text>
                          <Text size={30} weight='bold'>
                            {subscriptionsNumber}
                          </Text>
                        </div>
                      </Flex>
                    </div>
                  </Flex>
                </Flex>

                <MessengerBlock />

                <Button
                  type='outline'
                  size='medium'
                  short
                  onClick={editProfile}
                >
                  <PenIcon />
                  <Text size={17} weight='bold'>
                    {t('edit')}
                  </Text>
                </Button>
              </Flex>
            )}

            <div className={styles.barItem}>
              <Flex align='center' gap={20}>
                <div className={cn(styles.iconCircle, styles.iconCircleBig)}>
                  <UrgentIcon />
                </div>
                <div>
                  <Text color='gray'>{t('available_urgent_calls')}:</Text>
                  <Text size={30} weight='bold'>
                    {t('remained')}: {remainingUrgentCallsTotal} ({' '}
                    {urgentCallsUsedTotal}/{urgentCallsLimitTotal} )
                  </Text>
                </div>
              </Flex>
            </div>

            <div className={styles.barItem}>
              <Flex align='center' gap={20}>
                <div className={cn(styles.iconCircle, styles.iconCircleBig)}>
                  <PlannedIcon />
                </div>
                <div>
                  <Text color='gray'>{t('available_planned_calls')}:</Text>
                  <Text size={30} weight='bold'>
                    {' '}
                    {t('remained')}: {remainingPlannedCallsTotal} ({' '}
                    {plannedCallsUsedTotal}/{plannedCallsLimitTotal} )
                  </Text>
                </div>
              </Flex>
            </div>
          </Flex>
        ) : (
          <Flex direction='column' gap={20}>
            <Flex align='end' justify='between' gap={20}>
              <Text tag='h1' size={24} lineHeight='34px' weight='bold'>
                {' '}
                {t('user_profile')}{' '}
              </Text>
              <Button type='underline' onClick={toggleProfileVisibility}>
                <Text size={14} weight='bold'>
                  {t('profile')}
                </Text>
              </Button>
            </Flex>

            {subscriptionsNumber === 0 && <NoSubscriptionsWarning />}

            {isProfileVisible && (
              <Flex direction='column' gap={10}>
                <Bar>
                  <Flex align='center' gap={10}>
                    <div
                      className={cn(styles.iconCircle, styles.iconCircleBig)}
                    >
                      <UserIcon />
                    </div>
                    <div>
                      <Text color='gray'>{t('name')}:</Text>
                      <Text size={18} weight='bold'>
                        {userName}
                      </Text>
                    </div>
                  </Flex>
                </Bar>

                <Bar>
                  <Flex align='center' gap={10}>
                    <div
                      className={cn(styles.iconCircle, styles.iconCircleBig)}
                    >
                      <MessageIcon />
                    </div>
                    <div>
                      <Text color='gray'>{t('email')}:</Text>
                      <Text size={18} weight='bold'>
                        {userEmail}
                      </Text>
                    </div>
                  </Flex>
                </Bar>

                <Bar>
                  <Flex align='center' gap={10}>
                    <div
                      className={cn(styles.iconCircle, styles.iconCircleBig)}
                    >
                      <PhoneIcon />
                    </div>
                    <div>
                      <Text color='gray'>{t('phone')}:</Text>
                      {userPhone ? (
                        <Text size={18} weight='bold'>
                          {userPhone}
                        </Text>
                      ) : (
                        <Text size={18} color='gray'>
                          {t('not_specified')}
                        </Text>
                      )}
                    </div>
                  </Flex>
                </Bar>

                <Bar>
                  <Flex align='center' gap={10}>
                    <div
                      className={cn(styles.iconCircle, styles.iconCircleBig)}
                    >
                      <BoltIcon />
                    </div>
                    <div>
                      <Text color='gray'>{t('active_subscriptions')}:</Text>
                      <Text size={18} weight='bold'>
                        {subscriptionsNumber}
                      </Text>
                    </div>
                  </Flex>
                </Bar>

                <Bar>
                  <MessengerBlock />
                </Bar>

                <Button type='underline' onClick={editProfile}>
                  <PenIcon />
                  <Text size={14} weight='bold'>
                    {t('edit')}
                  </Text>
                </Button>
              </Flex>
            )}

            <Flex direction='column' gap={10}>
              {/* <Bar>
                <Flex gap={10} align='center'> */}
              <Bar>
                <Flex align='center' gap={10}>
                  <div className={cn(styles.iconCircle, styles.iconCircleBig)}>
                    <UrgentIcon />
                  </div>
                  <div>
                    <Text color='gray'>{t('available_urgent_calls')}:</Text>
                    <Text size={18} weight='bold'>
                      {t('remained')}: {remainingUrgentCallsTotal} ({' '}
                      {urgentCallsUsedTotal}/{urgentCallsLimitTotal} )
                    </Text>
                  </div>
                </Flex>
              </Bar>
              <Bar>
                <Flex align='center' gap={10}>
                  <div className={cn(styles.iconCircle, styles.iconCircleBig)}>
                    <PlannedIcon />
                  </div>
                  <div>
                    <Text color='gray'>{t('available_planned_calls')}:</Text>
                    <Text size={18} weight='bold'>
                      {' '}
                      {t('remained')}: {remainingPlannedCallsTotal} ({' '}
                      {plannedCallsUsedTotal}/{plannedCallsLimitTotal} )
                    </Text>
                  </div>
                </Flex>
              </Bar>

              {/* </Flex>
              </Bar> */}
            </Flex>
          </Flex>
        )}
      </Container>
    </section>
  );
};
