// import {useMediaQuery} from 'react-responsive';
// import cn from 'classnames';

// import {desktopQuery} from '../../consts';
import { Modal, Flex, IconButton, Text } from '../../ui';
import { CloseIcon } from '../../static/icons';
import { ProblemCard } from '../ProblemCard/ProblemCard';
import styles from './ProblemsListModal.module.scss';

export const ProblemsListModal = (props) => {
  const {
    closeModal,
    title,
    problems,
    skills,
    startEditingProblem,
    ControlModuleDelete,
    ControlModuleInfo,
    handleOpenPhotoModal,
  } = props;

  // const isDesktop = useMediaQuery(desktopQuery);

  return (
    <Modal closeModal={closeModal} className={styles.Modal}>
      {/* {isDesktop ? (
        null
      ) : ( */}
      <Flex direction='column' gap={40}>
        <Flex justify='between' align='baseline'>
          <Text size={20} lineHeight='25px' weight='bold'>
            {title}
          </Text>

          <IconButton type='transparent' size='m' onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </Flex>

        <div className={styles.problemCardsGridContainer}>
          <div className={styles.problemCardsGrid}>
            {problems.length ? (
              problems.map((problem) => {
                return (
                  <ProblemCard
                    key={problem._id}
                    problem={problem}
                    skills={skills}
                    handleEditProblem={() => startEditingProblem(problem)}
                    handleDeleteProblem={() => ControlModuleDelete(problem)}
                    handleGetProblemInfo={() => ControlModuleInfo(problem)}
                    handleOpenPhotoModal={() => {
                      // handleOpenPhotoModal(problem.initialPhotoID?.imageURL);
                    }}
                  />
                );
              })
            ) : (
              <>
                {/* <Text size={20} color="gray">Нет проблем в процессе</Text> */}
              </>
            )}
          </div>
        </div>
      </Flex>
      {/* )} */}
    </Modal>
  );
};
