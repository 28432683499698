import React, { useState } from 'react';
import axios from '../axios';
import { useNavigate } from 'react-router-dom';
import styles from './Login.module.css';
import { tokenService } from '../services/tokenService';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';

function Login() {
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  });
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    setErrorMessage('');

    try {
      const response = await axios.post(
        'https://fix.house/api/users/login',
        credentials
      );

      if (response.status === 200) {
        tokenService.saveToken(response.data.token);
        localStorage.setItem('userId', response.data.user._id);
        navigate('/client-page');
      } else {
        setErrorMessage('Ошибка при входе в систему');
      }
    } catch (error) {
      console.error('Ошибка при входе в систему:', error);
      setErrorMessage('Ошибка при входе в систему');
    }
  };

  return (
    <div className={styles.container}>
      <LanguageSwitcher />
      <h1 className={styles.title}>{t('login_title')}</h1>
      <p className={styles['welcome-back']}>{t('welcome_back')}!</p>
      <div className={styles['password-reminder-block']}>
        <p className={styles['password-reminder']}>{t('password_reminder')}.</p>
      </div>
      <form onSubmit={submitForm}>
        <div className={styles['form-group']}>
          <input
            type='email'
            name='email'
            value={credentials.email}
            onChange={handleInputChange}
            placeholder={t('email')}
            className={styles.input}
          />
        </div>
        <div className={styles['form-group']}>
          <input
            type='password'
            name='password'
            value={credentials.password}
            onChange={handleInputChange}
            placeholder={t('password')}
            className={styles.input}
          />
        </div>
        <button type='submit' className={styles.button}>
          {t('login')}
        </button>
        {errorMessage && (
          <p className={styles['error-message']}>{errorMessage}</p>
        )}
      </form>
      <br></br>
      <div className={styles['form-group']}>
        <button
          onClick={() => navigate('/forgot-password')}
          className={styles.button}
        >
          {t('forgot_password')}
        </button>
      </div>
    </div>
  );
}

export default Login;
