import React from 'react';
import styles from './CompletedProblemsBlock.module.scss';
import { Flex } from '../../ui';
import ShareIcon from './../../static/icons/material-symbols_share.svg';
import StarIcon from './../../static/icons/ic_round-star.svg';
import StarIconGray from './../../static/icons/ic_round-star-gray.svg';
import { useTranslation } from 'react-i18next';

const CompletedProblemsBlock = ({ completedProblems }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.completedProblems}>
      <div className={styles.blockContent}>
        <h3 className={styles.header}>{t('my_completed_problems')}</h3>
        {completedProblems.length > 0 ? (
          <ul className={styles.houseProblemsListContainer}>
            {completedProblems.map((problem) => (
              <li
                key={problem._id}
                className={styles.houseProblemsPrivateContainer}
              >
                <p className={styles.info}>
                  <strong className={styles.infoLabel}>
                    {t('description')}:{' '}
                  </strong>
                  {problem.description}
                </p>
                <p className={styles.info}>
                  <strong className={styles.infoLabel}>
                    {t('urgency_level')}:{' '}
                  </strong>
                  {problem.urgencyLevel}
                </p>
                <p className={styles.info}>
                  <strong className={styles.infoLabel}>{t('status')}: </strong>
                  {problem.status}
                </p>
                <p className={styles.info}>
                  <strong className={styles.infoLabel}>
                    {t('creation_date')}:{' '}
                  </strong>
                  {new Date(problem.creationDate).toLocaleDateString()}
                </p>
                <p className={styles.info}>
                  <strong className={styles.infoLabel}>
                    {t('preferred_time')}:
                  </strong>{' '}
                  {problem.preferredTime || t('not_specified')}
                </p>
                <p className={styles.info}>
                  <strong className={styles.infoLabel}>
                    {t('scheduled_date')}:
                  </strong>{' '}
                  {problem.scheduledDate
                    ? new Date(problem.scheduledDate).toLocaleDateString()
                    : t('not_specified')}
                </p>
                <p className={styles.info}>
                  <strong className={styles.infoLabel}>
                    {t('finished_date')}:{' '}
                  </strong>
                  {problem.finishedDate
                    ? new Date(problem.finishedDate).toLocaleString()
                    : 'Не указана'}
                </p>

                <Flex justify='between' gap={5}>
                  <button className={styles.openButton} onClick={() => {}}>
                    {t('open_house_info')}
                  </button>
                  <button className={styles.openButtonShare} onClick={() => {}}>
                    <img src={ShareIcon} alt='edit' />
                  </button>
                </Flex>
                <Flex align='center' gap={5}>
                  <img src={StarIcon} alt='star' />
                  <img src={StarIcon} alt='star' />
                  <img src={StarIcon} alt='star' />
                  <img src={StarIcon} alt='star' />
                  <img src={StarIconGray} alt='star gray' />
                  <span className={styles.infoLabel}>(4.5)</span>
                </Flex>
              </li>
            ))}
          </ul>
        ) : (
          <p className={styles.info}>{t('no_completed_problems')}.</p>
        )}
      </div>
    </div>
  );
};

export default CompletedProblemsBlock;
