import './SubscriptionPlans.css';
import React from 'react';

const SubscriptionPlans = () => {
    return (
        <div className="subscription-plans">
            <h1>🌟 Ваш Надежный Домашний Мастер - Подписки на Услуги Ремонта! 🌟</h1>
            <div className="plan">
                <h2>1️ Базовый План - "Домашний Комфорт"</h2>
                <p>Цена: 1900 бат/месяц</p>
                <p>Включает: 3 плановых и 3 срочных вызова мастера.</p>
                <p>Идеально подходит для поддержания вашего дома в порядке без лишних хлопот.</p>
            </div>
            <div className="plan">
                <h2>2️ Стандартный План - "Безупречный Уют"</h2>
                <p>Цена: 3200 бат/месяц</p>
                <p>Включает: 5 плановых и 5 срочных вызовов мастера.</p>
                <p>Оптимальный выбор для тех, кто ценит комфорт и хочет быть уверенным в безупречности своего дома.</p>
            </div>
            <div className="plan">
                <h2>3️ Премиум План - "Забота о Доме"</h2>
                <p>Цена: 6300 бат/месяц</p>
                <p>Включает: 10 плановых и 10 срочных вызовов мастера.</p>
                <p>Предназначен для тех, кто хочет максимальный уровень заботы о своем доме и готов инвестировать в его комфорт.</p>
            </div>
            <div className="plan">
                <h2>4️ Эксклюзивный План - "Дом Мечты"</h2>
                <p>Цена: 9500 бат/месяц</p>
                <p>Включает: 15 плановых и 15 срочных вызовов мастера.</p>
                <p>Создан для истинных ценителей уюта, которые не готовы на компромиссы в вопросах комфорта и качества жизни.</p>
            </div>
            <div className="advantages">
                <h2>🔧 Ваши Преимущества:</h2>
                <ul>
                    <li>Надежность: Наши мастера – профессионалы своего дела.</li>
                    <li>Гибкость: Вызовите мастера тогда, когда это нужно вам.</li>
                    <li>Экономия: Подписка позволяет экономить на частых и срочных ремонтах.</li>
                    <li>Спокойствие: Ваш дом всегда в надежных руках.</li>
                </ul>
            </div>
            <p>🏡 Оставайтесь с нами – и ваш дом всегда будет источником радости и уюта!</p>
        </div>
    );
};

export default SubscriptionPlans;
