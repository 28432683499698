import React from 'react';

import cn from 'classnames';

import styles from './Modal.module.scss';

export const Modal = (props) => {
  const {
    className,
    children,
    closeModal, 
    ...restProps
  } = props;

  return (
    <div className={styles.modalOverlay} onClick={(e) => {
      if (e.target === e.currentTarget && closeModal) {
        closeModal();
      }
    }}>
      <div className={cn(styles.modalContent, className)} {...restProps}>
        {children}
      </div>
    </div>
  )
}