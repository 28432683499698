import { useState } from 'react';
import cn from 'classnames';
import L from 'leaflet';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { useMediaQuery } from 'react-responsive';

import { desktopQuery } from '../../consts';
import { Button, Flex, Text, Container } from '../../ui';
import {
  PenIcon,
  HouseIcon,
  MapPointRoundIcon,
  MapPointWaveIcon,
  ArrowIcon,
} from '../../static';
import styles from './HouseProfileBlock.module.scss';
import styleMap from './map.module.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const HouseProfileBlock = (props) => {
  const { className, handleEditHouse, getImageUrl, house, ...restProps } =
    props;

  const [mapExpanded, setMapExpanded] = useState(false);

  const customMarkerIcon = L.icon({
    iconUrl: '/marker/marker-icon.png',
    shadowUrl: '/marker/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  const isDesktop = useMediaQuery(desktopQuery);
  const { t } = useTranslation();

  return (
    <section className={cn(styles.profileBlock, className)} {...restProps}>
      <Container>
        {isDesktop ? (
          <Flex direction='column' gap={40}>
            <Flex direction='column' gap={20}>
              <Text tag='h1' size={18} lineHeight='28px' color='gray'>
                {t('house_profile')}
              </Text>

              <Text
                tag='h2'
                size={60}
                lineHeight='70px'
                color='black'
                weight='bold'
                className={styles.clampable}
              >
                {house.name}
              </Text>
            </Flex>

            <div className={styles.houseGrid}>
              {/* to replace it with slider later */}
              <img
                className={styles.houseGridImage}
                src={getImageUrl(house.image)}
                alt='Изображение дома'
              />

              <Flex direction='column' gap={30}>
                <Flex gap={20}>
                  <div className={styles.houseGridInfoCard}>
                    <Flex direction='column' gap={10}>
                      <Flex gap={10}>
                        <MapPointRoundIcon />
                        <Text weight='medium' size={16} color='gray'>
                          {t('address')}:
                        </Text>
                      </Flex>

                      <Text
                        className={styles.clampable}
                        weight='bold'
                        size={30}
                      >
                        {house.address}
                      </Text>
                    </Flex>
                  </div>

                  <div className={styles.houseGridInfoCard}>
                    <Flex direction='column' gap={10}>
                      <Text weight='medium' size={16} color='gray'>
                        {t('squares')}:
                      </Text>

                      <Text
                        className={styles.clampable}
                        weight='bold'
                        size={30}
                      >
                        {house.squareMeters}
                      </Text>
                    </Flex>
                  </div>

                  <div className={styles.houseGridInfoCard}>
                    <Flex direction='column' gap={10}>
                      <Text weight='medium' size={16} color='gray'>
                        {t('house_type')}:{' '}
                      </Text>
                      <Text
                        className={styles.clampable}
                        weight='bold'
                        size={30}
                      >
                        {house.housingType}
                      </Text>
                    </Flex>
                  </div>

                  {house.housingType === 'Apartment' && (
                    <>
                      <div className={styles.houseGridInfoCard}>
                        <Flex direction='column' gap={10}>
                          <Text weight='medium' size={16} color='gray'>
                            {t('floor')}:
                          </Text>
                          <Text
                            className={styles.clampable}
                            weight='bold'
                            size={30}
                          >
                            {house.floor}
                          </Text>
                        </Flex>
                      </div>

                      <div className={styles.houseGridInfoCard}>
                        <Flex direction='column' gap={10}>
                          <Text weight='medium' size={16} color='gray'>
                            {t('apartment_number')}:
                          </Text>
                          <Text
                            className={styles.clampable}
                            weight='bold'
                            size={30}
                          >
                            {house.apartmentNumber}
                          </Text>
                        </Flex>
                      </div>
                    </>
                  )}

                  <Button
                    className={styles.houseGridEditButton}
                    type='outline'
                    size='medium'
                    short
                    onClick={handleEditHouse}
                  >
                    <PenIcon />
                    <Text size={17} weight='bold'>
                      {Text('edit')}
                    </Text>
                  </Button>
                </Flex>

                {house.mapLocationCoordinates?.coordinates && (
                  <div className='house-map'>
                    <MapContainer
                      key={house.mapLocationCoordinates?.coordinates.join(',')}
                      center={[
                        house.mapLocationCoordinates.coordinates[1],
                        house.mapLocationCoordinates.coordinates[0],
                      ]}
                      zoom={13}
                      className={styleMap.map}
                    >
                      <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
                      <Marker
                        position={[
                          house.mapLocationCoordinates.coordinates[1],
                          house.mapLocationCoordinates.coordinates[0],
                        ]}
                        icon={customMarkerIcon}
                      />
                      <div className={styleMap.map_info}>
                        <div className={styleMap.place}>
                          <span>{t('location')}:</span>
                          <p>{house.name}</p>
                        </div>
                        <div className={styleMap.coordination}>
                          <span>{t('coordinates')}:</span>
                          <p>{house.mapLocationCoordinates.coordinates[0]}</p>
                        </div>
                      </div>
                    </MapContainer>
                  </div>
                )}
              </Flex>
            </div>
          </Flex>
        ) : (
          <Flex direction='column' gap={20}>
            <Flex justify='between' align='end' gap={20}>
              <Flex direction='column' gap={8} className={styles.headerWidth}>
                <Text tag='h1' size={15} color='gray'>
                  {t('house_profile')}:
                </Text>
                <Text
                  tag='h2'
                  size={40}
                  color='black'
                  weight='bold'
                  className={styles.clampable}
                >
                  {house.name}
                </Text>
              </Flex>

              <Button type='underline' onClick={handleEditHouse}>
                <PenIcon />
                <Text size={14} weight='bold'>
                  {t('edit')}
                </Text>
              </Button>
            </Flex>

            <Flex direction='column' gap={20} className={styles.houseCard}>
              <img
                className={styles.houseCardImage}
                src={getImageUrl(house.image)}
                alt='Изображение дома'
              />

              <Flex gap={30}>
                <div className={styles.houseCardIcon}>
                  <Link to='/client-page'>
                    <HouseIcon />
                  </Link>
                </div>

                <Flex justify='between' gap={30} className={styles.growable}>
                  <div
                    className={cn(styles.houseCardInfoCard, styles.growable)}
                  >
                    <Flex direction='column' gap={5}>
                      <Flex className={styles.houseCardTitle} gap={5}>
                        <MapPointRoundIcon />
                        <Text weight='medium' size={12} color='gray'>
                          {t('address')}:
                        </Text>
                      </Flex>

                      <Text
                        weight='bold'
                        size={20}
                        className={styles.breakWord}
                      >
                        {house.address}
                      </Text>
                    </Flex>
                  </div>

                  <div
                    className={cn(styles.houseCardInfoCard, styles.growable)}
                  >
                    <Flex direction='column' gap={5}>
                      <Text weight='medium' size={12} color='gray'>
                        {t('squares')}:
                      </Text>

                      <Text weight='bold' size={20}>
                        {house.squareMeters}
                      </Text>
                    </Flex>
                  </div>
                </Flex>
              </Flex>

              <Flex direction='column' gap={20}>
                <div className={styles.houseCardInfoCard}>
                  <Flex direction='column' gap={5}>
                    <Text weight='medium' size={12} color='gray'>
                      {t('house_type')}:
                    </Text>
                    <Text weight='bold' size={20}>
                      {house.housingType}
                    </Text>
                  </Flex>
                </div>

                {house.housingType === 'Apartment' && (
                  <>
                    <div className={styles.houseCardInfoCard}>
                      <Flex direction='column' gap={5}>
                        <Text weight='medium' size={12} color='gray'>
                          {t('floor')}:
                        </Text>
                        <Text weight='bold' size={20}>
                          {house.floor}
                        </Text>
                      </Flex>
                    </div>

                    <div className={styles.houseCardInfoCard}>
                      <Flex direction='column' gap={5}>
                        <Text weight='medium' size={12} color='gray'>
                          {t('apartment_number')}:
                        </Text>
                        <Text weight='bold' size={20}>
                          {house.apartmentNumber}
                        </Text>
                      </Flex>
                    </div>
                  </>
                )}
              </Flex>

              <Flex
                gap={5}
                onClick={() => setMapExpanded((e) => !e)}
                className={cn(styles.expandable, styles.expandableMapBar)}
              >
                <MapPointWaveIcon />
                <Text size={15} weight='semiBold' className={styles.growable}>
                  {mapExpanded ? t('hide') : t('show')} {house.name}{' '}
                  {t('on_map')}
                </Text>
                <ArrowIcon
                  className={cn(styles.expandableArrow, {
                    [styles.expandableArrowExpanded]: mapExpanded,
                  })}
                />
              </Flex>

              {mapExpanded && house.mapLocationCoordinates?.coordinates && (
                <div className='house-map'>
                  <MapContainer
                    key={house.mapLocationCoordinates?.coordinates.join(',')}
                    center={[
                      house.mapLocationCoordinates.coordinates[1],
                      house.mapLocationCoordinates.coordinates[0],
                    ]}
                    zoom={13}
                    className={styleMap.map}
                  >
                    <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
                    <Marker
                      position={[
                        house.mapLocationCoordinates.coordinates[1],
                        house.mapLocationCoordinates.coordinates[0],
                      ]}
                      icon={customMarkerIcon}
                    />
                    <div className={styleMap.map_info}>
                      <div className={styleMap.place}>
                        <span>{t('location')}:</span>
                        <p>{house.name}</p>
                      </div>
                      <div className={styleMap.coordination}>
                        <span>{t('coordinates')}:</span>
                        <p>{house.mapLocationCoordinates.coordinates[0]}</p>
                      </div>
                    </div>
                  </MapContainer>
                </div>
              )}
            </Flex>
          </Flex>
        )}
      </Container>
    </section>
  );
};
