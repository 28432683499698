import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styles from './WorkerRegistrationForm.module.css';
import { tokenService } from './../../services/tokenService';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../LanguageSwitcher';
import { getHouseValidationSchema } from '../validationSchema';

const WorkerRegistrationForm = () => {
  const [worker, setWorker] = useState({
    name: '',
    phone: '',
    email: '',
    role: 'worker',
    preferredLanguage: 'en',
  });
  const [photo, setPhoto] = useState(null);
  const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [error, setError] = useState('');
  const languages = [
    { id: 'en', name: 'English' },
    { id: 'ru', name: 'Russian' },
    { id: 'th', name: 'Thai' },
  ];

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [errors, setErrors] = useState({});
  // const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  // const handleSubmitDisabling = () => {
  //   if (handleFormErrors()) {
  //     setIsSubmitDisabled(false);
  //   } else {
  //     setIsSubmitDisabled(true);
  //   }
  // };

  const handleFormErrors = () => {
    let hasError = false;
    Object.keys(errors).forEach((key) => {
      if (errors[key] !== '' && Object.keys(worker).includes(key)) {
        hasError = true;
      }
    });
    return hasError;
  };

  const validateField = async (name, value) => {
    try {
      await getHouseValidationSchema(t).validateAt(name, {
        ...worker,
        [name]: value,
      });
      setErrors((prev) => ({ ...prev, [name]: '' }));
    } catch (error) {
      setErrors((prev) => ({ ...prev, [name]: error.message }));
    }
  };

  const handleBlur = (e) => {
    let { name, value } = e.target;
    validateField(name, value);
  };

  useEffect(() => {
    const fetchSkills = async () => {
      try {
        const response = await axios.get('https://fix.house/api/skills');
        setSkills(response.data);
      } catch (error) {
        console.error('Ошибка при загрузке навыков:', error);
      }
    };
    fetchSkills();
  }, []);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setWorker((prevState) => ({ ...prevState, [id]: value }));
  };

  const handleSkillsChange = (e) => {
    const { value, checked } = e.target;
    setSelectedSkills((prev) =>
      checked ? [...prev, value] : prev.filter((skillId) => skillId !== value)
    );
  };

  const handlePhotoChange = (e) => {
    setPhoto(e.target.files[0]);
  };

  const handleLanguageChange = (event) => {
    const { value } = event.target;
    if (selectedLanguages.includes(value)) {
      setSelectedLanguages(selectedLanguages.filter((lang) => lang !== value));
    } else {
      setSelectedLanguages([...selectedLanguages, value]);
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();

    try {
      await getHouseValidationSchema(t).validate(worker, {
        abortEarly: false,
      });
    } catch (error) {
      if (error.name === 'ValidationError') {
        const newErrors = {};
        error.inner.forEach((err) => {
          newErrors[err.path] = err.message;
        });
        setErrors(newErrors);
      }
    }

    if (handleFormErrors()) {
      return;
    }

    if (!selectedLanguages.length) {
      setError('Выберите хотя бы один язык.');
      return;
    }

    if (!selectedSkills.length) {
      setError('Выберите хотя бы один навык.');
      return;
    }

    setError(''); // Очистить сообщения об ошибках, если все проверки пройдены

    const formData = new FormData();
    Object.keys(worker).forEach((key) => formData.append(key, worker[key]));
    formData.append('skills', JSON.stringify(selectedSkills));
    if (photo) {
      formData.append('photo', photo);
    }

    try {
      const response = await axios.post(
        'https://fix.house/api/workers',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.data.worker && response.data.worker._id) {
        tokenService.saveToken(response.data.token);
        tokenService.saveRole('worker');
        tokenService.saveWorkerId(response.data.worker._id);
        navigate(`/worker/${response.data.worker._id}`);
      }
    } catch (error) {
      console.error(
        'Ошибка при регистрации работника:',
        error.response ? error.response.data : error
      );
    }
  };

  return (
    <div className={styles.container}>
      <LanguageSwitcher />
      <h2 className={styles.heading}>{t('worker_registration_title')}</h2>

      <form
        onSubmit={submitForm}
        encType='multipart/form-data'
        className={styles.form}
      >
        <input
          type='text'
          placeholder={t('name')}
          id='name'
          value={worker.name}
          onChange={handleInputChange}
          required
          className={styles.inputField}
          onBlur={handleBlur}
          name='name'
        />
        {errors.name && (
          <div className={styles.errorMessage}>{errors.name}</div>
        )}
        <input
          type='text'
          placeholder={t('phone')}
          id='phone'
          value={worker.phone}
          onChange={handleInputChange}
          required
          className={styles.inputField}
          onBlur={handleBlur}
          name='phone'
        />
        {errors.phone && (
          <div className={styles.errorMessage}>{errors.phone}</div>
        )}
        <input
          type='email'
          placeholder={t('email')}
          id='email'
          value={worker.email}
          onChange={handleInputChange}
          required
          className={styles.inputField}
          onBlur={handleBlur}
          name='email'
        />
        {errors.email && (
          <div className={styles.errorMessage}>{errors.email}</div>
        )}

        {error && <p className={styles.error}>{error}</p>}
        <div className={styles.checkboxContainer}>
          {languages.map((language) => (
            <div key={language.id}>
              <input
                type='checkbox'
                id={`lang-${language.id}`}
                value={language.id}
                onChange={handleLanguageChange}
                checked={selectedLanguages.includes(language.id)}
                className={styles.checkboxInput}
              />
              <label
                htmlFor={`lang-${language.id}`}
                className={styles.checkboxLabel}
              >
                {language.name}
              </label>
            </div>
          ))}
        </div>

        <div className={styles.checkboxContainer}>
          {skills.map((skill) => (
            <div key={skill._id}>
              <input
                type='checkbox'
                id={`skill-${skill._id}`}
                value={skill._id}
                onChange={handleSkillsChange}
                checked={selectedSkills.includes(skill._id)}
                className={styles.checkboxInput}
                name='skills'
              />
              <label
                htmlFor={`skill-${skill._id}`}
                className={styles.checkboxLabel}
              >
                {skill.skillName}
              </label>
            </div>
          ))}
        </div>
        <input
          type='file'
          onChange={handlePhotoChange}
          className={styles.fileInput}
        />
        <button type='submit' className={styles.submitButton}>
          {t('register')}
        </button>
      </form>
    </div>
  );
};

export default WorkerRegistrationForm;
