import React from 'react';
import CRUDSubscriptionsAdmin from './admin/CRUDSubscriptionsAdmin';

const ManageSubscriptionsPage = () => {
  return (
    <div>
      <h1>Страница управления типами подписок</h1>
      <CRUDSubscriptionsAdmin />
    </div>
  );
};

export default ManageSubscriptionsPage;
