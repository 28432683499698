import React from 'react';
import styles from './PhotoModal.module.scss';
import { Flex, IconButton, Modal } from '../../ui';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { CloseIcon } from '../../static';

const PhotoModal = ({ photoModal, closePhotoModal }) => {
  return (
    <Modal closeModal={closePhotoModal} className={styles.Modal}>
      <Flex direction='column' justify='center'>
        <Flex justify='end' align='baseline'>
          <IconButton type='transparent' size='m' onClick={closePhotoModal}>
            <CloseIcon />
          </IconButton>
        </Flex>
        <TransformWrapper
          initialScale={1.3}
          minScale={1}
          maxScale={10}
          centerOnInit={true}
          centerZoomedOut={true}
          disablePadding={true}
        >
          <TransformComponent
            wrapperStyle={{
              maxWidth: '100%',
              height: '100%',
              maxHeight: '90vh',
            }}
          >
            <img
              src={
                photoModal?.includes('https://fix.house/')
                  ? photoModal
                  : 'https://fix.house/' + photoModal
              }
              alt='Фото проблемы'
              style={{ maxWidth: '100%' }}
            />
          </TransformComponent>
        </TransformWrapper>
      </Flex>
    </Modal>
  );
};

export default PhotoModal;
