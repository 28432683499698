import { useMediaQuery } from 'react-responsive';
import cn from 'classnames';

import { desktopQuery } from '../../consts';
import { getProblemImageUrl } from '../../utilities';
import { Modal, Flex, Button, IconButton, Text, Bar } from '../../ui';
import { CloseIcon, CalendarAddIcon } from '../../static/icons';
import styles from './ViewProblemModal.module.scss';
import { useTranslation } from 'react-i18next';

export const ViewProblemModal = (props) => {
  const { closeModal, problem, getSkillNameById, editProblem, deleteProblem } =
    props;

  const isDesktop = useMediaQuery(desktopQuery);
  const { t } = useTranslation();

  return (
    <Modal closeModal={closeModal} className={styles.Modal}>
      {/* {isDesktop ? (
        null
      ) : ( */}
      <Flex direction='column' gap={30}>
        <Flex justify='between' align='baseline'>
          <Text size={20} lineHeight='25px' weight='bold'>
            {t('edit_urgent_problems')}
          </Text>

          <IconButton type='transparent' size='m' onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </Flex>

        <div className={styles.problemImageContainer}>
          <img
            className={styles.problemImage}
            src={getProblemImageUrl(problem.initialPhotoID?.imageURL)}
            alt={problem.description}
          />
          <Text
            className={cn(styles.problemBubble, styles.problemBubbleBlack)}
            size={isDesktop ? 10 : 14}
            weight={isDesktop ? 'bold' : 'semiBold'}
            lineHeight={isDesktop ? 1 : 1.2}
            color='white'
          >
            {problem.urgencyLevel}
          </Text>
        </div>

        <Flex justify='between' align='center' className={styles.fullWidth}>
          <Text
            tag='h3'
            weight='bold'
            size={24}
            className={styles.problemTitle}
          >
            {problem.description}
          </Text>

          <Flex gap={5} className={styles.problemDate} align='center'>
            <CalendarAddIcon width={14} heigh={14} />
            <Text size={12} weight='medium' className={styles.shiftedText}>
              {new Date(problem.creationDate).toLocaleDateString()}
            </Text>
          </Flex>
        </Flex>

        <Flex direction='column' gap={16}>
          <Bar>
            <Flex justify='between'>
              <Text>{t('status')}:</Text>
              <Text size={17} weight='medium'>
                {problem.status}
              </Text>
            </Flex>
          </Bar>

          <Bar>
            <Flex justify='between'>
              <Text>{t('edit_problem_type')}:</Text>
              <Text size={17} weight='medium'>
                {getSkillNameById(problem.problemType)}
              </Text>
            </Flex>
          </Bar>

          <Bar>
            <Flex justify='between'>
              <Text>{t('time')}:</Text>
              <Text size={17} weight='medium'>
                {problem.preferredTime || 'Не указано'}
              </Text>
            </Flex>
          </Bar>
        </Flex>

        {problem?.status === 'Open' && (
          <Flex justify='between'>
            <Button type='black' onClick={editProblem}>
              {t('edit_problem')}
            </Button>
            <Button type='text' onClick={deleteProblem}>
              <Text size={14} weight='bold'>
                {t('delete_problem')}
              </Text>
            </Button>
          </Flex>
        )}
      </Flex>
      {/* )} */}
    </Modal>
  );
};
