import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { tokenService } from '../services/tokenService';
import {
  HomePagePromoBlock,
  HomePageBackgroundBlock,
  HomePagePricingBlock,
  HomePageGuideBlock,
  HomePageEndingBlock,
  HomePageFeaturesBlock,
} from '../features';

function HomePage() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userId, setUserId] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const token = tokenService.getToken();
      setIsAuthenticated(!!token);
      if (token) {
        const userId = await decodeToken();
        if (userId) {
          setUserId(userId);
        }
      }
    };

    fetchData();
  }, []);

  const decodeToken = async () => {
    try {
      const token = tokenService.getToken();
      if (!token) {
        console.error('Token not found');
        return null;
      }

      const base64Url = token.split('.')[1];
      if (!base64Url) {
        console.error('Invalid token format');
        return null;
      }

      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );

      const decoded = JSON.parse(jsonPayload);
      if (!decoded || !decoded._id) {
        console.error('Invalid token: no _id found');
        return null;
      }

      return decoded._id;
    } catch (error) {
      console.error('Ошибка при декодировании токена:', error);
      return null;
    }
  };

  useEffect(() => {
    if (location.hash) {
      // Задержка, чтобы дать время React отрендерить компоненты
      setTimeout(() => {
        const id = location.hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 100); // Небольшая задержка в 100мс
    }
  }, [location.hash]);

  const handleSubscriptionPurchase = (subscriptionType) => {
    if (isAuthenticated) {
      navigate('/payment', { state: { userId, subscriptionType } });
    } else {
      navigate('/client-register', {
        state: { from: location, subscriptionType },
      });
    }
  };

  return (
    <>
      <HomePagePromoBlock isAuthenticated={isAuthenticated} />
      <HomePageBackgroundBlock>
        <HomePageFeaturesBlock />
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <HomePageGuideBlock />

        <HomePagePricingBlock
          onSubscriptionPurchase={handleSubscriptionPurchase}
          userId={userId}
          sectionId='pricing'
        />
      </HomePageBackgroundBlock>
      <HomePageEndingBlock isAuthenticated={isAuthenticated} />
    </>
  );
}

export default HomePage;
