import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { desktopQuery } from '../../consts';
import { Modal, Flex, Button, IconButton, Text, Input, Select } from '../../ui';
import { CameraIcon, CloseIcon, FolderIcon } from '../../static/icons';
import styles from './AddProblemModal.module.scss';
import { useTranslation } from 'react-i18next';
import { getHouseValidationSchema } from '../../components/validationSchema';

const SubscriptionWarningModal = ({ isOpen, onClose, errorMessage, t }) => {
  console.log('SubscriptionWarningModal render', { isOpen, errorMessage });

  if (!isOpen) {
    console.log('SubscriptionWarningModal not rendered (isOpen is false)');
    return null;
  }

  return (
    <div className={styles.warningModalOverlay}>
      <div className={styles.warningModalContent}>
        <Text size={24} className={styles.noSubscriptionsWarning} weight='bold'>
          {errorMessage || t('no_sub_step_1')}{' '}
          <Link to='/#pricing' className={styles.linkStyle}>
            {t('no_sub_step_2')}
          </Link>
          {t('no_sub_step_3')}
        </Text>
        <Button
          type='black'
          onClick={() => {
            console.log('Closing SubscriptionWarningModal');
            onClose();
          }}
        >
          {t('close')}
        </Button>
      </div>
    </div>
  );
};

export const AddProblemModal = (props) => {
  const {
    closeModal,
    title,
    newProblem,
    setNewProblem,
    selectedPhoto,
    handleProblemPhotoUpload,
    skills,
    handleAddProblem,
    formattedMinDate,
    scheduledDate,
    setScheduledDate,
  } = props;

  const isDesktop = useMediaQuery(desktopQuery);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [activeUrgency, setActiveUrgency] = useState('Urgent');
  const [newUrgentProblem, setNewUrgentProblem] = useState({});
  const [newPlannedProblem, setNewPlannedProblem] = useState({});
  const [newProblemPhoto, setNewProblemPhoto] = useState(null);
  const { t } = useTranslation();

  const [errors, setErrors] = useState({});

  const validateField = async (name, value) => {
    try {
      await getHouseValidationSchema(t).validateAt(name, {
        ...{
          problemDescription: newProblem,
        },
        [name]: value,
      });
      setErrors((prev) => ({ ...prev, [name]: '' }));
    } catch (error) {
      setErrors((prev) => ({ ...prev, [name]: error.message }));
    }
  };

  const handleBlur = (e) => {
    let { name, value } = e.target;
    validateField(name, value);
  };

  const handleFormErrors = () => {
    let hasError = false;
    Object.keys(errors).forEach((key) => {
      if (errors[key] !== '' && key == 'problemDescription') {
        hasError = true;
      }
    });
    return hasError;
  };

  const handleAddProblemWithErrorCheck = async () => {
    try {
      console.log('Attempting to add problem');
      try {
        await getHouseValidationSchema(t).validate(
          { problemDescription: newProblem.description },
          {
            abortEarly: false,
          }
        );
      } catch (error) {
        if (error.name === 'ValidationError') {
          const newErrors = {};
          error.inner.forEach((err) => {
            newErrors[err.path] = err.message;
          });
          setErrors(newErrors);
        }
      }

      if (handleFormErrors()) {
        return;
      }

      const response = await handleAddProblem();
      if (response && response.status >= 200 && response.status < 300) {
        console.log('Problem added successfully');
        closeModal();
      } else {
        throw new Error('Failed to add problem');
      }
    } catch (error) {
      console.log('Error caught:', error);
      if (error.response && error.response.status === 400) {
        console.log('Received 400 error:', error.response.data);
        setErrorMessage(
          error.response.data.message || 'У пользователя нет активной подписки'
        );
      } else {
        console.error('Error adding problem:', error);
        setErrorMessage('Произошла ошибка при добавлении проблемы');
      }
      setIsWarningModalOpen(true);
    }
  };

  return (
    <>
      <Modal closeModal={closeModal} className={styles.Modal}>
        <Flex direction='column' gap={30}>
          <Flex justify='between' align='baseline'>
            <Text size={20} lineHeight='25px' weight='bold' tag='h2'>
              {title}
            </Text>
            <IconButton type='transparent' size='m' onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Flex>
          <Flex direction='column' gap={16}>
            <Flex justify='between'>
              <label>
                <Flex gap={5} align='center'>
                  <FolderIcon />
                  <Text className={styles.shiftedText} weight='medium'>
                    {t('select_photo')}
                  </Text>
                </Flex>
                <input
                  type='file'
                  onChange={handleProblemPhotoUpload}
                  accept='image/*'
                  className={styles.displayNone}
                />
              </label>
              <label>
                <Flex gap={5} align='center'>
                  <CameraIcon />
                  <Text className={styles.shiftedText} weight='medium'>
                    {t('take_photo')}
                  </Text>
                </Flex>
                <input
                  type='file'
                  onChange={handleProblemPhotoUpload}
                  accept='image/*'
                  className={styles.displayNone}
                />
              </label>
            </Flex>
            {selectedPhoto && (
              <img
                className={styles.previewProblemImage}
                src={selectedPhoto}
                alt='Изображение проблемы'
              />
            )}
          </Flex>
          <Flex direction='column' gap={16}>
            <Input
              label={t('description_problem') + ':'}
              value={newProblem.description}
              onChange={(value) =>
                setNewProblem({
                  ...newProblem,
                  description: value,
                })
              }
              onBlur={handleBlur}
              name='problemDescription'
            />
            {errors.problemDescription && (
              <div className={styles.errorMessage}>
                {errors.problemDescription}
              </div>
            )}
            <Select
              label={t('problem_type2') + ':'}
              onChange={(value) =>
                setNewProblem({
                  ...newProblem,
                  problemType: value,
                })
              }
              value={newProblem.problemType}
              options={skills?.map((skill) => ({
                value: skill._id,
                label: skill.skillName,
              }))}
            />
            <Select
              label={t('time_range') + ':'}
              onChange={(value) =>
                setNewProblem({
                  ...newProblem,
                  timeRange: value,
                })
              }
              value={newProblem.timeRange}
              options={[
                { value: '9:00--12:00' },
                { value: '12:00--15:00' },
                { value: '15:00--18:00' },
                { value: '18:00--21:00' },
              ]}
            />
            {formattedMinDate && scheduledDate && setScheduledDate && (
              <Input
                label={t('scheduled_date') + ':'}
                type='date'
                min={formattedMinDate}
                value={scheduledDate}
                onChange={(value) => setScheduledDate(value)}
              />
            )}
          </Flex>
          <Button type='black' onClick={handleAddProblemWithErrorCheck}>
            {t('add_problem')}
          </Button>
        </Flex>
      </Modal>
      <SubscriptionWarningModal
        isOpen={isWarningModalOpen}
        onClose={() => setIsWarningModalOpen(false)}
        errorMessage={errorMessage}
        t={t}
      />
    </>
  );
};
