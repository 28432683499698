import React from 'react';
import { Flex, Text } from '../../ui';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './WorkerMobileLinks.module.scss';

const WorkerMobileLinks = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.mobileLinks}>
      <Flex tag='ul' direction='column'>
        <li>
          <Link to='/worker-dash'>
            <Text weight='medium' size={14}>
              {t('dashboard')}{' '}
            </Text>
          </Link>
        </li>
        <li>
          <Link to='/worker-profile'>
            <Text weight='medium' size={14}>
              {t('profile')}{' '}
            </Text>
          </Link>
        </li>
        <li>
          <Link to='/worker-job'>
            <Text weight='medium' size={14}>
              {t('my_jobs')}
            </Text>
          </Link>
        </li>

        <li>
          <Link to='/worker-calendar'>
            <Text weight='medium' size={14}>
              {t('work_calendar')}
            </Text>
          </Link>
        </li>
        <li>
          <Link to='/manager-contacts'>
            <Text weight='medium' size={14}>
              {t('managers_contacts')}
            </Text>
          </Link>
        </li>
        <li>
          <Link to='/worker-settings'>
            <Text weight='medium' size={14}>
              {t('settings')}
            </Text>
          </Link>
        </li>
      </Flex>
    </div>
  );
};

export default WorkerMobileLinks;
