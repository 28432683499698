import { useTranslation } from 'react-i18next';
import { Modal, Flex, Text, Button } from '../../ui';
import styles from './DeleteProblemModal.module.scss';

export const DeleteProblemModal = (props) => {
  const { closeModal, handleDelete } = props;
  const { t } = useTranslation();

  return (
    <Modal className={styles.deleteProblemModal} closeModal={closeModal}>
      <Flex direction='column' align='center' gap={30}>
        <Flex direction='column' align='center' gap={10}>
          <Text size={36} align='center' weight='medium'>
            {t('problem_deletion')}
          </Text>

          <Text size={18} lineHeight='28px' align='center'>
            {t('delete_problem_text')}
          </Text>
        </Flex>

        <Flex justify='center' gap={20}>
          <Button
            type='gray'
            onClick={closeModal}
            className={styles.deleteProblemModalButton}
          >
            {t('cancel')}
          </Button>
          <Button
            type='black'
            onClick={handleDelete}
            className={styles.deleteProblemModalButton}
          >
            {t('delete')}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
