import React, { useState } from 'react';
import axios from 'axios';
import { tokenService } from '../services/tokenService'; 



//import RatingComponent from '../components/RatingComponent';


const RatingComponent = ({ problem, onRatingSubmit }) => {
  const [rating, setRating] = useState(problem.rating ? problem.rating.value : 0);

  const handleRatingChange = async (newRating) => {
    setRating(newRating);

    try {
      const token = tokenService.getToken();
      if (!token) {
        console.error('Аутентификационный токен не найден');
        return;
      }

      const response = await axios.patch(
        `https://fix.house/api/problems/${problem._id}/rating`,
        { rating: newRating },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        onRatingSubmit(newRating);
      }
    } catch (error) {
      console.error('Ошибка при сохранении рейтинга:', error);
    }
  };

  return (
    <div>
      {[1, 2, 3, 4, 5].map((star) => (
        <span
          key={star}
          onClick={() => handleRatingChange(star)}
          style={{ cursor: 'pointer', color: star <= rating ? 'gold' : 'gray' }}
        >
          ★
        </span>
      ))}
    </div>
  );
};

export default RatingComponent;