import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { tokenService } from './../../services/tokenService';
import markerIconUrl from './../img/marker-icon.png';
import markerShadowUrl from './../img/marker-shadow.png';
import markerIconActiveUrl from './../img/marker-icon.png';
import markerIconAllUrl from './../img/marker-icon.png';
import L from 'leaflet';
import axios from 'axios';

import AdminPanel from './AdminPanel';
import styles from './WorkerDetails.module.css';
import { FooterBlock } from '../../features';
import { Flex, IconButton, Input, Modal, Text } from '../../ui';
import PenIcon from './../../static/icons/pen.svg';
import Star from './../../static/icons/ic_round-star.svg';
import StarGray from './../../static/icons/ic_round-star-gray.svg';
import { CloseIcon } from '../../static';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../LanguageSwitcher';
import PhotoModal from '../../features/PhotoModal/PhotoModal';
import AdminMobileLinks from '../../features_admin/AdminMobileLinks/AdminMobileLinks';
import { useMediaQuery } from 'react-responsive';
import { desktopQuery } from '../../consts';

const WorkerDetails = () => {
  const { workerId } = useParams();
  const [worker, setWorker] = useState(null);
  const [histories, setHistories] = useState([]);
  const [problemsByWorker, setProblemsByWorker] = useState({});
  const token = tokenService.getToken();
  const [files, setFiles] = useState({});
  const [previewUrls, setPreviewUrls] = useState({});
  const [editFormData, setEditFormData] = useState({
    name: '',
    phone: '',
    email: '',
    salary: '',
  });
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [photoModal, setPhotoModal] = useState(null);
  const [openPhotoModal, setOpenPhotoModal] = useState(false);
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(desktopQuery);
  const navigate = useNavigate();
  const handleEditModalOpen = () => setEditModalOpen(true);

  const handleEditModalClose = () => setEditModalOpen(false);
  const handleOpenPhotoModal = (photo) => {
    setPhotoModal(photo);
    setOpenPhotoModal(true);
  };
  const handleClosePhotoModal = () => {
    setOpenPhotoModal(false);
  };

  const customMarkerIcon = L.icon({
    iconUrl: markerIconUrl,
    shadowUrl: markerShadowUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  const customMarkerIconActive = L.icon({
    iconUrl: markerIconActiveUrl,
    shadowUrl: markerShadowUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  const customMarkerIconAll = L.icon({
    iconUrl: markerIconAllUrl,
    shadowUrl: markerShadowUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  useEffect(() => {
    const fetchWorkerDetails = async () => {
      try {
        const workerResponse = await axios.get(
          `https://fix.house/api/workers/${workerId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setWorker(workerResponse.data);
      } catch (error) {
        console.error('Ошибка при получении данных о работнике:', error);
      }

      try {
        const historyResponse = await axios.get(
          `https://fix.house/api/workers/${workerId}/history`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setHistories(historyResponse.data);
      } catch (error) {
        console.error('Ошибка при получении истории работника:', error);
      }
    };

    const fetchProblems = async () => {
      try {
        const problemsResponse = await axios.get(
          'https://fix.house/api/problems/problemsAll',
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const problems = problemsResponse.data;
        const groupedProblems = problems.reduce((acc, problem) => {
          const workerId = problem.workerID
            ? problem.workerID._id
            : 'unassigned';
          if (!acc[workerId]) {
            acc[workerId] = [];
          }
          acc[workerId].push(problem);
          return acc;
        }, {});
        setProblemsByWorker(groupedProblems);
      } catch (error) {
        console.error('Ошибка при получении данных о проблемах:', error);
      }
    };

    fetchWorkerDetails();
    fetchProblems();
  }, [workerId, token]);

  const problems = histories
    .map((history) => history.problemId)
    .filter((problem) => problem);
  const currentProblems = problems.filter(
    (problem) => problem.status !== 'Finished'
  );
  const finishedProblems = problems.filter(
    (problem) => problem.status === 'Finished'
  );
  const uniqueCurrentProblems = [
    ...new Map(
      currentProblems.map((problem) => [problem._id, problem])
    ).values(),
  ];

  const getImageUrl = (imagePath) => {
    return imagePath
      ? `https://fix.house/${imagePath}`
      : '/path/to/default/image.jpg';
  };

  const handleFileChange = (problemId, index, event) => {
    const file = event.target.files[0];
    const newFiles = { ...files };
    newFiles[problemId] = newFiles[problemId] || [];
    newFiles[problemId][index] = file;

    const newPreviewUrls = { ...previewUrls };
    if (file) {
      newPreviewUrls[problemId] = newPreviewUrls[problemId] || [];
      newPreviewUrls[problemId][index] = URL.createObjectURL(file);
    }

    setFiles(newFiles);
    setPreviewUrls(newPreviewUrls);
  };

  const finishWork = async (problemId) => {
    const problemFiles = files[problemId];
    if (
      !problemFiles ||
      problemFiles.length < 2 ||
      !problemFiles[0] ||
      !problemFiles[1]
    ) {
      alert('Необходимо выбрать минимум 2 фотографии');
      return;
    }
  };

  const handleCancelEdit = () => {
    setEditFormData({
      name: worker.name || '',
      phone: worker.phone || '',
      email: worker.email || '',
      messenger: worker.messenger || '',
      availableTimeDuration: worker.availableTimeDuration || '',
      preferredLanguage: worker.preferredLanguage || '',
    });
    setEditModalOpen(false);
  };

  const handleLogout = () => {
    tokenService.removeToken();
    navigate('/client-register');
  };

  return (
    <div>
      <AdminPanel handleLogout={handleLogout} />
      <LanguageSwitcher />
      <div className={styles.workerDetailsContainer}>
        {!isDesktop && (
          <div className={styles.blockContent}>
            <AdminMobileLinks />
          </div>
        )}
        {worker ? (
          <>
            <div className={styles.blockContent}>
              <div className={styles.workerInfoBox}>
                <Flex
                  justify='between'
                  align='start'
                  className={styles.workerContent}
                >
                  <Flex className={styles.workerContentBlock}>
                    <div>
                      <p className={styles.info}>
                        <span className={styles.label}>{t('name')}:</span>
                      </p>
                      <h1>{worker.name}</h1>
                      <Flex gap={2} align='center'>
                        <img src={Star} alt='star' />
                        <img src={Star} alt='star' />
                        <img src={Star} alt='star' />
                        <img src={Star} alt='star' />
                        <img src={StarGray} alt='star' />
                        <span>(4.5)</span>
                      </Flex>
                    </div>
                    <div>
                      <p className={styles.info}>
                        <span className={styles.label}>{t('phone')}:</span>{' '}
                        {worker.phone}
                      </p>
                      <p className={styles.info}>
                        <span className={styles.label}>{t('email')}:</span>{' '}
                        {worker.email}
                      </p>
                      <p className={styles.info}>
                        <span className={styles.label}>{t('role')}:</span>{' '}
                        {worker.role}
                      </p>
                      <p className={styles.info}>
                        <span className={styles.label}>
                          {t('preferred_language')}:{' '}
                        </span>{' '}
                        {worker.preferredLanguage}
                      </p>
                    </div>
                  </Flex>
                  <button
                    className={styles.editButton}
                    onClick={handleEditModalOpen}
                  >
                    <img src={PenIcon} alt='edit' />
                    <span>{t('edit')}</span>
                  </button>
                </Flex>
              </div>
              <div className={styles.workerProblems}>
                <strong className={styles.sectionTitle}>
                  {t('current_problems')}:
                </strong>
                <div className={styles.currentProblems}>
                  {problemsByWorker[workerId] &&
                  problemsByWorker[workerId].filter(
                    (problem) => problem.status !== 'Finished'
                  ).length > 0 ? (
                    problemsByWorker[workerId]
                      .filter((problem) => problem.status !== 'Finished')
                      .map((problem) => (
                        <div
                          key={problem._id}
                          className={styles.problemDetails}
                        >
                          <Flex gap={20} className={styles.cardBlock}>
                            {problem.initialPhotoID && (
                              <img
                                src={getImageUrl(
                                  problem.initialPhotoID.imageURL
                                )}
                                alt='Фото проблемы'
                                className={styles.problemImage}
                                onClick={() =>
                                  handleOpenPhotoModal(
                                    problem.initialPhotoID.imageURL
                                  )
                                }
                              />
                            )}
                            <Flex direction='column'>
                              {problem.userId && (
                                <>
                                  <p className={styles.problemInfo}>
                                    <span className={styles.infoLabel}>
                                      {t('username')}:
                                    </span>{' '}
                                    {problem.userId.name}
                                  </p>
                                  <p className={styles.problemInfo}>
                                    <span className={styles.infoLabel}>
                                      {t('email')}:
                                    </span>{' '}
                                    {problem.userId.email}
                                  </p>
                                  <p className={styles.problemInfo}>
                                    <span className={styles.infoLabel}>
                                      {t('phone')}:
                                    </span>{' '}
                                    {problem.userId.phone}
                                  </p>
                                  <p className={styles.problemInfo}>
                                    <span className={styles.infoLabel}>
                                      {t('preferred_language')}:
                                    </span>{' '}
                                    {problem.userId.preferredLanguage}
                                  </p>
                                </>
                              )}
                            </Flex>
                          </Flex>
                          <p className={styles.problemInfo}>
                            <span className={styles.infoLabel}>
                              {t('description')}:
                            </span>{' '}
                            {problem.description}
                          </p>
                          <p className={styles.problemInfo}>
                            <span className={styles.infoLabel}>
                              {t('urgency_level')}:
                            </span>{' '}
                            {problem.urgencyLevel}
                          </p>
                          <p className={styles.problemInfo}>
                            <span className={styles.infoLabel}>
                              {t('status')}:
                            </span>{' '}
                            {problem.status}
                          </p>
                          <p className={styles.problemInfo}>
                            <span className={styles.infoLabel}>
                              {t('creation_date')}:
                            </span>{' '}
                            {new Date(
                              problem.creationDate
                            ).toLocaleDateString()}
                          </p>
                          <p className={styles.problemInfo}>
                            <span className={styles.infoLabel}>
                              {t('preferred_time')}:
                            </span>{' '}
                            {problem.preferredTime || t('not_specified')}
                          </p>
                          <p className={styles.problemInfo}>
                            <span className={styles.infoLabel}>
                              {t('scheduled_date')}:
                            </span>{' '}
                            {problem.scheduledDate
                              ? new Date(
                                  problem.scheduledDate
                                ).toLocaleDateString()
                              : t('not_specified')}
                          </p>
                          {problem.houseId && (
                            <>
                              <p className={styles.problemInfo}>
                                <span className={styles.infoLabel}>
                                  {t('address')}:{' '}
                                </span>{' '}
                                {problem.houseId.address}
                              </p>
                              <p className={styles.problemInfo}>
                                <span className={styles.infoLabel}>
                                  {t('area')}:
                                </span>
                                {problem.houseId.squareMeters} {t('sq_m')}
                              </p>
                              {problem.houseId.image && (
                                <img
                                  src={getImageUrl(problem.houseId.image)}
                                  alt='Изображение дома'
                                  className={styles.houseImage}
                                  onClick={() =>
                                    handleOpenPhotoModal(problem.houseId.image)
                                  }
                                />
                              )}
                              <div className={styles.mapContainer}>
                                <MapContainer
                                  center={[
                                    problem.houseId.mapLocationCoordinates
                                      .coordinates[1],
                                    problem.houseId.mapLocationCoordinates
                                      .coordinates[0],
                                  ]}
                                  zoom={13}
                                  scrollWheelZoom={false}
                                  style={{ height: 200, width: '100%' }}
                                >
                                  <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
                                  <Marker
                                    position={[
                                      problem.houseId.mapLocationCoordinates
                                        .coordinates[1],
                                      problem.houseId.mapLocationCoordinates
                                        .coordinates[0],
                                    ]}
                                    icon={customMarkerIcon}
                                  ></Marker>
                                </MapContainer>
                              </div>
                            </>
                          )}
                          <input
                            type='file'
                            id={`finishedPhotos-${problem._id}`}
                            accept='image/*'
                            // style={{ display: 'none' }}
                            onChange={(e) =>
                              handleFileChange(problem._id, 0, e)
                            }
                          />
                          {/* <label
                            htmlFor={`finishedPhotos-${problem._id}`}
                            className={styles.uploadButton}
                          >
                            Загрузить фото завершенной работы
                          </label> */}
                          {previewUrls[problem._id] &&
                            previewUrls[problem._id][0] && (
                              <img
                                src={previewUrls[problem._id][0]}
                                alt='Preview'
                                className={styles.previewImage}
                              />
                            )}
                          <input
                            type='file'
                            id={`finishedPhotos2-${problem._id}`}
                            accept='image/*'
                            // style={{ display: 'none' }}
                            onChange={(e) =>
                              handleFileChange(problem._id, 1, e)
                            }
                          />
                          {/* <label
                            htmlFor={`finishedPhotos2-${problem._id}`}
                            className={styles.uploadButton}
                          >
                            Загрузить фото завершенной работы
                          </label> */}
                          {previewUrls[problem._id] &&
                            previewUrls[problem._id][1] && (
                              <img
                                src={previewUrls[problem._id][1]}
                                alt='Preview'
                                className={styles.previewImage}
                              />
                            )}
                          <Flex gap={5}>
                            <button
                              className={styles.deleteButton}
                              onClick={() => {}}
                            >
                              {t('decline')}
                            </button>
                            <button
                              id={`finishButton-${problem._id}`}
                              className={styles.finishButton}
                              onClick={() => {}}
                            >
                              {t('finish_work')}
                            </button>
                          </Flex>
                        </div>
                      ))
                  ) : (
                    <p className={styles.noProblem}>
                      {t('no_current_problems')}.
                    </p>
                  )}
                </div>

                <strong className={styles.sectionTitle}>
                  {t('completed_problems')}:
                </strong>
                <div className={styles.finishedProblems}>
                  {problemsByWorker[workerId] &&
                  problemsByWorker[workerId].filter(
                    (problem) => problem.status === 'Finished'
                  ).length > 0 ? (
                    problemsByWorker[workerId]
                      .filter((problem) => problem.status === 'Finished')
                      .map((problem) => (
                        <div
                          key={problem._id}
                          className={styles.problemDetails}
                        >
                          <Flex gap={20} className={styles.cardBlock}>
                            {problem.initialPhotoID && (
                              <img
                                src={getImageUrl(
                                  problem.initialPhotoID.imageURL
                                )}
                                alt='Фото проблемы'
                                className={styles.problemImage}
                                onClick={() =>
                                  handleOpenPhotoModal(
                                    problem.initialPhotoID.imageURL
                                  )
                                }
                              />
                            )}
                            <Flex direction='column'>
                              {problem.userId && (
                                <>
                                  <p className={styles.problemInfo}>
                                    <span className={styles.infoLabel}>
                                      {t('username')}:
                                    </span>{' '}
                                    {problem.userId.name}
                                  </p>
                                  <p className={styles.problemInfo}>
                                    <span className={styles.infoLabel}>
                                      {t('email')}:
                                    </span>{' '}
                                    {problem.userId.email}
                                  </p>
                                  <p className={styles.problemInfo}>
                                    <span className={styles.infoLabel}>
                                      {t('phone')}:
                                    </span>{' '}
                                    {problem.userId.phone}
                                  </p>
                                  <p className={styles.problemInfo}>
                                    <span className={styles.infoLabel}>
                                      {t('preferred_language')}:
                                    </span>{' '}
                                    {problem.userId.preferredLanguage}
                                  </p>
                                </>
                              )}
                            </Flex>
                          </Flex>
                          <p className={styles.problemInfo}>
                            <span className={styles.infoLabel}>
                              {t('description')}:
                            </span>{' '}
                            {problem.description}
                          </p>
                          <p className={styles.problemInfo}>
                            <span className={styles.infoLabel}>
                              {t('urgency_level')}:{' '}
                            </span>{' '}
                            {problem.urgencyLevel}
                          </p>
                          <p className={styles.problemInfo}>
                            <span className={styles.infoLabel}>
                              {t('status')}:
                            </span>{' '}
                            {problem.status}
                          </p>
                          <p className={styles.problemInfo}>
                            <span className={styles.infoLabel}>
                              {t('creation_date')}:{' '}
                            </span>{' '}
                            {new Date(
                              problem.creationDate
                            ).toLocaleDateString()}
                          </p>
                          {problem.houseId && (
                            <>
                              <p className={styles.problemInfo}>
                                <span className={styles.infoLabel}>
                                  {t('address')}:
                                </span>{' '}
                                {problem.houseId.address}
                              </p>
                              <p className={styles.problemInfo}>
                                <span className={styles.infoLabel}>
                                  {t('area')}:
                                </span>
                                {problem.houseId.squareMeters} кв. м
                              </p>
                              {problem.houseId.image && (
                                <img
                                  src={getImageUrl(problem.houseId.image)}
                                  alt='Изображение дома'
                                  className={styles.houseImage}
                                  onClick={() =>
                                    handleOpenPhotoModal(problem.houseId.image)
                                  }
                                />
                              )}
                              <div className={styles.mapContainer}>
                                <MapContainer
                                  center={[
                                    problem.houseId.mapLocationCoordinates
                                      .coordinates[1],
                                    problem.houseId.mapLocationCoordinates
                                      .coordinates[0],
                                  ]}
                                  zoom={13}
                                  scrollWheelZoom={false}
                                  style={{ height: 200, width: '100%' }}
                                >
                                  <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
                                  <Marker
                                    position={[
                                      problem.houseId.mapLocationCoordinates
                                        .coordinates[1],
                                      problem.houseId.mapLocationCoordinates
                                        .coordinates[0],
                                    ]}
                                  ></Marker>
                                </MapContainer>
                              </div>
                            </>
                          )}
                          <Flex gap={2} align='center'>
                            <img src={Star} alt='star' />
                            <img src={Star} alt='star' />
                            <img src={Star} alt='star' />
                            <img src={Star} alt='star' />
                            <img src={StarGray} alt='star' />
                            <span>(4.5)</span>
                          </Flex>
                        </div>
                      ))
                  ) : (
                    <p className={styles.noProblem}>
                      {t('no_completed_problems')}.
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className={styles.grayBg}>
              <div className={styles.blockContent}>
                <div className={styles.workerHistory}>
                  <strong className={styles.sectionTitle}>
                    {t('current_problems_history')}:
                  </strong>
                  <ul className={styles.currentProblemsHistory}>
                    {histories
                      .filter(
                        (history) =>
                          history.action === 'Assigned' ||
                          history.action === 'Unassigned'
                      )
                      .map((history, index) => (
                        <li key={index} className={styles.historyItem}>
                          <p className={styles.problemInfo}>
                            <span className={styles.infoLabel}>
                              {t('action')}:
                            </span>{' '}
                            {history.action}
                          </p>
                          <p className={styles.problemInfo}>
                            <span className={styles.infoLabel}>
                              {t('date')}:{' '}
                            </span>
                            {new Date(history.timestamp).toLocaleString()}
                          </p>
                          <div className={styles.hr}></div>
                          {history.problemId && (
                            <div>
                              <p className={styles.problemInfo}>
                                <span className={styles.infoLabel}>
                                  {t('problem_text')}:
                                </span>
                                {history.problemId.description}
                              </p>
                              <p className={styles.problemInfo}>
                                <span className={styles.infoLabel}>
                                  {t('problem_status')}:
                                </span>
                                {history.problemId.status}
                              </p>
                              <p className={styles.problemInfo}>
                                <span className={styles.infoLabel}>
                                  {t('urgency_level')}:
                                </span>{' '}
                                {history.problemId.urgencyLevel}
                              </p>
                              <p className={styles.problemInfo}>
                                <span className={styles.infoLabel}>
                                  {t('creation_date')}:
                                </span>{' '}
                                {new Date(
                                  history.problemId.creationDate
                                ).toLocaleDateString()}
                              </p>
                              {history.problemId.houseId && (
                                <>
                                  <p className={styles.problemInfo}>
                                    <span className={styles.infoLabel}>
                                      {' '}
                                      {t('address')}:
                                    </span>{' '}
                                    {history.problemId.houseId.address}
                                  </p>
                                  <p className={styles.problemInfo}>
                                    <span className={styles.infoLabel}>
                                      {t('coordinates')}:
                                    </span>{' '}
                                    {history.problemId.houseId.mapLocationCoordinates.coordinates.join(
                                      ', '
                                    )}
                                  </p>
                                  <p className={styles.problemInfo}>
                                    <span className={styles.infoLabel}>
                                      {t('area')}:
                                    </span>{' '}
                                    {history.problemId.houseId.squareMeters}{' '}
                                    {t('sq_m')}
                                  </p>
                                  {history.problemId.houseId.image && (
                                    <img
                                      src={history.problemId.houseId.image}
                                      alt='Изображение дома'
                                      className={styles.houseImage}
                                    />
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
            {openPhotoModal && (
              <PhotoModal
                photoModal={photoModal}
                closePhotoModal={handleClosePhotoModal}
              />
            )}
            {editModalOpen && (
              <Modal closeModal={handleEditModalClose} className={styles.Modal}>
                <Flex direction='column' gap={30}>
                  <Flex justify='between' align='baseline'>
                    <Text size={20} lineHeight='25px' weight='bold'>
                      {t('edit_profile')}
                    </Text>
                    <IconButton
                      type='transparent'
                      size='m'
                      onClick={handleEditModalClose}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Flex>
                  <form onSubmit={() => {}} className={styles.form}>
                    <Flex direction='column' gap={16}>
                      <Input
                        label={t('name') + ':'}
                        name='name'
                        value={editFormData.name || ''}
                        onChange={(value) =>
                          setEditFormData({ ...editFormData, name: value })
                        }
                      />
                      <Input
                        label={t('phone') + ':'}
                        name='phone'
                        value={editFormData.phone || ''}
                        onChange={(value) =>
                          setEditFormData({ ...editFormData, phone: value })
                        }
                      />
                      <Input
                        label={t('email') + ':'}
                        name='email'
                        type='email'
                        value={editFormData.email || ''}
                        onChange={(value) =>
                          setEditFormData({ ...editFormData, email: value })
                        }
                      />
                      <Input
                        label={t('preferred_language') + ':'}
                        name='preferred language'
                        value={editFormData.preferredLanguage || ''}
                        onChange={(value) =>
                          setEditFormData({
                            ...editFormData,
                            preferredLanguage: value,
                          })
                        }
                      />
                      <Flex gap={10}>
                        <button type='submit' className={styles.saveButton}>
                          {t('save')}
                        </button>
                        <button type='button' onClick={handleCancelEdit}>
                          {t('cancel')}
                        </button>
                      </Flex>
                    </Flex>
                  </form>
                </Flex>
              </Modal>
            )}
          </>
        ) : (
          <p className={styles.noWorkerInfo}>{t('worker_not_found')}.</p>
        )}
      </div>
      <FooterBlock />
    </div>
  );
};

export default WorkerDetails;
