import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import markerIconUrl from './../img/marker-icon.png';
import markerShadowUrl from './../img/marker-shadow.png';
import { tokenService } from './../../services/tokenService';

const WorkerAssignedHousesMap = ({ workerId }) => {
  const [housesWithProblems, setHousesWithProblems] = useState([]);
  const [loading, setLoading] = useState(true);
  const mapRef = useRef(null);

  // Имитация метода calculateMapCenter из WorkerPage
  const calculateMapCenter = (houses) => {
    let totalLat = 0;
    let totalLng = 0;
    let validHouseCount = 0;

    houses.forEach((house) => {
      if (house.house && house.house.mapLocationCoordinates) {
        const coordinates = house.house.mapLocationCoordinates.coordinates;
        if (coordinates.length === 2) {
          totalLng += coordinates[0];
          totalLat += coordinates[1];
          validHouseCount++;
        }
      }
    });

    if (validHouseCount === 0) return [0, 0]; // Возвращаем значение по умолчанию, если нет корректных координат

    return [totalLat / validHouseCount, totalLng / validHouseCount];
  };

  const [mapCenter, setMapCenter] = useState([101.885485, 43.115536]);

  useEffect(() => {
    if (mapRef.current && housesWithProblems.length > 0) {
      const bounds = L.latLngBounds(
        housesWithProblems.map((house) => [
          house.house.mapLocationCoordinates.coordinates[1],
          house.house.mapLocationCoordinates.coordinates[0],
        ])
      );
      mapRef.current.fitBounds(bounds, { padding: [50, 50], maxZoom: 11 });
      setMapCenter(mapRef.current.getCenter());
    }
  }, [housesWithProblems]);

  useEffect(() => {
    const fetchHousesWithProblems = async () => {
      setLoading(true);
      const token = tokenService.getToken();
      if (!token) {
        console.error('Аутентификационный токен не найден');
        return;
      }

      try {
        const response = await axios.get(
          `https://fix.house/api/problems/problems/houses-with-problems/${workerId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setHousesWithProblems(response.data);
        setMapCenter(calculateMapCenter(response.data));
      } catch (error) {
        console.error('Ошибка при получении домов с проблемами:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchHousesWithProblems();
  }, [workerId]);

  const customMarkerIcon = L.icon({
    iconUrl: markerIconUrl,
    shadowUrl: markerShadowUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  return (
    <MapContainer
      center={mapCenter}
      zoom={13}
      style={{ height: '500px', width: '100%' }}
      ref={mapRef}
    >
      <TileLayer
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {!loading &&
        housesWithProblems.map((house) => (
          <Marker
            key={house.house._id}
            position={[
              house.house.mapLocationCoordinates.coordinates[1],
              house.house.mapLocationCoordinates.coordinates[0],
            ]}
            icon={customMarkerIcon}
          >
            <Popup>
              <div>
                <h4>{house.house.name}</h4>
                <p>{house.house.address}</p>
                <p>{house.problems.length} проблем(ы)</p>
              </div>
            </Popup>
          </Marker>
        ))}
    </MapContainer>
  );
};

export default WorkerAssignedHousesMap;