import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { tokenService } from '../../services/tokenService';
import ManageSkills from '../ManageSkills';
import styles from './AdminSubscriptionTypes.module.scss';
import { Flex, IconButton, Input, Modal, Text } from '../../ui';
import { CloseIcon, PenIcon, PlusIcon } from '../../static';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../LanguageSwitcher';
import AdminMobileLinks from '../../features_admin/AdminMobileLinks/AdminMobileLinks';
import { useMediaQuery } from 'react-responsive';
import { desktopQuery } from '../../consts';
import { getHouseValidationSchema } from '../validationSchema';

const AdminSubscriptionTypes = () => {
  const [subscriptionTypes, setSubscriptionTypes] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [currentSubscriptionType, setCurrentSubscriptionType] = useState(null);
  const [newTypeName, setNewTypeName] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const [newPrice, setNewPrice] = useState('');
  const [newDuration, setNewDuration] = useState('');
  const [typeName, setTypeName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [duration, setDuration] = useState('');
  const [newPlannedCallsLimit, setNewPlannedCallsLimit] = useState('');
  const [newUrgentCallsLimit, setNewUrgentCallsLimit] = useState('');
  const [plannedCallsLimit, setPlannedCallsLimit] = useState('');
  const [urgentCallsLimit, setUrgentCallsLimit] = useState('');
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(desktopQuery);

  useEffect(() => {
    fetchSubscriptionTypes();
  }, []);

  const fetchSubscriptionTypes = async () => {
    const token = tokenService.getToken();
    try {
      const response = await axios.get(
        'https://fix.house/api/subscription-types',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSubscriptionTypes(response.data);
    } catch (error) {
      console.error('Ошибка при получении типов подписок:', error);
    }
  };

  const startEditing = (subscriptionType) => {
    setIsEditing(true);
    setCurrentSubscriptionType(subscriptionType);
    setTypeName(subscriptionType.typeName);
    setDescription(subscriptionType.description);
    setPrice(subscriptionType.price);
    setDuration(subscriptionType.duration);
    setPlannedCallsLimit(subscriptionType.plannedCallsLimit);
    setUrgentCallsLimit(subscriptionType.urgentCallsLimit);
  };

  const editSubscriptionType = async () => {
    const token = tokenService.getToken();
    try {
      await axios.put(
        `https://fix.house/api/subscription-types/${currentSubscriptionType._id}`,
        {
          typeName,
          description,
          price,
          duration,
          plannedCallsLimit,
          urgentCallsLimit,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsEditing(false);
      fetchSubscriptionTypes();
    } catch (error) {
      console.error('Ошибка при редактировании типа подписки:', error);
    }
  };

  const addSubscriptionType = async () => {
    const token = tokenService.getToken();
    try {
      await axios.post(
        'https://fix.house/api/subscription-types',
        {
          typeName: newTypeName,
          description: newDescription,
          price: newPrice,
          duration: newDuration,
          plannedCallsLimit: newPlannedCallsLimit,
          urgentCallsLimit: newUrgentCallsLimit,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsAdding(false);
      fetchSubscriptionTypes();
    } catch (error) {
      console.error('Ошибка при добавлении типа подписки:', error);
    }
  };

  const deleteSubscriptionType = async (id) => {
    const token = tokenService.getToken();
    try {
      await axios.delete(`https://fix.house/api/subscription-types/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchSubscriptionTypes();
    } catch (error) {
      console.error('Ошибка при удалении типа подписки:', error);
    }
  };

  const validateField = async (name, value) => {
    try {
      await getHouseValidationSchema(t).validateAt(name, {
        ...{
          subscriptionType: newTypeName,
          subscriptionDescription: newDescription,
          price: newPrice,
          subscriptionDuration: newDuration,
          plannedCallsLimit: newPlannedCallsLimit,
          urgentCallsLimit: newUrgentCallsLimit,
          subscriptionTypeEdit: typeName,
          subscriptionDescriptionEdit: description,
          priceEdit: price,
          subscriptionDurationEdit: duration,
          plannedCallsLimitEdit: plannedCallsLimit,
          urgentCallsLimitEdit: urgentCallsLimit,
        },
        [name]: value,
      });
      setErrors((prev) => ({ ...prev, [name]: '' }));
    } catch (error) {
      setErrors((prev) => ({ ...prev, [name]: error.message }));
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  return (
    <div className={styles.typesContainer}>
      <div className={styles.blockContent}>
        {!isDesktop && <AdminMobileLinks />}
        <h1>{t('subscription_manage')}:</h1>
        <ul className={styles.subsList}>
          {subscriptionTypes.map((subscriptionType) => (
            <li key={subscriptionType._id} className={styles.subCard}>
              <p className={styles.label}>{t('title')}:</p>
              <p className={styles.title}>{subscriptionType.typeName}</p>
              <p className={styles.info}>
                <span className={styles.label}>{t('description')}:</span>
                {subscriptionType.description}
              </p>
              <p className={styles.info}>
                <span className={styles.label}>{t('price')}:</span>
                {subscriptionType.price}
              </p>
              <p className={styles.info}>
                <span className={styles.label}>{t('duration')}:</span>
                {subscriptionType.duration} {t('days')}
              </p>
              <p className={styles.info}>
                <span className={styles.label}>
                  {t('planned_calls_limit')}:
                </span>
                {subscriptionType.plannedCallsLimit}
              </p>
              <p className={styles.info}>
                <span className={styles.label}>{t('urgent_calls_limit')}:</span>
                {subscriptionType.urgentCallsLimit}
              </p>
              <Flex justify='between' gap={10}>
                <button
                  onClick={() => startEditing(subscriptionType)}
                  className={styles.editButton}
                >
                  <PenIcon />
                  {t('edit')}
                </button>
                <button
                  onClick={() => deleteSubscriptionType(subscriptionType._id)}
                  className={styles.deleteButton}
                >
                  {t('delete')}
                </button>
              </Flex>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.addSubContainer}>
        <div className={styles.blockContent}>
          <Flex gap={10} className={styles.addHeader}>
            <PlusIcon width={36} height={36} />
            <h2>{t('add_subscription_type')}</h2>
          </Flex>
          <Flex gap={20} className={styles.addFields}>
            <div>
              <Input
                className={styles.addSubInput}
                name='subscriptionType'
                type='text'
                value={newTypeName}
                onChange={(value) => setNewTypeName(value)}
                label={t('type_title')}
                onBlur={handleBlur}
              />
              {errors.subscriptionType && (
                <div className={styles.errorMessage}>
                  {errors.subscriptionType}
                </div>
              )}
              <Input
                className={styles.addSubInput}
                name='subscriptionDescription'
                type='text'
                value={newDescription}
                onChange={(value) => setNewDescription(value)}
                label={t('description')}
                onBlur={handleBlur}
              />
              {errors.subscriptionDescription && (
                <div className={styles.errorMessage}>
                  {errors.subscriptionDescription}
                </div>
              )}
              <Input
                className={styles.addSubInput}
                name='price'
                type='number'
                value={newPrice}
                onChange={(value) => setNewPrice(value)}
                label={t('price')}
                onBlur={handleBlur}
              />
              {errors.price && (
                <div className={styles.errorMessage}>{errors.price}</div>
              )}
            </div>
            <div>
              <Input
                className={styles.addSubInput}
                name='subscriptionDuration'
                type='number'
                value={newDuration}
                onChange={(value) => setNewDuration(value)}
                label={t('duration_days')}
                onBlur={handleBlur}
              />
              {errors.subscriptionDuration && (
                <div className={styles.errorMessage}>
                  {errors.subscriptionDuration}
                </div>
              )}
              <Input
                className={styles.addSubInput}
                name='plannedCallsLimit'
                type='number'
                value={newPlannedCallsLimit}
                onChange={(value) => setNewPlannedCallsLimit(value)}
                label={t('planned_calls_limit')}
                onBlur={handleBlur}
              />
              {errors.plannedCallsLimit && (
                <div className={styles.errorMessage}>
                  {errors.plannedCallsLimit}
                </div>
              )}
              <Input
                className={styles.addSubInput}
                name='urgentCallsLimit'
                type='number'
                value={newUrgentCallsLimit}
                onChange={(value) => setNewUrgentCallsLimit(value)}
                label={t('urgent_calls_limit')}
                onBlur={handleBlur}
              />
              {errors.urgentCallsLimit && (
                <div className={styles.errorMessage}>
                  {errors.urgentCallsLimit}
                </div>
              )}
            </div>
          </Flex>

          <button onClick={addSubscriptionType} className={styles.addButton}>
            {t('add')}
          </button>
          <button
            onClick={() => setIsAdding(false)}
            className={styles.cancelButton}
          >
            {t('cancel')}
          </button>
        </div>
      </div>
      <ManageSkills />
      {isEditing && (
        <Modal closeModal={() => setIsEditing(false)} className={styles.Modal}>
          <Flex direction='column' gap={30}>
            <Flex justify='between' align='baseline'>
              <Text size={20} lineHeight='25px' weight='bold'>
                {t('edit_subscription_type')}
              </Text>
              <IconButton
                type='transparent'
                size='m'
                onClick={() => setIsEditing(false)}
              >
                <CloseIcon />
              </IconButton>
            </Flex>
            <Flex direction='column' gap={16}>
              <Input
                label={t('type_title') + ':'}
                name='subscriptionTypeEdit'
                value={typeName || ''}
                onChange={(value) => setTypeName(value)}
                type='text'
                onBlur={handleBlur}
              />
              {errors.subscriptionTypeEdit && (
                <div className={styles.errorMessage}>
                  {errors.subscriptionTypeEdit}
                </div>
              )}
              <Input
                label={t('description') + ':'}
                name='subscriptionDescriptionEdit'
                value={description || ''}
                onChange={(value) => setDescription(value)}
                type='text'
                onBlur={handleBlur}
              />
              {errors.subscriptionDescriptionEdit && (
                <div className={styles.errorMessage}>
                  {errors.subscriptionDescriptionEdit}
                </div>
              )}
              <Input
                label={t('price') + ':'}
                name='priceEdit'
                value={price || ''}
                onChange={(value) => setPrice(value)}
                type='number'
                onBlur={handleBlur}
              />
              {errors.priceEdit && (
                <div className={styles.errorMessage}>{errors.priceEdit}</div>
              )}
              <Input
                label={t('duration') + ':'}
                name='subscriptionDurationEdit'
                value={duration || ''}
                onChange={(value) => setDuration(value)}
                type='number'
                onBlur={handleBlur}
              />
              {errors.subscriptionDurationEdit && (
                <div className={styles.errorMessage}>
                  {errors.subscriptionDurationEdit}
                </div>
              )}
              <Input
                label={t('planned_calls_limit') + ':'}
                name='plannedCallsLimitEdit'
                value={newPlannedCallsLimit || ''}
                onChange={(value) => setNewPlannedCallsLimit(value)}
                type='number'
                onBlur={handleBlur}
              />
              {errors.plannedCallsLimitEdit && (
                <div className={styles.errorMessage}>
                  {errors.plannedCallsLimitEdit}
                </div>
              )}
              <Input
                label={t('urgent_calls_limit') + ':'}
                name='urgentCallsLimitEdit'
                value={newUrgentCallsLimit || ''}
                onChange={(value) => setNewUrgentCallsLimit(value)}
                type='number'
                onBlur={handleBlur}
              />
              {errors.urgentCallsLimitEdit && (
                <div className={styles.errorMessage}>
                  {errors.urgentCallsLimitEdit}
                </div>
              )}
              <Flex gap={10}>
                <button
                  onClick={editSubscriptionType}
                  className={styles.saveButton}
                >
                  {t('save')}
                </button>
                <button
                  onClick={() => setIsEditing(false)}
                  className={styles.cancelButton}
                >
                  {t('cancel')}
                </button>
              </Flex>
            </Flex>
          </Flex>
        </Modal>
      )}
    </div>
  );
};

export default AdminSubscriptionTypes;
