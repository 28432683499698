import * as Yup from 'yup';

export const getHouseValidationSchema = (t) =>
  Yup.object().shape({
    name: Yup.string()
      .required(t('name_required'))
      .max(70, t('name_max'))
      .matches(/^[a-zA-Zа-яА-ЯёЁ0-9\s]+$/, t('name_invalid')),
    address: Yup.string()
      .required(t('address_required'))
      .max(70, t('address_max'))
      .matches(/^[a-zA-Zа-яА-ЯёЁ0-9\s,.-]+$/, t('address_invalid')),
    squareMeters: Yup.number()
      .positive(t('squareMeters_positive'))
      .typeError(t('squareMeters_positive'))
      .max(10000, t('squareMeters_max'))
      .typeError(t('squareMeters_max'))
      .required(t('squareMeters_required'))
      .typeError(t('squareMeters_required')),
    floor: Yup.number()
      .positive(t('floor_positive'))
      .typeError(t('floor_positive'))
      .max(1000, t('floor_max'))
      .typeError(t('floor_max'))
      .when('housingType', {
        is: 'Apartment',
        then: Yup.number()
          .positive(t('floor_positive'))
          .typeError(t('floor_positive'))
          .required(t('floor_required'))
          .typeError(t('floor_required'))
          .max(1000, t('floor_max'))
          .typeError(t('floor_max')),
      }),
    apartmentNumber: Yup.string()
      .matches(/^[a-zA-Zа-яА-ЯёЁ0-9\s]+$/, t('apartmentNumber_invalid'))
      .when('housingType', {
        is: 'Apartment',
        then: Yup.string().required(t('apartmentNumber_required')),
      }),
    phone: Yup.number()
      .max(12, t('phone_max'))
      .typeError(t('phone_max'))
      .required()
      .typeError(t('phone_required')),
    password: Yup.string().required(t('password_required')),
    passwordConfirm: Yup.string().required(t('password_required')),
    skill: Yup.string().required(t('skill_required')),
    skillDescription: Yup.string().required(t('skillDescription_required')),
    skillEdit: Yup.string().required(t('skill_required')),
    skillDescriptionEdit: Yup.string().required(t('skillDescription_required')),
    subscriptionType: Yup.string().required(t('subscriptionType_required')),
    subscriptionDuration: Yup.number()
      .required(t('subscriptionDuration_required'))
      .typeError(t('subscriptionDuration_required')),
    subscriptionDescription: Yup.string()
      .required(t('subscriptionDescription_required'))
      .typeError(t('subscriptionDescription_required')),
    urgentCallsLimit: Yup.number()
      .required(t('urgentCallsLimit_required'))
      .typeError(t('urgentCallsLimit_required')),
    plannedCallsLimit: Yup.number()
      .required(t('plannedCallsLimit_required'))
      .typeError(t('plannedCallsLimit_required')),
    price: Yup.number()
      .required(t('price_required'))
      .typeError(t('price_required')),
    subscriptionTypeEdit: Yup.string().required(t('subscriptionType_required')),
    subscriptionDurationEdit: Yup.number()
      .required(t('subscriptionDuration_required'))
      .typeError(t('subscriptionDuration_required')),
    subscriptionDescriptionEdit: Yup.string()
      .required(t('subscriptionDescription_required'))
      .typeError(t('subscriptionDescription_required')),
    urgentCallsLimitEdit: Yup.number()
      .required(t('urgentCallsLimit_required'))
      .typeError(t('urgentCallsLimit_required')),
    plannedCallsLimitEdit: Yup.number()
      .required(t('plannedCallsLimit_required'))
      .typeError(t('plannedCallsLimit_required')),
    priceEdit: Yup.number()
      .required(t('price_required'))
      .typeError(t('price_required')),
    email: Yup.string()
      .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, t('email_invalid'))
      .required(t('email_required')),
    country: Yup.string().required(t('country_required')),
    city: Yup.string().required('city_required'),
    cardNumber: Yup.number()
      .required()
      .typeError(t('cardNumber_required'))
      .min(16)
      .typeError(t('cardNumber_incorrect'))
      .max(16)
      .typeError(t('cardNumber_incorrect')),
    cardHolder: Yup.string().required(t('cardHolder_required')),
    cardExpirationMonth: Yup.number()
      .required()
      .typeError(t('cardExpirationMonth_required')),
    cardExpirationYear: Yup.number()
      .required()
      .typeError(t('cardExpirationYear_required')),
    cardCVV: Yup.number()
      .required()
      .typeError(t('cardCVV_required'))
      .min(3)
      .typeError(t('cardCVV_incorrect'))
      .max(3)
      .typeError(t('cardCVV_incorrect')),
    problemDescription: Yup.string().required(t('problemDescription_required')),
    preferredLanguage: Yup.string().required(t('language_required')),
    messenger: Yup.string().required(t('messenger_required')),
    availableTimeDuration: Yup.string().required(
      t('availableTimeDuration_required')
    ),
  });
