import { Modal, Flex, Text, IconButton, Input, Button } from '../../ui';
import {
  CloseIcon,
  TelegramIcon,
  WhatsappIcon,
  LineIcon,
} from '../../static/icons';
import { useTranslation } from 'react-i18next';
import styles from './EditProfileModal.module.scss';
import { getHouseValidationSchema } from '../../components/validationSchema';
import { useState } from 'react';

const messengerData = {
  telegram: {
    label: 'Telegram ID (@username):',
  },
  whatsapp: {
    label: 'WhatsApp номер телефона:',
  },
  line: {
    label: 'Line ID:',
  },
};

export const EditProfileModal = (props) => {
  const {
    closeModal,
    editableName,
    setEditableName,
    editableEmail,
    setEditableEmail,
    editablePhone,
    setEditablePhone,
    setSelectedMessenger,
    selectedMessenger,
    editableMessenger,
    setEditableMessenger,
    openChangePasswordModal,
    openLogoutModal,
    handleUpdateProfile,
    successMessage,
  } = props;

  const { t } = useTranslation();
  const [errors, setErrors] = useState({});

  const validateField = async (name, value) => {
    try {
      await getHouseValidationSchema(t).validateAt(name, {
        ...{ phone: editablePhone },
        [name]: value,
      });
      setErrors((prev) => ({ ...prev, [name]: '' }));
    } catch (error) {
      setErrors((prev) => ({ ...prev, [name]: error.message }));
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  const handleFormErrors = () => {
    let hasError = false;
    Object.keys(errors).forEach((key) => {
      if (errors[key] !== '' && key === 'phone') {
        hasError = true;
      }
    });
    return hasError;
  };

  return (
    <Modal closeModal={closeModal} className={styles.Modal}>
      <Flex direction='column' gap={30}>
        <Flex justify='between' align='baseline'>
          <Text size={20} lineHeight='25px' weight='bold'>
            {t('edit_profile')}
          </Text>
          <IconButton type='transparent' size='m' onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </Flex>

        <Flex direction='column' gap={16}>
          <Input
            label={t('name') + ':'}
            name='name'
            value={editableName}
            onChange={setEditableName}
          />
          <Input
            label={t('email') + ':'}
            name='email'
            value={editableEmail}
            onChange={setEditableEmail}
          />
          <Input
            label={t('phone') + ':'}
            name='phone'
            value={editablePhone}
            onChange={setEditablePhone}
            onBlur={handleBlur}
            type='number'
          />
          {errors.phone && (
            <div className={styles.errorMessage}>{errors.phone}</div>
          )}

          <Flex direction='column' gap={12}>
            <Text color='gray'>{t('select_messenger')}:</Text>
            <Flex gap={6}>
              <IconButton
                type='outline'
                size='m'
                onClick={() => setSelectedMessenger('telegram')}
                active={selectedMessenger === 'telegram'}
              >
                <TelegramIcon />
              </IconButton>
              <IconButton
                type='outline'
                size='m'
                onClick={() => setSelectedMessenger('whatsapp')}
                active={selectedMessenger === 'whatsapp'}
              >
                <WhatsappIcon />
              </IconButton>
              <IconButton
                type='outline'
                size='m'
                onClick={() => setSelectedMessenger('line')}
                active={selectedMessenger === 'line'}
              >
                <LineIcon />
              </IconButton>
            </Flex>
          </Flex>

          {messengerData[selectedMessenger]?.label && (
            <Input
              label={messengerData[selectedMessenger].label}
              name='messengerId'
              value={editableMessenger}
              onChange={setEditableMessenger}
            />
          )}

          <Flex direction='column' gap={20}>
            <Flex direction='column' gap={6}>
              <Button
                type='black'
                onClick={async (e) => {
                  try {
                    await getHouseValidationSchema(t).validate(
                      { phone: editablePhone },
                      {
                        abortEarly: false,
                      }
                    );
                  } catch (error) {
                    if (error.name === 'ValidationError') {
                      const newErrors = {};
                      error.inner.forEach((err) => {
                        newErrors[err.path] = err.message;
                      });
                      setErrors(newErrors);
                    }
                  }

                  if (handleFormErrors()) {
                    return;
                  }

                  handleUpdateProfile(e);
                }}
              >
                {t('save_changes')}
              </Button>
              {successMessage && (
                <Text align='center' size={14} weight='gray'>
                  {successMessage}
                </Text>
              )}
            </Flex>
            <Flex justify='between'>
              <Button type='text' onClick={openChangePasswordModal}>
                <Text size={14} weight='bold'>
                  {t('change_password')}
                </Text>
              </Button>
              <Button type='text' onClick={openLogoutModal}>
                <Text size={14} weight='bold'>
                  {t('logout')}
                </Text>
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
};
