import React from 'react';
import styles from './HousesWithProblems.module.scss';
import { useTranslation } from 'react-i18next';

const HousesWithProblems = ({
  housesWithProblems,
  handleCopyCoordinates,
  showCopyNotification,
  isProblemAssigned,
  unassignProblemFromSelf,
  assignProblemToSelf,
  handleOpenPhotoModal,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.housesContainer}>
      <div className={styles.blockContent}>
        <div className={styles.infoBox}>
          <h3 className={styles.header}>{t('houses_with_problems')}</h3>
          {housesWithProblems.length > 0 ? (
            <div className={styles.houseProblemsListContainer}>
              {housesWithProblems.map((house) => (
                <div
                  key={house.house._id}
                  className={styles.houseProblemsPrivateContainer}
                >
                  <img
                    src={house?.imageUrl}
                    alt={`Изображение дома ${house.house.name}`}
                    className={styles.problemPhoto}
                    onClick={() => handleOpenPhotoModal(house?.imageUrl)}
                  />
                  <h4>{house.house.name}</h4>
                  <p className={styles.info}>
                    <strong className={styles.infoLabel}>
                      {t('address')}:
                    </strong>{' '}
                    {house.house.address}
                  </p>
                  <p className={styles.info}>
                    <strong className={styles.infoLabel}>
                      {t('squares')}:
                    </strong>{' '}
                    {house.house.squareMeters}
                  </p>
                  <p className={styles.info}>
                    <strong className={styles.infoLabel}>
                      {t('coordinates')}:{' '}
                    </strong>
                    {house.house.mapLocationCoordinates.coordinates[1]}{' '}
                    {house.house.mapLocationCoordinates.coordinates[0]}{' '}
                    <button
                      onClick={() =>
                        handleCopyCoordinates(
                          house.house.mapLocationCoordinates.coordinates[1],
                          house.house.mapLocationCoordinates.coordinates[0]
                        )
                      }
                      className={styles.copyButton}
                    >
                      {t('copy')}
                    </button>
                    {showCopyNotification && (
                      <span style={{ marginLeft: '10px', color: 'green' }}>
                        {t('copied')}!
                      </span>
                    )}
                  </p>
                  <p className={styles.info}>
                    {t('house_type')}: {house.house.housingType}
                  </p>
                  {house.house.floor && (
                    <p className={styles.info}>
                      {t('floor')}: {house.house.floor}
                    </p>
                  )}
                  {house.house.apartmentNumber && (
                    <p className={styles.info}>
                      {t('apartment_number')}: {house.house.apartmentNumber}
                    </p>
                  )}
                  <p className={styles.info}>
                    <strong className={styles.infoLabel}>{t('owner')}:</strong>{' '}
                    {house.house.ownerID
                      ? house.house.ownerID.name
                      : t('no_info')}
                  </p>
                  <p className={styles.info}>
                    <strong className={styles.infoLabel}>{t('phone')}:</strong>{' '}
                    {house.house.ownerID
                      ? house.house.ownerID.phone
                      : t('no_info')}
                  </p>
                  <p className={styles.info}>
                    <strong className={styles.infoLabel}>{t('email')}:</strong>{' '}
                    {house.house.ownerID
                      ? house.house.ownerID.email
                      : t('no_info')}
                  </p>
                  <p className={styles.info}>
                    <strong className={styles.infoLabel}>
                      {t('messenger')}:
                    </strong>{' '}
                    {house.house.ownerID
                      ? `${house.house.ownerID.messenger} (${house.house.ownerID.messengerType})`
                      : t('no_info')}
                  </p>
                  <p className={styles.info}>
                    <strong className={styles.infoLabel}>
                      {t('language')}:
                    </strong>{' '}
                    {house.house.ownerID
                      ? house.house.ownerID.preferredLanguage
                      : t('no_info')}
                  </p>
                  <div>
                    <p className={styles.info}>{t('problems')}:</p>
                    {house.problems.map((problem) => (
                      <div key={problem._id} className={styles.problemDetail}>
                        <img
                          src={problem.imageUrl}
                          alt={`Изображение проблемы ${problem.description}`}
                          className={styles.problemPhoto}
                          onClick={() => {
                            handleOpenPhotoModal(
                              problem.initialPhotoID?.imageURL
                            );
                          }}
                        />
                        <p className={styles.info}>
                          <strong className={styles.infoLabel}>
                            {t('description')}:{' '}
                          </strong>{' '}
                          {problem.description}
                        </p>
                        <p className={styles.info}>
                          <strong className={styles.infoLabel}>
                            {t('urgency_level')}:{' '}
                          </strong>{' '}
                          {problem.urgencyLevel}
                        </p>
                        <p className={styles.info}>
                          <strong className={styles.infoLabel}>
                            {t('status')}:
                          </strong>{' '}
                          {problem.status}
                        </p>
                        <p className={styles.info}>
                          <strong className={styles.infoLabel}>
                            {t('creation_date')}:{' '}
                          </strong>{' '}
                          {new Date(problem.creationDate).toLocaleDateString()}
                        </p>
                        <button
                          onClick={() =>
                            isProblemAssigned(problem._id)
                              ? unassignProblemFromSelf(problem._id)
                              : assignProblemToSelf(
                                  problem._id,
                                  problem.initialPhotoID?.imageURL
                                )
                          }
                          className={styles.openButton}
                        >
                          {isProblemAssigned(problem._id)
                            ? t('decline_problem')
                            : t('assign_problem')}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className={styles.info}>{t('no_houses_with_problems')}.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default HousesWithProblems;
