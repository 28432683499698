import cn from 'classnames';

import styles from './Container.module.scss';

export const Container = (props) => {
  const {children, className, ...restProps} = props;

  return (
    <div className={cn(styles.container, className)} {...restProps}>
      {children}
    </div>
  )
}