import React from 'react';
import styles from './FiltersBlock.module.scss';
import { Flex } from '../../ui';
import { useTranslation } from 'react-i18next';

const FiltersBlock = ({
  showOnlyMySkills,
  showOnlyUrgent,
  setShowOnlyMySkills,
  setShowOnlyUrgent,
  handleEndDateChange,
  handleFilterChange,
  handleStartDateChange,
  startDate,
  statusOptions,
  selectedSkills,
  selectedStatuses,
  setSelectedSkills,
  setSelectedStatuses,
  skills,
  endDate,
  fetchHousesWithProblems,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.filters}>
      <div className={styles.filtersContainer}>
        <Flex
          justify='between'
          align='stretch'
          className={styles.filtersContent}
        >
          <div>
            <div className={styles.filtersRow}>
              <div>
                <label className={`${styles.checkbox} ${styles.mainLabel}`}>
                  <input
                    type='checkbox'
                    checked={showOnlyUrgent}
                    onChange={(e) => {
                      setShowOnlyUrgent(e.target.checked);
                      handleFilterChange();
                    }}
                  />
                  <span className={styles.checkmark}></span>
                  {t('only_urgent')}:
                </label>
              </div>

              {statusOptions.map((status) => (
                <label key={status} className={styles.checkbox}>
                  <input
                    type='checkbox'
                    checked={selectedStatuses.includes(status)}
                    onChange={(e) => {
                      const newSelectedStatuses = e.target.checked
                        ? [...selectedStatuses, status]
                        : selectedStatuses.filter((s) => s !== status);
                      setSelectedStatuses(newSelectedStatuses);
                      handleFilterChange();
                    }}
                  />
                  <span className={styles.checkmark}></span>
                  {status}
                </label>
              ))}

              <label className={styles.checkbox}>
                <input
                  type='checkbox'
                  checked={showOnlyMySkills}
                  onChange={(e) => {
                    setShowOnlyMySkills(e.target.checked);
                    handleFilterChange();
                  }}
                />
                <span className={styles.checkmark}></span>
                {t('show_only_my_skills')}:
              </label>
            </div>
            <div>
              <div>
                <label className={styles.mainLabel}>
                  {t('select_skills')}:{' '}
                </label>
              </div>
              {skills.map((skill) => (
                <label key={skill._id} className={styles.checkbox}>
                  <input
                    type='checkbox'
                    checked={selectedSkills.includes(skill._id)}
                    onChange={(e) => {
                      const newSelectedSkills = e.target.checked
                        ? [...selectedSkills, skill._id]
                        : selectedSkills.filter((s) => s !== skill._id);
                      setSelectedSkills(newSelectedSkills);
                    }}
                  />
                  <span className={styles.checkmark}></span>
                  {skill.skillName}
                </label>
              ))}
            </div>
          </div>

          <Flex
            direction='column'
            align='end'
            className={styles.filtersContentBlock}
          >
            <Flex gap={10} className={styles.dates}>
              <label className={styles.date}>
                <span> {t('start')}:</span>
                <input
                  type='date'
                  value={startDate}
                  onChange={handleStartDateChange}
                />
              </label>
              <label className={styles.date}>
                <span> {t('end')}:</span>
                <input
                  type='date'
                  value={endDate}
                  onChange={handleEndDateChange}
                />
              </label>
            </Flex>
            <button onClick={fetchHousesWithProblems}>
              {t('apply_filter')}
            </button>
          </Flex>
        </Flex>
      </div>
    </div>
  );
};

export default FiltersBlock;
