import cn from 'classnames';

import styles from './Bar.module.scss';

export const Bar = (props) => {
  const {
    className,
    children,
    ...restProps
  } = props;

  return (
    <div className={cn(styles.bar, className)} {...restProps}>
      {children}
    </div>
  )
}