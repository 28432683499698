import axios from 'axios';
import { tokenService } from './services/tokenService';

const instance = axios.create({
    baseURL: 'https://fix.house', 
    
});


instance.interceptors.request.use(config => {
  
    //добавление токена авторизации в заголовки
    const token = tokenService.getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, error => {
    
    return Promise.reject(error);
});

instance.interceptors.response.use(response => {
    
    return response;
}, error => {
    
    return Promise.reject(error);
});

export default instance;
