import React from 'react';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
 import { useTranslation } from 'react-i18next';

import { desktopQuery } from "../../consts";
import { Flex, Text, Container } from "../../ui";
import styles from './HomePageGuideBlock.module.scss';
import {step1, step2, step3, step4, step5, step6, step7} from "../../static";

export const HomePageGuideBlock = (props) => {
  const { className, subscriptions, ...restProps } = props;
  const isDesktop = useMediaQuery(desktopQuery);
   const { t, i18n } = useTranslation();

  return (
    <section id="guide" className={cn(styles.guide, className)} {...restProps}>
      <Container>
        {isDesktop ? (
          <Flex direction="column" gap={120}>
            <Text tag="h2" weight="medium" size={72} styles={styles.guideHeader}>{t('how_to_use_fix_house_service')}</Text>

            <Flex gap={20} align="center" justify="center">
              <div className={styles.guideStepNumber} align="center" justify="center">
                <Text className={styles.guideStepNumberInner} color="white" size={72} lineHeight={1} weight="medium">
                  1
                </Text>
              </div>
              
              <Text className={styles.guideStepDescription} size={26} weight="medium">{t('guide_step_1')}</Text>

              <img className={styles.guideStepImage} src={step1} alt="step"/>
            </Flex>

            <Flex gap={20} align="center" justify="center">
              <img className={styles.guideStepImage} src={step2} alt="step"/>

              <div className={styles.guideStepNumber} align="center" justify="center">
                <Text className={styles.guideStepNumberInner} color="white" size={72} lineHeight={1} weight="medium">
                  2
                </Text>
              </div>
              
              <Text className={styles.guideStepDescription} size={26} weight="medium">{t('guide_step_2')}</Text>


            </Flex>

            <Flex gap={20} align="center" justify="center">
              <div className={styles.guideStepNumber} align="center" justify="center">
                <Text className={styles.guideStepNumberInner} color="white" size={72} lineHeight={1} weight="medium">
                  3
                </Text>
              </div>
              
              <Text className={styles.guideStepDescription} size={26} weight="medium">{t('guide_step_3')}</Text>

              <img className={styles.guideStepImage} src={step3} alt="step"/>
            </Flex>

            <Flex gap={20} align="center" justify="center">
              <img className={styles.guideStepImage} src={step4} alt="step"/>

              <div className={styles.guideStepNumber} align="center" justify="center">
                <Text className={styles.guideStepNumberInner} color="white" size={72} lineHeight={1} weight="medium">
                  4
                </Text>
              </div>
              
              <Text className={styles.guideStepDescription} size={26} weight="medium">{t('guide_step_4')}</Text>
            </Flex>

            <Flex gap={20} align="center" justify="center">
              <div className={styles.guideStepNumber} align="center" justify="center">
                <Text className={styles.guideStepNumberInner} color="white" size={72} lineHeight={1} weight="medium">
                  5
                </Text>
              </div>
              
              <Text className={styles.guideStepDescription} size={26} weight="medium">{t('guide_step_5')}</Text>

              <img className={styles.guideStepImage} src={step5} alt="step"/>
            </Flex>

            <Flex gap={20} align="center" justify="center">
              <img className={styles.guideStepImage} src={step6} alt="step"/>

              <div className={styles.guideStepNumber} align="center" justify="center">
                <Text className={styles.guideStepNumberInner} color="white" size={72} lineHeight={1} weight="medium">
                  6
                </Text>
              </div>
              
              <Text className={styles.guideStepDescription} size={26} weight="medium">{t('guide_step_6')}</Text>
            </Flex>

            <Flex gap={20} align="center" justify="center">
              <div className={styles.guideStepNumber} align="center" justify="center">
                <Text className={styles.guideStepNumberInner} color="white" size={72} lineHeight={1} weight="medium">
                  7
                </Text>
              </div>
              
              <Text className={styles.guideStepDescription} size={26} weight="medium">{t('guide_step_7')}</Text>

              <img className={styles.guideStepImage} src={step7} alt="step"/>
            </Flex>
          </Flex>
        ) : 
        (
          <Flex direction="column" gap={120}>
<Text tag="h2" weight="medium" size={60} styles={styles.guideHeader} align="center">{t('how_to_use_fix_house_service')}</Text>

          <Flex gap={20} align="center" justify="end">
            <div className={styles.guideStepNumber} align="center" justify="center">
              <Text className={styles.guideStepNumberInner} color="white" size={72} lineHeight={1} weight="medium">
                1
              </Text>
            </div>

            <Flex direction="column" gap={20} className={styles.guideStepInfo}>
              <img className={styles.guideStepImage} src={step1} alt="step"/>
              
              <Text className={styles.guideStepDescription} size={20} weight="medium" align="center">
                Discover a problem and make a picture on your mobile device, then send it to us through our application
              </Text>
            </Flex>            
          </Flex>

          <Flex gap={20} align="center" justify="start">
            <Flex direction="column" gap={20} className={styles.guideStepInfo}>
              <img className={styles.guideStepImage} src={step2} alt="step"/>
              
              <Text className={styles.guideStepDescription} size={20} weight="medium" align="center">
                Our customer service will approve the order and make sure everything is in order
              </Text>
            </Flex>

            <div className={styles.guideStepNumber} align="center" justify="center">
              <Text className={styles.guideStepNumberInner} color="white" size={72} lineHeight={1} weight="medium">
                2
              </Text>
            </div>
          </Flex>

          <Flex gap={20} align="center" justify="end">
            <div className={styles.guideStepNumber} align="center" justify="center">
              <Text className={styles.guideStepNumberInner} color="white" size={72} lineHeight={1} weight="medium">
                3
              </Text>
            </div>

            <Flex direction="column" gap={20} className={styles.guideStepInfo}>
              <img className={styles.guideStepImage} src={step3} alt="step"/>
              
              <Text className={styles.guideStepDescription} size={20} weight="medium" align="center">
                Wait for our team to arrive to your home to provide nesessary service
              </Text>
            </Flex>            
          </Flex>

          <Flex gap={20} align="center" justify="start">
            <Flex direction="column" gap={20} className={styles.guideStepInfo}>
              <img className={styles.guideStepImage} src={step4} alt="step"/>
              
              <Text className={styles.guideStepDescription} size={20} weight="medium" align="center">
                We will make sure all works are done in highest quality, then you will need to approve the fixing
              </Text>
            </Flex>

            <div className={styles.guideStepNumber} align="center" justify="center">
              <Text className={styles.guideStepNumberInner} color="white" size={72} lineHeight={1} weight="medium">
                4
              </Text>
            </div>
          </Flex>

          <Flex gap={20} align="center" justify="end">
            <div className={styles.guideStepNumber} align="center" justify="center">
              <Text className={styles.guideStepNumberInner} color="white" size={72} lineHeight={1} weight="medium">
                5
              </Text>
            </div>

            <Flex direction="column" gap={20} className={styles.guideStepInfo}>
              <img className={styles.guideStepImage} src={step5} alt="step"/>
              
              <Text className={styles.guideStepDescription} size={20} weight="medium" align="center">
                Wait for our team to arrive to your home to provide nesessary service
              </Text>
            </Flex>            
          </Flex>

          <Flex gap={20} align="center" justify="start">
            <Flex direction="column" gap={20} className={styles.guideStepInfo}>
              <img className={styles.guideStepImage} src={step6} alt="step"/>
              
              <Text className={styles.guideStepDescription} size={20} weight="medium" align="center">
                We will make sure all works are done in highest quality, then you will need to approve the fixing
              </Text>
            </Flex>

            <div className={styles.guideStepNumber} align="center" justify="center">
              <Text className={styles.guideStepNumberInner} color="white" size={72} lineHeight={1} weight="medium">
                6
              </Text>
            </div>
          </Flex>

          <Flex gap={20} align="center" justify="end">
            <div className={styles.guideStepNumber} align="center" justify="center">
              <Text className={styles.guideStepNumberInner} color="white" size={72} lineHeight={1} weight="medium">
                7
              </Text>
            </div>

            <Flex direction="column" gap={20} className={styles.guideStepInfo}>
              <img className={styles.guideStepImage} src={step7} alt="step"/>
              
              <Text className={styles.guideStepDescription} size={20} weight="medium" align="center">
                Wait for our team to arrive to your home to provide nesessary service
              </Text>
            </Flex>            
          </Flex>
        </Flex>
        )}
      </Container>
    </section>
  )
}