import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { tokenService } from './services/tokenService';

function withAuth(WrappedComponent, redirectTo = '/login') {
  return function (props) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
      const token = tokenService.getToken();
      setIsAuthenticated(!!token);

      if (!token) {
        navigate(redirectTo);
      }
    }, [navigate]);

    return isAuthenticated ? <WrappedComponent {...props} /> : null;
  };
}

export default withAuth;
