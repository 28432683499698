import axios from 'axios';
import { tokenService } from '../services/tokenService';

export const addProblem = async (payload) => {
  const formData = new FormData()
  formData.append('houseId', payload.houseId)
  formData.append('urgencyLevel', payload.urgencyLevel)
  formData.append('status', 'Open')
  formData.append('description', payload.description)
  formData.append('problemType', payload.problemType)
  formData.append('preferredTime', payload.timeRange)
  formData.append('scheduledDate', payload.scheduledDate);

  console.log("payload in addproblem", payload);

  if (payload.newProblemPhoto) {
    formData.append('photo', payload.newProblemPhoto)
  } else if (payload.defaultImageUrl) {
    // Если нет загруженного фото, но есть дефолтное изображение категории
    formData.append('defaultImageUrl', payload.defaultImageUrl)
  }

  try {
    const token = tokenService.getToken();
    if (!token) {
      console.error('Аутентификационный токен не найден');
      throw new Error('Аутентификационный токен не найден');
    }

    const response = await axios.post(
      `https://fix.house/api/problems/client/houses/${payload.houseId}/problems`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    )

    return response.data;
  } catch (error) {
    console.error('Error in addProblem:', error);
    throw error;
  }
};