import React, { useState } from 'react';
import styles from './EditWorkerBlock.module.scss';
import { Flex, IconButton, Input, Modal, Text } from '../../ui';
import { CloseIcon } from '../../static';
import { useTranslation } from 'react-i18next';
import { getHouseValidationSchema } from '../../components/validationSchema';

const EditWorkerBlock = ({
  handleEditWorker,
  handleCancelEdit,
  handleDeleteWorker,
  editFormData,
  setEditFormData,
  setChangingPassword,
  changingPassword,
  handleEditModalClose,
}) => {
  const { t } = useTranslation();

  const [errors, setErrors] = useState({});

  const validateField = async (name, value) => {
    try {
      await getHouseValidationSchema(t).validateAt(name, {
        ...editFormData,
        [name]: value,
      });
      setErrors((prev) => ({ ...prev, [name]: '' }));
    } catch (error) {
      setErrors((prev) => ({ ...prev, [name]: error.message }));
    }
  };

  const handleBlur = (e) => {
    let { name, value } = e.target;
    validateField(name, value);
  };

  const handleFormErrors = () => {
    let hasError = false;
    Object.keys(errors).forEach((key) => {
      if (errors[key] !== '' && Object.keys(editFormData).includes(key)) {
        hasError = true;
      }
    });
    return hasError;
  };

  return (
    <Modal closeModal={handleEditModalClose} className={styles.Modal}>
      <Flex direction='column' gap={30}>
        <Flex justify='between' align='baseline'>
          <Text size={20} lineHeight='25px' weight='bold'>
            {t('edit_profile')}
          </Text>
          <IconButton
            type='transparent'
            size='m'
            onClick={handleEditModalClose}
          >
            <CloseIcon />
          </IconButton>
        </Flex>
        <form
          onSubmit={async (e) => {
            e.preventDefault();

            try {
              await getHouseValidationSchema(t).validate(editFormData, {
                abortEarly: false,
              });
            } catch (error) {
              if (error.name === 'ValidationError') {
                const newErrors = {};
                error.inner.forEach((err) => {
                  newErrors[err.path] = err.message;
                });
                setErrors(newErrors);
              }
            }
            if (!handleFormErrors()) {
              handleEditWorker(e);
            }
          }}
          className={styles.form}
        >
          <Flex direction='column' gap={16}>
            <Input
              label={t('name') + ':'}
              name='name'
              value={editFormData.name || ''}
              onChange={(value) =>
                setEditFormData({ ...editFormData, name: value })
              }
              onBlur={handleBlur}
            />
            {errors.name && (
              <div className={styles.errorMessage}>{errors.name}</div>
            )}
            <Input
              label={t('phone') + ':'}
              name='phone'
              value={editFormData.phone || ''}
              onChange={(value) =>
                setEditFormData({ ...editFormData, phone: value })
              }
              onBlur={handleBlur}
            />
            {errors.phone && (
              <div className={styles.errorMessage}>{errors.phone}</div>
            )}
            <Input
              label={t('email') + ':'}
              name='email'
              type='email'
              value={editFormData.email || ''}
              onChange={(value) =>
                setEditFormData({ ...editFormData, email: value })
              }
              onBlur={handleBlur}
            />
            {errors.email && (
              <div className={styles.errorMessage}>
                {errors.problemDescription}
              </div>
            )}
            <Input
              label={t('messenger') + ':'}
              name='messenger'
              value={editFormData.messenger || ''}
              onChange={(value) =>
                setEditFormData({ ...editFormData, messenger: value })
              }
              onBlur={handleBlur}
            />
            {errors.messenger && (
              <div className={styles.errorMessage}>{errors.messenger}</div>
            )}
            <Input
              label={t('available_time') + ':'}
              name='availableTimeDuration'
              value={editFormData.availableTimeDuration || ''}
              onChange={(value) =>
                setEditFormData({
                  ...editFormData,
                  availableTimeDuration: value,
                })
              }
              onBlur={handleBlur}
            />
            {errors.availableTimeDuration && (
              <div className={styles.errorMessage}>
                {errors.availableTimeDuration}
              </div>
            )}
            <Input
              label={t('preferred_language') + ':'}
              name='preferredLanguage'
              value={editFormData.preferredLanguage || ''}
              onChange={(value) =>
                setEditFormData({ ...editFormData, preferredLanguage: value })
              }
              onBlur={handleBlur}
            />
            {errors.preferredLanguage && (
              <div className={styles.errorMessage}>
                {errors.preferredLanguage}
              </div>
            )}
            <Flex gap={10}>
              <button
                className={styles.deleteButton}
                onClick={handleDeleteWorker}
              >
                {t('delete_worker')}
              </button>

              <button
                className={styles.changePasswordButton}
                onClick={() => {
                  setChangingPassword(!changingPassword);
                  handleEditModalClose();
                }}
              >
                {t('change_password')}
              </button>
            </Flex>
            <Flex gap={10}>
              <button
                type='submit'
                className={styles.saveButton}
                disabled={() => handleFormErrors()}
              >
                {t('save')}
              </button>
              <button type='button' onClick={handleCancelEdit}>
                {t('cancel')}
              </button>
            </Flex>
          </Flex>
        </form>
      </Flex>
    </Modal>
  );
};

export default EditWorkerBlock;
