import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { useParams } from 'react-router-dom';
import L from 'leaflet';
import styles from './WorkerPage.module.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import markerIconUrl from './img/marker-icon.png';
import markerShadowUrl from './img/marker-shadow.png';
import markerIconActiveUrl from './img/marker-icon-active.png';
import markerIconAllUrl from './img/marker-icon-all.png';

import WorkersList from '../components/admin/WorkersList';
import WorkerPanel from '../components/admin/WorkerPanel';
import { tokenService } from '../services/tokenService';
import WorkerPageBlock from '../features_worker/WorkerPageBlock/WorkerPageBlock';
import EditWorkerBlock from '../features_worker/EditWorkerBlock/EditWorkerBlock';
import ChangingPasswordBlock from '../features_worker/ChangingPasswordBlock/ChangingPasswordBlock';
import FiltersBlock from '../features_worker/FiltersBlock/FiltersBlock';
import WorkerMapBlock from '../features_worker/WorkerMapBlock/WorkerMapBlock';
import AssignedProblemsBlock from '../features_worker/AssignedProblemsBlock/AssignedProblemsBlock';
import CompletedProblemsBlock from '../features_worker/CompletedProblemsBlock/CompletedProblemsBlock';
import HousesWithProblems from '../features_worker/HousesWithProblems/HousesWithProblems';
import { FooterBlock, HeaderBlock } from '../features';
import CurrentProblemModal from '../features_worker/CurrentProblemModal/CurrentProblemModal';
import HouseInfoBlock from '../features_worker/HouseInfoBlock/HouseInfoBlock';
import LanguageSwitcher from '../components/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import PhotoModal from '../features/PhotoModal/PhotoModal';
import { Flex, Text } from '../ui';
import { useMediaQuery } from 'react-responsive';
import { desktopQuery } from '../consts';
import WorkerMobileLinks from '../features_worker/WorkerMobileLinks/WorkerMobileLinks';

const WorkerPage = ({ match }) => {
  // Определение состояний компонента
  const [worker, setWorker] = useState(null);
  const [housesWithProblems, setHousesWithProblems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showOnlyUrgent, setShowOnlyUrgent] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [showOnlyMySkills, setShowOnlyMySkills] = useState(false);
  const [assignedProblems, setAssignedProblems] = useState([]);
  const [skills, setSkills] = useState([]);
  const [completedProblems, setCompletedProblems] = useState([]);
  const workerId = tokenService.getWorkerId();
  const [previewPhotos, setPreviewPhotos] = useState({});
  const [dateFilter, setDateFilter] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [statusMessage, setStatusMessage] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const [refreshHousesWithProblems, setRefreshHousesWithProblems] =
    useState(false);
  const [editFormData, setEditFormData] = useState({
    name: '',
    phone: '',
    email: '',
    salary: '',
  });
  const [editing, setEditing] = useState(false);
  const [changingPassword, setChangingPassword] = useState(false);
  const [passwordData, setPasswordData] = useState({ newPassword: '' });

  const [showCopyNotification, setShowCopyNotification] = useState(false);
  const [selectedHouse, setSelectedHouse] = useState(null);
  const [houseInfo, setHouseInfo] = useState(null);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [activeTab, setActiveTab] = useState('Open');

  const [subTab, setSubTab] = useState('Urgent');

  const [selectedSkills, setSelectedSkills] = useState([]);

  const [workerPhoto, setWorkerPhoto] = useState('');
  const [workerSkills, setWorkerSkills] = useState([]);
  const [openProblem, setOpenProblem] = useState(false);
  const [currentProblem, setCurrentProblem] = useState(null);
  const [openPhotoModal, setOpenPhotoModal] = useState(false);
  const [photoModal, setPhotoModal] = useState(null);
  const isDesktop = useMediaQuery(desktopQuery);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleOpenPhotoModal = (photo) => {
    setPhotoModal(photo);
    setOpenPhotoModal(true);
  };

  const handleClosePhotoModal = () => {
    setOpenPhotoModal(false);
  };

  const handleOpenProblem = () => {
    setOpenProblem(true);
  };

  const handleCloseProblem = () => {
    setOpenProblem(false);
  };

  const handleSetCurrentProblem = (problem) => {
    const house = housesWithProblems.find(
      (house) => house.house._id == problem.houseId
    );
    setCurrentProblem({ ...problem, ...house });
  };

  const calculateMapCenter = (houses) => {
    let totalLat = 0;
    let totalLng = 0;
    let validHouseCount = 0; // Счетчик домов с корректными координатами

    houses.forEach((house) => {
      const coordinates = house.house.mapLocationCoordinates.coordinates;
      // console.log(`Исходные координаты для дома ${house.house.name}: ${coordinates}`);

      if (
        coordinates &&
        coordinates.length === 2 &&
        !isNaN(coordinates[0]) &&
        !isNaN(coordinates[1])
      ) {
        totalLng += coordinates[0]; // Сначала широта
        totalLat += coordinates[1]; // Потом долгота
        validHouseCount++;
        //console.log(`Дом: ${house.house.name}, Координаты: ${coordinates}`);
      }
    });

    if (validHouseCount === 0) {
      //console.log("Нет домов с корректными координатами для расчета центра карты.");
      // return [58, 7]; // Возвращаем значения по умолчанию
    }

    const centerLng = totalLng / validHouseCount; // Средняя широта
    const centerLat = totalLat / validHouseCount; // Средняя долгота
    const mapCenter = [centerLat, centerLng]; // Обратите внимание на порядок: сначала широта, потом долгота
    //console.log(`Рассчитанный центр карты: ${mapCenter}`);

    return mapCenter;
  };

  const [mapCenter, setMapCenter] = useState(() => {
    const initialCenter = calculateMapCenter(housesWithProblems); // Рассчитываем начальные координаты
    return initialCenter; // Возвращаем их как начальное значение для mapCenter
  });

  const mapRef = useRef(null); // Инициализация ссылки

  useEffect(() => {
    const workerIdFromStorage = tokenService.getWorkerId();

    const actualWorkerId = workerId || workerIdFromStorage;

    if (actualWorkerId) {
      fetchWorkerData(actualWorkerId);
    } else {
      console.error('Worker ID не найден');
    }
  }, [workerId]);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);

      try {
        await fetchWorkerData();
        await fetchHousesWithProblems();
        await fetchAssignedProblems();
        await fetchSkills();
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [workerId, showOnlyUrgent, selectedStatuses, showOnlyMySkills]);

  useEffect(() => {
    if (housesWithProblems.length > 0) {
      const newCenter = calculateMapCenter(housesWithProblems);
      setMapCenter(newCenter);
    }
  }, [housesWithProblems]);

  useEffect(() => {
    fetchSkills();
  }, []);

  useEffect(() => {
    if (refreshHousesWithProblems || selectedSkills.length > 0) {
      fetchHousesWithProblems();
      setRefreshHousesWithProblems(false);
    }
  }, [
    dateFilter,
    startDate,
    endDate,
    showOnlyUrgent,
    showOnlyMySkills,
    refreshHousesWithProblems,
    selectedSkills,
  ]);

  useEffect(() => {
    if (selectedSkills.length === 0) {
      setHousesWithProblems([]);
    }
  }, [selectedSkills]);

  useEffect(() => {
    if (skills.length > 0) {
      setSelectedSkills(skills.map((skill) => skill._id));
    }
  }, [skills]);

  // Функции для работы с данными
  const formatSalary = (salary) => {
    return salary ? salary.toString() : '';
  };

  const fetchWorkerData = async () => {
    try {
      const token = tokenService.getToken();

      if (!token) {
        console.error('Аутентификационный токен не найден');
        return;
      }

      const response = await axios.get(
        `https://fix.house/api/workers/${workerId}/complete-profile`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('Данные работника получены:', response.data);

      console.log('URL фото работника:', response.data.photo);

      setWorker(response.data);
      setWorkerPhoto(response.data.photo);
      setWorkerSkills(response.data.skills);
      tokenService.saveWorkerId(response.data._id);
    } catch (error) {
      console.error('Ошибка при загрузке данных работника:', error);
    }
  };

  const handleDateChange = (e) => {
    setDateFilter(e.target.value);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleFileChange = (problemId, event) => {
    const files = Array.from(event.target.files);
    if (files.length < 1) {
      return;
    }

    const previews = files.map((file) => URL.createObjectURL(file));
    setPreviewPhotos((prev) => ({ ...prev, [problemId]: previews }));

    setAssignedProblems((prev) =>
      prev.map((problem) =>
        problem._id === problemId ? { ...problem, canFinish: true } : problem
      )
    );
  };

  const fetchHousesWithProblems = async () => {
    try {
      const token = tokenService.getToken();
      if (!token) {
        console.error('Аутентификационный токен не найден');
        return;
      }

      const params = new URLSearchParams();
      if (showOnlyUrgent) {
        params.append('urgent', 'true');
      }
      selectedStatuses.forEach((status) => params.append('status', status));

      if (showOnlyMySkills && worker && worker.skills) {
        worker.skills.forEach((skill) => {
          params.append('skillIds', skill._id);
        });
      }
      if (selectedSkills.length > 0) {
        selectedSkills.forEach((skill) => params.append('skillIds', skill));
      }

      if (dateFilter) {
        params.append('scheduledDate', dateFilter);
      }

      const response = await axios.get(
        'https://fix.house/api/problems/client/problems/houses-with-problems',
        {
          headers: { Authorization: `Bearer ${token}` },
          params: params,
        }
      );

      let housesWithProblems = await Promise.all(
        response.data.map(async (house) => {
          const problems = await Promise.all(
            house.problems.map(async (problem) => {
              const problemWithImage = {
                ...problem,
                imageUrl: getProblemImageUrl(problem),
              };
              // Загрузка данных работника для проблем в статусе "In Progress"
              if (
                problem.status === 'In Progress' &&
                problem.assignedWorkerId
              ) {
                try {
                  const workerResponse = await axios.get(
                    `https://fix.house/api/workers/${problem.assignedWorkerId}`,
                    {
                      headers: { Authorization: `Bearer ${token}` },
                    }
                  );
                  problemWithImage.assignedWorker = workerResponse.data;
                } catch (err) {
                  console.error(
                    `Ошибка при загрузке данных работника ${problem.assignedWorkerId}:`,
                    err
                  );
                  problemWithImage.assignedWorker = null; // Обработка случая, когда данные работника не доступны
                }
              }
              return problemWithImage;
            })
          );

          return {
            ...house,
            imageUrl: getHouseImageUrl(house.house.image),
            problems: problems,
          };
        })
      );

      // Применяем фильтрацию по датам на клиенте
      if (startDate && endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);
        housesWithProblems = housesWithProblems.filter((house) =>
          house.problems.some((problem) => {
            const problemDate = new Date(problem.scheduledDate);
            return problemDate >= start && problemDate <= end;
          })
        );
      }

      setHousesWithProblems(housesWithProblems);

      if (housesWithProblems.length > 0) {
        const newCenter = calculateMapCenter(housesWithProblems);
        setMapCenter(newCenter);
      } else {
        setMapCenter([101.885485, 43.115536]); // Установка дефолтного центра карты
      }
    } catch (error) {
      console.error('Ошибка при загрузке домов с проблемами:', error);
    }
  };

  const getProblemImageUrl = (problem) => {
    return problem.initialPhotoID
      ? `https://fix.house/${problem.initialPhotoID.imageURL}`
      : 'путь/к/запасному/изображению.jpg';
  };

  const fetchAssignedProblems = async () => {
    try {
      const token = tokenService.getToken();
      if (!token) {
        console.error('Аутентификационный токен не найден');
        return;
      }

      const response = await axios.get(
        'https://fix.house/api/problems/assigned',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log('Назначенные проблемы:', response.data);

      // Получаем информацию о фотографиях для каждой проблемы
      const problemsWithPhotos = await Promise.all(
        response.data.map(async (problem) => {
          if (problem.initialPhotoID) {
            try {
              const photoResponse = await axios.get(
                `https://fix.house/api/photos/${problem.initialPhotoID}`,
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              );
              return { ...problem, photoURL: photoResponse.data.imageURL };
            } catch (error) {
              console.error(
                `Ошибка при получении фото для проблемы ${problem._id}:`,
                error
              );
              return problem;
            }
          }
          return problem;
        })
      );

      // Сохраняем существующую логику фильтрации
      const assignedProblems = problemsWithPhotos.filter(
        (problem) => problem.status !== 'Finished'
      );
      const completedProblems = problemsWithPhotos.filter(
        (problem) => problem.status === 'Finished'
      );

      setAssignedProblems(assignedProblems);
      setCompletedProblems(completedProblems);
    } catch (error) {
      console.error('Ошибка при получении проблем:', error);
    }
  };

  const fetchSkills = async () => {
    try {
      const response = await axios.get('https://fix.house/api/skills');
      //console.log('Список навыков:', response.data);

      setSkills(response.data);
    } catch (error) {
      console.error('Ошибка при получении списка навыков:', error);
    }
  };

  const assignProblemToSelf = async (problemId, image) => {
    try {
      const token = tokenService.getToken();

      if (!token) {
        console.error('Аутентификационный токен не найден');
        return;
      }

      const response = await axios.put(
        `https://fix.house/api/problems/${problemId}/assign-worker`,
        { workerId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log('Проблема назначена:', response.data);

      // Обновляем состояние housesWithProblems и assignedProblems
      setHousesWithProblems((prevHouses) => {
        return prevHouses
          .map((house) => {
            const updatedProblems = house.problems.filter(
              (problem) => problem._id !== problemId
            );
            return {
              ...house,
              problems: updatedProblems,
            };
          })
          .filter((house) => house.problems.length > 0);
      });

      setAssignedProblems((prevProblems) => [
        ...prevProblems,
        { ...response.data, initialPhotoID: { imageURL: image } },
      ]);
      setRefreshHousesWithProblems(true);
    } catch (error) {
      console.error('Ошибка при назначении задачи:', error);
    }
  };

  const unassignProblemFromSelf = async (problemId) => {
    try {
      const token = tokenService.getToken();

      if (!token) {
        console.error('Аутентификационный токен не найден');
        return;
      }

      await axios.put(
        `https://fix.house/api/problems/problems/${problemId}`,
        { status: 'Open' },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      await axios.put(
        `https://fix.house/api/problems/${problemId}/unassign-worker`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Удаляем проблему из списка назначенных проблем
      setAssignedProblems((prevProblems) =>
        prevProblems.filter((problem) => problem._id !== problemId)
      );

      // Устанавливаем флаг для обновления домов с проблемами
      setRefreshHousesWithProblems(true);
    } catch (error) {
      console.error('Ошибка при отказе от задачи:', error);
      // Обработка ошибки 403 Forbidden
      if (error.response && error.response.status === 403) {
        console.error(
          'Нет доступа к ресурсу. Возможно, истек токен авторизации.'
        );
        // Дополнительные действия, например, перенаправление на страницу входа
      }
    }
  };

  const markProblemAsFinished = async (problemId) => {
    const token = tokenService.getToken();
    if (!token) {
      console.error('Аутентификационный токен не найден');
      return;
    }

    const fileInput = document.getElementById(`finishedPhotos-${problemId}`);
    const files = fileInput.files;

    if (files.length < 1) {
      setStatusMessage({
        type: 'error',
        text: 'Необходимо загрузить фото для завершения работы.',
      });
      return;
    }

    const formData = new FormData();
    formData.append('status', 'Finished');

    for (let i = 0; i < files.length; i++) {
      formData.append('finishedPhotos', files[i]);
    }

    try {
      const response = await axios.put(
        `https://fix.house/api/problems/problems/${problemId}/finish`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      setCompletedProblems((prev) => [...prev, response.data]);
      setAssignedProblems((prev) =>
        prev.filter((problem) => problem._id !== problemId)
      );

      // Очищаем предпросмотр фотографий
      setPreviewPhotos((prev) => {
        const newPreviews = { ...prev };
        delete newPreviews[problemId];
        return newPreviews;
      });

      fileInput.value = '';

      // Добавляем сообщение об успешном завершении
      setStatusMessage({
        type: 'success',
        text: 'Проблема успешно завершена и фотография загружена.',
      });
    } catch (error) {
      console.error('Ошибка при обновлении статуса проблемы:', error);
      // Добавляем сообщение об ошибке
      setStatusMessage({
        type: 'error',
        text: 'Произошла ошибка при завершении работы. Пожалуйста, попробуйте еще раз.',
      });
    }
  };

  useEffect(() => {
    //console.log('Список назначенных проблем обновлен:', assignedProblems);
  }, [assignedProblems]);

  const updateProblemsData = (updatedProblem, isAssigned) => {
    const updatedAssignedProblems = assignedProblems.filter(
      (p) => p._id !== updatedProblem._id
    );
    if (isAssigned) updatedAssignedProblems.push(updatedProblem);

    setAssignedProblems(updatedAssignedProblems);

    setHousesWithProblems(
      housesWithProblems.map((house) => ({
        ...house,
        problems: house.problems.map((p) => {
          if (p._id === updatedProblem._id) {
            // Проверяем, содержит ли updatedProblem информацию о изображении
            if (!updatedProblem.imageUrl && p.imageUrl) {
              // Если нет, сохраняем старый URL изображения
              return { ...updatedProblem, imageUrl: p.imageUrl };
            }
            return updatedProblem;
          }
          return p;
        }),
      }))
    );
  };

  const getSkillNameById = (skillId) => {
    const skill = skills.find((s) => s._id === skillId);
    return skill ? skill.skillName : 'Неизвестный навык';
  };

  const generateTelegramLink = () => {
    const botUsername = 'house_keepers_bot'; // Замените на имя вашего бота
    const startParams = `${workerId}-worker`; // Формат: 'userID-role'
    return `https://t.me/${botUsername}?start=${encodeURIComponent(
      startParams
    )}`;
  };

  const statusOptions = ['Open', 'In Progress', 'Finished'];

  // Объявляем функцию calculateMapCenter здесь

  // Далее ваш компонент WorkerPage и его код, где используется calculateMapCenter

  const isProblemAssigned = (problemId) => {
    const assigned = assignedProblems.some(
      (problem) => problem._id === problemId
    );

    return assigned;
  };

  const handleFilterChange = () => {
    // Здесь можно добавить логику для обновления URL, если это необходимо
    fetchHousesWithProblems();
  };

  const customMarkerIcon = L.icon({
    iconUrl: markerIconUrl,
    shadowUrl: markerShadowUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  const customMarkerIconActive = L.icon({
    iconUrl: markerIconActiveUrl,
    shadowUrl: markerShadowUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  const customMarkerIconAll = L.icon({
    iconUrl: markerIconAllUrl,
    shadowUrl: markerShadowUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  const getHouseImageUrl = (imagePath) => {
    if (!imagePath) {
      return 'путь/к/запасному/изображению.jpg'; // Запасной URL изображения
    }
    return `https://fix.house/${imagePath}`; // Базовый URL сервера
  };

  /////////////////

  const handleEditWorker = async (e) => {
    e.preventDefault();
    try {
      const token = tokenService.getToken();

      await axios.put(
        `https://fix.house/api/workers/${workerId}`,
        editFormData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // Обновление данных работника на клиенте
      fetchWorkerData();
      setEditModalOpen(false);
    } catch (error) {
      console.error('Ошибка при редактировании информации о работнике:', error);
    }
  };

  const handleDeleteWorker = async () => {
    try {
      const token = tokenService.getToken();

      await axios.delete(`https://fix.house/api/workers/${workerId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // Перенаправление на другую страницу после удаления
      navigate('/workers');
    } catch (error) {
      console.error('Ошибка при удалении работника:', error);
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    try {
      const token = tokenService.getToken();

      await axios.put(
        `https://fix.house/api/workers/${workerId}/change-password`,
        passwordData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setPasswordData({ newPassword: '' });
      setChangingPassword(false);
    } catch (error) {
      console.error('Ошибка при изменении пароля:', error);
    }
  };

  const handleCancelEdit = () => {
    setEditFormData({
      name: worker.name || '',
      phone: worker.phone || '',
      email: worker.email || '',
      messenger: worker.messenger || '',
      availableTimeDuration: worker.availableTimeDuration || '',
      preferredLanguage: worker.preferredLanguage || '',
    });
    setEditModalOpen(false);
  };

  const handleEditClick = () => {
    setEditFormData({
      name: worker.name || '',
      phone: worker.phone || '',
      email: worker.email || '',
      messenger: worker.messenger || '',
      availableTimeDuration: worker.availableTimeDuration || '',
      preferredLanguage: worker.preferredLanguage || '',
    });
    setEditModalOpen(true);
  };

  const handleCopyCoordinates = (latitude, longitude) => {
    const coordinates = `${latitude}, ${longitude}`;
    navigator.clipboard
      .writeText(coordinates)
      .then(() => {
        setShowCopyNotification(true); // Показать уведомление
        setTimeout(() => {
          setShowCopyNotification(false); // Автоматически скрыть уведомление через 3 секунды
        }, 3000);
      })
      .catch((err) => console.error('Ошибка при копировании координат: ', err));
  };

  const handleMarkerClick = (house) => {
    setSelectedHouse(house);
  };

  ////////////////////////////////////////////////////////
  useEffect(() => {
    if (selectedHouse) {
      setHouseInfo(selectedHouse);
    } else {
      setHouseInfo(null);
    }
  }, [selectedHouse]);
  ///////////////////////////////////////////////////////

  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
  };

  const handleSubTabChange = (newSubTab) => {
    setSubTab(newSubTab);
  };

  const handleEditModalOpen = () => setEditModalOpen(true);

  const handleEditModalClose = () => setEditModalOpen(false);

  const handleLogout = () => {
    tokenService.removeToken();
    navigate('/client-register');
  };

  ////////////////////////////////////////////////

  return (
    <div>
      <WorkerPanel handleLogout={handleLogout} />
      <LanguageSwitcher />

      <div className={styles.pageContainer}>
        <div className={styles.pageContainerContent}>
          <div className={styles.blockContent}>
            {!isDesktop && <WorkerMobileLinks />}
            <div>
              <h2 className={styles.pageHeader}>{t('worker_header')}</h2>
            </div>
          </div>
          {loading ? (
            <p>{t('loading')}...</p>
          ) : (
            <div>
              {worker && (
                <WorkerPageBlock
                  worker={worker}
                  workerPhoto={workerPhoto}
                  workerSkills={workerSkills}
                  handleEditClick={handleEditClick}
                />
              )}
              {editModalOpen && (
                <EditWorkerBlock
                  handleCancelEdit={handleCancelEdit}
                  handleDeleteWorker={handleDeleteWorker}
                  handleEditWorker={handleEditWorker}
                  editFormData={editFormData}
                  setEditFormData={setEditFormData}
                  setChangingPassword={setChangingPassword}
                  changingPassword={changingPassword}
                  handleEditModalClose={handleEditModalClose}
                />
              )}

              {changingPassword && (
                <ChangingPasswordBlock
                  handleChangePassword={handleChangePassword}
                  passwordData={passwordData}
                  setPasswordData={setPasswordData}
                  changingPassword={changingPassword}
                  setChangingPassword={setChangingPassword}
                />
              )}

              <FiltersBlock
                showOnlyMySkills={showOnlyMySkills}
                showOnlyUrgent={showOnlyUrgent}
                setSelectedSkills={setSelectedSkills}
                setSelectedStatuses={setSelectedStatuses}
                setShowOnlyMySkills={setShowOnlyMySkills}
                setShowOnlyUrgent={setShowOnlyUrgent}
                handleEndDateChange={handleEndDateChange}
                handleFilterChange={handleFilterChange}
                handleStartDateChange={handleStartDateChange}
                selectedStatuses={selectedStatuses}
                statusOptions={statusOptions}
                skills={skills}
                selectedSkills={selectedSkills}
                startDate={startDate}
                endDate={endDate}
                fetchHousesWithProblems={fetchHousesWithProblems}
              />

              <WorkerMapBlock
                mapCenter={mapCenter}
                mapRef={mapRef}
                housesWithProblems={housesWithProblems}
                selectedHouse={selectedHouse}
                isProblemAssigned={isProblemAssigned}
                customMarkerIcon={customMarkerIcon}
                customMarkerIconActive={customMarkerIconActive}
                customMarkerIconAll={customMarkerIconAll}
                setSelectedHouse={setSelectedHouse}
                assignProblemToSelf={assignProblemToSelf}
                activeTab={activeTab}
                workerId={workerId}
                unassignProblemFromSelf={unassignProblemFromSelf}
                setSubTab={setSubTab}
                showCopyNotification={showCopyNotification}
                subTab={subTab}
                handleCopyCoordinates={handleCopyCoordinates}
                handleSubTabChange={handleSubTabChange}
                handleTabChange={handleTabChange}
                houseInfo={houseInfo}
              />

              {houseInfo && (
                <HouseInfoBlock
                  houseInfo={houseInfo}
                  handleCopyCoordinates={handleCopyCoordinates}
                  handleSubTabChange={handleSubTabChange}
                  handleTabChange={handleTabChange}
                  activeTab={activeTab}
                  setSubTab={setSubTab}
                  subTab={subTab}
                  isProblemAssigned={isProblemAssigned}
                  assignProblemToSelf={assignProblemToSelf}
                  workerId={workerId}
                  unassignProblemFromSelf={unassignProblemFromSelf}
                  showCopyNotification={showCopyNotification}
                />
              )}

              {/* ///////////// HOUSE INFO - ОКНО СБОКУ ОТ КАРТЫ ------END/////////////////////////////// */}

              <AssignedProblemsBlock
                assignedProblems={assignedProblems}
                handleFileChange={handleFileChange}
                previewPhotos={previewPhotos}
                markProblemAsFinished={markProblemAsFinished}
                unassignProblemFromSelf={unassignProblemFromSelf}
                statusMessage={statusMessage}
                setCurrentProblem={handleSetCurrentProblem}
                handleOpenProblem={handleOpenProblem}
                handleOpenPhotoModal={handleOpenPhotoModal}
              />

              <CompletedProblemsBlock
                completedProblems={completedProblems}
                handleOpenPhotoModal={handleOpenPhotoModal}
              />
              <HousesWithProblems
                housesWithProblems={housesWithProblems}
                handleCopyCoordinates={handleCopyCoordinates}
                showCopyNotification={showCopyNotification}
                isProblemAssigned={isProblemAssigned}
                unassignProblemFromSelf={unassignProblemFromSelf}
                assignProblemToSelf={assignProblemToSelf}
                handleOpenPhotoModal={handleOpenPhotoModal}
              />
              {openProblem && (
                <CurrentProblemModal
                  handleCloseEvent={handleCloseProblem}
                  currentEvent={currentProblem}
                  unassignProblemFromSelf={unassignProblemFromSelf}
                  markProblemAsFinished={markProblemAsFinished}
                  previewPhotos={previewPhotos}
                />
              )}
              {openPhotoModal && (
                <PhotoModal
                  photoModal={photoModal}
                  closePhotoModal={handleClosePhotoModal}
                />
              )}
            </div>
          )}
        </div>
      </div>

      <FooterBlock />
    </div>
  );
};

export default WorkerPage;
