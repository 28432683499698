import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FooterBlock, HeaderBlock } from '../features';
import styles from './NotFoundPage.module.scss';
import { Flex } from '../ui';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../components/LanguageSwitcher';
import { tokenService } from '../services/tokenService';

function NotFoundPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLogout = () => {
    tokenService.removeToken();
    navigate('/login');
  };

  return (
    <div>
      <HeaderBlock handleLogout={handleLogout} />
      <LanguageSwitcher />
      <div className={styles.container}>
        <div className={styles.blockContent}>
          <h1>{t('not_found_title')}</h1>
          <p className={styles.text}>{t('not_found_description')}</p>
          <Flex gap={10}>
            <button onClick={() => navigate('/login')}>{t('login')}</button>
            <button onClick={() => navigate('/')}>{t('go_home')}</button>
            <button onClick={() => navigate(-1)}>{t('go_back')}</button>
          </Flex>
        </div>
      </div>
      <FooterBlock />
    </div>
  );
}

export default NotFoundPage;
