import cn from 'classnames';

import styles from './Text.module.scss';

export const Text = (props) => {
  const {
    size = 16,
    lineHeight = 1.2,
    weight = "regular",
    color = "black",
    align = "start",
    decoration,
    tag = "p",
    ellipsis,
    children,
    className,
    style,
    ...restProps
  } = props;

  const Component = tag;

  return (
    <Component 
      className={cn(styles.text, {
        [styles.textBold]: weight === "bold",
        [styles.textSemiBold]: weight === "semiBold",
        [styles.textMedium]: weight === "medium",
        [styles.textRegular]: weight === "regular",
        [styles.textBlack]: color === "black",
        [styles.textGray]: color === "gray",
        [styles.textWhite]: color === "white",
        [styles.textCenter]: align === "center",
        [styles.textStart]: align === "start",
        [styles.textEnd]: align === "end",
        [styles.textUnderline]: decoration === "underline",
        [styles.textEllipsis]: ellipsis,
      }, className)}
      style={{fontSize: size, lineHeight, ...style}}
      {...restProps}
    >
      {children}
    </Component>
  )
}