import React from 'react';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';

import { desktopQuery } from '../../consts';
import { Button, Flex, Divider, Text, Container, Bar } from '../../ui';
import { PenIcon, PlusIcon } from '../../static';
import styles from './PropertyBlock.module.scss';
import { useTranslation } from 'react-i18next';

export const PropertyBlock = (props) => {
  const {
    className,
    houses,
    getImageUrl,
    addHouse,
    manageHouse,
    ...restProps
  } = props;

  const isDesktop = useMediaQuery(desktopQuery);
  const { t } = useTranslation();

  return isDesktop ? (
    <section className={cn(styles.propertyBlock, className)} {...restProps}>
      <Container>
        <Flex direction='column' gap={40}>
          <Button type='white' gap='large' onClick={addHouse}>
            <PlusIcon />
            <Text size={17} weight='bold'>
              {t('add_house')}
            </Text>
          </Button>

          <Flex direction='row' gap={40} wrap>
            {houses.map((house) => (
              <Flex
                direction='column'
                gap={14}
                key={house._id}
                className={styles.propertyCard}
              >
                <Flex direction='column' gap={6}>
                  <Text color='gray'>{t('house_name')}:</Text>
                  <Text
                    size={22}
                    lineHeight='30px'
                    weight='bold'
                    className={styles.clampable}
                  >
                    {house.name}
                  </Text>
                </Flex>

                <Flex direction='column' gap={10}>
                  <img
                    className={styles.propertyCardImage}
                    src={getImageUrl(house.image)}
                    alt='Изображение дома'
                  />

                  <div>
                    <Text lineHeight='24px' color='gray'>
                      {t('address')}:
                    </Text>
                    <Text size={20} weight='bold' className={styles.clampable}>
                      {house.address}
                    </Text>
                  </div>

                  <div>
                    <Text lineHeight='24px' color='gray'>
                      {t('area')}:
                    </Text>
                    <Text size={20} weight='bold'>
                      {house.squareMeters} {t('sq_m')}
                    </Text>
                  </div>
                </Flex>

                <Button type='white' onClick={() => manageHouse(house._id)}>
                  <PenIcon />
                  <Text size={17} weight='bold'>
                    {t('manage_house')}
                  </Text>
                </Button>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Container>
    </section>
  ) : (
    <Container>
      <section className={cn(styles.propertyBlock, className)} {...restProps}>
        <Flex direction='column' gap={12}>
          <Button type='white' gap='large' onClick={addHouse}>
            <PlusIcon />
            <Text size={14} weight='bold'>
              {t('add_house')}
            </Text>
          </Button>

          <Flex direction={'column'} gap={12}>
            {houses.map((house, index) => (
              <React.Fragment key={house._id}>
                <Flex
                  direction='column'
                  gap={20}
                  className={styles.propertyCard}
                  onClick={() => manageHouse(house._id)}
                >
                  <Bar>
                    <Text color='gray'>{t('house_name')}:</Text>
                    <Text size={18} weight='bold'>
                      {house.name}
                    </Text>
                  </Bar>

                  <div className={styles.propertyMainInfo}>
                    <img
                      className={styles.propertyCardImage}
                      src={getImageUrl(house.image)}
                      alt='Изображение дома'
                    />

                    <div>
                      <Text size={14} color='gray'>
                        {t('address')}:
                      </Text>
                      <Text size={20} weight='bold'>
                        {house.address}
                      </Text>
                    </div>

                    <div>
                      <Text size={14} color='gray'>
                        {t('area')}:
                      </Text>
                      <Text size={20} weight='bold'>
                        {house.squareMeters} {t('sq_m')}
                      </Text>
                    </div>
                  </div>

                  <Flex justify='start' className={styles.manageHouseButton}>
                    <Button
                      type='white'
                      gap='large'
                      onClick={() => manageHouse(house._id)}
                    >
                      <PenIcon />
                      <Text size={14} weight='bold'>
                        {t('manage_house')}
                      </Text>
                    </Button>
                  </Flex>
                </Flex>
                {index < houses.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </Flex>
        </Flex>
      </section>
    </Container>
  );
};
