import cn from 'classnames';

import styles from './IconButton.module.scss';

export const IconButton = (props) => {
  const {
    active,
    type,
    size,
    tag = "button",
    children,
    className,
    ...restProps
  } = props;

  const Component = tag;

  return (
    <Component className={cn(styles.iconButton, {
      [styles.iconButtonOutline]: type === "outline",
      [styles.iconButtonTransparent]: type === "transparent",
      [styles.iconButtonGray]: type === "gray",
      [styles.small]: size === "s",
      [styles.medium]: size === "m",
      [styles.large]: size === "l",
      [styles.active]: active
    }, className)} {...restProps}>
      {children}
    </Component>
  )
}