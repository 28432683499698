import axios from 'axios';
import { tokenService } from '../services/tokenService';

export const getProblems = async (payload) => {
  try {
    const token = tokenService.getToken();
    if (!token) {
      console.error('Аутентификационный токен не найден');
      return;
    }

    const response = await axios.get(
      `https://fix.house/api/problems/client/houses/${payload.houseId}/problems`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log('Проблемы:', response.data);

    return response.data;
  } catch (error) {
    throw error;
  }
};
