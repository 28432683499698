import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { tokenService } from './../../services/tokenService';

const CRUDSubscriptionsAdmin = () => {
  const [subscriptionTypes, setSubscriptionTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  // Состояние для управления формой добавления нового типа подписки
  const [newTypeName, setNewTypeName] = useState('');
  const [newTypeDescription, setNewTypeDescription] = useState('');
  const [newTypePrice, setNewTypePrice] = useState('');
  const [newTypeDuration, setNewTypeDuration] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingSubscription, setEditingSubscription] = useState({
    id: '',
    typeName: '',
    description: '',
    price: '',
    duration: '',
  });

  // Загрузка типов подписок
  useEffect(() => {
    const fetchSubscriptionTypes = async () => {
      setLoading(true);

      const token = tokenService.getToken();
      if (!token) {
        console.error('Аутентификационный токен не найден');
        setLoading(false); // Обязательно остановить индикатор загрузки
        return;
      }

      try {
        const response = await axios.get(
          'http://localhost:3000/api/subscription-types',
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSubscriptionTypes(response.data);
      } catch (error) {
        console.error('Ошибка при получении типов подписок:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionTypes();
  }, []);

  // Функция для добавления нового типа подписки
  const addSubscriptionType = async (
    typeName,
    description,
    price,
    duration
  ) => {
    const token = tokenService.getToken();
    if (!token) {
      console.error('Аутентификационный токен не найден');
      return;
    }

    try {
      const response = await axios.post(
        'http://localhost:3000/api/subscription-types',
        { typeName, description, price, duration },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Обновляем список типов подписок после успешного добавления
      setSubscriptionTypes([...subscriptionTypes, response.data]);
    } catch (error) {
      console.error('Ошибка при добавлении типа подписки:', error);
    }
  };

  // Функция для редактирования типа подписки
  const editSubscriptionType = async (id, updatedType) => {
    const token = tokenService.getToken();
    if (!token) {
      console.error('Аутентификационный токен не найден');
      return;
    }

    try {
      const response = await axios.put(
        `http://localhost:3000/api/subscription-types/${id}`,
        updatedType,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Обновляем список типов подписок после успешного редактирования
      const updatedSubscriptionTypes = subscriptionTypes.map((type) =>
        type._id === id ? { ...type, ...updatedType } : type
      );
      setSubscriptionTypes(updatedSubscriptionTypes);
    } catch (error) {
      console.error('Ошибка при редактировании типа подписки:', error);
    }
  };

  // Функция для удаления типа подписки
  // Функция для удаления типа подписки
  const deleteSubscriptionType = async (id) => {
    const token = tokenService.getToken();
    if (!token) {
      console.error('Аутентификационный токен не найден');
      return;
    }

    try {
      await axios.delete(`http://localhost:3000/api/subscription-types/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Обновляем список типов подписок после успешного удаления
      const updatedSubscriptionTypes = subscriptionTypes.filter(
        (type) => type._id !== id
      );
      setSubscriptionTypes(updatedSubscriptionTypes);
    } catch (error) {
      console.error('Ошибка при удалении типа подписки:', error);
    }
  };

  // Функция обработки добавления нового типа подписки
  const handleAddNewType = async () => {
    await addSubscriptionType(
      newTypeName,
      newTypeDescription,
      newTypePrice,
      newTypeDuration
    );
    setNewTypeName('');
    setNewTypeDescription('');
    setNewTypePrice('');
    setNewTypeDuration('');
  };

  const openEditModal = (subscription) => {
    setEditingSubscription({
      id: subscription._id,
      typeName: subscription.typeName,
      description: subscription.description,
      price: subscription.price,
      duration: subscription.duration,
    });
    setIsModalOpen(true);
  };

  const saveChanges = async () => {
    await editSubscriptionType(editingSubscription.id, {
      typeName: editingSubscription.typeName,
      description: editingSubscription.description,
      price: editingSubscription.price,
      duration: editingSubscription.duration,
    });
    setIsModalOpen(false);
  };

  if (loading) {
    return <p>Загрузка...</p>;
  }

  // ... JSX ...
  return (
    <div>
      <h1>Управление типами подписок</h1>
      <div>
        <input
          type='text'
          placeholder='Название типа подписки'
          value={newTypeName}
          onChange={(e) => setNewTypeName(e.target.value)}
        />
        <textarea
          placeholder='Описание'
          value={newTypeDescription}
          onChange={(e) => setNewTypeDescription(e.target.value)}
        />
        <input
          type='number'
          placeholder='Цена'
          value={newTypePrice}
          onChange={(e) => setNewTypePrice(e.target.value)}
        />
        <input
          type='number'
          placeholder='Продолжительность (дни)'
          value={newTypeDuration}
          onChange={(e) => setNewTypeDuration(e.target.value)}
        />
        <button onClick={handleAddNewType}>Добавить тип подписки</button>
      </div>
      <ul>
        {subscriptionTypes.map((type) => (
          <li key={type._id}>
            {type.typeName} - {type.price} - {type.duration} дней
            <button onClick={() => openEditModal(type)}>Редактировать</button>
            <button onClick={() => deleteSubscriptionType(type._id)}>
              Удалить
            </button>
          </li>
        ))}
      </ul>

      {isModalOpen && (
        <div className='modal'>
          <div className='modal-content'>
            <h2>Редактировать подписку</h2>
            <input
              type='text'
              value={editingSubscription.typeName}
              onChange={(e) =>
                setEditingSubscription({
                  ...editingSubscription,
                  typeName: e.target.value,
                })
              }
              placeholder='Название типа подписки'
            />
            <textarea
              value={editingSubscription.description}
              onChange={(e) =>
                setEditingSubscription({
                  ...editingSubscription,
                  description: e.target.value,
                })
              }
              placeholder='Описание'
            />
            <input
              type='number'
              value={editingSubscription.price}
              onChange={(e) =>
                setEditingSubscription({
                  ...editingSubscription,
                  price: e.target.value,
                })
              }
              placeholder='Цена'
            />
            <input
              type='number'
              value={editingSubscription.duration}
              onChange={(e) =>
                setEditingSubscription({
                  ...editingSubscription,
                  duration: e.target.value,
                })
              }
              placeholder='Продолжительность (дни)'
            />
            <button onClick={saveChanges}>Сохранить изменения</button>
            <button onClick={() => setIsModalOpen(false)}>Отмена</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CRUDSubscriptionsAdmin;