// src/pages/ClientPage.js
import React, { useState, useEffect } from 'react';
import axios from '../axios';
import { useNavigate } from 'react-router-dom';
import LanguageSwitcher from '../components/LanguageSwitcher';
import { useTranslation } from 'react-i18next';

import { tokenService } from '../services/tokenService';

// import profileIcon from './img/1.jpg';
// import houseIcon from './img/3.jpg';
// import subscriptionIcon from './img/5.jpg';

import {
  ChangePasswordModal,
  EditProfileModal,
  LogoutModal,
  UserProfileBlock,
  PropertyBlock,
  ActiveSubscriptionsBlock,
  AvailableSubscriptionsBlock,
  HeaderBlock,
  FooterBlock,
} from '../features';
import styles from './ClientPage.module.scss';

function ClientPage() {
  const { t, i18n } = useTranslation();

  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');

  const [userPhone, setUserPhone] = useState('');
  const [userMessenger, setUserMessenger] = useState('');
  const [houses, setHouses] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionTypes, setSubscriptionTypes] = useState([]);

  const [isEditing, setIsEditing] = useState(false);
  const [editableName, setEditableName] = useState('');
  const [editableEmail, setEditableEmail] = useState('');
  const [editablePhone, setEditablePhone] = useState('');
  const [editableMessenger, setEditableMessenger] = useState('');
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [selectedMessenger, setSelectedMessenger] = useState('');

  const [selectedMessengerType, setSelectedMessengerType] = useState('');
  const [subscriptionUsage, setSubscriptionUsage] = useState([]);

  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const openLogoutModal = () => setIsLogoutModalOpen(true);
  const closeLogoutModal = () => setIsLogoutModalOpen(false);

  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const [error, setError] = useState('');
  const [isProfileVisible, setIsProfileVisible] = useState(false);

  const toggleProfileVisibility = () => {
    setIsProfileVisible(!isProfileVisible);
  };

  const navigate = useNavigate();

  useEffect(() => {
    // Платежный шлюз
    if (window.Omise) {
      window.Omise.setPublicKey('pkey_test_5yzdtnfb9xoxb0iwr9j');
    } else {
      console.error('Omise не загружен');
    }

    const fetchData = async () => {
      const userId = await decodeToken();
      if (!userId) return;

      setUserId(userId);
      await loadUserProfile(userId);
      await loadUserHouses(userId);
      await loadSubscriptionTypes();
      await loadUserSubscriptions();
      await loadSubscriptionUsage(userId);
    };

    fetchData();
    loadSubscriptionUsage();
  }, []);

  const decodeToken = async () => {
    console.log('decodeToken: Начало');
    try {
      const token = tokenService.getToken();
      if (!token) {
        console.error('Token not found');
        //navigate('/client-register');

        return null;
      }

      const base64Url = token.split('.')[1];
      if (!base64Url) {
        console.error('Invalid token format');
        return null;
      }

      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );

      const decoded = JSON.parse(jsonPayload);
      if (!decoded || !decoded._id) {
        console.error('Invalid token: no _id found');
        return null;
      }

      console.log('decodeToken: Конец, userId:', decoded._id);
      return decoded._id;
    } catch (error) {
      console.error('Ошибка при декодировании токена:', error);
      //navigate('/client-register');

      return null;
    }
  };

  const loadUserProfile = async (userId) => {
    if (!userId) {
      console.error('User ID is required to load profile.');
      return;
    }

    try {
      const token = tokenService.getToken();
      const response = await axios.get(
        `https://fix.house/api/users/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const userData = response.data;
      setUserName(userData.name);
      setUserEmail(userData.email);
      setUserPhone(userData.phone);
      setUserMessenger(userData.messenger);
      setSelectedMessengerType(userData.messengerType);

      i18n.changeLanguage(userData.preferredLanguage || 'en');
    } catch (error) {
      console.error('Error loading user profile:', error);
      throw error;
    }
  };

  const loadSubscriptionUsage = async (userIdFromParam) => {
    const userIdEffective = userIdFromParam || userId; // Используем переданный параметр или состояние
    if (!userIdEffective) {
      console.error('User ID is required to load subscription usage.');
      return;
    }

    try {
      const token = tokenService.getToken();
      const response = await axios.get(
        `https://fix.house/api/subscription-usage/user/${userIdEffective}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data) {
        setSubscriptionUsage(response.data);
      }
    } catch (error) {
      console.error('Error loading subscription usage:', error);
      throw error;
    }
  };

  const handleUpdateProfile = async () => {
    const updatedUserData = {};
    if (editableName && editableName !== userName)
      updatedUserData.name = editableName;
    if (editableEmail && editableEmail !== userEmail)
      updatedUserData.email = editableEmail;
    if (editablePhone !== userPhone)
      updatedUserData.phone = editablePhone || '';
    if (editableMessenger !== userMessenger)
      updatedUserData.messenger = editableMessenger || '';
    if (selectedMessenger !== selectedMessengerType)
      updatedUserData.messengerType = selectedMessenger || '';

    if (Object.keys(updatedUserData).length === 0) {
      console.log('Нет изменений для обновления');
      return; // Выход, если нет изменений
    }

    console.log('Отправляемые данные для обновления:', updatedUserData);

    try {
      const token = tokenService.getToken();
      const response = await axios.put(
        `https://fix.house/api/users/${userId}`,
        updatedUserData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        if ('name' in updatedUserData) setUserName(editableName);
        if ('email' in updatedUserData) setUserEmail(editableEmail);
        if ('phone' in updatedUserData) setUserPhone(editablePhone);
        if ('messenger' in updatedUserData) setUserMessenger(editableMessenger);
        if ('messengerType' in updatedUserData)
          setSelectedMessengerType(selectedMessenger);

        closeEditModal();
        setError('');
      }
    } catch (error) {
      console.error('Ошибка при обновлении профиля:', error);
      setError(
        'Произошла ошибка при обновлении профиля. Пожалуйста, попробуйте снова.'
      );
    }
  };

  const formatCoordinates = (coordinatesObject) => {
    if (coordinatesObject && coordinatesObject.coordinates) {
      return `Широта: ${coordinatesObject.coordinates[1]}, Долгота: ${coordinatesObject.coordinates[0]}`;
    }
    return 'Координаты не указаны';
  };

  const loadUserHouses = async (userId) => {
    console.log('Loading houses for userId:', userId);

    if (!userId) return;

    try {
      const response = await axios.get(
        `https://fix.house/api/client/houses/${userId}/houses`
      );
      if (response.status === 401 || response.status === 403) {
        // navigate('/client-register');
        return;
      }
      /////////
      setHouses(response.data);
    } catch (error) {
      console.error('Ошибка при загрузке домов пользователя:', error);
    }
  };

  const loadSubscriptionTypes = async () => {
    try {
      const token = tokenService.getToken();
      if (!token) {
        console.error('Аутентификационный токен не найден');
        return;
      }

      // Получаем текущий язык интерфейса непосредственно внутри функции
      const currentLanguage = i18n.language;
      console.log(`Загрузка типов подписок для языка: ${currentLanguage}`);

      const response = await axios.get(
        `https://fix.house/api/subscription-types?language=${currentLanguage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log('Полученные данные о типах подписок:', response.data);

      setSubscriptionTypes(response.data);
    } catch (error) {
      console.error('Ошибка при загрузке типов подписок:', error);
    }
  };

  useEffect(() => {
    loadSubscriptionTypes();
  }, [i18n.language]);

  const loadUserSubscriptions = async () => {
    try {
      const token = tokenService.getToken();
      if (!token) {
        console.error('Аутентификационный токен не найден');
        return;
      }

      const currentLanguage = i18n.language;
      const response = await axios.get(
        `https://fix.house/api/subscriptions?language=${currentLanguage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSubscriptions(response.data);
    } catch (error) {
      console.error('Ошибка при загрузке подписок пользователя:', error);
    }
  };

  useEffect(() => {
    loadUserSubscriptions();
  }, [i18n.language]);

  const handleSubscriptionPurchase = async (subscriptionType, paymentMethod) => {
  if (paymentMethod === 'card') {
    navigate('/payment', { state: { userId, subscriptionType, paymentMethod } });
  } else {
    try {
      const token = tokenService.getToken();
      const response = await axios.post(
        'https://fix.house/api/subscriptions/create-subscription',
        {
          subscriptionTypeID: subscriptionType._id,
          userID: userId,
          amount: subscriptionType.price,
          paymentMethod: paymentMethod
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 201) {
        console.log('Подписка успешно создана');
        await loadUserSubscriptions();
        await loadSubscriptionUsage(userId);
        // Можно добавить уведомление пользователю
        // setNotification('Подписка успешно активирована');
      } else {
        console.error('Подписка не была создана');
        setError('Произошла ошибка при активации подписки');
      }
    } catch (error) {
      console.error('Ошибка при создании подписки:', error);
      setError('Внутренняя ошибка сервера');
    }
  }
};

  const updateSubscriptionUsage = async (plannedUsed, urgentUsed) => {
    try {
      const token = tokenService.getToken();
      const response = await axios.post(
        'https://fix.house/api/subscription-usage/update-usage',
        {
          userId,
          subscriptionTypeId: plannedUsed,
          urgentUsed,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log('Использование подписки обновлено');
      }
    } catch (error) {
      console.error('Ошибка при обновлении использования подписки:', error);
    }
  };

  const getImageUrl = (imagePath) => {
    return imagePath
      ? `https://fix.house/${imagePath}`
      : '/path/to/default/image.jpg';
  };

  const editProfile = () => {
    setIsEditing(true);

    setSuccessMessage('');

    setEditableName(userName || '');
    setEditableEmail(userEmail || '');
    setEditablePhone(userPhone || '');
    setEditableMessenger(userMessenger || '');
    setSelectedMessenger(selectedMessengerType || '');
  };

  const closeEditModal = () => {
    setIsEditing(false);
  };

  const openChangePasswordModal = () => {
    setIsChangingPassword(true);
  };

  const closeChangePasswordModal = () => {
    setIsChangingPassword(false);
  };

  const handleChangePassword = async () => {
    if (!newPassword || !confirmNewPassword) {
      setError('Пароль не может быть пустым');
      return;
    }

    if (newPassword.length < 6 || confirmNewPassword.length < 6) {
      setError('Пароль не может быть короче 6 символов');
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setError('Пароли не совпадают');
      return;
    }

    try {
      const token = tokenService.getToken();
      await axios.put(
        `https://fix.house/api/users/${userId}/change-password`,
        { newPassword },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setError('');
      setSuccessMessage(t('success_password_change'));
      closeChangePasswordModal();
    } catch (error) {
      console.error('Ошибка при изменении пароля:', error);
      setError(t('error_password_change'));
    }
  };

  const addHouse = () => {
    navigate('/add-house');
  };

  const manageHouse = (houseId) => {
    navigate(`/house/${houseId}`);
  };

  const createRepairRequest = () => {};

  const addSubscription = () => {};

  const handleLogout = () => {
    tokenService.removeToken();
    navigate('/client-register');
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  let plannedCallsLimitTotal = 0;
  let plannedCallsUsedTotal = 0;
  let urgentCallsLimitTotal = 0;
  let urgentCallsUsedTotal = 0;
  let remainingPlannedCallsTotal = 0;
  let remainingUrgentCallsTotal = 0;

  subscriptionUsage.forEach((usage) => {
    plannedCallsLimitTotal += usage.plannedCallsLimit;
    plannedCallsUsedTotal += usage.plannedCallsUsed;
    urgentCallsLimitTotal += usage.urgentCallsLimit;
    urgentCallsUsedTotal += usage.urgentCallsUsed;
    remainingPlannedCallsTotal += usage.remainingPlannedCalls || 0;
    remainingUrgentCallsTotal += usage.remainingUrgentCalls || 0;
  });

  // JSX

  return (
    <>
      <HeaderBlock
        isLoggedIn
        userName={userName}
        handleUserClick={toggleProfileVisibility}
      />
      <br></br>
      <LanguageSwitcher />
      <main className={styles.clientPage}>
        <UserProfileBlock
          className={styles.profileBlock}
          editProfile={editProfile}
          userName={userName}
          userEmail={userEmail}
          userPhone={userPhone}
          selectedMessengerType={selectedMessengerType}
          userMessenger={userMessenger}
          subscriptionsNumber={subscriptions.length}
          plannedCallsUsedTotal={plannedCallsUsedTotal}
          plannedCallsLimitTotal={plannedCallsLimitTotal}
          urgentCallsUsedTotal={urgentCallsUsedTotal}
          urgentCallsLimitTotal={urgentCallsLimitTotal}
          remainingPlannedCallsTotal={remainingPlannedCallsTotal}
          remainingUrgentCallsTotal={remainingUrgentCallsTotal}
          toggleProfileVisibility={toggleProfileVisibility}
          isProfileVisible={isProfileVisible}
        />

        <PropertyBlock
          className={styles.propertyBlock}
          houses={houses}
          getImageUrl={getImageUrl}
          addHouse={addHouse}
          manageHouse={manageHouse}
        />

        <ActiveSubscriptionsBlock
          className={styles.activeSubscriptionsBlock}
          subscriptions={subscriptions}
        />

        <AvailableSubscriptionsBlock
          className={styles.availableSubscriptionsBlock}
          subscriptionTypes={subscriptionTypes}
          handleSubscriptionPurchase={handleSubscriptionPurchase}
        />
      </main>

      <FooterBlock />

      {isEditing && !isLogoutModalOpen && !isChangingPassword && (
        <EditProfileModal
          closeModal={closeEditModal}
          editableName={editableName}
          setEditableName={setEditableName}
          editableEmail={editableEmail}
          setEditableEmail={setEditableEmail}
          editablePhone={editablePhone}
          setEditablePhone={setEditablePhone}
          selectedMessenger={selectedMessenger}
          setSelectedMessenger={setSelectedMessenger}
          editableMessenger={editableMessenger}
          setEditableMessenger={setEditableMessenger}
          openChangePasswordModal={openChangePasswordModal}
          openLogoutModal={openLogoutModal}
          handleUpdateProfile={handleUpdateProfile}
          successMessage={successMessage}
        />
      )}

      {isChangingPassword && (
        <ChangePasswordModal
          closeModal={closeChangePasswordModal}
          newPassword={newPassword}
          setNewPassword={setNewPassword}
          confirmNewPassword={confirmNewPassword}
          setConfirmNewPassword={setConfirmNewPassword}
          handleChangePassword={handleChangePassword}
          error={error}
        />
      )}

      {isLogoutModalOpen && (
        <LogoutModal
          handleLogout={handleLogout}
          closeModal={closeLogoutModal}
        />
      )}
    </>
  );
}

export default ClientPage;
