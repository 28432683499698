import React from 'react';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { desktopQuery } from '../../consts';
import LanguageSwitcher from '../../components/LanguageSwitcher';
import { Flex, Text, Container, IconButton } from '../../ui';
import styles from './HomePagePromoBlock.module.scss';
import { MenuCloseIcon, MenuOpenIcon } from '../../static';

export const HomePagePromoBlock = (props) => {
  const { className, isAuthenticated, ...restProps } = props;
  const isDesktop = useMediaQuery(desktopQuery);
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = React.useState(false);

  return (
    <section className={cn(styles.promo, className)} {...restProps}>
      <Container className={styles.fullHeight}>
        <Flex
          direction='column'
          gap={40}
          justify='between'
          className={styles.fullHeight}
        >
          <Flex
            justify='between'
            gap={40}
            align='baseline'
            className={styles.promoHeader}
          >
            <Text color='white' size={32} lineHeight={1} weight='medium'>
              {t('fix_house')}
            </Text>

            {!isDesktop && (
              <IconButton
                className={styles.promoMenuBtn}
                onClick={() => setMenuOpen((open) => !open)}
              >
                {menuOpen ? (
                  <MenuCloseIcon width={32} height={32} />
                ) : (
                  <MenuOpenIcon width={32} height={32} />
                )}
              </IconButton>
            )}

            <Flex
              className={cn(styles.promoMenu, {
                [styles.promoMenuOpen]: menuOpen,
              })}
              direction={isDesktop ? 'row' : 'column'}
              gap={40}
              align='baseline'
            >
              <nav className={styles.promoNav}>
                <Flex
                  tag='ul'
                  gap={40}
                  direction={isDesktop ? 'row' : 'column'}
                >
                  <li>
                    <Text
                      color='white'
                      weight='medium'
                      className={styles.clickable}
                      onClick={() => {
                        window.scrollTo({
                          top: window['pricing']?.getBoundingClientRect().top,
                          behavior: 'smooth',
                        });
                      }}
                    >
                      {t('pricing')}
                    </Text>
                  </li>

                  <li>
                    <Text
                      color='white'
                      weight='medium'
                      className={styles.clickable}
                      onClick={() => {
                        window.scrollTo({
                          top: window['guide']?.getBoundingClientRect().top,
                          behavior: 'smooth',
                        });
                      }}
                    >
                      {t('about_us')}
                    </Text>
                  </li>

                  <li>
                    <Text
                      color='white'
                      weight='medium'
                      className={styles.clickable}
                      onClick={() => {
                        window.scrollTo({
                          top: window['features']?.getBoundingClientRect().top,
                          behavior: 'smooth',
                        });
                      }}
                    >
                      {t('features')}
                    </Text>
                  </li>

                  <li>
                    <Link to='#'>
                      <Text color='white' weight='medium'>
                        {t('get_the_app')}
                      </Text>
                    </Link>
                  </li>

                  {!isAuthenticated && (
                    <li>
                      <Link to='/client-register'>
                        <Text color='white' weight='medium'>
                          {t('log_in')}
                        </Text>
                      </Link>
                    </li>
                  )}
                </Flex>
              </nav>

              <Link
                to={isAuthenticated ? '/client-page' : '/client-register'}
                className={styles.promoHeaderBtn}
              >
                <Text color='white' weight='medium'>
                  {isAuthenticated ? t('my_account') : t('sign_up')}
                </Text>
              </Link>

              <LanguageSwitcher />
            </Flex>
          </Flex>

          <Flex direction='column' gap={60}>
            <Text
              tag='h1'
              size={isDesktop ? 72 : 50}
              color='white'
              weight='medium'
              className={styles.promoTitle}
            >
              {t('welcome_subtitle')}
            </Text>

            <Link
              to={isAuthenticated ? '/client-page' : '/client-register'}
              className={cn(styles.promoBtn, styles.flexStart)}
            >
              <Text color='white' weight='medium' size={24}>
                {isAuthenticated ? t('my_account') : t('join_now')}
              </Text>
            </Link>
          </Flex>

          <div className={styles.promoFooter} />
        </Flex>
      </Container>
    </section>
  );
};
