import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FooterBlock, HeaderBlock } from '../features';
import styles from './SuccessPayment.module.css';
import { tokenService } from '../services/tokenService';

const SuccessPayment = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const redirectTimer = setTimeout(() => {
      navigate('/client-page');
    }, 2000);

    return () => clearTimeout(redirectTimer);
  }, [navigate]);

  const handleLogout = () => {
    tokenService.removeToken();
    navigate('/client-register');
  };

  return (
    <div>
      <HeaderBlock handleLogout={handleLogout} />
      <div className={styles.container}>
        <div className={styles.blockContent}>
          <h1>{t('success_payment_title')}!</h1>
          <p>{t('success_payment_description')}</p>
        </div>
      </div>
      <FooterBlock />
    </div>
  );
};

export default SuccessPayment;
