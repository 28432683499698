import { useTranslation } from 'react-i18next';
import { Modal, Flex, Text, Button } from '../../ui';
import styles from './BuyModal.module.scss';

export const BuyModal = (props) => {
  const { handleConfirm, closeModal, subscription } = props;
  const { t } = useTranslation();

  return (
    <Modal className={styles.Modal} closeModal={closeModal}>
      <Flex direction='column' gap={30}>
        <Text
          size={20}
          lineHeight='25px'
          align='center'
          className={styles.logoutModalHeader}
        >
          {t('buy_confirm_text')}
        </Text>
        <Flex justify='center' gap={20}>
          <Button
            type='black'
            onClick={() => {
              handleConfirm(subscription);
              closeModal();
            }}
            className={styles.logoutModalButton}
          >
            {t('yes')}
          </Button>
          <Button
            type='black'
            onClick={closeModal}
            className={styles.logoutModalButton}
          >
            {t('no')}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
